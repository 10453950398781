import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
// eslint-disable-next-line no-unused-vars
import { IntegrationSystem } from '@biteinc/enums';

app.VendorList = app.AbstractCollection.extend({
  model: app.Vendor,

  getDefaultVendor() {
    return _.find(this.models, (vendor) => {
      return vendor.get('isDefault');
    });
  },

  /**
   * @returns {app.Vendor} The default vendor if the user has access to it, otherwise the first
   *     vendor in the list of vendors that the user has access to
   */
  getDefaultVendorOrFallback() {
    return this.getDefaultVendor() ?? this.models[0];
  },

  getDefaultNonintegratedVendor() {
    const integratedVendorIds = this.getIntegratedVendorIdsSet();
    const defaultVendor = this.getDefaultVendor();

    if (defaultVendor && !integratedVendorIds.has(defaultVendor.id.toString())) {
      return defaultVendor;
    }

    return this.getNonintegratedVendors().at(0);
  },

  url() {
    return app.apiPathForLocation('/vendors');
  },

  getIntegratedVendorIdsSet() {
    return new Set(
      app.integrationList.models
        .filter((integration) => {
          /** @type IntegrationSystem */
          const system = integration.get('system');
          return I9nSchemaBySystem[system].type === 'pos';
        })
        .map((integration) => {
          return integration.get('vendorId').toString();
        }),
    );
  },

  getIntegratedVendors() {
    const integratedVendorIds = this.getIntegratedVendorIdsSet();

    return this.models.filter((vendor) => {
      return integratedVendorIds.has(vendor.id.toString());
    });
  },

  getNonintegratedVendors() {
    const integratedVendorIds = this.getIntegratedVendorIdsSet();

    return this.models.filter((vendor) => {
      return !integratedVendorIds.has(vendor.id.toString());
    });
  },

  isEveryVendorIntegrated() {
    return this.getIntegratedVendors().length === this.models.length;
  },

  isSomeVendorNonintegrated() {
    return !this.isEveryVendorIntegrated();
  },

  buildNonintegratedVendorsList() {
    const selectableVendors = this.clone();
    const integratedVendors = selectableVendors.getIntegratedVendors();
    selectableVendors.remove(integratedVendors);
    return selectableVendors;
  },

  buildSingletonVendorList(vendorId) {
    return new app.VendorList(app.vendorList.where({ _id: vendorId }));
  },

  getVendorsThatDoNotSyncModelsWithType(modelType) {
    return this.models.filter((vendor) => {
      return !vendor.syncsModelsWithType(modelType);
    });
  },

  buildListOfVendorsThatDoNotSyncModelsWithType(modelType) {
    return new app.VendorList(this.getVendorsThatDoNotSyncModelsWithType(modelType));
  },
});
