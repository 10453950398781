import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';
import { orderLeadTimeRuleSchema } from '@biteinc/schemas';

const { fields: OrderLeadTimeSchemaFields } = orderLeadTimeRuleSchema;

app.OrderLeadTimeRuleView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} order-lead-time-rule-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
          menuSectionIds: [],
        };
      },
      getTitle: (fieldGroupView) => {
        const { menuSectionIds } = fieldGroupView.getValue();
        const menuSectionNames = [];
        menuSectionIds.forEach((menuSectionId) => {
          if (menuSectionId && app.menuSectionList.get(menuSectionId)) {
            menuSectionNames.push(app.menuSectionList.get(menuSectionId).displayName());
          }
        });
        return menuSectionNames.join(', ');
      },
      getElementSchemaFields: (/* element */) => {
        return OrderLeadTimeSchemaFields;
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new lead time rule for menu section',
        emptyText: 'no order lead time rules yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
