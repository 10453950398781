import { ModelType, TaxRateType } from '@biteinc/enums';
import { taxProfileSchema } from '@biteinc/schemas';

app.TaxProfile = app.AbstractModel.extend({
  ModelName: 'taxProfile',
  Schema: taxProfileSchema,
  Type: ModelType.TaxProfile,

  canBeDestroyed() {
    if (app.taxProfileList.size() < 2) {
      return false;
    }
    return app.AbstractModel.prototype.canBeDestroyed.apply(this);
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: TaxRateType,
          nameGenerator: (taxRateType) => {
            switch (taxRateType) {
              case TaxRateType.Percent:
                return 'Percent (%)';
              case TaxRateType.FixedUnimplemented:
                return 'Fixed (Unimplemented)';
            }
          },
        });
    }
    return null;
  },
});
