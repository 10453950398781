app.RecommendationVariationDetailsView = app.BaseDetailsView.extend({
  getSchema() {
    const schema = app.BaseDetailsView.prototype.getSchema.apply(this);

    const schemaFields = {
      ...schema.fields,
    };

    return {
      ...schema,
      fields: schemaFields,
    };
  },

  _didSaveModel() {
    // refetch the rules list because we would have just made some new default rules on the server
    app.recommendationRuleList.fetch({ reset: true });

    // Listen for the rules to be loaded so we can render the rules select list
    this.listenToOnce(app.recommendationRuleList, 'reset', (/* e */) => {
      this.render();
    });
  },
});
