import Backbone from 'backbone';
import _ from 'underscore';

import { Time } from '@biteinc/helpers';

app.ExtendBackboneSync = function ExtendBackboneSync() {
  const oldSync = Backbone.sync;
  Backbone.sync = function sync(method, model, opts) {
    const options = _.extend(
      {
        showErrorModal: true,
        timeout: Time.SECOND * 30,
      },
      opts || {},
    );

    if (!options.noWait) {
      options.wait = true;
    }
    const errorHandler = options.error;
    const successHandler = options.success;
    options.error = function onError(response) {
      let requestMethod = 'UNKNOWN_HTTP';
      switch (method) {
        case 'create':
          requestMethod = 'POST';
          break;
        case 'delete':
          requestMethod = 'DELETE';
          break;
        case 'read':
          requestMethod = 'GET';
          break;
        case 'update':
          requestMethod = 'PUT';
          break;
      }
      app.handleJQueryErrorResponse({
        requestMethod,
        requestUrl: model.url(),
        response,
        showErrorModal: options.showErrorModal,
        errorHandler,
      });
    };
    options.success = function onSuccess(result) {
      if (_.size((result.data || {}).warnings)) {
        new app.AlertView().showWarnings(result.data.warnings);
      }
      if (successHandler) {
        successHandler.apply(null, arguments);
      }
    };
    oldSync.apply(Backbone, [method, model, options]);
  };
};
