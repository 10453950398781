app.KioskStatusList = app.AbstractCollection.extend({
  model: app.KioskStatus,

  comparator(model) {
    return (model.get('lastStatusReceivedAt') || model.get('updatedAt')) * -1;
  },

  url() {
    return app.apiPathForLocation('/status');
  },
});
