import _ from 'underscore';

app.BrinkDiscountMapByLoyaltyIdView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} virtual-sub-group-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          loyaltyRewardId: '',
          posDiscountId: '',
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        const fields = fieldGroupView.getValue();
        const { loyaltyRewardId, posDiscountId } = fields || {};

        let title = '';

        if (posDiscountId) {
          const discountById = app.location.getFullPosI9n().getSyncedProp('discountById');
          const discount = discountById[posDiscountId];
          title = discount ? `${discount.posName} [${posDiscountId}]` : '';
        }

        if (posDiscountId && loyaltyRewardId) {
          title += ' - ';
        }

        if (loyaltyRewardId) {
          const loyaltyById = app.location.getFullLoyaltyI9n().getSyncedProp('discountById');
          const loyalty = loyaltyById[loyaltyRewardId];
          title += loyalty ? `${loyalty.name} [${loyaltyRewardId}]` : '';
        }

        if (!title) {
          title = 'New mapping';
        }

        return title;
      },
      templateData: {
        title: 'Loyalty Reward - POS Discount Mapping',
        buttonText: 'New mapping',
        emptyText: 'No mappings yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
