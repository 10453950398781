app.BuildList = app.AbstractCollection.extend({
  model: app.Build,

  initialize(models, options) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);
    if (!options?.buildType) {
      throw new Error('Missing required param buildType');
    }
    this.buildType = options.buildType;
  },

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  url() {
    return `/api/v2/builds/${this.buildType}`;
  },
});
