import _ from 'underscore';

app.OrgList = app.AbstractCollection.extend({
  model: app.Org,

  initialize() {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    this.on('reset', this._rebuildLocationIndex);
    this.on('add', this._rebuildLocationIndex);
    this.on('remove', this._rebuildLocationIndex);
    this._rebuildLocationIndex();
  },

  _rebuildLocationIndex() {
    const self = this;
    this._locationById = {};
    _.each(this.models, (org) => {
      _.each(org.locations, (location) => {
        self._locationById[location.id] = location;
      });
    });
  },

  getLocationById(locationId) {
    return this._locationById[locationId];
  },

  getAllLocations() {
    return Object.values(this._locationById);
  },

  getAllLiveLocations() {
    return this.getAllLocations().filter((location) => {
      return location.isLive();
    });
  },

  url() {
    return '/api/orgs';
  },
});
