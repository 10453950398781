import _ from 'underscore';

app.FreshKdsOnPremDevicesView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} virtual-sub-group-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          deviceName: '',
          deviceIpAddress: '',
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        const fields = fieldGroupView.getValue();
        return fields?.deviceName;
      },
      templateData: {
        title: 'Fresh KDS On-Prem Devices',
        buttonText: 'New Device',
        emptyText: 'No devices yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
