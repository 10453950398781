import _ from 'underscore';

import {
  MenuSectionDesignation,
  MenuSectionHeaderType,
  MenuSectionPriceDisplay,
  ModelType,
} from '@biteinc/enums';
import { menuSectionSchema } from '@biteinc/schemas';

app.MenuSection = app.MenuItemArray.extend({
  ModelName: 'menuSection',
  Schema: menuSectionSchema,
  Type: ModelType.MenuSection,

  defaults() {
    const defaults = {
      designation: MenuSectionDesignation.Regular,
      showAllPhotoPreviews: true,
      priceDisplay: MenuSectionPriceDisplay.Default,
      items: [],
    };
    if (app.vendorList && app.vendorList.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = (
        app.vendorList.isEveryVendorIntegrated()
          ? app.vendorList.getDefaultVendorOrFallback()
          : app.vendorList.getDefaultNonintegratedVendor()
      ).id;
    }
    return defaults;
  },

  displayName() {
    const name = app.MenuItemArray.prototype.displayName.apply(this, arguments);
    if (app.location.supportsMultipleVendors()) {
      const vendor = this.getFullVendor();
      return `${name}  (${vendor.displayName()})`;
    }
    return name;
  },

  // override menuItemArray super method
  updateItemEventListeners() {},

  displayNameHtml() {
    const title = `<span>${this.displayName()}</span>`;
    if (this.isMissingImages()) {
      return `${title}&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>`;
    }
    return title;
  },

  isMissingImages() {
    return !this.hasArr('images');
  },

  getVisibleInPlaces() {
    const places = [];
    app.menuStructureList.each((structure) => {
      const paths = structure.pathsToSectionWithId(this.id);
      places.push(...paths);
    });
    return places;
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'vendorId':
        if (this.getFullVendor().syncsModelsWithType(this.Type)) {
          // If the section is synced, then we only need the vendor of the section
          return app.vendorList.buildSingletonVendorList(this.get('vendorId'));
        }

        // If the section is unsynced, then we need the list of vendors that don't sync sections
        return app.vendorList.buildListOfVendorsThatDoNotSyncModelsWithType(this.Type);
      case 'designation':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuSectionDesignation,
          nameGenerator: (designation) => {
            switch (designation) {
              case MenuSectionDesignation.Regular:
                return 'Regular Section';
              case MenuSectionDesignation.RecommendationPane:
                return 'Recommendation Pane';
              case MenuSectionDesignation.OrderHistory:
                return 'Order History';
            }
          },
        });
      case 'activeTimings':
        return app.menuTimingList;
      case 'fulfillmentStationIds': {
        return app.AbstractCollection.createFromEnum({
          schema: app.site.get('fulfillmentStations').filter((station) => station.isEnabled),
          sort: app.AbstractCollection.SortOptions.NAME,
        });
      }
      case 'priceDisplay':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuSectionPriceDisplay,
          nameGenerator: (priceDisplay) => {
            switch (priceDisplay) {
              case MenuSectionPriceDisplay.Default:
                return 'Show prices';
              case MenuSectionPriceDisplay.HidePriceOptions:
                return 'Hide price options';
              default:
                throw new Error(`unrecognized priceDisplay value: ${priceDisplay}`);
            }
          },
        });
      case 'headerType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuSectionHeaderType,
          nameGenerator: (headerType) => {
            switch (headerType) {
              case MenuSectionHeaderType.Text:
                return 'Text';
              case MenuSectionHeaderType.Image:
                return 'Image (only)';
              case MenuSectionHeaderType.ImageAndText:
                return 'Image and Text';
              default:
                throw new Error(`unrecognized headerType value: ${headerType}`);
            }
          },
        });
    }
    return null;
  },

  fieldIsPermanent(field /* subProperty */) {
    if (app.AbstractModel.prototype.fieldIsPermanent.apply(this, arguments)) {
      return true;
    }

    if (field === 'vendorId' && this.getFullVendor().syncsModelsWithType(this.Type)) {
      return true;
    }

    return false;
  },

  fieldIsHidden(field /* subProperty */) {
    if (app.AbstractModel.prototype.fieldIsHidden.apply(this, arguments)) {
      return true;
    }

    if (field === 'vendorId' && app.vendorList.isEveryVendorIntegrated()) {
      return true;
    }

    return false;
  },

  getListFieldElementAttributesFromModel(field, element) {
    return { _id: element.id };
  },

  subtitleForListFieldElement(field, element) {
    switch (field) {
      case 'items':
        return this.getItemDescription(element.id);
    }
    return '';
  },

  hasVisibleMenuItem(menuItem) {
    if (menuItem.isMod()) {
      return _.any(this.get('items') || [], (itemRef) => {
        const item = app.menuItemList.get(itemRef._id);
        return item.hasModWithId(menuItem.id);
      });
    }
    return _.contains(_.pluck(this.get('items'), '_id'), menuItem.id);
  },

  canBePrecisionCopied() {
    return true;
  },
});
