// eslint-disable-next-line no-unused-vars
import { I9nSchemaPos } from '@biteinc/common';
// eslint-disable-next-line no-unused-vars
import { IntegrationSystem } from '@biteinc/enums';

app.QuicksandTabView = app.TabView.extend({
  initialize() {
    app.TabView.prototype.initialize.apply(this, arguments);

    app.recordList = new app.QuicksandRecordList();

    /** @type I9nSchemaPos<IntegrationSystem.Pos> */
    const i9nSchema = app.location.getFullPosI9n().getI9nSchema();
    const { syncNotes, scheduledSyncIntervalHours } = i9nSchema;
    let subtitle = scheduledSyncIntervalHours
      ? `POS is synced every ${scheduledSyncIntervalHours} hours (${i9nSchema.name} restriction).`
      : 'POS is synced every 10 mins during work hours.';
    if (syncNotes) {
      subtitle += `\nNote: ${syncNotes}`;
    }
    const isSubtitleWarning = syncNotes || scheduledSyncIntervalHours;

    const recordListView = new app.QuicksandRecordListView({
      cellView: app.QuicksandRecordCellView,
      collection: app.recordList,
      subtitle,
      fetchOnInit: false,
      cellOptions: {
        clickable: false,
      },
      ...(isSubtitleWarning ? { subtitleWarning: subtitle } : { subtitle }),
    });
    this.setLeftView(recordListView);
  },
});
