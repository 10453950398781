import { CreateUserType } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

import { convertCsvLinesToCsvData } from '../../csv_builder';
import { TimeHelper } from '../../helpers/time_helper';
import { getCsvLinesFromUserList } from '../../user_list_csv_builder';

app.UserListView = app.BaseListView.extend({
  _createNew(type) {
    const detailsView = new app.NewUsersDetailsView({
      collection: this.collection,
      canAccessParentModel: this.canAccessParentModel,
      attributes: {
        type,
      },
    });
    this.showDetailsView(detailsView);
  },

  createNew() {
    // Bite-View
    this._createNew(CreateUserType.BiteAdminPortal);
  },

  // Reliably start the download and allows us to set the file name.
  _exportUserList() {
    getCsvLinesFromUserList(this.collection, (err, csvLines) => {
      if (err) {
        return;
      }

      const timestamp = TimeHelper.format(Date.now(), 'YYYY-MM-DD-HH-mm');
      const aLink = document.createElement('a');
      aLink.download = `${StringHelper.slug(app.org.get('name'))}-users-${timestamp}.csv`;
      aLink.href = convertCsvLinesToCsvData(csvLines);
      const event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    });
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    this.$el.addClass('user-list');

    // Show the CSV download button only on the org page.
    // We could show it on the location page as well, but that page doesn't have a list of location
    // groups so it would be hard to produce an identical report with the same location access.
    if (app.org && !app.site) {
      const $downloadButton = $(
        // prettier-ignore
        '<button type="button" class="btn btn-sm btn-primary download">' +
          'Download as CSV' +
        '</button>',
      );
      this.$('.card-header .right-button-container').prepend($downloadButton);
      $downloadButton.click(() => {
        this._exportUserList();
      });
    }

    if (this.options.canCreate && app.org) {
      const collection = app.AbstractCollection.createFromTsEnum({
        tsEnum: CreateUserType,
        nameGenerator: (value) => {
          switch (value) {
            case CreateUserType.InStore:
              return 'In-Store';
            case CreateUserType.BiteAdminPortal:
              return 'Bite Admin Portal';
          }
        },
      });

      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });

      // Replace the existing "create" button with the dropdown.
      this.$('.card-header .right-button-container .btn-primary.create').remove();
      this.$('.card-header .right-button-container').append(dropdownView.render().$el);

      dropdownView.setTitle(`<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new user`);
      dropdownView.setup(collection, {
        onAdd: (userType) => {
          this._createNew(userType.id);
        },
      });
    }

    return this;
  },
});
