import _ from 'underscore';

app.CompoundMenuItemListView = app.MenuItemListView.extend({
  _filteredModels() {
    return _.filter(this.collection.models, (menuItem) => {
      return menuItem.isCompoundItem();
    });
  },

  getListSections() {
    return [{ models: this._filteredModels() }];
  },
});
