app.TransactionList = app.AbstractCollection.extend({
  model: app.Transaction,

  comparator(model) {
    return model.get('startedAt') * -1;
  },

  getSchema() {
    return { displayName: 'transaction' };
  },
});
