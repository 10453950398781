import _ from 'underscore';

import { MathHelper } from '@biteinc/helpers';

import { template } from '../template';

app.SalesSummaryView = app.ModallableView.extend({
  // TODO: Clean up css classes.
  className: 'sales-summary-view report',
  template: template(
    // prettier-ignore
    '<div class="summary date-range">' +
      '<div><@= fromDay @></div>' +
      '<div><@= throughDay @></div>' +
    '</div>' +
    '<@= sectionsHtml @>',
  ),
  _sectionTemplate: template(
    '<div class="summary">' +
      '<div class="header"><@= header @></div>' +
      '<div class="body"><@= body @></div>' +
      '</div>',
  ),

  initialize(options) {
    options.title = 'Sales Summary Report';
    app.ModallableView.prototype.initialize.apply(this, arguments);

    this._options = options || {};
  },

  _getSummaryBody(data) {
    let html =
      `<div>Order Count: ${data.orderCount}</div>` +
      `<div>Total Sales (NET): $${MathHelper.displayPrice(data.totalSalesNet)}</div>` +
      `<div>Total Sales (GROSS): $${MathHelper.displayPrice(data.totalSalesGross)}</div>` +
      `<div>Total Amount Charged: $${MathHelper.displayPrice(data.totalAmountCharged)}</div>` +
      `<div>Average Order Check Value: $${MathHelper.displayPrice(data.averageOrderCheckValue)}</div>`;
    if (data.totalDiscounts) {
      html += `<div>Total Discounts: $${MathHelper.displayPrice(data.totalDiscounts)}</div>`;
    }
    if (data.refundCount) {
      html +=
        `<div>Refund Count: ${data.refundCount}</div>` +
        `<div>Total Refunds: $${MathHelper.displayPrice(data.totalRefunds)}</div>`;
    }
    return html;
  },

  _getTopItemsBody(data) {
    return _.chain(data.sortedItemStats)
      .first(3)
      .map((stat) => {
        return new app.MenuItem({ ...stat, priceOptions: [{ price: stat.price }] });
      })
      .reduce((html, item) => {
        return html + app.HtmlHelper.createItemElement(item);
      }, '')
      .value();
  },

  _getSectionBreakdownBody(data) {
    return _.reduce(
      data.sortedSectionStats,
      (html, stat) => {
        const salesStr = MathHelper.displayPrice(stat.sales ? stat.sales : 0);
        const count = stat.count ? stat.count : 0;
        return `${html}<div>${stat.name}: $${salesStr} (${count} item${
          count === 1 ? '' : 's'
        })</div>`;
      },
      '',
    );
  },

  _getStatsBody(stats) {
    return _.reduce(
      stats,
      (html, stat) => {
        let orderCountStr = `${stat.orderCount} order${stat.orderCount === 1 ? '' : 's'}`;
        if (stat.refundCount) {
          orderCountStr += `, ${stat.refundCount} refund${stat.refundCount === 1 ? '' : 's'}`;
        }
        const totalSalesGrossStr = MathHelper.displayPrice(stat.totalSalesGross);
        return `${html}<div>${stat.name}: $${totalSalesGrossStr} (${orderCountStr})</div>`;
      },
      '',
    );
  },

  render() {
    app.ModallableView.prototype.render.apply(this, arguments);
    const self = this;

    if (this._options.data) {
      const data = this._options.data;

      const sections = [
        {
          header: 'SUMMARY',
          body: this._getSummaryBody(data),
        },
        {
          header: 'TOP ITEMS',
          body: this._getTopItemsBody(data),
        },
        {
          header: 'BREAKDOWN BY SECTION',
          body: this._getSectionBreakdownBody(data),
        },
        {
          header: 'PAYMENT TYPE',
          body: this._getStatsBody(data.paymentTypeStats),
        },
        {
          header: 'ORDER CHANNEL',
          body: this._getStatsBody(data.orderChannelStats),
        },
        {
          header: 'ORDER TYPE',
          body: this._getStatsBody(data.orderTypeStats),
        },
      ];

      const sectionsHtml = _.map(sections, (section) => {
        return section.body.length ? self._sectionTemplate(section) : '';
      }).join('');

      this.$el.html(
        this.template({
          fromDay: `From: ${data.fromDay}`,
          throughDay: `Through: ${data.throughDay}`,
          sectionsHtml,
        }),
      );
    } else {
      this.$el.html('<div class="loading">No report data.</div>');
    }

    return self;
  },
});
