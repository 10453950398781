app.IntegrationsTabView = app.TabView.extend({
  initialize() {
    app.TabView.prototype.initialize.apply(this, arguments);

    const integrationListView = new app.IntegrationListView({
      collection: app.integrationList,
      cellView: app.IntegrationCellView,
      detailsView: app.IntegrationDetailsView,
      searchEnabled: false,
      canCreate: app.sessionUser.canManageFullI9ns(),
      cellOptions: {
        clickable: app.sessionUser.canManageFullI9ns(),
      },
    });
    this.setLeftView(integrationListView);

    this.listenTo(app.integrationList, 'update', this._updateBadge.bind(this));

    setTimeout(() => {
      this._updateBadge();
    }, 1);
  },

  _updateBadge() {
    const needsAction = app.integrationList.models.some((i9n) => {
      return i9n.getNeededActions().length || i9n.get('syncInfo')?.automaticSyncIncidentLastError;
    });
    this.setBadge(needsAction ? ' ' : '');
  },
});
