import async from 'async';
import Backbone from 'backbone';

import { Log } from '@biteinc/common';
import { SystemHealthErrorEvaluation } from '@biteinc/enums';
import { MathHelper, StringHelper, Time } from '@biteinc/helpers';

import { template } from '../template';

app.SystemHealthLogsView = Backbone.View.extend({
  template: template(`
    <h1>System Health Logs</h1>
    <p>Table shows error rate breakdown by location for <em><@= healthTag @></em>.</p>
    <table id="system-health-logs-breakdown-location-table" class="table table-bordered">
      <thead>
        <tr>
          <th>Location</th>
          <th>Error Rate</th>
          <th>Error Count</th>
          <th>Success Count</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <hr />
    <p>Table shows logs that contributed to <em><@= healthTag @></em>.</p>
    <br />
    <br />
    <table id="system-health-logs-table" class="table table-bordered">
      <thead>
        <tr>
          <th>Error?</th>
          <th>Date</th>
          <th>Status</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>`),
  className: 'system-health-logs-view clearfix',

  initialize(healthTag) {
    this._healthTag = healthTag;

    this.listenTo(this, app.SystemHealthLogsView.Events.SystemHealthLogsDidLoad, () => {
      this.render();
    });

    this.listenTo(this, app.SystemHealthLogsView.Events.SystemHealthLogsDidFailLoad, () => {
      this.render();
    });

    // best effort, might have a location
    this._locationByLocationId = {};

    this._isFetchingSystemHealthLogs = false;
    this._hasFetchedSystemHealthLogs = false;
  },

  /**
   * @param {any[]} systemHealthLogs
   */
  _fetchLocations(systemHealthLogs, callback) {
    systemHealthLogs
      .filter((systemHealthLog) => {
        return !!systemHealthLog.locationId;
      })
      .forEach((systemHealthLog) => {
        this._locationByLocationId[systemHealthLog.locationId] = null;
      });

    const locationIds = Object.keys(this._locationByLocationId);
    // best effort
    async.forEachLimit(
      locationIds,
      10,
      (locationId, cb) => {
        app.makeRequestWithOptions({
          method: 'GET',
          url: `/api/v2/locations/${locationId}`,
          onSuccess: (data) => {
            // update view state
            this._locationByLocationId[locationId] = new app.Location(data.location);
            cb();
          },
          onFailure: () => {
            // do nothing
            cb();
          },
        });
      },
      () => {
        callback();
      },
    );
  },

  _fetchSystemHealthLogs() {
    if (this._hasFetchedSystemHealthLogs || this._isFetchingSystemHealthLogs) {
      // don't fetch twice
      return;
    }

    // in case something breaks, don't hammer our server forever
    const pageFetchLimit = 100;
    let pageFetchCount = 0;

    this._systemHealthLogs = [];
    this._systemHealthLogIds = new Set();

    this._isFetchingSystemHealthLogs = true;

    // setup on failure callback for all pages of logs
    const onFailureCallback = (err) => {
      // don't bother refetching, assume user will do something
      this._hasFetchedSystemHealthLogs = true;
      this._isFetchingSystemHealthLogs = false;
      this.trigger(app.SystemHealthLogsView.Events.SystemHealthLogsDidFailLoad);
      Log.error('SystemHealthLogsView fetch logs', err);
    };

    // setup on success callback for all pages of logs
    const onSuccessCallback = (data) => {
      pageFetchCount += 1;

      const systemHealthLogs = data.models;
      systemHealthLogs.forEach((systemHealthLog) => {
        if (this._systemHealthLogIds.has(systemHealthLog._id)) {
          // don't add duplicates
          return;
        }
        this._systemHealthLogIds.add(systemHealthLog._id);
        this._systemHealthLogs.push(systemHealthLog);
      });

      // recursion
      // get the next page until no more pages exist
      const hasReachedPageFetchLimit = pageFetchCount >= pageFetchLimit;
      if (data.next && !hasReachedPageFetchLimit) {
        app.makeRequestWithOptions({
          method: 'GET',
          url: data.next,
          onSuccess: onSuccessCallback,
          onFailure: onFailureCallback,
        });
        return;
      }

      // we've fetched all the pages or reached a limit

      this._hasFetchedSystemHealthLogs = true;
      this._isFetchingSystemHealthLogs = false;

      this._fetchLocations(this._systemHealthLogs, () => {
        this.trigger(app.SystemHealthLogsView.Events.SystemHealthLogsDidLoad);
      });
    };

    // load the system health logs with pagination
    const url = `/api/v2/system-health/tags/${this._healthTag}/logs`;
    app.makeRequestWithOptions({
      method: 'GET',
      url,
      onSuccess: onSuccessCallback,
      onFailure: onFailureCallback,
    });
  },

  render() {
    this.$el.html(
      this.template({
        healthTag: this._healthTag,
      }),
    );

    this._fetchSystemHealthLogs();

    const $systemHealthLogsTable = this.$('#system-health-logs-table tbody');
    const $systemHealthLogsBreakdownLocationTable = $(
      '#system-health-logs-breakdown-location-table tbody',
    );

    let loadingSystemHealthLogsErrorMessage;
    switch (true) {
      case this._isFetchingSystemHealthLogs:
        loadingSystemHealthLogsErrorMessage = 'Fetching system health logs';
        break;
      case !this._hasFetchedSystemHealthLogs:
        loadingSystemHealthLogsErrorMessage = 'Failed to fetch system health logs';
        break;
      case !this._systemHealthLogs.length:
        loadingSystemHealthLogsErrorMessage = 'No system health logs found';
        break;
    }
    if (loadingSystemHealthLogsErrorMessage) {
      $systemHealthLogsTable.append(
        `<tr><td colspan="6">${loadingSystemHealthLogsErrorMessage}</td></tr>`,
      );
      $systemHealthLogsBreakdownLocationTable.append(
        `<tr><td colspan="4">${loadingSystemHealthLogsErrorMessage}</td></tr>`,
      );
      return this;
    }

    const systemHealthFragmentByLocationId = {};

    this._systemHealthLogs.forEach((systemHealthLog) => {
      const location = app.orgList.getLocationById(systemHealthLog.locationId);
      const locationHref = location
        ? `${window.location.href.split('#')[0]}${location.get('orgNormalizedName')}/${location.get(
            'normalizedName',
          )}/${location.get('orderChannel')}#system-logs/${systemHealthLog._id}`
        : '';
      // render logs table
      $systemHealthLogsTable.append(`
        <tr data-log-id="${systemHealthLog._id}">
          <td>${systemHealthLog.errorEvaluation}</td>
          <td>${Time.stringFromTimestamp(systemHealthLog.createdAt)}</td>
          <td>${systemHealthLog.humanReadableStatus}</td>
          <td><a href="#" target="_blank" class="btn btn-secondary expand-log-button" data-log-id="${
            systemHealthLog._id
          }" data-log-expanded="false">Expand Log</button></td>
          <td><a href="/api/v2/system-health/tags/${this._healthTag}/logs/${
            systemHealthLog._id
          }" target="_blank" class="btn btn-secondary">View Log</button></td>
        <td><a href="${locationHref}" target="_blank" class="btn btn-secondary">Go To Log</button></td>
        </tr>`);

      // build systemHealthFragmentByLocationId
      if (!systemHealthLog.locationId) {
        // some logs don't have a location
        return;
      }

      if (!systemHealthFragmentByLocationId[systemHealthLog.locationId]) {
        systemHealthFragmentByLocationId[systemHealthLog.locationId] = {
          healthTag: this._healthTag,
          errorCount: 0,
          successCount: 0,
        };
      }
      switch (systemHealthLog.errorEvaluation) {
        case SystemHealthErrorEvaluation.Success:
          systemHealthFragmentByLocationId[systemHealthLog.locationId].successCount += 1;
          break;
        case SystemHealthErrorEvaluation.Error:
          systemHealthFragmentByLocationId[systemHealthLog.locationId].errorCount += 1;
          break;
      }
    });

    // render location breakdown table
    Object.keys(systemHealthFragmentByLocationId)
      .map((locationId) => {
        const { errorCount, successCount } = systemHealthFragmentByLocationId[locationId];
        const errorRate = errorCount > 0 ? errorCount / (errorCount + successCount) : 0;
        const location = this._locationByLocationId[locationId];
        let locationNameOrId = locationId;
        if (location) {
          locationNameOrId = `<a href="${location.bureauUrl()}" target="_blank">${location.debugName()}</a>`;
        }
        return {
          locationNameOrId,
          errorRate,
          errorCount,
          successCount,
        };
      })
      .sort((a, b) => {
        // sort on errorRate
        return b.errorRate - a.errorRate;
      })
      .forEach(({ locationNameOrId, errorRate, errorCount, successCount }) => {
        $systemHealthLogsBreakdownLocationTable.append(`
        <tr>
          <td>${locationNameOrId}</td>
          <td>${MathHelper.floatRateToPercentStr(errorRate)}%</td>
          <td>${errorCount}</td>
          <td>${successCount}</td>
        </tr>`);
      });

    $('.expand-log-button').on('click', (event) => {
      event.preventDefault();

      const $expandLogButton = $(event.target);

      const logId = $expandLogButton.attr('data-log-id');

      if ($expandLogButton.attr('data-log-expanded') === 'true') {
        // hide row on already expanded row
        $(`.expanded-log-output-row[data-log-id=${logId}]`).html('');
        $expandLogButton.removeClass('active');
        $expandLogButton.attr('data-log-expanded', 'false');
        return;
      }

      const logViewRow = $(`
        <tr class="expanded-log-output-row" data-log-id="${logId}">
          <td colspan="6">
            <div class="expanded-log-output" data-log-id="${logId}">
              <p>Loading Log</p>
            </div>
          </td>
        </tr>`);

      // insert log json row below existing row
      $(`tr[data-log-id=${logId}]`).after(logViewRow);

      app.makeRequestWithOptions({
        method: 'GET',
        url: `/api/v2/system-health/tags/${this._healthTag}/logs/${logId}`,
        onSuccess: (data) => {
          // render output
          $(`.expanded-log-output[data-log-id=${logId}]`).html(
            `<pre>${StringHelper.escapeHtml(JSON.stringify(data.log, null, 2))}</pre>`,
          );
          // set button to expanded
          $expandLogButton.attr('data-log-expanded', 'true');
          $expandLogButton.addClass('active');
        },
        onFailure: (err) => {
          // don't bother refetching, assume user will do something
          Log.error('SystemHealthLogsView fetch log', err);
        },
      });
    });

    return this;
  },
});

app.SystemHealthLogsView.Events = {
  SystemHealthLogsDidLoad: 'SystemHealthLogsDidLoad',
  SystemHealthLogsDidFailLoad: 'SystemHealthLogsDidFailLoad',
};
