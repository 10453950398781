import _ from 'underscore';

import { WeekdayHelper } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

import { template } from '../../template';

app.MenuItemAvailabilityFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(
      this,
      arguments,
    )} menu-item-availability-field-view`;
  },
  template: template(
    `<label class="col-form-label col-md-4">Date Availability:</label>
    <div class="col-md-8 input-container collapsible dates"></div>
    <div class="col-md-12"></div>
    <label class="col-form-label col-md-4">Day Availability:</label>
    <div class="col-md-8 input-container collapsible days"></div>`,
  ),
  setValue(value, model) {
    /** @type {MenuItem.I9nAvailability} */
    this.value = value;
    this.model = model;

    this.render();
  },

  getValue() {
    // Currently read-only
    return this.value;
  },

  render() {
    this.$el.html(this.template());

    this.$daysInputContainer = this.$el.find('.input-container.days');
    this.$datesInputContainer = this.$el.find('.input-container.dates');

    const hasDays = this.value && this.value.days && this.value.days.length > 0;
    const hasDates = this.value && this.value.dates && this.value.dates.length > 0;

    if (hasDays) {
      _.each(this.value.days, (timeRange) => {
        /** @type {number} */
        const from = timeRange.from;
        /** @type {number} */
        const to = timeRange.to;
        /** @type {Weekday} */
        const weekday = timeRange.day;

        const timeRangeLabel = [
          `${WeekdayHelper.name(weekday)}:`,
          `from ${Time.stringTimeFromDayTimeStamp(from)}`,
          `to ${Time.stringTimeFromDayTimeStamp(to)}`,
        ].join(' ');

        const $timeRange = $(`<span class="simple-value">${timeRangeLabel}</span>`);

        this.$daysInputContainer.append($timeRange);
      });
    } else {
      const $noDayData = $('<span class="simple-value">No Specified Day Availability</span>');
      this.$daysInputContainer.append($noDayData);
    }

    if (hasDates) {
      /** @type {string} */
      const tz = app.location.get('timezone');

      _.each(this.value.dates, (dateRange) => {
        /** @type {number | null} */
        const from = dateRange.from;
        /** @type {number | null} */
        const to = dateRange.to;

        const dateRangeLabel = [
          from ? `From: ${Time.stringFromTimestamp(from, tz)}` : undefined,
          to ? `To: ${Time.stringFromTimestamp(to, tz)}` : undefined,
        ]
          .filter((s) => {
            return s;
          })
          .join(', ');

        const $dateRange = $(`<span class="simple-value">${dateRangeLabel}</span>`);

        this.$datesInputContainer.append($dateRange);
      });
    } else {
      const $noDateData = $('<span class="simple-value">No Specified Date Availability</span>');
      this.$datesInputContainer.append($noDateData);
    }

    return this;
  },
});
