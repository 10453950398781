import { MenuRules } from '@biteinc/business-rules';
import {
  IntegrationSystem,
  ItemTaxationStatus,
  MenuItemCategory,
  MenuItemDisplayStyle,
  ModelType,
} from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { modSchema } from '@biteinc/schemas';

app.Mod = app.MenuItem.extend({
  Schema: modSchema,
  Type: ModelType.Mod,

  defaults() {
    const defaults = {
      displayStyle: MenuItemDisplayStyle.NameAndDescription,
      category: MenuItemCategory.Food,
      isMod: true,
      taxationStatus: ItemTaxationStatus.Inherit,
      priceOptions: [{ _id: StringHelper.randomHexString(24) }],
    };
    if (app.vendorList && app.vendorList.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = (
        app.vendorList.isEveryVendorIntegrated()
          ? app.vendorList.getDefaultVendorOrFallback()
          : app.vendorList.getDefaultNonintegratedVendor()
      ).id;
    }
    return defaults;
  },

  canChangeParent() {
    return !this.getFullVendor().syncsModelsWithType(this.Type);
  },

  fieldNamesThatCanBeCopiedToOtherModels() {
    return MenuRules.modFieldNamesThatCanBeCopiedToOtherModels();
  },

  supportsSyncingMultiplePriceOptions() {
    if (!this.hasArr('integrations')) {
      return false;
    }

    const posSystem = this.get('integrations')[0].system;

    switch (posSystem) {
      case IntegrationSystem.Simphony:
        return true;
      default:
        return false;
    }
  },
});
