import {
  MenuCoverPromoLinkType,
  MenuCoverPromoLinkTypeHelper,
  MenuCoverPromotionImageSize,
  ModelType,
} from '@biteinc/enums';
import { menuCoverPromoSchema } from '@biteinc/schemas';

app.MenuCoverPromo = app.AbstractModel.extend({
  ModelName: 'menuCoverPromo',
  Schema: menuCoverPromoSchema,
  Type: ModelType.MenuCoverPromo,

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'linkType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuCoverPromoLinkType,
          nameGenerator: MenuCoverPromoLinkTypeHelper.name,
        });
      case 'promoImageSize':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuCoverPromotionImageSize,
          nameGenerator: (variation) => {
            switch (variation) {
              case MenuCoverPromotionImageSize.GcnFullWidth:
                return 'GCN: Full Width';
              case MenuCoverPromotionImageSize.GcnHalfWidth:
                return 'GCN: Half Width';
              case MenuCoverPromotionImageSize.VitrineMdHalfWidth:
                return 'Vitrine: Medium Height - Half Width';
              case MenuCoverPromotionImageSize.VitrineMdFullWidth:
                return 'Vitrine: Medium Height - Full Width';
              case MenuCoverPromotionImageSize.VitrineSmHalfWidth:
                return 'Vitrine: Small Height - Half Width';
              case MenuCoverPromotionImageSize.VitrineSmFullWidth:
                return 'Vitrine: Small Height - Full Width';
            }
          },
        });
    }
    return null;
  },
});
