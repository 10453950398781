import Backbone from 'backbone';
import _ from 'underscore';

app.ChangePasswordModal = Backbone.View.extend({
  initialize(options) {
    this.user = options.user;
    this.title = `Change Password for ${this.user.get('username')}`;

    this._schema = app.JsonHelper.deepClone(app.User.Schema().fields.password);
    delete this._schema.isPermanent;
  },

  setSuperView(superView) {
    this.superView = superView;
  },

  clearState() {
    this.callback = null;
    this.superView = null;
  },

  render() {
    const self = this;

    self.superView.$titleLabel.text(self.title);

    const $fieldGroup = $('<div class="field-group"></div>');
    this.$el.append($fieldGroup);

    this.fieldViews = [];
    if (this.user.id === app.sessionUser.id) {
      const currentFieldView = new app.FieldView({
        field: 'currentPassword',
        schema: {
          displayName: 'Current Password',
          type: 'password',
          required: true,
        },
      });
      $fieldGroup.append(currentFieldView.render().el);
      currentFieldView.setValue(null, this.user);
      this.fieldViews.push(currentFieldView);
    }

    const passwordFieldView = new app.PasswordFieldView({
      field: 'password',
      schema: this._schema,
    });
    $fieldGroup.append(passwordFieldView.render().el);
    passwordFieldView.setValue(null, this.user);
    this.fieldViews.push(passwordFieldView);

    const footer = self.superView.footer;
    footer.setDeleteButtonVisible(false);

    footer.setSaveButtonVisible(true, 'Change', () => {
      const params = {};
      let isValid = true;
      _.each(self.fieldViews, (fieldView) => {
        isValid = isValid && fieldView.checkValidity().isValid;
        const value = fieldView.getValue();
        if (value !== null) {
          params[fieldView.field] = value;
        }
      });

      if (!isValid) {
        const error = 'There were some errors with the data you entered.';
        new app.AlertView().show(error);
        return;
      }

      self.user.changePassword(params, (err) => {
        if (!err) {
          self.trigger(app.ModalView.Events.ModalViewShouldHide, self);
        }
      });
    });

    return self;
  },
});
