import type { Schema } from '@biteinc/common';
import { Validators } from '@biteinc/common';

export const openHoursOverrideSchema: Schema.Model = {
  displayName: 'open hours override',
  type: 'object',
  fields: {
    reason: {
      type: 'keyString',
      required: true,
      validationRegex: Validators.simpleStringRegexString({ allowCommas: true }),
    },
    date: {
      type: 'timestamp',
      required: true,
      allowFutureDates: true,
      // The API only accepts future dates, but we need to allow them on the UI since otherwise when
      // the user opens an override from the past, the box will be empty, which confuses some users
      allowPastDates: true,
      showDatePickerOnly: true,
    },
    fulfillmentMethod: {
      type: 'int',
      ui: 'dropdown',
      required: true,
      displayName: 'Affected Dining Option',
    },
    schedule: {
      type: 'object',
      subtype: 'Schedule',
      required: true,
      displayName: 'Schedule',
    },
    isOpen: {
      type: 'bool',
      required: true,
      displayName: 'Is Open',
      defaultValue: true,
      tooltip: {
        text: 'If checked, then the scheduled hours are open and any hours outside of an override are closed. If unchecked, then the scheduled hours are closed and any hours outside of an override are open.',
      },
    },
  },
};
