import { Validators } from '@biteinc/common';
import { BuildType, ModelType } from '@biteinc/enums';

import { TimeHelper } from '../helpers/time_helper';

app.Build = app.AbstractModel.extend({
  ModelName: 'build',
  Schema: {
    displayName: 'build',
  },
  Type: ModelType.Build,

  displayName() {
    if ([BuildType.CustomCssGcn, BuildType.CustomCssVitrine].includes(this.get('type'))) {
      const [orgName, filename, checksum] = this.get('name').split('|');
      return `${orgName}/${filename} ${TimeHelper.format(
        this.get('createdAt'),
      )} (${checksum.substring(0, 6)})`;
    }
    return this.get('name');
  },

  getSentryProjectId() {
    switch (this.get('type')) {
      case BuildType.Garcon:
        return '6777735';
      case BuildType.Gcn:
        return '132234';
      case BuildType.ChromeOS:
        return '4505285009539072';
      case BuildType.Vitrine:
        return '4505477187043328';
      default:
        throw new Error(`no known sentry project id for ${this.get('type')} builds`);
    }
  },

  isPrereleaseBuild() {
    return !new RegExp(Validators.generalAvailabilityBuildNameRegexString).test(this.get('name'));
  },
});
