import type { Schema } from '@biteinc/common';
import { UserRight, Weekday } from '@biteinc/enums';

export const openHoursTimetableSchema: Schema.Model = {
  displayName: 'open hours',
  type: 'object',
  fields: {
    daySchedules: {
      writeRight: UserRight.ManageMenu,
      displayName: 'Availability',
      type: 'array',
      elementType: 'object',
      ui: 'accordion',
      uiSubtype: 'DaySchedule',
      fields: {
        weekday: {
          type: 'stringEnum',
          enum: Weekday,
          required: true,
          isHidden: true,
        },
        schedules: {
          displayName: 'Schedules',
          type: 'array',
          elementType: 'object',
          elementSubtype: 'Schedule',
          required: true,
          minCount: 1,
          ui: 'accordion',
          uiSubtype: 'Schedule',
        },
      },
    },
  },
};
