import Backbone from 'backbone';

import { template } from '../template';

app.DropdownView = Backbone.View.extend({
  className: 'select-panel input-group card-body',
  template: template(
    // prettier-ignore
    '<div class="btn-group" role="group" aria-label="...">' +
      '<div class="btn-group item-dropdown" role="group">' +
        '<button type="button" class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">' +
          '<span class="text"></span>' +
        '</button>' +
        '<ul class="dropdown-menu" role="menu"></ul>' +
      '</div>' +
    '</div>',
  ),

  initialize(options) {
    this.options = options || {};
    this.isReadOnly = this.options.isReadOnly;
  },

  setup(collection, options) {
    const dropdown = new app.Dropdown();
    dropdown.setupDropdown(this.$el, collection, options);
  },

  setTitle(title) {
    this.$('button.dropdown-toggle .text').html(title);
  },

  render() {
    this.$el.html(this.template());
    if (this.options.isPrimary) {
      this.$('button').addClass('btn-primary').removeClass('btn-secondary');
    }

    this.$('button').prop('disabled', this.isReadOnly);

    return this;
  },
});
