export enum ConditionSchemaName {
  None = 'none',
  Custom = 'custom',
  OneOfMenuSectionsIsSelectedItem = 'one-of-menu-sections-is-selected-item',
  OneOfMenuSectionsAreInCart = 'one-of-menu-sections-are-in-cart',
  OneOfMenuSectionsAreSelectedItemOrInCart = 'one-of-menu-sections-are-selected-item-or-in-cart',
  OneOfMenuItemsIsSelectedItem = 'one-of-menu-items-is-selected-item',
  OneOfMenuItemsAreInCart = 'one-of-menu-items-are-in-cart',
  OneOfMenuItemsAreSelectedItemOrInCart = 'one-of-menu-items-are-selected-item-or-in-cart',
  PreCheckoutPopup = 'pre-checkout-popup',
  CartView = 'cart-view',
  DietaryRestrictionsApplied = 'dietary-restrictions-applied',
  MenuSectionIsRecommendation = 'menu-section-is-recommendation',
}

export function nameFromConditionSchemaName(name: ConditionSchemaName): string {
  switch (name) {
    case ConditionSchemaName.None:
      return 'None';
    case ConditionSchemaName.Custom:
      return 'Custom';
    case ConditionSchemaName.OneOfMenuSectionsIsSelectedItem:
      return 'One of menu sections is selected item';
    case ConditionSchemaName.OneOfMenuSectionsAreInCart:
      return 'One of menu sections are in cart';
    case ConditionSchemaName.OneOfMenuSectionsAreSelectedItemOrInCart:
      return 'One of menu sections are selected item or in cart';
    case ConditionSchemaName.OneOfMenuItemsIsSelectedItem:
      return 'One of menu items is selected item';
    case ConditionSchemaName.OneOfMenuItemsAreInCart:
      return 'One of menu items are in cart';
    case ConditionSchemaName.OneOfMenuItemsAreSelectedItemOrInCart:
      return 'One of menu items are selected item or in cart';
    case ConditionSchemaName.PreCheckoutPopup:
      return 'Is pre-checkout popup';
    case ConditionSchemaName.CartView:
      return 'Is cart view';
    case ConditionSchemaName.DietaryRestrictionsApplied:
      return 'Dietary restrictions applied';
    case ConditionSchemaName.MenuSectionIsRecommendation:
      return 'Menu section is recommendation pane';
  }
}
