app.MenuItemArrayList = app.AbstractCollection.extend({
  initialize(models, options) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    if (options && options.itemList) {
      this.itemList = options.itemList;
      this.listenTo(this.itemList, 'reset', this._attachModelListeners);
    }
  },

  getSchema() {
    if (!this._schema) {
      const schema = app.AbstractCollection.prototype.getSchema.apply(this);
      this._schema = app.JsonHelper.deepClone(schema);
      if (app.location) {
        if (app.location.getPosI9nSchema()) {
          this._schema.fields.name.displayName = 'Display Name';
          this._schema.fields.description.displayName = 'Display Description';
        } else {
          this._schema.fields.name.required = true;
          delete this._schema.fields.posName;
          delete this._schema.fields.posDescription;
        }

        if (!app.location.usesFulfillmentNames()) {
          delete this._schema.fields.fulfillmentName;
        }
      }
    }
    return this._schema;
  },

  _attachModelListeners() {
    this.each((itemArray) => {
      itemArray.updateItemEventListeners();
    });
  },
});
