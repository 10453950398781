app.SettingsDetailsView = app.BaseDetailsView.extend({
  initialize(options) {
    switch (this.model.get('scope')) {
      case 'org':
        options.isReadOnly = !app.sessionUser.isOrgWide();
        break;
      case 'site':
        options.isReadOnly = !app.sessionUser.isSiteWide();
        break;
    }

    app.BaseDetailsView.prototype.initialize.apply(this, [options]);
  },

  _infoIcon() {
    const $icon = $(`<div class="field-tooltip"></div>`);
    $icon.addClass('override-state-tooltip');
    return $icon;
  },

  _showOverrideModal(fieldName) {
    const detailsView = new app.BiteSettingsFieldInheritanceView({
      model: this.model,
      field: fieldName,
      schema: this.getSchema(),
    });
    app.modalManager.showModalWithView(detailsView);
  },

  _updateFieldOverrideState() {
    const fieldViewsByField = this.fieldGroupView.fieldViewsByField;

    const overrideSet = this.model.get('overrideSet');
    Object.keys(fieldViewsByField).forEach((fieldName) => {
      const fieldView = fieldViewsByField[fieldName];

      // Insert override tooltip if not already present
      const $overrideStateTooltip =
        fieldViewsByField[fieldName].$el.find('.override-state-tooltip');
      if (!$overrideStateTooltip.length) {
        const $formLabel = fieldView.$el.find('.col-form-label');
        const $icon = this._infoIcon();
        $formLabel.prepend($icon);
      }

      // Toggle Override state
      const $icon = fieldView.$el.find('.override-state-tooltip');
      if (overrideSet.includes(fieldName)) {
        $icon.toggleClass('overriden', true);
      } else {
        $icon.toggleClass('overriden', false);
      }
      $icon.click(() => {
        this._showOverrideModal(fieldName);
      });
    });
  },

  _fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this.__updateFieldOverrideState();
  },

  getSchema() {
    let schema = app.BaseDetailsView.prototype.getSchema.apply(this);

    return schema;
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this._updateFieldOverrideState();
    return this;
  },
});
