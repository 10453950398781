import { customDomainSchema } from '@biteinc/schemas';

app.CustomDomainView = app.NestedObjectFieldView.extend({
  getNestedObjectDefaultValue() {
    return {
      domain: '',
    };
  },

  getNestedObjectSchema() {
    return customDomainSchema;
  },
});
