import { IntegrationSystem, ReportType } from '@biteinc/enums';

export module ReportHelper {
  export function getAvailableReportTypes(
    isOneTimeReport: boolean,
    sessionUser: any,
    org: any,
    site: any,
    location: any,
    settings: any,
  ): ReportType[] {
    const reportTypes: ReportType[] = [];

    if (sessionUser.canViewOrders()) {
      reportTypes.push(ReportType.SalesGuests);
      reportTypes.push(ReportType.FutureOrders);
      reportTypes.push(ReportType.SalesOrders);
      reportTypes.push(ReportType.SalesTransactions);
      reportTypes.push(ReportType.EightySixingActivity);
      reportTypes.push(ReportType.CouponRedemptionHistory);
      if (sessionUser.isBite()) {
        reportTypes.push(ReportType.ItemUpsell);
        reportTypes.push(ReportType.PilotEngagement);
        reportTypes.push(ReportType.PilotLaborAnalysis);
      }
      if (location) {
        if (isOneTimeReport) {
          reportTypes.push(ReportType.SalesSummaryModal);
        }
        reportTypes.push(ReportType.SalesSummaryChannel);
        if (org.get('customerAccountsScope') && settings.get('customerAccountsUsage')) {
          reportTypes.push(ReportType.CustomerOrders);
        }
      } else if (site) {
        reportTypes.push(ReportType.SalesSummarySite);
      } else {
        reportTypes.push(ReportType.SalesSummaryOrg);
        if (sessionUser.isBite() && isOneTimeReport) {
          reportTypes.push(ReportType.VitrineCompatibility);
        }
        if (isOneTimeReport) {
          reportTypes.push(ReportType.KioskPeripherals);
        }
        const orgHasStripe =
          org.hasIntegrationWithSystem(IntegrationSystem.Stripe) ||
          org.hasIntegrationWithSystem(IntegrationSystem.StripeTerminal);
        if (orgHasStripe) {
          reportTypes.push(ReportType.FlashPayouts);
        }

        if (org.get('customerAccountsScope')) {
          reportTypes.push(ReportType.CustomerOrders);
        }
      }
    }

    if (location) {
      const locationHasStripe =
        location.hasIntegrationWithSystem(IntegrationSystem.Stripe) ||
        location.hasIntegrationWithSystem(IntegrationSystem.StripeTerminal);
      if (locationHasStripe) {
        reportTypes.push(ReportType.VendorPayouts);
      }
    }

    return reportTypes;
  }
}
