import $ from 'jquery';
import _ from 'underscore';

app.AutoCompleteList = app.AbstractCollection.extend({
  // NOTE: this needs to be an unnamed function for backbone to work
  model: function (attributes, options) {
    return new app.AutoCompleteResult(
      attributes,
      _.extend(options, {
        model: options.collection.resultModel,
      }),
    );
  },

  initialize(models, options) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    this.resultModel = options.resultModel;
  },

  parse(data) {
    return data.data.results;
  },

  comparator(model) {
    if (model.has('_frequency')) {
      return model.get('_frequency') * -1;
    }
    return model.displayName().toLowerCase();
  },

  searchFor(query) {
    this.query = query;
    this.fetch({ reset: true });
  },

  fetch(opts) {
    const options = _.extend(
      {
        data: $.param({ query: this.query }),
        processData: true,
      },
      opts || {},
    );
    app.AbstractCollection.prototype.fetch.apply(this, [options]);
  },
});
