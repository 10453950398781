import { ModelType } from '@biteinc/enums';
import { menuSchema } from '@biteinc/schemas';

app.Menu = app.AbstractModel.extend({
  ModelName: 'menu',
  Schema: menuSchema,
  Type: ModelType.Menu,

  url() {
    let base = null;
    if (this.collection) {
      base = this.collection.url();
    } else {
      base = `/api/orgs/${this.get('orgId')}/locations/${this.get('locationId')}/menus`;
    }
    return `${base}/${this.isNew() ? '' : this.id}`;
  },

  canBeDestroyed() {
    return false;
  },

  detailsViewClassForListField(field) {
    if ('sectionIds' === field) {
      return app.MenuItemArrayDetailsView;
    }
    return null;
  },

  removeArchive(options) {
    const url = `${this.url()}/archive`;
    app.makeRequest('DELETE', url, null, options.success, options.error);
  },
});
