import { Environment } from '@biteinc/enums';

app.MenuAppearanceDetailsView = app.BaseDetailsView.extend({
  getSchema() {
    const schema = app.JsonHelper.deepClone(app.BaseDetailsView.prototype.getSchema.apply(this));

    if (Environment.DEV !== app.data.APP_ENV) {
      delete schema.fields.customCssPath;
    }

    return schema;
  },
});
