import _ from 'underscore';

import { MenuItemCategory, ModelType } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { spiritsSchemas } from '@biteinc/schemas';

app.Spirit = app.AbstractModel.extend({
  ModelName: 'spirit',
  Type: ModelType.Spirit,

  defaults() {
    if (this.collection) {
      const defaults = { category: this.collection.category };
      if (defaults.category === app.Spirit.Category.CIDER) {
        defaults.style = 'Cider';
      }
      return defaults;
    }
    return {};
  },

  url() {
    if (this.isNew()) {
      return '/api/spirits';
    }
    return `/api/spirits/${this.id}`;
  },

  getParamsForSchema(itemSchema, optParams) {
    const params = {
      spiritId: this.id,
      category: MenuItemCategory.AlcoholicBeverage,
    };

    if (itemSchema.fields.name) {
      params.name = this.get('name');
    }
    if (itemSchema.fields.internalName && this.hasStr('internalName')) {
      params.internalName = this.get('internalName');
    }
    if (itemSchema.fields.story && this.hasStr('story')) {
      params.story = this.get('story');
    }

    let images = [];
    if (itemSchema.fields.images && this.hasArr('images')) {
      images = images.concat(this.get('images') || []);
      params.images = images;
    }

    const descParts = [];

    switch (this.get('category')) {
      case app.Spirit.Category.BEER:
      case app.Spirit.Category.CIDER:
      case app.Spirit.Category.BEER_LIKE:
        if (this.hasStr('style')) {
          if (itemSchema.fields.description) {
            descParts.push(this.get('style'));
          }
          if (itemSchema.fields.style) {
            params.style = this.get('style');
          }
        }
        if (this.hasStr('abv')) {
          if (itemSchema.fields.description) {
            descParts.push(`${this.get('abv')}% ABV`);
          }
          if (itemSchema.fields.abv) {
            params.abv = this.get('abv');
          }
        }
        if (this.hasStr('region')) {
          if (itemSchema.fields.description) {
            descParts.push(this.get('region'));
          }
          if (itemSchema.fields.region) {
            params.region = this.get('region');
          }
        }
        if (this.hasStr('country')) {
          if (itemSchema.fields.description) {
            descParts.push(this.get('country'));
          }
          if (itemSchema.fields.country) {
            params.country = this.get('country');
          }
        }

        if (descParts.length) {
          params.description = descParts.join(', ');
        }

        if (itemSchema.fields.images) {
          if (this.hasArr('canImage')) {
            images.push(this.get('canImage')[0]);
          }
          if (this.hasArr('bottleImage')) {
            images.push(this.get('bottleImage')[0]);
          }
          if (this.hasArr('onTapImage')) {
            images.push(this.get('onTapImage')[0]);
          }
          if (this.hasArr('breweryLogo')) {
            images.push(this.get('breweryLogo')[0]);
          }
        }
        if (images.length) {
          params.images = images;
        }
        break;
      case app.Spirit.Category.WINE:
        if (itemSchema.fields.description) {
          if (this.hasStr('varietal')) {
            descParts.push(this.get('varietal'));
          }
          if (this.hasStr('vintage')) {
            descParts.push(this.get('vintage'));
          }
          if (this.hasStr('region')) {
            descParts.push(this.get('region'));
          }
          if (this.hasStr('country')) {
            descParts.push(this.get('country'));
          }
        }

        if (descParts.length) {
          params.description = descParts.join(', ');
        }

        optParams.priceOptions = [
          {
            _id: StringHelper.randomHexString(24),
            name: 'Glass',
          },
          {
            _id: StringHelper.randomHexString(24),
            name: 'Bottle',
          },
        ];
        break;
      case app.Spirit.Category.SCOTCH:
        if (itemSchema.fields.description && this.hasStr('location')) {
          params.description = this.get('location');
        }
        break;
      case app.Spirit.Category.SODA:
        params.category = MenuItemCategory.NonalcoholicBeverage;
        if (itemSchema.fields.images) {
          if (this.hasArr('canImage')) {
            images.push(this.get('canImage')[0]);
          }
          if (this.hasArr('bottleImage')) {
            images.push(this.get('bottleImage')[0]);
          }
          if (this.hasArr('onTapImage')) {
            images.push(this.get('onTapImage')[0]);
          }
          if (this.hasArr('fullBleedImage')) {
            const fullBleedImage = this.get('fullBleedImage')[0];
            fullBleedImage.fitToFrame = true;
            images.push(fullBleedImage);
          }
          if (this.hasArr('breweryLogo')) {
            images.push(this.get('breweryLogo')[0]);
          }
        }
        if (images.length) {
          params.images = images;
        }
        break;
    }

    _.each(params.images || [], (image) => {
      image.isCellar = true;
    });

    return params;
  },

  isMissingImages() {
    const schema = this.collection.getSchema();
    if (schema.fields.images) {
      return !this.hasArr('images');
    }
    if (schema.fields.bottleImage) {
      return (
        !this.hasArr('canImage') &&
        !this.hasArr('bottleImage') &&
        !this.hasArr('onTapImage') &&
        !this.hasArr('fullBleedImage')
      );
    }
    return false;
  },

  autoCompleteDisplayName() {
    let displayName = this.displayName();
    if (this.get('isGeneric')) {
      displayName += ' <span>(generic)</span>';
    }
    return displayName;
  },

  displayNameHtml() {
    let title = `<span>${this.displayName()}</span>`;
    if (this.isMissingImages()) {
      title +=
        '&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" ' +
        'srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>';
    }

    return title;
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'producerId':
        return app.spiritProducerList;
    }
    return null;
  },
});

app.Spirit.Category = spiritsSchemas.Category;
app.Spirit.Schemas = spiritsSchemas.schemaByCategory;
