import Backbone from 'backbone';

import { template } from '../template';

app.ImagePreview = Backbone.View.extend({
  className: 'image-preview',
  template: template('<img class="preview" src="<@= src @>" />'),

  initialize() {
    this.listenTo(this.model, 'change', this.render);
  },

  destroy() {
    this.stopListening(this.model);
  },

  render() {
    let width = this.model.width();
    let height = this.model.height();
    const ratio = width / height;
    let topPadding = 0;
    let leftPadding = 0;
    if (ratio >= 1) {
      width = 100;
      height = 100 / ratio;
      topPadding = (100 - height) / 2.0;
    } else {
      height = 100;
      width = 100 * ratio;
      leftPadding = (100 - width) / 2.0;
    }
    const src = this.model.src();
    this.$el.html(this.template({ src }));
    if (src) {
      this.$('img').css('margin', `${topPadding}% ${leftPadding}%`);
      this.$('img').css('width', `${width}%`);
      this.$('img').css('height', `${height}%`);
      this.$el.addClass('loading');
    } else {
      this.$el.removeClass('loading');
    }
    return this;
  },
});
