app.CustomerList = app.AbstractCollection.extend({
  model: app.Customer,

  isValidQuery(query) {
    return query.length >= 2; // Order number can be A`
  },

  url() {
    const url = '/api/v2/customers';
    if (this.query) {
      return `${url}/search?query=${encodeURIComponent(this.query)}`;
    }
    return url;
  },
});
