import async from 'async';
import _ from 'underscore';

app.OrgLocationsToolsView = app.BaseToolsView.extend({
  initialize() {
    this.listenTo(app.locationList, 'reset', this.render);
  },

  _getSyncableLocations() {
    return _.filter(app.locationList.models, (location) => {
      return location.getPosI9nSchema();
    });
  },

  _syncPosButtonWasClicked() {
    const errorMessages = [];
    const locations = this._getSyncableLocations();
    async.each(
      locations,
      (location, cb) => {
        const url = `${location.url()}/sync-menu`;
        app.postRequest(
          url,
          null,
          () => {
            cb(); // ignore response, we aren't listening for the queued jobs to finish
          },
          (err) => {
            errorMessages.push(`Error syncing ${location.displayName()}: ${err}`);
            cb();
          },
        );
      },
      () => {
        if (errorMessages.length) {
          new app.AlertView().show(errorMessages.join('\n'));
        } else {
          app.showSavedToast(
            `Scheduled POS sync for ${locations.length} location${
              locations.length === 1 ? '' : 's'
            }. Stand by!`,
          );
        }
      },
    );
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    if (this._getSyncableLocations().length) {
      this.addToolbarButton(
        'btn-primary',
        '<i class="bi bi-arrow-clockwise"></i>&nbsp;Sync Menu for all POS-connected Locations',
        this._syncPosButtonWasClicked.bind(this),
      );
    }

    return this;
  },
});
