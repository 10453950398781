import { KeyCode } from '../../enums/key_code';

app.SpiritListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'add', this._updateTitle);
    this.listenTo(this.collection, 'remove', this._updateTitle);
    this.listenTo(this.collection, 'change', this._updateTitle);
    this.listenTo(this.collection, 'reset', this._updateTitle);
  },

  getTitle() {
    let title = app.BaseListView.prototype.getTitle.apply(this, arguments);

    if (this.collection.size()) {
      title += ` (${this.collection.size()} item${this.collection.size() === 1 ? '' : 's'}`;
      let imagelessItemCount = 0;
      this.collection.each((spirit) => {
        if (spirit.isMissingImages()) {
          imagelessItemCount++;
        }
      });
      if (imagelessItemCount) {
        title += ` | ${imagelessItemCount}`;
        title +=
          ' <img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png"' +
          ' srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos">';
      }
      title += ')';
    }
    return title;
  },

  _updateTitle() {
    this.setTitle(this.getTitle());
  },
});

app.SpiritTabView = app.TabView.extend({
  isSingleListTab: true,

  initialize(options) {
    app.TabView.prototype.initialize.apply(this, arguments);

    this.category = options.category;
    this.schema = app.Spirit.Schemas[this.category];
    this.collection = new app.SpiritList(null, { category: this.category });
    this.collection.fetch({ reset: true });

    this.spiritListView = new app.SpiritListView({
      collection: this.collection,
      detailsView: app.SpiritDetailsView,
      tabView: this,
    });
    this.setLeftView(this.spiritListView);

    this.addKeyboardListener(KeyCode.n, () => {
      this.spiritListView.createNew();
    });
  },
});

app.SpiritProducerTabView = app.TabView.extend({
  isSingleListTab: true,
});
