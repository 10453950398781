import _ from 'underscore';

app.JsonHelper = {
  deepClone(obj) {
    const newObj = _.clone(obj);
    _.each(_.keys(newObj), (key) => {
      const value = newObj[key];
      if (_.isArray(value)) {
        newObj[key] = _.map(value, _.clone);
      } else if (_.isObject(value)) {
        newObj[key] = app.JsonHelper.deepClone(value);
      }
    });
    return newObj;
  },

  swap(array, i, j) {
    if (!array || i < 0 || i >= array.length || j < 0 || j >= array.length) {
      return;
    }
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  },

  removeElementFromArray(array, element) {
    for (let i = 0; i < _.size(array); i++) {
      if (array[i] === element) {
        array.splice(i, 1);
        return true;
      }
    }
    return false;
  },

  parseOrNull(string) {
    try {
      return JSON.parse(string);
    } catch (e) {
      return null;
    }
  },
};
