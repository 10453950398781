import {
  Currency,
  CustomerAccountsUsage,
  CustomerAccountsUsageHelper,
  GroupOrderingMode,
  GroupOrderingNameType,
  GuestReceiptTextSize,
  KioskOrderingMode,
  KioskOrderingModeHelper,
  LanguageCode,
  LanguageCodeHelper,
  LocationRecommendationsLevel,
  LocationRecommendationsLevelHelper,
  LoyaltyInMenuMode,
  MenuItemDisplayPricePrecalculation,
  MenuModifierPriceDisplayStyle,
  MenuModifierPriceDisplayStyleHelper,
  MenuPriceStyle,
  MenuPriceStyleHelper,
  ModelType,
  QuickAddEligibility,
  RecommendationsFirstLoadVisibility,
  RecommendationsFirstLoadVisibilityHelper,
  SecretMenuType,
  SimpleLoyaltySignupMode,
  SimpleLoyaltySignupModeHelper,
} from '@biteinc/enums';
import { settingsSchema } from '@biteinc/schemas';

app.Settings = app.AbstractModel.extend({
  ModelName: 'settings',
  Schema: settingsSchema,
  Type: ModelType.Settings,

  canBeDestroyed() {
    return false;
  },

  getFieldCollection(field, subProperty) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'currencyCode':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Currency,
          useEnumValueAsName: true,
        });
      case 'kioskOrderingMode':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: KioskOrderingMode,
          nameGenerator: KioskOrderingModeHelper.name,
        });
      case 'supportedLanguages':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LanguageCode,
          nameGenerator: LanguageCodeHelper.name,
        });
      case 'recommendationsFirstLoadVisibility':
      case 'recommendationsInMenuVisibility':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: RecommendationsFirstLoadVisibility,
          nameGenerator: RecommendationsFirstLoadVisibilityHelper.name,
        });
      case 'recommendationsLevel':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationRecommendationsLevel,
          nameGenerator: LocationRecommendationsLevelHelper.name,
        });
      case 'simpleLoyaltySignupMode':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: SimpleLoyaltySignupMode,
          nameGenerator: SimpleLoyaltySignupModeHelper.name,
        });
      case 'customerAccountsUsage':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CustomerAccountsUsage,
          nameGenerator: CustomerAccountsUsageHelper.name,
        });
      case 'priceStyle':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuPriceStyle,
          nameGenerator: MenuPriceStyleHelper.name,
        });
      case 'modifierPriceDisplayStyle':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuModifierPriceDisplayStyle,
          nameGenerator: MenuModifierPriceDisplayStyleHelper.name,
        });
      case 'loyaltyInMenuMode':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LoyaltyInMenuMode,
          nameGenerator: (loyaltyInMenuMode) => {
            switch (loyaltyInMenuMode) {
              case LoyaltyInMenuMode.Off:
                return 'Off';
              case LoyaltyInMenuMode.ShowLogInButton:
                return 'Show Log In Button';
              case LoyaltyInMenuMode.PromptToLogInAtTheBeginning:
                return 'Prompt to Log In at the Beginning';
            }
          },
        });
      case 'vendorInstructionsSize':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: GuestReceiptTextSize,
          nameGenerator: (size) => {
            switch (size) {
              case GuestReceiptTextSize.Small:
                return 'Small';
              case GuestReceiptTextSize.Medium:
                return 'Medium';
              case GuestReceiptTextSize.Large:
                return 'Large';
            }
          },
        });
      case 'precalculateMenuItemDisplayPrice':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemDisplayPricePrecalculation,
          nameGenerator: (style) => {
            switch (style) {
              case MenuItemDisplayPricePrecalculation.Off:
                return 'Off';
              case MenuItemDisplayPricePrecalculation.IncludeDefaultMods:
                return 'Include default mods';
              case MenuItemDisplayPricePrecalculation.IncludeCheapestModsOnRequiredGroups:
                return 'Include cheapest mod on required groups';
            }
          },
        });
      case 'secretMenu.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: SecretMenuType,
          nameGenerator: (secretMenuType) => {
            switch (secretMenuType) {
              case SecretMenuType.SideNav:
                return 'Side Nav';
            }
          },
        });
      case 'quickAddEligibility':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: QuickAddEligibility,
          nameGenerator: (quickAddEligibility) => {
            switch (quickAddEligibility) {
              case QuickAddEligibility.HasNoModGroups:
                return 'Has no mod groups';
              case QuickAddEligibility.HasModGroupsNoRequiredChoices:
                return 'Has mod groups, no required choices';
              case QuickAddEligibility.AllRequiredModGroupsArePreselected:
                return 'All required mod groups are preselected';
            }
          },
        });
      case 'groupOrderingMode':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: GroupOrderingMode,
          nameGenerator: (groupOrderingMode) => {
            switch (groupOrderingMode) {
              case GroupOrderingMode.Off:
                return 'Off';
              case GroupOrderingMode.AskGroupOrderRequired:
                return 'Ask Group Order Required';
              case GroupOrderingMode.AskGroupOrderOptional:
                return 'Ask Group Order Optional';
            }
          },
        });
      case 'groupOrderingNameType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: GroupOrderingNameType,
          nameGenerator: (groupOrderingNameType) => {
            switch (groupOrderingNameType) {
              case GroupOrderingNameType.Name:
                return 'Guest Names';
              case GroupOrderingNameType.Number:
                return 'Number (auto)';
            }
          },
        });
    }
  },

  detailsViewClassForListField(/* field, subProperty, keyModel */) {
    return null;
  },

  url() {
    return `/api/v2/settings/${this.id}`;
  },
});
