import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

app.OutpostLocationsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} outpost-locations-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
          name: 'New Outpost Location',
        };
      },
      getTitle: (fieldGroupView) => {
        return fieldGroupView.getValue().name || '';
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new outpost',
        emptyText: 'no outpost locations yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
