app.SchemaDetailsView = app.BaseDetailsView.extend({
  initialize(options) {
    options.model = new app.AbstractModel();
    app.BaseDetailsView.prototype.initialize.apply(this, [options]);
  },

  canShowDeleteButton() {
    return false;
  },

  _save(data) {
    this.options.callback(data);
    this.isDestroyed = true;
    this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
  },
});
