import Backbone from 'backbone';
import _ from 'underscore';

import { BiteUrl, Log } from '@biteinc/common';

app.Image = Backbone.Model.extend({
  initialize(attributes, options) {
    this.uploadParams = options.uploadParams || {};
    this.schema = options.schema;
    this.fileCounter = 0;
  },

  src() {
    if (this.has('image')) {
      return this.get('image').src;
    }
    return BiteUrl.biteAssetUrlWithNewHost(this.get('url'));
  },

  width() {
    return this.has('image') ? this.get('image').width : this.get('width');
  },

  height() {
    return this.has('image') ? this.get('image').height : this.get('height');
  },

  setFromFile(file) {
    this.file = file;
    const fileCounter = this.fileCounter + 1;
    this.fileCounter = fileCounter;

    if (!file) {
      return null;
    }

    if (!_.contains(['image/jpeg', 'image/png', 'image/gif', 'image/webp'], file.type)) {
      return 'This file type is not supported. Only jpg/jpeg, png, gif, and webp files can be uploaded to Bite.';
    }
    const maxFileSizeMb = this.schema.maxFileSizeMb || 10;
    const maxFileSizeBytes = maxFileSizeMb * 1024 * 1024;
    const fileSize = Math.round(parseInt(file.size, 10));
    if (fileSize > maxFileSizeBytes) {
      return `This file is too big. The max file size is ${maxFileSizeMb}Mb.`;
    }

    const self = this;
    const reader = new window.FileReader();
    reader.onload = (function readerOnLoad(f) {
      return (e) => {
        if (self.fileCounter === fileCounter) {
          const image = new window.Image();
          image.src = e.target.result;
          image.onload = function imageOnLoad() {
            if (self.fileCounter === fileCounter) {
              self.set('image', image);

              const schema = self.schema;
              if (self.file.type !== 'image/jpeg') {
                self.uploader.settings.resize.width = schema.size.width;
                self.uploader.settings.resize.height = schema.size.height;
              }
              const ratio = self.width() / self.height();
              if (ratio < 1.2 && !schema.forceCrop) {
                self.uploader.settings.resize.crop = false;
              }
              if (schema.keepAspectRatio) {
                self.uploader.settings.resize.crop = false;

                if (
                  self.width() <= self.uploader.settings.resize.width &&
                  self.height() <= self.uploader.settings.resize.height
                ) {
                  self.uploader.settings.resize.width = self.width();
                  self.uploader.settings.resize.height = self.height();
                }
              }

              self.startUploading(f);
            }
          };
        }
      };
    })(file);
    reader.readAsDataURL(file);
    return null;
  },

  startUploading() {
    this.trigger(app.Image.Events.ImageUploadDidStart, this);
    if (this._hasValidUploadUrl()) {
      this._uploadFileToS3();
    } else {
      this._getUploadUrls(this.file);
    }
  },

  _hasValidUploadUrl() {
    return !!this.uploads && Date.now() - this.uploads.timestamp > 60 * 1000;
  },

  _getUploadUrls(file) {
    const self = this;
    const fileCounter = this.fileCounter;
    const params = _.extend(this.uploadParams, {
      contentType: file.type,
    });
    const url = '/api/imageUploads/assets';
    window.app.postRequest(
      url,
      params,
      (data) => {
        if (self.fileCounter === fileCounter) {
          self.uploads = data;
          self.uploads.timestamp = Date.now();
          self._uploadFileToS3(file);
        }
      },
      () => {
        if (self.fileCounter === fileCounter) {
          self.trigger(app.Image.Events.ImageUploadDidFail, self);
        }
      },
    );
  },

  _uploadFileToS3() {
    const self = this;
    const fileCounter = this.fileCounter;
    this.uploader.bind('UploadProgress', (up, file) => {
      if (self.fileCounter === fileCounter) {
        const eventName = app.Image.Events.ImageUploadDidProgress;
        self.trigger(eventName, self, file.percent, file.isOriginal);
      }
    });
    this.uploader.bind('Error', (up, err) => {
      if (self.fileCounter === fileCounter) {
        Log.error('uploader err', err);
        self.trigger(app.Image.Events.ImageUploadDidFail, self);
      }
    });
    this.uploader.bind('FileUploaded', (up, file) => {
      if (self.fileCounter === fileCounter) {
        const setDict = {};
        if (file.isOriginal) {
          setDict.origUrl = self.uploads.originalImage.url;
          setDict.origWidth = self.width();
          setDict.origHeight = self.height();
        } else {
          setDict.url = self.uploads.processedImage.url;
          if (up.settings.resize.crop) {
            setDict.width = up.settings.resize.width;
            setDict.height = up.settings.resize.height;
          } else {
            const ratio = self.width() / self.height();
            if (ratio >= 1) {
              setDict.width = up.settings.resize.width;
              setDict.height = Math.round(up.settings.resize.width / ratio);
            } else {
              setDict.width = Math.round(up.settings.resize.height * ratio);
              setDict.height = up.settings.resize.height;
            }
          }
        }
        self.set(setDict);
        if (file.isOriginal) {
          self.set({ image: null });
          self.uploads = null;
          self.trigger(app.Image.Events.ImageUploadDidFinish, self);
        }
      }
    });
    this.uploader.bind('BeforeUpload', (up, file) => {
      if (file.isOriginal) {
        up.settings.url = self.uploads.originalImage.signedUrl;
      } else {
        up.settings.url = self.uploads.processedImage.signedUrl;
      }
      up.settings.headers = {
        'Content-Type': file.type,
      };
    });
    this.uploader.start();
  },
});

app.Image.Events = {
  ImageUploadDidFail: 'imageUploadDidFail',
  ImageUploadDidFinish: 'imageUploadDidFinish',
  ImageUploadDidProgress: 'imageUploadDidProgress',
  ImageUploadDidStart: 'imageUploadDidStart',
};
