import type { Schema } from '@biteinc/common';
import { WebhookEvent, WebhookType } from '@biteinc/enums';

export const webhookSchema: Schema.Model = {
  displayName: 'webhook',
  type: 'object',
  fields: {
    url: {
      type: 'url',
      displayName: 'URL',
      required: true,
    },
    locationGroupId: {
      type: 'mongoId',
      displayName: 'Locations',
      ui: 'dropdown',
      required: true,
    },
    events: {
      displayName: 'Events',
      type: 'array',
      elementType: 'stringEnum',
      elementEnum: WebhookEvent,
      ui: 'table',
      placeholder: 'Select Events',
      required: true,
      minCount: 1,
    },
    type: {
      displayName: 'Type',
      type: 'stringEnum',
      enum: WebhookType,
      ui: 'dropdown',
      required: true,
    },
    privateKey: {
      type: 'keyString',
      displayName: 'Private Key',
      isReadOnly: true,
      tooltip: {
        text: 'Generated by Bite Server',
      },
    },
  },
};
