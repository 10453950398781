import $ from 'jquery';

import { Log } from '@biteinc/common';

app.InstallJqueryLoadingButton = function installJqueryLoadingButton() {
  $.fn.initLoadingButton = function initLoadingButton(title, loadingTitle, successTitle) {
    this._reqId = (this._reqId || 0) + 1;
    this._isPending = true;
    this._title = title || this.html();
    this._loadingTitle = loadingTitle;
    this._successTitle = successTitle || `${title}d!`;
    this.loadingDidStart(this._reqId);
    return this._reqId;
  };
  $.fn.setTitle = function setTitle(title) {
    this._title = title;
    if (!this._isPending) {
      this.text(this._title);
    }
  };
  $.fn.loadingDidStart = function loadingDidStart(reqId) {
    if (!this._reqId) {
      Log.error('Please init the loading button first', this);
      return;
    }
    if (this._reqId === reqId) {
      this.html(this._loadingTitle);
      this.toggleClass('disabled', true);
      this.toggleClass('saved', false);
      this.toggleClass('with-spinner', true);
    }
  };
  $.fn._resetLoadingButton = function resetLoadingButton(reqId) {
    if (this._reqId === reqId) {
      this.html(this._title);
      this.toggleClass('disabled', false);
      this.toggleClass('saved', false);
      this.toggleClass('with-spinner', false);
      this._isPending = false;
    }
  };
  $.fn.loadingDidFinishWithError = function loadingDidFinishWithError(reqId) {
    if (!this._reqId) {
      Log.error('Please init the loading button first', this);
      return;
    }
    this._resetLoadingButton(reqId);
  };
  $.fn.loadingDidFinishSuccessfully = function loadingDidFinishSuccessfully(reqId) {
    if (!this._reqId) {
      Log.error('Please init the loading button first', this);
      return;
    }
    if (this._reqId === reqId) {
      this.html(`\u2713 ${this._successTitle}`);
      this.toggleClass('disabled', true);
      this.toggleClass('saved', true);
      this.toggleClass('with-spinner', false);
      setTimeout(() => {
        this._resetLoadingButton(reqId);
      }, 1500);
    }
  };
};
