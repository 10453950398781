import type { Schema } from '@biteinc/common';
import { allEnumValues, BiteLogType } from '@biteinc/enums';

export function buildBiteLogListFilterSchema(
  hasOrg: boolean,
  hasSite: boolean,
  hasLocation: boolean,
): Schema.ModelTypedObjectField {
  const schema: Schema.ModelTypedObjectField = {
    type: 'object',
    fields: {
      fromTimestamp: {
        type: 'timestamp',
        displayName: 'From',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: !!hasSite,
      },
      toTimestamp: {
        type: 'timestamp',
        displayName: 'To',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: !!hasSite,
      },
      logType: {
        type: 'int',
        displayName: 'Log Type',
        ui: 'dropdown',
        isNullable: true,
      },
      logStatus: {
        type: 'int',
        displayName: 'Log Status',
        ui: 'dropdown',
        isNullable: true,
        conditions: [
          {
            field: 'logType',
            // Every type except for Simple
            values: allEnumValues<BiteLogType>(BiteLogType).filter((logType) => {
              return logType !== BiteLogType.Simple;
            }),
          },
        ],
      },
      ...(hasLocation && {
        orderId: {
          type: 'mongoId',
          displayName: 'Order ID',
        },
        kioskQuery: {
          type: 'mongoId',
          displayName: 'Kiosk',
          tooltip: {
            text: 'Kiosk ID or Full Name ("A" will not return results if the full name is "Kiosk A")',
          },
        },
      }),
    },
  };
  return schema;
}
