app.BiteLogList = app.AbstractCollection.extend({
  model: app.BiteLog,

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  url() {
    return '/api/v2/bite-logs';
  },
});
