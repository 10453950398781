app.UserRoleList = app.AbstractCollection.extend({
  model: app.UserRole,

  url: () => {
    if (app.location) {
      return `/api/orgs/${app.location.get('orgId')}/locations/${app.location.id}/userRoles`;
    }
    return `/api/orgs/${app.org.id}/userRoles`;
  },
});
