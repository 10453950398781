import async from 'async';
import Backbone from 'backbone';

app.ExtendBackboneAjax = function ExtendBackboneAjax() {
  const oldAjax = Backbone.ajax;
  Backbone.ajax = function ajax(options) {
    if (options.paginatedFetchOptions) {
      let nextUrl = null;
      const models = [];
      let lastResult = null;
      async.doWhilst(
        (cb) => {
          oldAjax.apply(Backbone, [
            {
              ...options,
              url: nextUrl || options.url,
              error: (response) => {
                cb(response);
              },
              success: (result) => {
                lastResult = result;
                if (result?.data?.next) {
                  nextUrl = result.data.next;
                } else {
                  nextUrl = null;
                }

                // This may not be a paginated response.
                if (!nextUrl && !models.length) {
                  cb();
                  return;
                }

                models.push(...result.data[options.paginatedFetchOptions.key]);

                cb();
              },
            },
          ]);
        },
        (cb) => {
          cb(null, !!nextUrl);
        },
        (err) => {
          if (err) {
            options.error(err);
          } else {
            // We want to be super careful and make sure that if this request wasn't meant to be
            // paginated we don't massage the response at all.
            // If it was paginated and we got something back, then we want to make sure that
            // preserve the last response as much as possible except for returning more models.
            options.success(
              models.length
                ? {
                    ...lastResult,
                    data: {
                      ...lastResult.data,
                      [options.paginatedFetchOptions.key]: models,
                    },
                  }
                : lastResult,
            );
          }
        },
      );
    } else {
      oldAjax.apply(Backbone, [options]);
    }
  };
};
