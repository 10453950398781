import { DayPartHelper } from '@biteinc/business-rules';
import { ModelType } from '@biteinc/enums';

import { openHoursTimetableSchema } from './open_hours_timetable_schema';

app.OpenHoursTimetable = app.AbstractModel.extend({
  ModelName: 'openHoursTimetable',
  Schema: openHoursTimetableSchema,
  Type: ModelType.OpenHoursTimetable,

  displayName() {
    const diningOptionName = app.location.getDiningOptionName(this.get('fulfillmentMethod'));
    const summary = DayPartHelper.openHoursTimetableSummary(this.get('daySchedules') ?? []).join(
      ', ',
    );

    return `${diningOptionName}${summary.length ? ` (${summary})` : ''}`;
  },

  canBeDestroyed() {
    return false;
  },

  fieldIsPermanent() {
    return app.location.syncsModelsWithType(this.Type);
  },
});
