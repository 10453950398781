import Sortable from 'sortablejs';
import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

import { template } from '../../template';

app.ArrayFieldRowView = app.FieldGroupView.extend({
  className() {
    return `${app.FieldGroupView.prototype.className.apply(
      this,
      arguments,
    )} map-field-row-view clearfix`;
  },

  setValue(value, model) {
    app.FieldGroupView.prototype.setValue.apply(this, arguments);
    this.$name.text(model.displayName());
  },

  render() {
    app.FieldGroupView.prototype.render.apply(this, arguments);

    this.$name = $('<label class="col-form-label col-md-4 name"></label>');
    this.$el.prepend(this.$name);

    this.$deleteButton = $('<button type="button">Delete</button>');
    this.$deleteButton.addClass('btn btn-danger');
    this.$el.prepend(this.$deleteButton);

    return this;
  },
});

app.ArrayFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} array-field-view`;
  },
  template: template(
    '<label class="col-form-label col-md-6"></label>' +
      '<div class="col-md-12 collapsible">' +
      '<div class="page-container card panel-default list-group">' +
      '<div class="page-list list-group"></div>' +
      '<div class="control-pane page">' +
      '<div class="new-panel input-group card-body">' +
      '<input type="text" size="40" class="form-control" />' +
      '<span class="input-group-btn">' +
      '<button type="button" class="btn btn-primary create">Add</button>' +
      '</span>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '<div>',
  ),

  setValue(value, model) {
    const self = this;

    // Create a new array object so that the pointer would be different
    this.value = [].concat(value || []);
    this.model = model;
    this.initialValue = value;

    this.$pageList.html('');

    _.each(this.value, (valueElement) => {
      self._addElement(valueElement);
    });

    this.toggleCollapsedView(!this.value.length);
  },

  getValue() {
    const list = [].concat(this.value);
    if (!list.length && !this.initialValue) {
      return this.initialValue;
    }
    return list;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const list = this.getValue() || [];
    if (_.has(this.schema, 'minCount') && list.length < this.schema.minCount) {
      this.setState(this.states.ERROR);
      const { minCount } = this.schema;
      return {
        isValid: false,
        invalidFieldNames: [
          `Provide at least ${minCount} ${StringHelper.pluralize('entry', minCount)}`,
        ],
      };
    }
    if (_.has(this.schema, 'maxCount') && list.length > this.schema.maxCount) {
      this.setState(this.states.ERROR);
      const { maxCount } = this.schema;
      return {
        isValid: false,
        invalidFieldNames: [
          `Provide at most ${maxCount} ${StringHelper.pluralize('entry', maxCount)}`,
        ],
      };
    }
    this.setState(this.states.SUCCESS);
    return { isValid: true };
  },

  _addElement(element, animated) {
    const self = this;
    const cell = new app.ComplexCellView({
      confirmation: 'Are you sure you want to delete this footnote?',
      onDelete() {
        self.removeElement(element);
        cell.remove();
        self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
      },
      onRename(name, callback) {
        self.replaceElement(element, name);
        if (element !== name) {
          // eslint-disable-next-line no-param-reassign
          element = name;
          self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
        }
        if (callback) {
          callback();
        }
      },
      getName() {
        return element;
      },
      canDelete() {
        return !self.isReadOnly;
      },
      canRename() {
        return !self.isReadOnly;
      },
      canReorder() {
        return !self.isReadOnly;
      },
    });
    this.$pageList.append(cell.render().$el);
    cell.$el.find('.form-control').attr('placeholder', 'enter footnote');

    if (animated) {
      cell.$el.hide();
      cell.$el.slideDown(400, 'easeInOutCubic');
    }
  },

  removeElement(element) {
    const index = this.value.indexOf(element);
    if (index > -1) {
      this.value.splice(index, 1);
    }
  },

  replaceElement(origElement, newElement) {
    const index = this.value.indexOf(origElement);
    if (index > -1) {
      this.value[index] = newElement;
    }
  },

  moveElement(oldIndex, newIndex) {
    const element = this.value[oldIndex];
    this.value.splice(oldIndex, 1);
    this.value.splice(newIndex, 0, element);
  },

  _setupControlPane() {
    const self = this;
    this.$controlPane
      .find('.new-panel input')
      .attr('placeholder', this.schema.placeholder || 'add another');
    app.attachNewFormListeners(self.$controlPane, (name, $button, $inputField) => {
      $inputField.val('');

      self.value.push(name);
      self._addElement(name, true);
      self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
    });
  },

  render() {
    this.$el.html(this.template());

    this.$controlPane = this.$('.control-pane');
    this.$addButton = this.$('button.create');
    this.$pageList = this.$('.page-list');

    this.$('.col-form-label').html(`${this.getDisplayName()}:`);

    this._setupControlPane();

    this.addCollapseButton();

    if (this.isReadOnly) {
      this.$controlPane.hide();
    }

    const self = this;
    Sortable.create(self.$pageList[0], {
      handle: '.handle',
      delay: app.sortableDelay(),
      draggable: '.sortable',
      ghostClass: 'ghost',
      onEnd(e) {
        const oldIndex = e.oldIndex >= 0 ? e.oldIndex : -1;
        const newIndex = e.newIndex >= 0 ? e.newIndex : -1;
        if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
          self.moveElement(oldIndex, newIndex);
          self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
        }
      },
    });

    return this;
  },
});
