import _ from 'underscore';

import { TriggerEventName } from '@biteinc/enums';
import { VitrineTriggerEventName } from '@biteinc/enums/trigger_event_name';

import { ConditionSchemaName } from '../enums/condition_schema_name';

export module RecommendationRuleHelper {
  type DeconstructedConditionSchema = {
    name: ConditionSchemaName;
    itemIds?: string[];
    sectionIds?: string[];
    sectionId?: string;
  };

  // TODO should we install ajv and make recommendation ask type global to get the actually type?
  type JSONSchemaType = any;

  function buildPreCheckoutPopupTriggerEventNameConditionSchema(): JSONSchemaType {
    return {
      type: 'object',
      required: ['triggerEventName'],
      properties: {
        triggerEventName: {
          type: 'string',
          const: TriggerEventName.PreCheckout,
        },
      },
    };
  }

  function buildSideCartTriggerEventNameConditionSchema(): JSONSchemaType {
    return {
      type: 'object',
      required: ['triggerEventName'],
      properties: {
        triggerEventName: {
          type: 'string',
          const: TriggerEventName.CartView,
        },
      },
    };
  }

  function buildMenuSectionIsRecommendationConditionSchema(sectionId?: string): JSONSchemaType {
    return {
      type: 'object',
      required: ['triggerEventName', 'sectionId'],
      properties: {
        triggerEventName: {
          type: 'string',
          const: VitrineTriggerEventName.MenuSectionRecommendation,
        },
        sectionId: {
          type: 'string',
          const: sectionId,
        },
      },
    };
  }

  function getDeconstructedMenuSectionRecommendationPaneConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (
      schema?.properties?.triggerEventName?.const !==
        VitrineTriggerEventName.MenuSectionRecommendation ||
      !schema?.properties?.sectionId
    ) {
      return null;
    }

    const sectionId = schema.properties.sectionId.const;
    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildMenuSectionIsRecommendationConditionSchema(sectionId);

    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.MenuSectionIsRecommendation,
      sectionId,
    };
  }

  function getDeconstructedPreCheckoutPopupTriggerEventNameConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    const rebuiltSchema = buildPreCheckoutPopupTriggerEventNameConditionSchema();
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.PreCheckoutPopup,
    };
  }

  function getDeconstructedSideCartTriggerEventNameConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    const rebuiltSchema = buildSideCartTriggerEventNameConditionSchema();
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.CartView,
    };
  }

  function buildOneOfMenuItemsAreInCartConditionSchema(itemIds?: string[]): JSONSchemaType {
    return {
      type: 'object',
      required: ['cartItems'],
      properties: {
        cartItems: {
          type: 'array',
          minItems: 1,
          contains: {
            type: 'object',
            required: ['id'],
            properties: {
              id: {
                type: 'string',
                enum: itemIds,
              },
            },
          },
        },
      },
    };
  }

  function getDeconstructedOneOfMenuItemsAreInCartConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (!schema?.properties?.cartItems?.contains?.properties?.id?.enum) {
      return null;
    }
    // get any variables from the prebuilt schema
    const itemIds = schema.properties.cartItems.contains.properties.id.enum;
    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuItemsAreInCartConditionSchema(itemIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.OneOfMenuItemsAreInCart,
      itemIds,
    };
  }

  function buildOneOfMenuSectionsAreInCartConditionSchema(sectionIds?: string[]): JSONSchemaType {
    return {
      type: 'object',
      required: ['cartItems'],
      properties: {
        cartItems: {
          type: 'array',
          minItems: 1,
          contains: {
            type: 'object',
            required: ['sectionId'],
            properties: {
              sectionId: {
                type: 'string',
                enum: sectionIds,
              },
            },
          },
        },
      },
    };
  }

  function getDeconstructedOneOfMenuSectionsAreInCartConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (!schema?.properties?.cartItems?.contains?.properties?.sectionId?.enum) {
      return null;
    }
    // get any variables from the prebuilt schema
    const sectionIds = schema.properties.cartItems.contains.properties.sectionId.enum;
    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuSectionsAreInCartConditionSchema(sectionIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }
    return {
      name: ConditionSchemaName.OneOfMenuSectionsAreInCart,
      sectionIds,
    };
  }

  function buildOneOfMenuItemsIsSelectedItemConditionSchema(itemIds?: string[]): JSONSchemaType {
    return {
      type: 'object',
      required: ['selectedItem'],
      properties: {
        selectedItem: {
          type: 'object',
          required: ['id'],
          properties: {
            id: {
              type: 'string',
              enum: itemIds,
            },
          },
        },
      },
    };
  }

  function getDeconstructedOneOfMenuItemsIsSelectedItemConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (!schema?.properties?.selectedItem?.properties?.id?.enum) {
      return null;
    }
    // get any variables from the prebuilt schema
    const itemIds = schema.properties.selectedItem.properties.id.enum;
    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuItemsIsSelectedItemConditionSchema(itemIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.OneOfMenuItemsIsSelectedItem,
      itemIds,
    };
  }

  function buildOneOfMenuSectionsIsSelectedItemConditionSchema(
    sectionIds?: string[],
  ): JSONSchemaType {
    return {
      type: 'object',
      required: ['selectedItem'],
      properties: {
        selectedItem: {
          type: 'object',
          required: ['sectionId'],
          properties: {
            sectionId: {
              type: 'string',
              enum: sectionIds,
            },
          },
        },
      },
    };
  }

  function getDeconstructedOneOfMenuSectionsIsSelectedItemConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (!schema?.properties?.selectedItem?.properties?.sectionId?.enum) {
      return null;
    }
    // get any variables from the prebuilt schema
    const sectionIds = schema.properties.selectedItem.properties.sectionId.enum;
    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuSectionsIsSelectedItemConditionSchema(sectionIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.OneOfMenuSectionsIsSelectedItem,
      sectionIds,
    };
  }

  function buildOneOfMenuItemsAreSelectedItemOrInCartConditionSchema(
    itemIds?: string[],
  ): JSONSchemaType {
    return {
      anyOf: [
        buildOneOfMenuItemsAreInCartConditionSchema(itemIds),
        buildOneOfMenuItemsIsSelectedItemConditionSchema(itemIds),
      ],
    };
  }

  function getDeconstructedOneOfMenuItemsAreSelectedItemOrInCartConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (schema?.anyOf?.length !== 2) {
      return null;
    }

    // get any variables from the prebuilt schema
    // get itemIds from just one of the sub-schemas
    const deconstructedSubSchema = getDeconstructedOneOfMenuItemsAreInCartConditionSchema(
      schema.anyOf[0],
    );
    if (!deconstructedSubSchema) {
      return null;
    }
    const { itemIds } = deconstructedSubSchema;

    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuItemsAreSelectedItemOrInCartConditionSchema(itemIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.OneOfMenuItemsAreSelectedItemOrInCart,
      itemIds,
    };
  }

  function buildOneOfMenuSectionsAreSelectedItemOrInCartConditionSchema(
    sectionIds?: string[],
  ): JSONSchemaType {
    return {
      anyOf: [
        buildOneOfMenuSectionsAreInCartConditionSchema(sectionIds),
        buildOneOfMenuSectionsIsSelectedItemConditionSchema(sectionIds),
      ],
    };
  }

  function getDeconstructedOneOfMenuSectionsAreSelectedItemOrInCartConditionSchema(
    schema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (schema?.anyOf?.length !== 2) {
      return null;
    }

    // get any variables from the prebuilt schema
    // get sectionIds from just one of the sub-schemas
    const deconstructedSubSchema = getDeconstructedOneOfMenuSectionsAreInCartConditionSchema(
      schema.anyOf[0],
    );
    if (!deconstructedSubSchema) {
      return null;
    }
    const { sectionIds } = deconstructedSubSchema;

    // rebuild the prebuilt schema with the variables
    const rebuiltSchema = buildOneOfMenuSectionsAreSelectedItemOrInCartConditionSchema(sectionIds);
    // directly compare to see if we have an original prebuilt schema
    if (!_.isEqual(schema, rebuiltSchema)) {
      return null;
    }

    return {
      name: ConditionSchemaName.OneOfMenuSectionsAreSelectedItemOrInCart,
      sectionIds,
    };
  }

  // the model on the server only stores the condition schema in JSON schema format
  // so in order to correctly render the bureau UI with the prebuilt schemas
  // we need to determine which prebuilt schema the condition schema comes from
  // and extract any relevant variables
  export function getDeconstructedConditionSchema(
    conditionSchema: JSONSchemaType,
  ): DeconstructedConditionSchema | null {
    if (!conditionSchema) {
      return null;
    }

    // check against our prebuilt condition schemas and see if it matches any
    // inefficient, but whatever, tests the whole code path
    return (
      [
        getDeconstructedOneOfMenuItemsAreInCartConditionSchema(conditionSchema),
        getDeconstructedOneOfMenuSectionsAreInCartConditionSchema(conditionSchema),
        getDeconstructedOneOfMenuItemsIsSelectedItemConditionSchema(conditionSchema),
        getDeconstructedOneOfMenuSectionsIsSelectedItemConditionSchema(conditionSchema),
        getDeconstructedOneOfMenuItemsAreSelectedItemOrInCartConditionSchema(conditionSchema),
        getDeconstructedOneOfMenuSectionsAreSelectedItemOrInCartConditionSchema(conditionSchema),
        getDeconstructedPreCheckoutPopupTriggerEventNameConditionSchema(conditionSchema),
        getDeconstructedSideCartTriggerEventNameConditionSchema(conditionSchema),
        getDeconstructedMenuSectionRecommendationPaneConditionSchema(conditionSchema),
      ].find((result) => {
        return !!result;
      }) || null
    );
  }

  // the model on the server only stores the condition schema in JSON schema format
  // in bureau UI, we have extra UI elements to help build common prebuilt schemas
  // based on those what the user has selected, build the schema we can send to the server
  export function buildConditionSchema(
    // attributes from the bureau UI model
    attrs: {
      conditionSchema?: string;
      conditionSchemaName?: ConditionSchemaName;
      conditionSchemaSectionIds?: string[];
      conditionSchemaSectionId?: string;
      conditionSchemaItemIds?: string[];
    },
  ): string | undefined {
    const {
      conditionSchema,
      conditionSchemaName,
      conditionSchemaSectionIds,
      conditionSchemaSectionId,
      conditionSchemaItemIds,
    } = attrs;

    switch (conditionSchemaName) {
      case ConditionSchemaName.OneOfMenuSectionsIsSelectedItem: {
        const schema =
          buildOneOfMenuSectionsIsSelectedItemConditionSchema(conditionSchemaSectionIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.OneOfMenuSectionsAreInCart: {
        const schema = buildOneOfMenuSectionsAreInCartConditionSchema(conditionSchemaSectionIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.OneOfMenuSectionsAreSelectedItemOrInCart: {
        const schema =
          buildOneOfMenuSectionsAreSelectedItemOrInCartConditionSchema(conditionSchemaSectionIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.OneOfMenuItemsIsSelectedItem: {
        const schema = buildOneOfMenuItemsIsSelectedItemConditionSchema(conditionSchemaItemIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.OneOfMenuItemsAreInCart: {
        const schema = buildOneOfMenuItemsAreInCartConditionSchema(conditionSchemaItemIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.OneOfMenuItemsAreSelectedItemOrInCart: {
        const schema =
          buildOneOfMenuItemsAreSelectedItemOrInCartConditionSchema(conditionSchemaItemIds);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.PreCheckoutPopup: {
        const schema = buildPreCheckoutPopupTriggerEventNameConditionSchema();
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.CartView: {
        const schema = buildSideCartTriggerEventNameConditionSchema();
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.MenuSectionIsRecommendation: {
        const schema = buildMenuSectionIsRecommendationConditionSchema(conditionSchemaSectionId);
        return JSON.stringify(schema, null, 2);
      }
      case ConditionSchemaName.None:
        return;
      case ConditionSchemaName.Custom:
      default:
        return conditionSchema;
    }
  }
}
