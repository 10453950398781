import Backbone from 'backbone';

import { template } from '../template';

app.ModalView = Backbone.View.extend({
  template: template($('#modal-view-template').html()),

  initialize(options) {
    this.uid = Date.now();
    this.view = options.view;
    this.listenTo(this.view, app.ModalView.Events.ModalViewShouldHide, this._modalViewShouldHide);
  },

  setBackgroundColor(color) {
    this.$('.modal-content').css('background-color', color);
  },

  setMaxWidth(width) {
    this.$('.modal-dialog').css('max-width', width);
  },

  render() {
    this.setElement(this.template());

    this.$header = this.$('.modal-header');
    this.$titleLabel = this.$el.find('h4.modal-title');
    this.$buttonContainer = this.$el.find('.modal-header .right-button-container');
    this.$body = this.$el.find('.modal-body');

    this.footer = new app.ModalFooterView({
      className: 'modal-footer',
      $headerTitle: this.$titleLabel,
    });
    this.footer.render().$el.insertAfter(this.$body);
    this.footer.$discardButton.click(() => {
      this._modalViewShouldHide();
      return false;
    });
    this.footer.$hardDiscardButton.click(() => {
      this._modalViewShouldHide();
      return false;
    });

    this.$('.modal-header button.close').click(this._modalViewShouldHide.bind(this));

    this.view.setSuperView(this);
    this.$body.html(this.view.render().el);

    $(window).on(`resize.${this.uid}`, this._adjustSizeToFitWindow.bind(this));
    this.el.addEventListener('show.bs.modal', this._adjustSizeToFitWindow.bind(this));
    this.el.addEventListener('shown.bs.modal', this._adjustSizeToFitWindow.bind(this));

    return this;
  },

  clearState() {
    if (this.view) {
      $(window).off(`resize.${this.uid}`);
      this.stopListening(this.view);
      this.view.clearState();
      this.view = null;
    }
  },

  canDismissModal() {
    return !this.view.checkForUnsavedChanges || !this.view.checkForUnsavedChanges();
  },

  _modalViewShouldHide() {
    app.modalManager.hideModal(this);
  },

  _adjustSizeToFitWindow() {
    let margin = 30;
    if ($('body').width() < 560) {
      margin = 24;
    }

    let bodyHeight = window.innerHeight || $('body')[0].clientHeight;
    if (this.$header.length) {
      bodyHeight -= this.$el.find('.modal-header').outerHeight() || 56;
    }
    if (this.$el.find('.modal-footer').length) {
      bodyHeight -= this.$el.find('.modal-footer').outerHeight() || 65;
    }

    // Try to center the modal
    if (this.$body.height() > 0 && this.$body.outerHeight() < bodyHeight) {
      margin += Math.floor((bodyHeight - this.$body.outerHeight()) / 2);
    }
    this.$('.modal-dialog').css('margin-top', `${margin}px`);
    bodyHeight -= margin;

    bodyHeight -= 2; // borders
    this.$body.css('max-height', `${bodyHeight}px`);
  },
});

app.ModalView.Events = {
  ModalViewShouldHide: 'modalViewShouldHide',
};
