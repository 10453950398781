// cspell:ignore ciphertext

import CryptoJS from 'crypto-js';

export module CryptHelper {
  /**
   * Returns the hex-encoded, AES-256-ECB ciphertext of `text` using `key`.
   */
  export function encrypt(text: string, key: string): string {
    return CryptoJS.AES.encrypt(text, CryptoJS.enc.Hex.parse(key), {
      mode: CryptoJS.mode.ECB,
    }).ciphertext.toString(CryptoJS.enc.Base64);
  }

  export function generateHash(text: string): string {
    return CryptoJS.MD5(text).toString(CryptoJS.enc.Hex);
  }
}
