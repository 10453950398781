import { customDomainMetaDataSchema } from '@biteinc/schemas';

app.CustomDomainMetaDataView = app.NestedObjectFieldView.extend({
  getNestedObjectDefaultValue() {
    return {
      favicon: [],
      appleTouchIcon: [],
      ogImage: [],
      ogTitle: '',
      ogDescription: '',
    };
  },

  getNestedObjectSchema() {
    return customDomainMetaDataSchema;
  },
});
