import Backbone from 'backbone';

import { KeyCode } from '../enums/key_code';
import { template } from '../template';

app.LoginView = Backbone.View.extend({
  template: template($('#login-template').html()),

  events: {
    'keyup input.username': '_onUsernameKeyUp',
    'keyup input.password': '_onPasswordKeyUp',
    'change input.username': '_validateInputs',
    'change input.password': '_validateInputs',
    'click button.submit': '_submitButtonWasClicked',
  },

  _validateInputs() {
    // HACK: We can't detect when Chrome has autofilled the password field by
    // querying the value of the field. However, we can detect if it decided
    // to color it not white (to show that it has been autofilled).
    const passwordColor = getComputedStyle(this.$password[0]).backgroundColor;
    const passwordIsAutoFilled = 'rgb(255, 255, 255)' !== passwordColor;
    const isValid =
      this.$username.val().trim().length > 0 &&
      (this.$password.val().length > 0 || passwordIsAutoFilled);

    this._submitButtonIsDisabled = !isValid;
    this.$('button.submit').prop('disabled', !isValid);
  },

  _onUsernameKeyUp(e) {
    this._validateInputs();
    if (KeyCode.Enter === e.keyCode && this.$username.val().trim().length) {
      this.$password.focus();
    }
  },

  _onPasswordKeyUp(e) {
    this._validateInputs();
    if (
      KeyCode.Enter === e.keyCode &&
      this.$username.val().trim().length &&
      this.$password.val().length
    ) {
      this._submitButtonWasClicked();
    }
  },

  _submitButtonWasClicked() {
    if (this._submitButtonIsDisabled) {
      return;
    }

    const username = this.$username.val().trim();
    const password = this.$password.val();

    this._submitButtonIsDisabled = true;
    this.$message.hide();
    this._adjustAppHeight();
    app.makeRequest(
      'POST',
      '/api/auth/login',
      {
        username,
        password,
      },
      (_response) => {
        this._submitButtonIsDisabled = false;
        if ((this.postLoginUrl || '').length) {
          window.location = this.postLoginUrl;
        } else {
          window.location = '/';
        }
      },
      (error) => {
        this._submitButtonIsDisabled = false;
        this.$message.text(error || '').show();
        this._adjustAppHeight();
      },
    );
  },

  _adjustAppHeight() {
    this.$el.css('padding', '0px');
    $('body').toggleClass('login-view', true);
    const winHeight = window.innerHeight || $('body')[0].clientHeight;
    const appHeight = Math.ceil(this.$('.login.card').outerHeight()) + 100;

    if (appHeight < winHeight) {
      const margin = (winHeight - appHeight) / 2;
      this.$el.css('padding', `${Math.floor(margin)}px 0`);
    }
  },

  _getParameterByName(name) {
    const url = window.location.href;
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  render() {
    this.$el.html(this.template());
    this.$username = this.$('input.username');
    this.$password = this.$('input.password');
    this.$message = this.$('.message');

    const message = this._getParameterByName('msg');
    if (message) {
      this.$message.text(message).toggle(true);
    }
    const url = this._getParameterByName('url');
    if (url) {
      this.postLoginUrl = url + window.location.hash.trim();
    }

    this.$el.css('padding', '0px');

    $(window).on('resize', this._adjustAppHeight.bind(this));
    setTimeout(() => {
      this._adjustAppHeight();
      this.$username.focus();
      this._validateInputs();
    }, 1);

    return this;
  },
});
