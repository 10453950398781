import { Log } from '@biteinc/common';
import { KioskNotificationType } from '@biteinc/enums';
import { RequestSchemas } from '@biteinc/schemas';

app.KioskConfig = app.AbstractModel.extend({
  ModelName: 'kioskConfig',
  Schema: {
    displayName: 'kiosk config',
    fields: {
      config: {
        type: 'map',
        displayName: 'Kiosk Config',
        key: {
          type: 'keyString',
          displayName: 'Config Key',
          ui: 'dropdown',
        },
        fields: {
          value: {
            type: 'keyString',
          },
        },
      },
    },
  },

  save(data, opts) {
    const config = {};
    Object.entries(data.config).forEach(([fieldName, fieldObject]) => {
      const fieldSchema = RequestSchemas.kiosks.notificationConfig.fields.config.fields[fieldName];
      if ('value' in fieldObject) {
        config[fieldName] = fieldObject.value;
      } else {
        switch (fieldSchema.type) {
          case 'keyString':
            config[fieldName] = '';
            break;
          case 'bool':
            config[fieldName] = false;
            break;
          default:
            Log.error('unhandled type in config map', fieldSchema.type);
            break;
        }
      }
    });

    this.options.kiosk.notifyDevice(
      KioskNotificationType.ConfigUpdate,
      {
        config,
      },
      {
        error: (err) => {
          app.showSavedToast('Failed. :(');
          if (opts.error) {
            opts.error(err);
          }
        },
        success: (d, response) => {
          this.set(data);
          if (opts.success) {
            opts.success(this, response);
          }
        },
      },
    );
  },

  getFieldCollection(field /* subProperty, includeAllValues */) {
    switch (field) {
      case 'config':
        return app.AbstractCollection.createFromEnum({
          schema: Object.entries(RequestSchemas.kiosks.notificationConfig.fields.config.fields).map(
            ([fieldName, fieldSchema]) => {
              return {
                _id: fieldName,
                name: fieldSchema.displayName || fieldName,
                Schema: {
                  fields: {
                    value: {
                      type: fieldSchema.type,
                    },
                  },
                },
              };
            },
          ),
        });
    }
    return null;
  },
});
