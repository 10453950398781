import { MaitredActivityType, MaitredActivityUpdateType } from '@biteinc/enums';

export function stringFromActivityType(type: MaitredActivityType): string {
  switch (type) {
    case MaitredActivityType.Create:
      return 'Create';
    case MaitredActivityType.Update:
      return 'Update';
    case MaitredActivityType.Delete:
      return 'Delete';
    case MaitredActivityType.Add:
      return `Add`;
    case MaitredActivityType.Remove:
      return `Remove`;
    case MaitredActivityType.Move:
      return `Move`;
    case MaitredActivityType.LoggedIn:
      return 'Log In';
    case MaitredActivityType.LocationBackup:
      return 'Back Up';
    case MaitredActivityType.LocationRestore:
      return 'Roll Back';
    case MaitredActivityType.Checkpoint:
      return 'Establish Checkpoint';
    case MaitredActivityType.CheckpointRevert:
      return 'Revert Checkpoint';
    default:
      return `Unknown'`;
  }
}

export function stringFromUpdateType(updateType: MaitredActivityUpdateType): string {
  switch (updateType) {
    case MaitredActivityUpdateType.Changeset:
      return 'Changeset';
    case MaitredActivityUpdateType.Clone:
      return 'Clone';
    case MaitredActivityUpdateType.EightySix:
      return '86 or Un-86';
    case MaitredActivityUpdateType.MagicCopy:
      return 'Magic Copy or Precision Copy';
  }
}
