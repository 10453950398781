import { orgSchema, settingsSchema } from '@biteinc/schemas';

import { KeyCode } from '../enums/key_code';

app.OrgView = app.TabbedView.extend({
  defaultTabId: 'sites',
  requiresPageHeader: true,
  context: 'org',

  initialize() {
    app.TabbedView.prototype.initialize.apply(this, arguments);

    ['change:webEnabled', 'change:mobileAppEnabled'].forEach((eventName) => {
      this.listenTo(app.org, eventName, () => {
        setTimeout(() => {
          window.location.reload();
        }, 10);
      });
    });

    app.locationList = new app.LocationList();
    app.locationList.fetch({ reset: true });

    // SITES TAB
    app.siteList = new app.SiteList();
    const siteListView = new app.SiteListView({
      collection: app.siteList,
      title: `${app.org.displayName()} Sites`,
      canCreate: app.sessionUser.canCreateSitesAndChannels(),
    });

    const sitesTab = new app.TabView();
    sitesTab.setLeftView(siteListView);
    if (app.sessionUser.isAdmin()) {
      sitesTab.setRightView(new app.OrgLocationsToolsView());
    }
    this.addTab(sitesTab, 'Sites', 'sites');

    // LOCATION GROUPS TAB
    app.locationGroupList = new app.LocationGroupList();
    if (app.sessionUser.canViewLocationGroups()) {
      const locationGroupListView = new app.LocationGroupListView({
        collection: app.locationGroupList,
        isReadOnly: !app.sessionUser.isAdmin(),
      });

      const locationGroupsTab = new app.TabView();
      locationGroupsTab.setLeftView(locationGroupListView);
      this.addTab(locationGroupsTab, 'Location Groups', 'location-groups');
    } else if (app.sessionUser.canManageUsers() || app.sessionUser.canGenerateReports()) {
      app.locationGroupList.fetch({ reset: true });
    }

    // USER ROLES TAB
    if (app.sessionUser.canManageUsers()) {
      app.userRoleList = new app.UserRoleList();
      const userRoleListView = new app.BaseListView({
        collection: app.userRoleList,
        detailsView: app.UserRoleDetailsView,
        canCreate: app.sessionUser.isAdmin(),
        fetchOnInit: false,
      });

      const userRolesTab = new app.TabView();
      userRolesTab.setLeftView(userRoleListView);
      this.addTab(userRolesTab, 'Roles', 'user-roles');
    }

    // USERS TAB
    if (app.sessionUser.canManageUsers()) {
      app.userList = new app.UserList();
      const userListView = new app.UserListView({
        collection: app.userList,
        detailsView: app.UserDetailsView,
        fetchOnInit: false,
      });

      const usersTab = new app.TabView();
      usersTab.setLeftView(userListView);
      usersTab.addKeyboardListener(KeyCode.n, () => {
        userListView.createNew();
      });
      this.addTab(usersTab, 'Users', 'users');
    }

    // API TOKENS TAB
    if (app.sessionUser.isAdmin()) {
      app.apiTokenList = new app.ApiTokenList();
      const apiTokenListView = new app.ApiTokenListView({
        collection: app.apiTokenList,
        detailsView: app.ApiTokenDetailsView,
        searchEnabled: false,
        canCreate: app.sessionUser.isBiteOps(),
        isReadOnly: !app.sessionUser.isBiteOps(),
        fetchOnInit: false,
      });

      const apiTokensTab = new app.TabView();
      apiTokensTab.setLeftView(apiTokenListView);
      apiTokensTab.addKeyboardListener(KeyCode.n, () => {
        apiTokenListView.createNew();
      });
      this.addTab(apiTokensTab, 'API Tokens', 'apiTokens');
    }

    // WEBHOOKS TAB
    if (app.sessionUser.isBiteSupport()) {
      app.webhookList = new app.WebhookList();
      const webhookListView = new app.BaseListView({
        collection: app.webhookList,
        detailsView: app.BaseDetailsView,
        searchEnabled: false,
        canCreate: true,
        fetchOnInit: false,
      });

      const webhooksTab = new app.TabView();
      webhooksTab.setLeftView(webhookListView);
      webhooksTab.addKeyboardListener(KeyCode.n, () => {
        webhookListView.createNew();
      });
      this.addTab(webhooksTab, `${app.HtmlHelper.biteRightIcon} Webhooks`, 'webhooks');
    }

    if (app.sessionUser.canManageCustomers() && app.org.get('customerAccountsScope')) {
      // CUSTOMERS TAB
      app.customerList = new app.CustomerList();
      const customerTab = new app.TabView();
      const customerListView = new app.CustomerListView({
        collection: app.customerList,
        tabView: customerTab,
        detailsView: app.CustomerView,
        fetchOnInit: false,
      });
      customerTab.setLeftView(customerListView);
      this.addTab(customerTab, 'Customers', 'customers');
    }

    const canViewStati = app.sessionUser.isAdmin();
    // All org-level reports require the user to have view-orders permission
    const canAccessReporting =
      app.sessionUser.canGenerateReports() && app.sessionUser.canViewOrders();
    const canAccessActivityLog = app.sessionUser.canViewActivities();
    const canAccessCouponLog = app.sessionUser.canManageDiscounts();
    const canAccessSystemLogs = app.sessionUser.canViewSystemLogs();

    if (canViewStati || canAccessReporting || canAccessActivityLog || canAccessSystemLogs) {
      this.addSpacer();
      this.addSeparatorWithTitle('Ops');

      // KIOSK STATI TAB
      if (canViewStati) {
        app.kioskList = new app.KioskList();
        const kioskListView = new app.KioskListView({
          canCreate: false,
          cellView: app.KioskCellView,
          collection: app.kioskList,
          fetchOnInit: false,
        });
        const statiTab = new app.TabView();
        statiTab.setLeftView(kioskListView);
        this.addTab(statiTab, 'Kiosks', 'kiosks');
      }

      // REPORTING TAB
      if (canAccessReporting) {
        const oneTimeReportView = new app.OneTimeReportView({
          model: new app.OneTimeReport(),
          schema: app.OneTimeReport.Schema(),
          title: 'Run a One-Time Report',
          saveButtonTitles: ['Run', 'Running', 'Done!'],
        });

        app.reportScheduleList = new app.ReportScheduleList();
        const reportScheduleListView = new app.BaseListView({
          collection: app.reportScheduleList,
          detailsView: app.ReportScheduleDetailsView,
          searchEnabled: false,
          fetchOnInit: false,
        });
        const reportingTab = new app.TabView();
        reportingTab.setLeftView(oneTimeReportView, true);
        reportingTab.setRightView(reportScheduleListView);
        this.addTab(reportingTab, 'Reporting', 'reporting');
      }

      if (canAccessCouponLog) {
        // COUPON LOGS TAB
        const couponTab = new app.TabView();
        app.appliedBiteCouponLogList = new app.AppliedBiteCouponLogList();
        const biteLogListView = new app.BiteLogListView({
          collection: app.appliedBiteCouponLogList,
          hideStatusButton: true,
          searchEnabled: false,
          filterSchemaButton: false,
          filterSchema: null,
        });
        couponTab.setLeftView(biteLogListView);
        this.addTab(couponTab, 'Coupon Logs', 'coupons');
      }

      if (canAccessActivityLog) {
        // ACTIVITY LOGS TAB
        const activityTab = new app.TabView();
        app.activityList = new app.MaitredActivityList();
        const activityListView = new app.MaitredActivityListView({
          collection: app.activityList,
        });
        activityTab.setLeftView(activityListView);
        this.addTab(activityTab, 'Activity Log', 'activity-log');
      }

      if (canAccessSystemLogs) {
        // SYSTEM LOGS TAB
        app.biteLogList = new app.BiteLogList();
        const biteLogTab = new app.TabView();
        biteLogTab.supportsElementRouting = true;
        const biteLogListView = new app.BiteLogListView({
          collection: app.biteLogList,
          tabView: biteLogTab,
        });
        biteLogTab.setLeftView(biteLogListView);
        this.addTab(biteLogTab, `${app.HtmlHelper.resellerRightIcon} System Logs`, 'system-logs');
      }
    }

    const canManageWebSettings =
      app.org.get('webEnabled') && app.sessionUser.canManageWebSettings();
    const canManageCateringSettings =
      app.org.get('webEnabled') && app.sessionUser.canManageCateringSettings();
    const canManageMobileAppSettings = app.sessionUser.isAdmin() && app.org.get('mobileAppEnabled');
    const canManageOrgSettings = app.sessionUser.canManageOrgSettings();
    if (
      canManageWebSettings ||
      canManageCateringSettings ||
      canManageMobileAppSettings ||
      canManageOrgSettings
    ) {
      this.addSpacer();
      this.addSeparatorWithTitle('Configuration');

      if (canManageOrgSettings) {
        app.customCssGcnBuildList = new app.CustomCssGcnBuildList();
        app.customCssVitrineBuildList = new app.CustomCssVitrineBuildList();

        const appearancesListView = new app.BaseListView({
          detailsView: app.AppearanceV2DetailsView,
          collection: app.orgAppearanceList,
          searchEnabled: false,
        });
        const appearancesTab = new app.TabView();
        appearancesTab.setLeftView(appearancesListView);
        this.addTab(appearancesTab, 'Appearances', 'appearances');
      }

      if (canManageWebSettings) {
        this._addSubSchemaTab('Web Settings', 'web-settings');
      }
      if (canManageCateringSettings) {
        this._addSubSchemaTab('Catering Settings', 'catering-settings');
      }
      if (canManageMobileAppSettings) {
        this._addSubSchemaTab('Mobile App Settings', 'mobile-app-settings');
      }

      // ORG SETTINGS TAB
      if (canManageOrgSettings) {
        const settingsTab = this._addSubSchemaTab('Settings', 'org-settings');

        if (app.sessionUser.isBiteOps()) {
          settingsTab.setRightView(new app.OrgToolsView());
        }
      }
    }

    const isBite = app.sessionUser.isBite();
    const canAccessCommonSettings = app.sessionUser.canAccessSomeCommonSettings();
    const canManageFlashSettings = app.sessionUser.canManageFlashSettings();
    const canManageChannelSettings = app.sessionUser.canManageChannelSettings();
    const canManageMenuAppearance = app.sessionUser.canEditAppearance();
    const canManageBiteLiftSettings = app.sessionUser.canManageBiteLift();
    if (
      isBite ||
      canAccessCommonSettings ||
      canManageFlashSettings ||
      canManageChannelSettings ||
      canManageMenuAppearance ||
      canManageBiteLiftSettings
    ) {
      // Configuration V2
      this.addSpacer();
      this.addSeparatorWithTitle('Configuration V2');

      // Settings V2
      if (isBite) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Bite Settings',
          'bite-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (canAccessCommonSettings) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Common Settings',
          'common-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (canManageFlashSettings) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Flash Settings',
          'flash-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (canManageChannelSettings) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Kiosk Settings',
          'kiosk-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (canManageMenuAppearance) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Menu Appearance Settings',
          'menu-appearance-settings-v2',
          app.SettingsDetailsView,
        );
      }
      if (canManageBiteLiftSettings) {
        this.addSettingsTab(
          app.orgSettings,
          settingsSchema,
          'Recommendations Settings',
          'recommendation-settings-v2',
          app.SettingsDetailsView,
        );
      }
    }

    this.addBottomLinks(true /* help */, true /* edit profile */);
  },

  setPageHeader(header) {
    app.TabbedView.prototype.setPageHeader.apply(this, arguments);
    if (app.sessionUser.isBite() || app.sessionUser.getOrgMemberships().length === 1) {
      header.setMainTitle(app.org.get('name'), app.org.get('name'), app.org.bureauUrl());
    } else {
      header.addOrgDropdown(true);
    }
  },

  _addSubSchemaTab(tabName, tabId) {
    return this.addSettingsTab(app.org, orgSchema, tabName, tabId, app.OrgDetailsView);
  },
});
