import _ from 'underscore';

app.LocationGroupListView = app.BaseListView.extend({
  getListSections() {
    const nonAutoGeneratedGroups = _.filter(this.collection.models, (locationGroup) => {
      return locationGroup.isManuallyCreated();
    });
    return [{ models: nonAutoGeneratedGroups }];
  },
});
