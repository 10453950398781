import async from 'async';

import { ErrorCode } from '@biteinc/common';

app.QueueHelperCommon = {
  buildDoUntilAsyncFunction(result) {
    return (parallelCb) => {
      let attempts = 0;
      async.doUntil(
        (doUntilIterateeCb) => {
          // 0, 1, 2, 4, 8, 16, 16, 16...
          const delay = attempts === 0 ? 0 : 1000 * Math.min(16, Math.pow(2, attempts - 1));
          setTimeout(() => {
            app.makeRequestWithOptions({
              method: 'GET',
              url: result.queuePath,
              showAlertOnError: false,
              onSuccess(response) {
                doUntilIterateeCb(null, response);
              },
              onFailure(errMessage, err) {
                // Only consider it a fatal error if the server returns a code
                if (err?.code !== ErrorCode.NetworkError) {
                  new app.AlertView().show(err.message);
                  doUntilIterateeCb(err);
                } else {
                  // This must be a timeout. Let's continue polling. But we have to return
                  // something as response; otherwise, the test callback will have cb as
                  // the first argument
                  doUntilIterateeCb(null, {});
                }
              },
            });
          }, delay);
        },
        (response, testCb) => {
          attempts++;
          testCb(
            null,
            response && (response.jobState === 'completed' || response.jobState === 'failed'),
          );
        },
        parallelCb,
      );
    };
  },

  makeRequest(
    method,
    url,
    body,
    checkStatusAsyncFunction,
    onJobFinishCallback,
    onJobCreateCallback,
  ) {
    async.auto(
      {
        postJob(postJobCb) {
          app.makeRequest(
            method,
            url,
            body,
            (response) => {
              if (onJobCreateCallback) {
                onJobCreateCallback(null, response);
              }
              postJobCb(null, response);
            },
            (err) => {
              const phiErr = err || 'An error has occurred!';
              if (onJobCreateCallback) {
                onJobCreateCallback(phiErr);
              }
              postJobCb(phiErr);
            },
          );
        },
        checkStatus: ['postJob', checkStatusAsyncFunction],
      },
      onJobFinishCallback,
    );
  },
};
