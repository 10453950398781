app.CompoundMenuItemDetailsView = app.MenuItemDetailsView.extend({
  initialize() {
    app.MenuItemDetailsView.prototype.initialize.apply(this, arguments);
    this.model.Schema = this.getSchema();
  },

  getSchema() {
    const schema = app.AbstractCollection.prototype.getSchema.apply(this.collection);
    const compoundMenuItemSchema = app.JsonHelper.deepClone(schema);

    compoundMenuItemSchema.displayName = 'compound menu item';

    delete compoundMenuItemSchema.fields.category;
    delete compoundMenuItemSchema.fields.taxationStatus;
    delete compoundMenuItemSchema.fields.taxProfileId;
    delete compoundMenuItemSchema.fields.priceOptions;
    delete compoundMenuItemSchema.fields.integrations;
    delete compoundMenuItemSchema.fields.posName;
    delete compoundMenuItemSchema.fields.posDescription;

    compoundMenuItemSchema.fields.constituentItems.required = true;
    compoundMenuItemSchema.fields.name.required = false;

    compoundMenuItemSchema.fields.vendorId.isPermanent = true;
    compoundMenuItemSchema.fields.vendorId.required = false;

    return compoundMenuItemSchema;
  },

  _renderPosIdGroup() {},
});
