import type { Schema } from '@biteinc/common';
import { allEnumValues, UserRight, UserRightHelper } from '@biteinc/enums';

export const apiTokenSchema: Schema.Model = {
  displayName: 'api token',
  type: 'object',
  fields: {
    firstName: {
      type: 'shortString',
      displayName: 'Name',
      required: true,
    },
    locationGroupId: {
      type: 'mongoId',
      displayName: 'Location Access',
      ui: 'dropdown',
      required: true,
    },
    rights: {
      type: 'array',
      elementType: 'int',
      elementValues: allEnumValues<UserRight>(UserRight).filter((userRight) => {
        return UserRightHelper.isApiTokenRight(userRight);
      }),
      required: true,
      minCount: 1,
      ui: 'extended-checklist',
    },
    token: {
      displayName: 'Token Value',
      type: 'keyString',
      isReadOnly: true,
      isHidden: true,
    },
    disabled: {
      displayName: 'Disabled',
      type: 'bool',
      biteOnly: true,
    },
  },
};
