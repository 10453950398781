app.MaitredActivityList = app.AbstractCollection.extend({
  model: app.MaitredActivity,

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  url: () => {
    return '/api/v2/activities';
  },
});
