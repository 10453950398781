import _ from 'underscore';

import {
  CustomerIdentifier,
  CustomerIdentifierHelper,
  FulfillmentMethodHelper,
} from '@biteinc/enums';

app.CustomerIdentifiersView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} customer-identifier-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: CustomerIdentifier,
            nameGenerator: CustomerIdentifierHelper.name,
            values: FulfillmentMethodHelper.customerIdentifiers(this.keyModel.fulfillmentMethod),
          });
        },
        getNewElementFromModel: (customerIdentifier) => {
          return {
            customerIdentifier: customerIdentifier.id,
            askAtBeginning: CustomerIdentifierHelper.enforcedAskAtBeginning(customerIdentifier.id),
          };
        },
        canShowModel: (customerIdentifier, elements) => {
          // Filter out the order types that are already entered (but maybe not saved)
          return !_.findWhere(elements, { customerIdentifier: customerIdentifier.id });
        },
      },
      getTitle: (fieldGroupView) => {
        return CustomerIdentifierHelper.name(fieldGroupView.getValue().customerIdentifier);
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'customer identifier',
        emptyText: 'no customer identifiers yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
