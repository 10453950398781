app.MenuSectionList = app.MenuItemArrayList.extend({
  model: app.MenuSection,

  usesPaginatedFetch() {
    return true;
  },

  url() {
    return '/api/v2/menu-sections';
  },
});
