import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { template } from '../../template';

app.MapFieldRowView = app.FieldGroupView.extend({
  className(...args) {
    const className = app.FieldGroupView.prototype.className.apply(this, args);
    return `${className} map-field-row-view clearfix`;
  },

  render(...args) {
    app.FieldGroupView.prototype.render.apply(this, args);

    this.$name = $('<label class="name"></label>');
    this.$name.html(this.keyModel.displayName());
    this.$el.prepend(this.$name);

    const $btnGroup = $('<div class="btn-group"></div>');
    this.$deleteButton = $('<button type="button">Delete</button>');
    this.$deleteButton.addClass('btn btn-danger');
    $btnGroup.append(this.$deleteButton);
    this.$el.append($btnGroup);

    this.$deleteButton.prop('disabled', this.isReadOnly);

    return this;
  },
});

app.MapFieldView = app.FieldView.extend({
  className(...args) {
    return `${app.FieldView.prototype.className.apply(this, args)} map-field-view`;
  },
  template: template(
    // prettier-ignore
    '<label class="col-form-label col-md-6"></label>' +
    '<div class="col-md-12 collapsible">' +
      '<div class="wrapper">' +
        '<div class="col-md-12 row-list"></div>' +
      '</div>' +
    '<div>',
  ),

  initialize(...args) {
    app.FieldView.prototype.initialize.apply(this, args);

    this._isCustomStrings = this.field === 'customStrings';
  },

  _addValueRow(rowFields, keyModel, model) {
    const schema = keyModel.get('Schema') || this.schema;
    let subProperty = this.field;
    if (this.subProperty) {
      subProperty = `${this.subProperty}.${subProperty}`;
    }
    const row = new app.MapFieldRowView({
      subProperty,
      keyModel,
      schema: this._isCustomStrings
        ? {
            ...schema,
            fields: Object.keys(schema.fields).reduce((schemaFields, languageCode) => {
              return {
                ...schemaFields,
                [languageCode]: {
                  ...schema.fields[languageCode],
                  placeholder: Strings[keyModel.id][languageCode] || '[no default value]',
                },
              };
            }, {}),
          }
        : schema,
      isReadOnly: this.isReadOnly,
    });
    this.$rowList.append(row.render().$el);
    row.setValue(rowFields, model);

    if (!this.isPermanent()) {
      row.$deleteButton.click(() => {
        row.remove();
        this.rows = this.rows.filter((r) => {
          return r !== row;
        });
        this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
      });
    }

    this.rows.push(row);
    this.listenTo(row, app.FieldGroupView.Events.FieldGroupDidChangeValue, () => {
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    });
  },

  _getSubtitleForElement(element) {
    if (this.model && this.model.subtitleForListFieldElement) {
      return this.model.subtitleForListFieldElement(this.field, element);
    }
    // empty strings will not show as subtitles
    return '';
  },

  setValue(value, model) {
    this.value = value;
    this.model = model;
    this.initialValue = value;
    const collection = model.getFieldCollection(this.field, this.subProperty, false);

    this.$rowList.html('');
    this.rows = [];
    _.each(this.value || {}, (rowFields, keyId) => {
      this._addValueRow(rowFields, collection.get(keyId), model);
    });

    this.dropdown.setup(collection, {
      getSubtitle: (element) => {
        if (this.model && this.model.subtitleForListFieldElement) {
          return this.model.subtitleForListFieldElement(this.field, element);
        }
        // empty strings will not show as subtitles
        return '';
      },
      validate: (keyModel) => {
        return !_.any(this.rows, (row) => {
          return row.keyModel === keyModel;
        });
      },
      onAdd: (keyModel) => {
        this._addValueRow({}, keyModel, model);
        this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
      },
    });

    this.toggleCollapsedView(!_.size(this.rows));
  },

  getValue() {
    const value = {};
    _.each(this.rows, (row) => {
      const rowValue = row.getValue();
      value[row.options.keyModel.id] = rowValue;
    });
    if (!_.keys(value).length && !this.initialValue) {
      return this.initialValue;
    }

    return value;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    return this.rows.reduce(
      (response, row) => {
        const rowName = row.keyModel.displayName();
        const { isValid, invalidFieldNames } = row.checkValidity();
        if (isValid) {
          return response;
        }
        return {
          isValid: false,
          invalidFieldNames: [
            // previously encountered invalid field names
            ...(response.isValid ? [] : response.invalidFieldNames),
            // Either we have nested fields that are invalid or this current field is invalid by itself.
            ...(invalidFieldNames.length
              ? invalidFieldNames.map((nestedFieldName) => `${rowName} > ${nestedFieldName}`)
              : [rowName]),
          ],
        };
      },
      { isValid: true },
    );
  },

  render() {
    this.$el.html(this.template());

    this.$('.col-form-label').html(`${this.getDisplayName()}:`);

    this.rows = [];
    this.$rowList = this.$('.row-list');

    this.dropdown = new app.DropdownView({ isReadOnly: this.isReadOnly });
    this.dropdown.render();
    this.dropdown.$el.insertAfter(this.$rowList);
    this.dropdown.setTitle(this.schema.key.placeholder || `Select ${this.schema.key.displayName}:`);

    if (this.field === 'customStrings') {
      this.$el.addClass('custom-strings');
    }

    if (this.schema.collapse) {
      this.addCollapseButton();
    }

    return this;
  },
});
