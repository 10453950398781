import _ from 'underscore';

import { MenuItemDisplayBlockListItemType } from '@biteinc/enums';
import { menuItemSchema } from '@biteinc/schemas';

const { fields: ListItemFields } = menuItemSchema.fields.displayBlocks.fields.listItems;

app.MenuItemDisplayBlockListItemView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          type: MenuItemDisplayBlockListItemType.Text,
        };
      },
      getTitle: (fieldGroupView) => {
        const { text } = fieldGroupView.getValue();
        return text || '';
      },
      getElementSchemaFields: (/* element */) => {
        return ListItemFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new list item',
        emptyText: 'no list items yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
