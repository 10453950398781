import { MathHelper, StringHelper } from '@biteinc/helpers';

import { TimeHelper } from '../helpers/time_helper';
import { template } from '../template';

app.CustomerView = app.ModallableView.extend({
  className: 'guest-view report',
  template: template(`
    <div class="header">
      <div class="info">
        <div class="title"><@= customer.fullName() @></div>
        <div class="summary visit"><@= visitSummary @></div>
      </div>
    </div>
    <div class="summary contact">
      <div class="header">CONTACT INFO</div>
      <div class="body">
        Email: <@= customer.get('email') @>
        <br />
        Phone Number: <@= phoneNumber @>
      </div>
    </div>
    <div class="controls">
      <button type="button" class="btn btn-secondary reset-password">Reset Password</button>
      <button type="button" class="btn btn-secondary btn-danger toggle-customer">Disable</button>
      <button type="button" class="btn btn-secondary btn-danger delete-customer">Delete</button>
    </div>
    <div class="order-history">
      <div class="title">Visit History:</div>
      <div class="order-list"></div>
    </div>
  `),

  orderTemplate: template(`
    <div class="order">
      <div class="location-name">Location: <@= locationName @></div>
      <div class="created-at">Time: <@= createdAt @></div>
      <div class="created-at">Duration: <@= duration @></div>
      <div class="transaction-summary">Discounts: <@= discounts @></div>
      <div class="transaction-summary">Total: <@= total @></div>
      <div class="ordered-item-list"></div>
    </div>
  `),

  initialize(options) {
    options.title = 'Customer Details';
    app.ModallableView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.model, 'change', this.render);

    this.model.fetchOrders();
  },

  _getVisitSummaryHtml() {
    const lines = [];
    const orderCount = this.model.getTotalOrderCount();
    lines.push(`${orderCount} order${orderCount === 1 ? '' : 's'}`);
    if (app.sessionUser.isBite()) {
      lines.push(`Customer ID ${app.HtmlHelper.biteRightIcon}: ${this.model.id}`);
    }
    return lines.join('<br />');
  },

  _resetPasswordButtonWasClicked(e) {
    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Resetting', 'Reset');
    this.model.resetPassword((err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToast('Password reset email sent!');
      }
    });
  },

  _toggleCustomerButtonWasClicked(e) {
    const $button = $(e.target);
    const isDisabled = this.model.isDisabled();
    const reqId = $button.initLoadingButton(
      $button.html(),
      isDisabled ? 'Enabling' : 'Disabling',
      isDisabled ? 'Enabled' : 'Disabled',
    );
    this.model.toggleAvailability((err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        $button.text(this.model.isDisabled() ? 'Enable' : 'Disable');
      }
    });
  },

  _deleteCustomerButtonWasClicked(e) {
    if (
      !window.confirm(
        'Are you sure you want to delete this customer? This action cannot be undone.',
      )
    ) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    this.model.destroy({
      error: () => {
        $button.loadingDidFinishWithError(reqId);
      },
      success: () => {
        $button.loadingDidFinishSuccessfully(reqId);
        this.trigger(app.ModalView.Events.ModalViewShouldHide, this);
      },
    });
  },

  render() {
    app.ModallableView.prototype.render.apply(this, arguments);

    const orders = this.model.getOrders();
    if (orders) {
      this.$el.html(
        this.template({
          customer: this.model,
          phoneNumber: this.model.get('phoneNumber')
            ? StringHelper.toFormattedPhoneNumber(this.model.get('phoneNumber'))
            : '(not provided)',
          visitSummary: this._getVisitSummaryHtml(),
        }),
      );

      this.$('button.reset-password').click(this._resetPasswordButtonWasClicked.bind(this));
      this.$('button.toggle-customer').click(this._toggleCustomerButtonWasClicked.bind(this));
      this.$('button.toggle-customer').text(this.model.isDisabled() ? 'Enable' : 'Disable');
      if (app.sessionUser.canDeleteCustomers()) {
        this.$('button.delete-customer').click(this._deleteCustomerButtonWasClicked.bind(this));
      } else {
        this.$('button.delete-customer').remove();
      }

      // Populate the orders.
      const $orderList = this.$('.order-list');
      orders.forEach((order) => {
        const location = app.locationList.get(order.get('locationId'));
        const $order = $(
          this.orderTemplate({
            locationName: location.nameWithChannel(),
            createdAt: TimeHelper.displayDateFromTimestamp(
              order.get('createdAt'),
              location.get('timezone'),
            ),
            duration: TimeHelper.durationFromTimestamp(order.get('sessionDuration')),
            discounts: `$${MathHelper.displayPrice(order.get('discountTotal') || 0)}`,
            total: `$${MathHelper.displayPrice(order.get('total') || order.get('subTotal'))}`,
          }),
        );
        $orderList.append($order);

        $order.find('.ordered-item-list').html(
          order.orderedItems
            .map((orderedItem) => {
              return app.HtmlHelper.createItemElement(orderedItem);
            })
            .join(''),
        );
      });
    } else {
      // TODO: style this with a spinner or something
      this.$el.html('<div class="loading">Loading Customer Info</div>');
    }

    return this;
  },
});
