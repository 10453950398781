import { template } from '../../template';

app.SpiritDetailsView = app.BaseDetailsView.extend({
  template: template($('#menu-item-array-details-template').html()),

  _renderItemList() {
    const itemIds = this._itemList.map((item) => {
      return item.id;
    });

    if (this.dropdown) {
      this.dropdown.destroy();
    }
    this.dropdown = new app.Dropdown();
    const $itemPanel = this.$('.item-list-panel');
    this.dropdown.setupRows($itemPanel, itemIds, this._itemList, {
      onClick(item) {
        const detailsView = new app.MenuItemDetailsView({
          model: item,
        });
        app.modalManager.showModalWithView(detailsView);
      },
      getDisplayName(item) {
        return `${item.get('locationName')}: ${item.displayNameHtml()}`;
      },
      canBeRemoved() {
        return false;
      },
    });

    this.$el.find('.card-footer').show();
    let summary = '';
    if (itemIds.length) {
      summary += `${itemIds.length} item${itemIds.length === 1 ? '' : 's'}`;
    } else {
      summary += 'No menu items use this spirit';
    }
    this.$el.find('.card-footer').html(summary);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.model.isNew() && !this._itemList) {
      this._itemList = new app.MenuItemList([], {
        url: `${this.model.url()}/items`,
      });
      this._itemList.comparator = (model) => {
        return `${model.get('locationName').toLowerCase()}: ${model.displayName().toLowerCase()}`;
      };

      this.listenTo(this._itemList, 'reset', this.render);
      this._itemList.fetch({ reset: true });
    }

    if (this.model.canBeDestroyed() && !this.$dupeButton) {
      const self = this;
      const footer = self.superView.footer;
      this.$dupeButton = footer.addButton('Duplicate', 'dupe');
      this.$dupeButton.click(() => {
        self.trigger(app.ModalView.Events.ModalViewShouldHide, self);

        const attributes = app.JsonHelper.deepClone(self.model.attributes);
        delete attributes._id;
        const dupeSpirit = new app.Spirit(attributes, {
          collection: self.collection,
        });

        const detailsView = new app.SpiritDetailsView({
          collection: self.collection,
          model: dupeSpirit,
        });
        app.modalManager.showModalWithView(detailsView);
      });
    }

    if (this._itemList) {
      this.$el.append(
        this.template({
          canAddItems: false,
          canCreateItems: false,
          itemCategory: 'Menu Item',
        }),
      );
      this.$('.reco-panel').remove();

      if (this._itemList.hasBeenFetched()) {
        this._renderItemList();
      }
    }

    return this;
  },
});
