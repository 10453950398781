app.AppliedBiteCouponLogList = app.AbstractCollection.extend({
  model: app.BiteLog,

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  url() {
    return '/api/v2/bite-logs/applied-coupons';
  },

  type() {
    return 'Applied Coupon';
  },
});
