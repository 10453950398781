app.ModGroupList = app.MenuItemArrayList.extend({
  model: app.ModGroup,

  parse(data) {
    return data.data.modGroups.map((modGroupJson) => {
      if (modGroupJson.posWalletSettings?.values?.length) {
        return {
          ...modGroupJson,
          posWalletSettings: {
            ...modGroupJson.posWalletSettings,
            values: modGroupJson.posWalletSettings.values.map((value) => {
              return {
                ...value,
                _id: `${value.inclusiveQuantityCap}`,
              };
            }),
          },
        };
      }

      return modGroupJson;
    });
  },

  usesPaginatedFetch() {
    return true;
  },

  url() {
    return '/api/v2/mod-groups';
  },

  buildNonintegratedModGroupsList() {
    const integratedVendorIds = app.vendorList.getIntegratedVendorIdsSet();
    const integratedModGroups = app.modGroupList.models.filter((modGroup) => {
      return integratedVendorIds.has(modGroup.get('vendorId').toString());
    });
    const selectableModGroups = app.modGroupList.clone();
    selectableModGroups.remove(integratedModGroups);

    return selectableModGroups;
  },

  buildModGroupsWithVendorList(vendorId) {
    return new app.ModGroupList(app.modGroupList.where({ vendorId }));
  },
});
