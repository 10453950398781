import _ from 'underscore';

import { BiteUrl, I9nSchemaBySystem } from '@biteinc/common';
import { IntegrationSystem } from '@biteinc/enums';

import { template } from '../../../template';

const WebhookSettingsGroupView = app.AccordionFieldGroupView.extend({
  valueDisplayTemplate: template(`
    <div class="field-container">
      <label class="col-form-label col-md-4"><@= label @></label>
      <div class="col-md-12">
        <span class="simple-value"><@= value @></span>
      </div>
    </div>`),

  getInstructionsForSystem(system) {
    switch (system) {
      case IntegrationSystem.Olo:
        return `
          Please ask the client to set up a webhook for Bite in their Olo Dashboard (under Developer Tools) with the following details.
          <br />
          <a href="https://olosupport.zendesk.com/hc/en-us/articles/360061153692-Self-Service-Webhooks" target="_blank">Olo Knowledge Base Article</a>
          <br />
          <br />
          <u>Webhook Url</u>: ${BiteUrl.webhookReceiverBaseUrl(window.env)}/olo/${app.org.id}
          <br />
          <u>Webhook Events</u>:
          <ul>
            <li>MenuItemAvailabilityChanged</li>
            <li>OrderPlaced</li>
            <li>OrderClosed</li>
            <li>OrderCancelled</li>
          </ul>
          <br />
          Once it's saved, the client will be shown a secret key that needs to be saved here for the webhooks to work.`;
      default:
        return 'Please ask an engineer to provide instructions!';
    }
  },

  setValue() {
    const isFirstTime = !this.params;
    app.AccordionFieldGroupView.prototype.setValue.apply(this, arguments);

    if (isFirstTime && this.params?.system) {
      const $displayGroup = $('<div class="field-group"></div>').append(
        this.valueDisplayTemplate({
          label: 'Instructions:',
          value: this.getInstructionsForSystem(this.params.system),
        }),
      );
      this.$el.append($displayGroup);
    }
  },
});

app.WebhookSettingsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} webhook-settings-accordion`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: IntegrationSystem,
            nameGenerator: (system) => {
              return I9nSchemaBySystem[system].name;
            },
            values: [IntegrationSystem.Olo],
          });
        },
        getNewElementFromModel: (i9nSystemModel) => {
          const i9nSystem = i9nSystemModel.id;
          return {
            system: i9nSystem,
          };
        },
        canShowModel: (i9nSystemModel, elements) => {
          // Filter out the i9n systems that are already entered (but maybe not saved)
          return !_.findWhere(elements, { system: i9nSystemModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const { system } = fieldGroupView.getValue();
        return I9nSchemaBySystem[system].name;
      },
      FGVClass: WebhookSettingsGroupView,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'New System',
        emptyText: 'No Webhook Settings Yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
