import Backbone from 'backbone';

import { Log } from '@biteinc/common';
import { SystemHealthTag, SystemHealthTagHelper, TimeBlock } from '@biteinc/enums';
import { MathHelper, StringHelper, Time } from '@biteinc/helpers';

import { template } from '../template';

const deemphasizedHealthTags = [
  SystemHealthTag.IntegrationCardConnectTerminalDisconnected,
  SystemHealthTag.IntegrationWorldpayTerminalDisconnected,
  SystemHealthTag.IntegrationHeartlandTerminalDisconnected,
];
const isDeemphasizedByHealthTag = {};
deemphasizedHealthTags.forEach((healthTag) => {
  isDeemphasizedByHealthTag[healthTag] = true;
});

app.SystemHealthView = Backbone.View.extend({
  template: template(`
    <h1>System Health</h1>
    <p>Table shows health metrics based on the error resolution threshold for each one.</p>
    <button id="refresh-metrics-button" class="btn btn-success" disabled="true">Refresh Metrics</button>
    <br />
    <br />
    <table id="system-health-fragment-table" class="table table-bordered">
      <thead>
        <tr>
          <th>Health Metric</th>
          <th>Error Rate</th>
          <th>Error Count</th>
          <th>Success Count</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <p>The purpose of System Health is to, within 1 minute, determine the magnitude of an issue's effect. Is it a systemic or a one-off/infrequent issue?</p>
    <p>Alerts for various metrics are configured with error rate thresholds (# of error events divided by # of combined errors and success events) and a combined minimum error count for low-frequency events.</p>
    <p><code>warning</code> threshold:</p>
    <ul>
      <li>with medium confidence, we can say that this is a problem that needs investigation</li>
      <li>someone on call should stop what they're doing and take a look at system health</li>
    </ul>

    <p><code>critical</code> threshold:</p>
    <ul>
      <li>with high confidence, we can say that this is a problem that needs investigation</li>
      <li>someone on call should stop what they're doing and take a look at system health</li>
      <li>ops team and/or eng team needs to be notified</li>
    </ul>`),
  className: 'system-health-view clearfix',

  initialize(options) {
    this.options = options;

    this.listenTo(this, app.SystemHealthView.Events.SystemHealthSummariesDidLoad, () => {
      this.render();
    });

    this.listenTo(this, app.SystemHealthView.Events.SystemHealthSummariesDidFailLoad, () => {
      this.render();
    });

    this._isFetchingSystemHealthSummaries = false;
    this._hasFetchedSystemHealthSummaries = false;
  },

  _buildDashboardAlertsByHealthTag(dashboardAlerts) {
    const dashboardAlertsByHealthTag = {};
    dashboardAlerts.forEach((dashboardAlert) => {
      if (!dashboardAlertsByHealthTag[dashboardAlert.healthTag]) {
        dashboardAlertsByHealthTag[dashboardAlert.healthTag] = [];
      }
      dashboardAlertsByHealthTag[dashboardAlert.healthTag].push(dashboardAlert);
    });
    return dashboardAlertsByHealthTag;
  },

  _fetchSystemHealthSummaries() {
    if (this._hasFetchedSystemHealthSummaries || this._isFetchingSystemHealthSummaries) {
      // don't fetch twice
      return;
    }

    this._isFetchingSystemHealthSummaries = true;
    // load the system health summaries
    app.makeRequestWithOptions({
      method: 'GET',
      url: `/api/v2/system-health/summaries?timeBlock=${TimeBlock.TwoMinutes}`,
      onSuccess: (data) => {
        this._hasFetchedSystemHealthSummaries = true;
        this._isFetchingSystemHealthSummaries = false;
        this._systemHealthSummaries = data.systemHealthSummaries;
        this._dashboardAlertsByHealthTag = this._buildDashboardAlertsByHealthTag(
          data.dashboardAlerts,
        );
        this.trigger(app.SystemHealthView.Events.SystemHealthSummariesDidLoad);
      },
      onFailure: (err) => {
        // don't bother refetching, assume user will refresh
        this._hasFetchedSystemHealthSummaries = true;
        this._isFetchingSystemHealthSummaries = false;
        this.trigger(app.SystemHealthView.Events.SystemHealthSummariesDidFailLoad);
        Log.error('SystemHealthView fetch summaries', err);
      },
    });
  },

  _renderErrorRateWithDashboardAlertTooltip(healthTag, errorRate) {
    const dashboardAlerts = this._dashboardAlertsByHealthTag[healthTag];
    const errorRateStr = `${MathHelper.floatRateToPercentStr(errorRate)}%`;
    if (!dashboardAlerts?.length) {
      return errorRateStr;
    }

    const tooltipTitleStrs = dashboardAlerts.map((dashboardAlert) => {
      const severity = StringHelper.toTitleCase(dashboardAlert.severity);
      const errorThreshold = MathHelper.floatRateToPercentStr(dashboardAlert.errorRateThreshold);
      return `${severity} at ${errorThreshold}% and combined count > ${dashboardAlert.errorThresholdSuccessAndErrorCombinedMinimumCount}.\nMandatory success count threshold at ${dashboardAlert.mandatorySuccessCountThreshold}.`;
    });

    // Join and then split because individual lines may have line breaks as well.
    const tooltipTitle = tooltipTitleStrs.join('\n\n').split('\n').join('<br />');
    return `${errorRateStr} <span class="field-tooltip" data-bs-toggle="tooltip" data-html="true" data-bs-title="${tooltipTitle}"></span>`;
  },

  _renderDashboardAlertCssClass(systemHealthFragment, errorRate) {
    const { healthTag, errorCount, successCount } = systemHealthFragment;

    // TODO this doesn't take into account errorRateTimeResolution
    const dashboardAlerts = this._dashboardAlertsByHealthTag[healthTag];
    let isCritical = false;
    let isWarning = false;

    if (!dashboardAlerts?.length) {
      // no alerts, no color
      return '';
    }

    // alerts may not be in order, so make sure to check them all
    dashboardAlerts.forEach((dashboardAlert) => {
      const {
        errorThresholdSuccessAndErrorCombinedMinimumCount,
        mandatorySuccessCountThreshold,
        severity,
        errorRateThreshold,
      } = dashboardAlert;

      const combinedSuccessAndError = errorCount + successCount;
      const hasEnoughData =
        combinedSuccessAndError > errorThresholdSuccessAndErrorCombinedMinimumCount;

      const isAboveErrorRateThreshold = errorRate > errorRateThreshold;

      const isBelowMandatorySuccessCountThreshold = successCount < mandatorySuccessCountThreshold;

      const isBeyondThreshold = isAboveErrorRateThreshold || isBelowMandatorySuccessCountThreshold;

      if (!hasEnoughData && !isBelowMandatorySuccessCountThreshold) {
        return;
      }

      if (!isBeyondThreshold) {
        return;
      }

      switch (severity) {
        case 'critical':
          isCritical = true;
          break;
        case 'warning':
          isWarning = true;
          break;
      }
    });

    // we could have a warning and a critical, so prefer critical
    if (isCritical) {
      return 'danger';
    }

    if (isWarning) {
      return 'warning';
    }

    // there are alerts configured, but none triggered
    return 'success';
  },

  _getSystemHealthFragments() {
    const systemHealthFragmentByHealthTag = {};
    this._systemHealthSummaries?.forEach((systemHealthSummary) => {
      const { errorCount, successCount, healthTag } = systemHealthSummary;
      if (!systemHealthFragmentByHealthTag[healthTag]) {
        // default time resolution block
        systemHealthFragmentByHealthTag[healthTag] = {
          healthTag,
          errorCount: 0,
          successCount: 0,
        };
      }
      systemHealthFragmentByHealthTag[healthTag].errorCount += errorCount;
      systemHealthFragmentByHealthTag[healthTag].successCount += successCount;
    });

    // show empty system health summaries for transparency
    SystemHealthTagHelper.systemHealthTags.forEach((healthTag) => {
      if (!systemHealthFragmentByHealthTag[healthTag]) {
        systemHealthFragmentByHealthTag[healthTag] = {
          healthTag,
          errorCount: 0,
          successCount: 0,
        };
      }
    });

    const systemHealthFragments = Object.values(systemHealthFragmentByHealthTag);

    return systemHealthFragments.sort((systemHealthFragmentA, systemHealthFragmentB) => {
      // push deemphasized health tags to bottom of the table
      if (isDeemphasizedByHealthTag[systemHealthFragmentA.healthTag]) {
        return 1;
      }
      if (isDeemphasizedByHealthTag[systemHealthFragmentB.healthTag]) {
        return -1;
      }

      // descending error rate
      return (
        systemHealthFragmentB.errorCount / systemHealthFragmentB.successCount -
        systemHealthFragmentA.errorCount / systemHealthFragmentA.successCount
      );
    });
  },

  render() {
    this.$el.html(this.template());

    this._fetchSystemHealthSummaries();

    // render a refresh button that a user can click when new data will be available
    const refreshAt = Time.getTimeBlockForTime(Date.now(), 2 * Time.MINUTE).endAt + Time.SECOND;
    const refreshDelay = refreshAt - Date.now();
    $('#refresh-metrics-button').on('click', () => {
      window.location.reload();
    });
    setTimeout(() => {
      $('#refresh-metrics-button').attr('disabled', false);
    }, refreshDelay);

    const $systemHealthFragmentTable = this.$('#system-health-fragment-table tbody');

    if (this._isFetchingSystemHealthSummaries) {
      $systemHealthFragmentTable.append(`
        <tr>
          <td colspan="5">Fetching system health statistics</td>
        </tr>`);
      return this;
    }

    if (!this._hasFetchedSystemHealthSummaries) {
      $systemHealthFragmentTable.append(`
        <tr>
          <td colspan="5">Failed to fetch system health statistics</td>
        </tr>`);
      return this;
    }

    const systemHealthFragments = this._getSystemHealthFragments();

    if (!systemHealthFragments.length) {
      $systemHealthFragmentTable.append(`
        <tr>
          <td colspan="5">No system health statistics found</td>
        </tr>`);
      return this;
    }

    systemHealthFragments.forEach((systemHealthFragment) => {
      const { errorCount, successCount, healthTag } = systemHealthFragment;

      let errorRate = 0;
      if (errorCount > 0) {
        errorRate = errorCount / (errorCount + successCount);
      }

      const $errorRate = this._renderErrorRateWithDashboardAlertTooltip(healthTag, errorRate);

      $systemHealthFragmentTable.append(`
        <tr class="${this._renderDashboardAlertCssClass(systemHealthFragment, errorRate)}">
          <td>${healthTag}</td>
          <td>${$errorRate}</td>
          <td>${errorCount}</td>
          <td>${successCount}</td>
          <td><button class="btn btn-secondary view-logs-button" data-health-tag="${healthTag}">View Logs</button></td>
        </tr>`);
    });

    $('.view-logs-button').on('click', (event) => {
      event.preventDefault();

      const healthTag = $(event.target).attr('data-health-tag');
      const systemHealthLogsView = new app.SystemHealthLogsView(healthTag);
      this.options.tabView.setRightView(systemHealthLogsView, false, true);
    });

    app.activateTooltips(this.$el);

    return this;
  },
});

app.SystemHealthView.Events = {
  SystemHealthSummariesDidLoad: 'SystemHealthSummariesDidLoad',
  SystemHealthSummariesDidFailLoad: 'SystemHealthSummariesDidFailLoad',
};
