import _ from 'underscore';

import {
  MaitredActivityScope,
  MaitredActivityType,
  ModelType,
  ModelTypeHelper,
} from '@biteinc/enums';

import { TimeHelper } from '../helpers/time_helper';
import * as MaitredActivityHelper from './maitred_activity_helper';

app.MaitredActivity = app.AbstractModel.extend({
  ModelName: 'activity',
  Schema: {
    displayName: 'maitred activity',
  },
  Type: ModelType.MaitredActivity,

  _modelNameSpan() {
    const modelType = this.get('modelType');
    const modelId = this.get('modelId');
    const modelName = ModelTypeHelper.name(modelType);

    let html = `${modelName} "<span class="model-name" data-bs-toggle="tooltip" data-bs-title="${modelId}"`;
    if (this._modelIsClickable()) {
      html += ` model-id="${modelId}" model-type="${modelType}"`;
    }
    html += `>${this.get('modelName')}</span>"`;
    return html;
  },

  _modelIsClickable() {
    if (!app.MaitredActivity._isClickableModelType(this.get('modelType'))) {
      return false;
    }

    return this._isInCurrentScope();
  },

  _isInCurrentScope() {
    switch (this.get('scope')) {
      case MaitredActivityScope.Location:
        return !!app.location && this.get('scopeId') === app.location.id;
      case MaitredActivityScope.Site:
        return !!app.site && this.get('scopeId') === app.site.id;
      case MaitredActivityScope.Org:
        return !!app.org && this.get('scopeId') === app.org.id;
      case MaitredActivityScope.Bite:
        return !app.org && !app.site && !app.location;
    }
  },

  _scopeLink() {
    switch (this.get('scope')) {
      case MaitredActivityScope.Location: {
        const location = app.locationList.get(this.get('locationId'));
        const site = app.siteList.get(this.get('siteId'));
        const channel = location.get('orderChannel');
        return {
          name: `Channel: ${location.displayName()}`,
          url: `/${app.org.get('normalizedName')}/${site.get('normalizedName')}/${channel}#activity-log`,
        };
      }
      case MaitredActivityScope.Site: {
        const site = app.siteList.get(this.get('siteId'));
        return {
          name: `Site: ${site.get('name')}`,
          url: `/${app.org.get('normalizedName')}/${site.get('normalizedName')}#activity-log`,
        };
      }
      case MaitredActivityScope.Org:
      case MaitredActivityScope.Bite:
        return null;
    }
  },

  displayNameHtml() {
    if (MaitredActivityType.LoggedIn === this.get('type')) {
      return `${this._modelNameSpan()} logged in`;
    }

    const name = $('<div></div>');
    if (!this._isInCurrentScope()) {
      const scopeLink = this._scopeLink();
      if (scopeLink) {
        name.append(
          $(
            `<a class="activity-scope-name" href="${scopeLink.url}" target="_blank">${scopeLink.name}</a>`,
          ),
        );
      }
    }
    name.append(
      $(
        `<span class="username" data-bs-toggle="tooltip" data-bs-title="${this.get(
          'userId',
        )}">${this.get('username')}</span>`,
      ),
    );
    name.append($('<span> </span>'));

    let typeStr;

    switch (this.get('type')) {
      case MaitredActivityType.Create:
        typeStr = 'created';
        break;
      case MaitredActivityType.Update:
        typeStr = 'updated';
        break;
      case MaitredActivityType.Delete:
        typeStr = 'deleted';
        break;
      case MaitredActivityType.Add:
        typeStr = `added ${this.get('diff')} to`;
        break;
      case MaitredActivityType.Remove:
        typeStr = `removed ${this.get('diff')} from`;
        break;
      case MaitredActivityType.Move:
        typeStr = `moved ${this.get('diff')} in`;
        break;
      case MaitredActivityType.LoggedIn:
        typeStr = 'logged in';
        break;
      case MaitredActivityType.LocationBackup:
        typeStr = 'backed up';
        break;
      case MaitredActivityType.LocationRestore:
        typeStr = 'rolled back';
        break;
      case MaitredActivityType.Checkpoint:
        typeStr = 'established checkpoint';
        break;
      case MaitredActivityType.CheckpointRevert:
        typeStr = 'reverted checkpoint';
        break;
      default:
        typeStr = `${this.get('type')} + '????ed'`;
        break;
    }

    if (
      [MaitredActivityType.LocationRestore, MaitredActivityType.CheckpointRevert].includes(
        this.get('type'),
      )
    ) {
      name.append($(`<span>${typeStr}</span>`));
      name.append($(`<span> ${this._modelNameSpan()}</span>`));
      name.append($(`<span> to:</span>`));
      name.append($(`</br>`));
      name.append($(`<span>${this.get('diff')}</span>`));
    } else if (this.get('type') === MaitredActivityType.Delete && this.get('diff')) {
      name.append($(`<span>${this.get('diff')}</span>`));
    } else {
      name.append($(`<span>${typeStr}</span>`));
      name.append($(`<span> ${this._modelNameSpan()}</span>`));
    }

    if (this.get('type') === MaitredActivityType.Checkpoint) {
      name.append(
        new app.JsonView({
          jsonValue: this.get('diff'),
          altText: 'now',
        }).render().$el,
      );
    }

    if (MaitredActivityType.Update === this.get('type')) {
      name.append($('<span>: </span>'));
      if (this.has('orig') && this.has('diff')) {
        name.append(
          new app.JsonView({
            jsonValue: this.get('orig'),
            altText: 'from',
          }).render().$el,
        );
        name.append(
          new app.JsonView({
            jsonValue: this.get('diff'),
            altText: 'to',
          }).render().$el,
        );
      } else if (_.isObject(this.get('diff'))) {
        name.append(
          new app.JsonView({
            jsonValue: this.get('diff'),
            altText: 'now',
          }).render().$el,
        );
      } else {
        name.append($(`<span>${app.HtmlHelper.replaceMongoIdWithLinks(this.get('diff'))}</span>`));
      }
    }

    return name;
  },

  url() {
    if (!this.id) {
      return '/api/v2/activities';
    }
    return `/api/v2/activities/${this.id}`;
  },

  toCsv() {
    let recordType = MaitredActivityHelper.stringFromActivityType(this.get('type'));
    if (this.get('type') === MaitredActivityType.Update) {
      if (this.get('updateType')) {
        recordType += ` (${MaitredActivityHelper.stringFromUpdateType(this.get('updateType'))})`;
      }
    }

    let formattedDiff = '';
    if (this.get('diff')) {
      if (typeof this.get('diff') === 'string') {
        formattedDiff = this.get('diff');
      } else {
        const formattedObj = {};
        _.each(this.get('diff'), (value, key) => {
          formattedObj[key.replace(/^\\\$/, '$')] = value;
        });
        formattedDiff = `"${JSON.stringify(formattedObj).replaceAll('"', '""')}"`;
      }
    }

    let formattedOrig = '';
    if (this.get('orig')) {
      const formattedObj = {};
      _.each(this.get('diff'), (value, key) => {
        formattedObj[key.replace(/^\\\$/, '$')] = value;
      });
      formattedOrig = `"${JSON.stringify(formattedObj).replaceAll('"', '""')}"`;
    }

    return [
      TimeHelper.displayDateFromTimestamp(this.get('createdAt'), app.location.get('timezone')),
      this.get('username'),
      recordType,
      ModelTypeHelper.name(this.get('modelType')),
      this.get('modelName'),
      this.get('modelId'),
      formattedDiff,
      formattedOrig,
    ];
  },
});

app.MaitredActivity._isClickableModelType = function isClickableModelType(type) {
  switch (type) {
    case ModelType.Badge:
    case ModelType.Coupon:
    case ModelType.DictionaryWord:
    case ModelType.Integration:
    case ModelType.Kiosk:
    case ModelType.LocationGroup:
    case ModelType.MenuItem:
    case ModelType.MenuSection:
    case ModelType.MenuCover:
    case ModelType.MenuCoverPromo:
    case ModelType.MenuStructure:
    case ModelType.MenuTiming:
    case ModelType.Mod:
    case ModelType.ModGroup:
    case ModelType.ReportSchedule:
    case ModelType.Vendor:
    case ModelType.User:
    case ModelType.UserRole:
      return true;
    default:
      return false;
  }
};
