import { ChangesetType, ChangesetTypeHelper, ModelType } from '@biteinc/enums';

import { TimeHelper } from '../helpers/time_helper';
import { changesetSchema } from './changeset_schema';

app.Changeset = app.AbstractModel.extend({
  ModelName: 'changeset',
  Schema: changesetSchema,
  Type: ModelType.Changeset,

  initialize() {
    this.Schema.fields.scheduledFor.useSiteTimezone = !!app.site;
    app.AbstractModel.prototype.initialize.apply(this, arguments);
  },

  displayName() {
    const type = ChangesetTypeHelper.name(this.get('type'));
    const tz = app.site?.get('timezone');
    const scheduledFor = TimeHelper.displayDateFromTimestamp(this.get('scheduledFor'), tz);

    const name = `${type} at ${scheduledFor}`;

    if (!this.get('appliedAt')) {
      return `${name} (Pending)`;
    }

    const appliedAt = TimeHelper.displayDateFromTimestamp(this.get('appliedAt'), tz);

    return `${name} (Applied at ${appliedAt})`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = [subProperty, field]
      .filter((str) => {
        return !!str;
      })
      .join('.');

    switch (fieldName) {
      case 'type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ChangesetType,
          nameGenerator: ChangesetTypeHelper.name,
        });
      case 'magicCopyParams.toLocationIds':
        return app.locationList;
    }
  },

  canBeDestroyed() {
    // Only changesets that have not been applied yet can be destroyed
    return !this.get('appliedAt');
  },

  fieldIsPermanent(/* field, subProperty */) {
    // Only changesets that have not been applied yet can be updated
    return !!this.get('appliedAt');
  },

  hasAccessoryLabel() {
    return false;
  },
});
