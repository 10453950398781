import { KioskOrderingMode } from '@biteinc/enums';

app.MenuItemList = app.AbstractCollection.extend({
  model: app.MenuItem,

  initialize(models, opts) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    const options = opts || {};
    if (options.modGroupList) {
      this.listenTo(options.modGroupList, 'destroy', function onDestroy(modGroup) {
        this.each((item) => {
          item.removeModGroup(modGroup);
        });
      });
    }
    if (options.badgeList) {
      this.listenTo(options.badgeList, 'destroy', function onDestroy(badge) {
        this.each((item) => {
          item.removeBadge(badge);
        });
      });
    }
  },

  getSchema() {
    if (!this._schema) {
      const schema = app.AbstractCollection.prototype.getSchema.apply(this);
      this._schema = app.JsonHelper.deepClone(schema);
      delete this._schema.fields.constituentItems;
      delete this._schema.fields.invalidReason;
      if (app.location) {
        if (app.location.getPosI9nSchema()) {
          this._schema.fields.name.displayName = 'Display Name';
          this._schema.fields.description.displayName = 'Display Description';
        } else {
          this._schema.fields.name.required = true;
          delete this._schema.fields.posName;
          delete this._schema.fields.posDescription;
        }

        if (!app.location.usesFulfillmentNames()) {
          delete this._schema.fields.fulfillmentName;
          delete this._schema.fields.priceOptions.fields.fulfillmentName;
        }
      } else {
        delete this._schema.fields.posName;
        delete this._schema.fields.badgeIds;
        delete this._schema.fields.priceOptions.fields.addonSetIds;
        delete this._schema.fields.fulfillmentName;
        delete this._schema.fields.priceOptions.fields.fulfillmentName;
      }
      if (app.locationSettings?.get('kioskOrderingMode') !== KioskOrderingMode.InfoOnly) {
        delete this._schema.fields.displayBlocks;
      }
    }
    return this._schema;
  },

  parse(data) {
    return data.data.items;
  },

  usesPaginatedFetch() {
    return true;
  },

  url() {
    return '/api/v2/menu-items';
  },

  getItemWithRecoId(recoId) {
    return this.find((item) => {
      return item.recoId() === recoId;
    });
  },
});
