app.OpenHoursTimetableList = app.AbstractCollection.extend({
  model: app.OpenHoursTimetable,

  url() {
    return `/api/v2/open-hours-timetables/${this.id || ''}`;
  },

  type() {
    return 'Open Hours';
  },
});
