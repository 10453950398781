import type { Schema } from '@biteinc/common';

export function buildOrderListSchema(hasSite: boolean): Schema.ModelTypedObjectField {
  const schema: Schema.ModelTypedObjectField = {
    type: 'object',
    fields: {
      fromTimestamp: {
        type: 'timestamp',
        displayName: 'From',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: hasSite,
      },
      toTimestamp: {
        type: 'timestamp',
        displayName: 'To',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: hasSite,
      },
    },
  };
  return schema;
}
