import _ from 'underscore';

export const templateSettings = {
  evaluate: /<@([\s\S]+?)@>/g,
  interpolate: /<@=([\s\S]+?)@>/g,
  escape: /<@-([\s\S]+?)@>/g,
};

export const template = (text: string): ((data: any) => string) =>
  _.template(text, templateSettings);
