import type { Schema } from '@biteinc/common';
import { Timezone, UserRight } from '@biteinc/enums';

type SitePageTabIds = 'site-settings' | 'order-throttling';
export const siteSchema: Schema.ModelWithTabIds<SitePageTabIds> = {
  displayName: 'site',
  type: 'object',
  fields: {
    name: {
      type: 'keyString',
      required: true,
      tabId: 'site-settings',
      writeRight: UserRight.CreateSitesAndChannels,
    },
    timezone: {
      displayName: 'Time zone',
      type: 'stringEnum',
      enum: Timezone,
      required: true,
      ui: 'dropdown',
      tabId: 'site-settings',
      writeRight: UserRight.CreateSitesAndChannels,
    },
    biteOnly: {
      type: 'bool',
      displayName: 'Bite-Only',
      tabId: 'site-settings',
      biteOnly: true,
    },
    orderThrottlingRules: {
      type: 'array',
      elementType: 'object',
      elementSubtype: 'OrderThrottlingRule',
      displayName: 'Order throttling rules',
      ui: 'accordion',
      uiSubtype: 'OrderThrottlingRule',
      tabId: 'order-throttling',
      // Hack to hide this field from the create dialogue
      conditions: [
        {
          field: '_id',
          exists: true,
        },
      ],
      writeRight: UserRight.ManageOrderThrottling,
    },
    orderThrottlingAsapOrdersShouldCount: {
      type: 'bool',
      defaultValue: true,
      displayName: 'ASAP orders should count',
      tabId: 'order-throttling',
      writeRight: UserRight.ManageOrderThrottling,
      conditions: [
        {
          field: '_id',
          exists: true,
        },
        {
          field: 'orderThrottlingRules',
          exists: true,
        },
      ],
    },
    fulfillmentStations: {
      type: 'array',
      elementType: 'object',
      ui: 'accordion',
      uiSubtype: 'FulfillmentStations',
      tabId: 'site-settings',
      displayName: 'Fulfillment stations',
      tooltip: {
        text: 'Only applies to Fresh KDS.',
      },
      fields: {
        _id: {
          type: 'mongoId',
          isHidden: true,
        },
        name: {
          type: 'shortString',
          displayName: 'Name',
          required: true,
        },
        isEnabled: {
          type: 'bool',
          displayName: 'Enabled',
          defaultValue: true,
        },
      },
    },
  },
};
