import { allEnumValues, ModelType, UserRight, UserRightHelper } from '@biteinc/enums';
import { userRoleSchema } from '@biteinc/schemas';

import {
  descriptionFromAssignableUserRight,
  nameFromAssignableUserRight,
} from '../helpers/user_right_helper';

app.UserRole = app.AbstractModel.extend({
  ModelName: 'userRole',
  Schema: userRoleSchema,
  Type: ModelType.UserRole,

  canBeDestroyed() {
    return (
      app.AbstractModel.prototype.canBeDestroyed.apply(this, arguments) && app.sessionUser.isAdmin()
    );
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'rights': {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: UserRight,
          nameGenerator: nameFromAssignableUserRight,
          disclaimerGenerator: (right) => {
            return this.disclaimerFromUserRight(right);
          },
          sort: app.AbstractCollection.SortOptions.AS_IS,
          values: allEnumValues(UserRight)
            .filter((right) => {
              return (
                app.sessionUser.hasRight(right) &&
                !UserRightHelper.isBiteRight(right) &&
                !UserRightHelper.isApiTokenRight(right)
              );
            })
            .sort((a, b) => {
              return (
                UserRightHelper.getAllPrerequisites(b).length -
                  UserRightHelper.getAllPrerequisites(a).length || b - a
              );
            }),
        });
      }
    }
    return null;
  },

  getFieldHelpers(field) {
    switch (field) {
      case 'rights': {
        return {
          isValueDisabled: (right, roleRightsArray) => {
            const roleRightsSet = new Set(roleRightsArray);

            return (
              // Just in case the user deselected a prerequisite, we cannot disable a right
              // currently selected
              !roleRightsSet.has(right) &&
              UserRightHelper.getDirectPrerequisites(right).some((prereq) => {
                return !roleRightsSet.has(prereq);
              })
            );
          },
        };
      }
    }

    return null;
  },

  disclaimerFromUserRight(right) {
    const description = descriptionFromAssignableUserRight(right);
    const prereqs = UserRightHelper.getDirectPrerequisites(right);

    if (!prereqs.length) {
      return description;
    }

    return `${description}\n\nPrerequisites:\n${prereqs
      .map((prereq) => {
        return `- ${nameFromAssignableUserRight(prereq)}`;
      })
      .join('\n')}`;
  },

  fieldIsPermanent(/* field, subProperty */) {
    return !app.sessionUser.isAdmin();
  },
});
