import _ from 'underscore';

import { LocationPresetTipType } from '@biteinc/enums';

app.PresetTipsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} preset-tips-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          type: LocationPresetTipType.Percentage,
          amount: 15,
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let { type, amount } = fieldGroupView.getValue();
        amount ||= 0;
        if (type === LocationPresetTipType.Percentage) {
          return `${amount}%`;
        }
        if (type === LocationPresetTipType.Fixed) {
          return `$${amount.toFixed(2)}`;
        }
        throw new Error(`Unknown preset tip type: ${type}`);
      },
      templateData: {
        title: 'Preset Tips',
        buttonText: 'New Preset Tip',
        emptyText: 'No preset tips yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
