// cspell:ignore JaroWinkler

import { JaroWinklerDistance } from 'natural';

import type { Schema } from '@biteinc/common';
import {
  appearanceSchema,
  locationSchema,
  menuAppearanceSchema,
  orgSchema,
  SchemaHelper,
  settingsSchema,
} from '@biteinc/schemas';

import { siteSchema } from '../models/site_schema';

const schemasByAppContext: Record<string, Schema.ModelWithTabIds<string>[]> = {
  org: [orgSchema, settingsSchema, appearanceSchema as Schema.ModelWithTabIds<string>],
  site: [siteSchema, settingsSchema],
  location: [
    locationSchema,
    menuAppearanceSchema as Schema.ModelWithTabIds<string>,
    appearanceSchema as Schema.ModelWithTabIds<string>,
    settingsSchema,
  ],
};

export enum SearchContext {
  Org = 'org',
  Site = 'site',
  Location = 'location',
}

type MatchResult = {
  fieldName: string;
  displayName: string;
  section: string;
  context: SearchContext;
  priority: number;
};

export module SchemaFieldSearchHelper {
  /**
   * Look above where we are for a setting that matches the search string
   * It doesn't make sense to look below as it would mean having to select the child site or channel
   */
  function getOtherContextsToSearch(context: SearchContext): SearchContext[] {
    switch (context) {
      case SearchContext.Org:
        return [];
      case SearchContext.Site:
        return [SearchContext.Org];
      case SearchContext.Location:
        return [SearchContext.Org, SearchContext.Site];
    }
  }

  type SchemaMatchResult = { fieldName: string; displayName: string; tabId?: string };

  function fieldMatchesSearchString(
    searchString: string,
    fieldName: string,
    fieldSchema: Schema.AnyField,
  ): boolean {
    const distanceForFieldName = JaroWinklerDistance(fieldName, searchString, {
      ignoreCase: true,
    });
    const distanceForDisplayName = fieldSchema.displayName
      ? JaroWinklerDistance(fieldSchema.displayName, searchString, {
          ignoreCase: true,
        })
      : 0;
    // This is meant to catch small words like "page" or "slug"
    const containedInDisplayNameBetweenWordBoundaries =
      searchString.length >= 3 && fieldSchema.displayName
        ? new RegExp(`\\b${searchString}\\b`, 'i').test(fieldSchema.displayName)
        : false;
    // This is meant to catch partial expressions like "page nav" for "page navigation"
    const containedInDisplayName =
      searchString.length >= 7 && fieldSchema.displayName
        ? fieldSchema.displayName.toLowerCase().includes(searchString)
        : false;
    return (
      distanceForDisplayName > 0.8 ||
      distanceForFieldName > 0.8 ||
      containedInDisplayNameBetweenWordBoundaries ||
      containedInDisplayName
    );
  }

  function getSchemaMatchesForSearchString(
    dirtySearchString: string,
    schema: Schema.TypedObjectField | Schema.TypedObjectArrayField,
  ): SchemaMatchResult[] {
    const searchString = dirtySearchString.trim().toLowerCase();
    return Object.entries(schema.fields).reduce((acc, [fieldName, fieldSchema]) => {
      // See if any child fields match
      const nestedResults: SchemaMatchResult[] = [];
      if (fieldSchema.type === 'array' && fieldSchema.elementType === 'object') {
        if ('fields' in fieldSchema) {
          nestedResults.push(...getSchemaMatchesForSearchString(searchString, fieldSchema));
        } else if ('elementSubtype' in fieldSchema) {
          nestedResults.push(
            ...getSchemaMatchesForSearchString(
              searchString,
              SchemaHelper.getObjectSchemaFromSubtype(fieldSchema.elementSubtype),
            ),
          );
        }
      } else if (fieldSchema.type === 'object') {
        if ('fields' in fieldSchema) {
          nestedResults.push(...getSchemaMatchesForSearchString(searchString, fieldSchema));
        } else if ('subtype' in fieldSchema) {
          nestedResults.push(
            ...getSchemaMatchesForSearchString(
              searchString,
              SchemaHelper.getObjectSchemaFromSubtype(fieldSchema.subtype),
            ),
          );
        }
      }

      const currentFieldIsAMatch = fieldMatchesSearchString(searchString, fieldName, fieldSchema);

      const currentFieldDisplayName =
        fieldSchema.displayName || getDisplayNameFromFieldName(fieldName);
      return [
        ...acc,
        ...nestedResults.map((nestedResult) => {
          return {
            // Override the nested result's fieldName with the top level one so bureau can navigate
            fieldName,
            displayName: `${currentFieldDisplayName} > ${nestedResult.displayName}`,
            ...('tabId' in fieldSchema && { tabId: fieldSchema.tabId as string }),
          };
        }),
        ...(currentFieldIsAMatch
          ? [
              {
                fieldName,
                displayName: currentFieldDisplayName,
                ...('tabId' in fieldSchema && { tabId: fieldSchema.tabId as string }),
              },
            ]
          : []),
      ];
    }, [] as SchemaMatchResult[]);
  }

  /**
   * Some nested schema objects do not have a displayName, so we need to generate one
   */
  function getDisplayNameFromFieldName(fieldName: string): string {
    const words = fieldName.split(/(?=[A-Z])/);
    return words.map((word) => word[0].toUpperCase() + word.slice(1)).join(' ');
  }

  function getPriorityFromContext(context: SearchContext): number {
    switch (context) {
      case SearchContext.Org:
        return 0;
      case SearchContext.Site:
        return 1;
      case SearchContext.Location:
        return 2;
    }
  }

  export function getSchemaFieldSections(
    context: SearchContext,
    searchString: string,
  ): MatchResult[] {
    const schemas = schemasByAppContext[context];
    const contextSections = schemas
      .map((schema) => {
        const matches = getSchemaMatchesForSearchString(searchString, schema);
        return matches.map(({ fieldName, displayName, tabId }) => {
          return {
            fieldName,
            displayName: displayName,
            section: tabId!,
            context,
            priority: getPriorityFromContext(context),
          };
        });
      })
      .flat();
    const otherContexts = getOtherContextsToSearch(context);
    const otherContextSections = otherContexts
      .map((otherContext) => getSchemaFieldSections(otherContext, searchString))
      .flat();
    return [...contextSections, ...otherContextSections].sort((a, b) => a.priority - b.priority);
  }
}
