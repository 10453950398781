import async from 'async';
import _ from 'underscore';

app.MultiQueueHelper = {
  makeRequest(url, body, showAlertOnError, callback) {
    app.QueueHelperCommon.makeRequest(
      'POST',
      url,
      body,
      (buildJobsResponse, checkStatusCb) => {
        const queryJobStatusUntilDoneFunctions = buildJobsResponse.postJob.map((jobInfo) => {
          return app.QueueHelperCommon.buildDoUntilAsyncFunction(jobInfo);
        });
        async.parallel(queryJobStatusUntilDoneFunctions, checkStatusCb);
      },
      (err, getJobResponses) => {
        const error =
          err ||
          _.find(getJobResponses.checkStatus, (getJobResponse) => {
            return !!getJobResponse.err;
          })?.err;

        if (error) {
          if (showAlertOnError) {
            new app.AlertView().show(error.message || error);
          }
          callback(error);
        } else {
          const results = _.map(getJobResponses.checkStatus, (getJobResponse) => {
            return getJobResponse.result;
          });
          callback(null, results);
        }
      },
    );
  },
};
