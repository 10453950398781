app.AppearanceV2DetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    this.listenTo(app.customCssGcnBuildList, 'reset', this.render);
    this.listenTo(app.customCssVitrineBuildList, 'reset', this.render);
  },

  viewWasAddedToDom() {
    app.BaseDetailsView.prototype.viewWasAddedToDom.apply(this, arguments);

    if (!app.customCssGcnBuildList.hasBeenFetched()) {
      app.customCssGcnBuildList.fetch({ reset: true });
    }
    if (!app.customCssVitrineBuildList.hasBeenFetched()) {
      app.customCssVitrineBuildList.fetch({ reset: true });
    }
  },
});
