import {
  allEnumValues,
  OrderChannel,
  OrderChannelHelper,
  ReportType,
  ReportTypeHelper,
  Timezone,
  TimezoneHelper,
} from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

import { ReportHelper } from '../helpers/report_helper';
import { TimeHelper } from '../helpers/time_helper';
import { oneTimeReportSchema } from './one_time_report_schema';

app.OneTimeReport = app.AbstractModel.extend({
  ModelName: 'oneTimeReport',
  Schema: oneTimeReportSchema,

  // We get the timestamp as the beginning of the day in whatever timezone the browser is in.
  // So we must convert it to be 4am in the specified timezone.
  _rewriteDayTimestamp(t, tz) {
    const m = tz
      ? Time.moment(TimeHelper.localMoment(t).format('YYYY-MM-DD'), tz)
      : TimeHelper.localMoment(t);
    return m.add(4, 'hours');
  },

  defaults() {
    const reportDefaults = {
      // Add our user's email by default so they don't have to type it in
      ...(app.sessionUser?.attributes.username && {
        emailAddresses: [`${app.sessionUser?.attributes.username}`],
      }),
    };
    if (!app.site || app.location) {
      return {
        ...reportDefaults,
        orderChannels: allEnumValues(OrderChannel),
      };
    }
    const siteLocations = app.locationList.getLocationsWithSiteId(app.site.id);
    const orderChannels = siteLocations
      .filter((siteLocation) => {
        return !siteLocation.isBiteOnly();
      })
      .map((siteLocation) => {
        return siteLocation.get('orderChannel');
      });

    return {
      ...reportDefaults,
      orderChannels,
    };
  },

  save(data, opts) {
    const timezone = app.location ? app.location.get('timezone') : data.timezone;
    if (data.timeRange) {
      data.timeRange.from = this._rewriteDayTimestamp(data.timeRange.from, timezone).valueOf();
      data.timeRange.to = this._rewriteDayTimestamp(data.timeRange.to, timezone).valueOf();
    }

    app.QueueHelper.makeRequest('POST', '/api/v2/reporting/one-off', data, true, (err, result) => {
      if (err) {
        if (opts.error) {
          opts.error(err);
        }
        return;
      }

      if (opts.success) {
        opts.success(this, result || {});
      }
    });
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'type': {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ReportType,
          nameGenerator: ReportTypeHelper.name,
          values: ReportHelper.getAvailableReportTypes(
            true,
            app.sessionUser,
            app.org,
            app.site,
            app.location,
            app.getSettingsFromContext(),
          ),
        });
      }
      case 'orderChannels': {
        let orderChannels = allEnumValues(OrderChannel);
        // Get channels only being used by locations in the site
        if (app.site && !app.location) {
          const siteLocations = app.locationList.getLocationsWithSiteId(app.site.id);
          orderChannels = siteLocations
            .filter((siteLocation) => {
              return !siteLocation.isBiteOnly();
            })
            .map((siteLocation) => {
              return siteLocation.get('orderChannel');
            });
        }

        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrderChannel,
          values: orderChannels,
          nameGenerator: OrderChannelHelper.nameCombiningKioskAndFlash,
        });
      }
      case 'locationGroupId':
        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: true,
        });
      case 'salesByLocationGroupIds':
        return app.LocationGroupList.newSelectableList(app.locationGroupList);
      case 'timezone':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Timezone,
          nameGenerator: (timezone) => {
            const name = TimezoneHelper.name(timezone);
            // prepend timezones likes EST/CST with a space so they are sorted to the top
            return name === timezone ? timezone : ` ${name}`;
          },
        });
      case 'vendorId':
        return app.vendorList;
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId':
      case 'salesByLocationGroupIds': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },
});
