app.CouponListView = app.BaseListView.extend({
  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (this.options.canCreate) {
      const self = this;
      // require the user to select the posIntegrationId because the posDiscountID depends on it
      const posI9ns = app.location.getFullPosI9ns();
      const posI9nCollection = new app.AbstractCollection(posI9ns);
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle('<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new coupon');
      dropdownView.setup(posI9nCollection, {
        onAdd(posI9n) {
          // eslint-disable-next-line new-cap
          const model = new self.collection.model(
            {
              posIntegrationId: posI9n.id,
            },
            {
              collection: self.collection,
            },
          );
          self.editModel(model);
        },
      });
    }

    return this;
  },
});
