import qs from 'qs';

app.OrderList = app.AbstractCollection.extend({
  model: app.Order,

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  isValidQuery(query) {
    // Order number can be A`
    return query.length >= 2 || Object.keys(this.filterFieldGroupView.getValue()).length;
  },

  // Use the v2 search endpoint instead of just getting the order by id, because that endpoint
  // returns only successful transactions useful for gcn
  fetchModelWithId(modelId, callback) {
    this.fetch({
      url: `${this.url()}/search?query=${modelId}`,
      remove: false,
      showErrorModal: false,
      error(collection, err) {
        callback(err);
      },
    });
  },

  url() {
    let urlWip = '/api/v2/orders';

    const filterFieldGroupViewValue = this.filterFieldGroupView.getValue();
    const hasFilterParams = Object.keys(filterFieldGroupViewValue).length;

    if (this.query || hasFilterParams) {
      const qsObj = {
        query: this.query,
        ...filterFieldGroupViewValue,
      };
      urlWip += `/search?${qs.stringify(qsObj)}`;
    }

    return urlWip;
  },
});
