app.RecommendationRuleList = app.AbstractCollection.extend({
  model: app.RecommendationRule,

  initialize() {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);
    this.variationNameByRuleId = {};

    // if anything updates, then update the variation to rules map
    this.on('reset', this._rebuildVariationNameByRuleId);
    this.on('add', this._rebuildVariationNameByRuleId);
    this.on('remove', this._rebuildVariationNameByRuleId);
    app.recommendationVariationList.on('reset', this._rebuildVariationNameByRuleId);
    app.recommendationVariationList.on('add', this._rebuildVariationNameByRuleId);
    app.recommendationVariationList.on('remove', this._rebuildVariationNameByRuleId);
    this._rebuildVariationNameByRuleId();
  },

  getVariationNameByRuleId() {
    return this.variationNameByRuleId;
  },

  _rebuildVariationNameByRuleId() {
    this.variationNameByRuleId = {};

    app.recommendationVariationList.models.forEach((variation) => {
      variation.get('recommendationRuleIds').forEach((recommendationRuleId) => {
        if (!this.variationNameByRuleId[recommendationRuleId]) {
          this.variationNameByRuleId[recommendationRuleId] = [];
        }
        this.variationNameByRuleId[recommendationRuleId].push(variation.get('name'));
      });
    });
  },

  url() {
    return '/api/v2/recommendation-rules';
  },
});
