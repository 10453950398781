import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';

app.AlertRulesView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} alert-rules-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let title;
        const fields = fieldGroupView.getValue();
        if (_.has(fields, 'email')) {
          title = fields.email;
        }
        return title || '';
      },
      setSetValueHandlers(fieldView, handler) {
        fieldView.fieldViewsByField.email.$form.keyup(handler);
      },
      templateData: {
        title: 'Alert Rules',
        buttonText: 'new alert rule',
        emptyText: 'no alert rules yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
