import { Time } from '@biteinc/helpers';

import { TimeHelper } from '../../helpers/time_helper';

app.PaginatedListView = app.BaseListView.extend({
  initialize(opts) {
    const options = {
      canCreate: false,
      paginateToLoadSelectedItem: false,
      ...(opts || {}),
    };
    app.BaseListView.prototype.initialize.apply(this, [options]);
  },

  createCell(model) {
    const options = {
      clickable: false,
      selectable: true,
      showTimestampsInLocationTimezone: false,
      ...(this.options.cellOptions || {}),
    };
    options.model = model;

    const CellClass = this.options.cellView || app.CellView;
    const cell = new CellClass(options);
    cell.addAccessoryLabel(this.getAccessoryLabelText(model));

    if (options.clickable) {
      this.listenTo(cell, app.CellView.Events.CellViewWasClicked, this.__cellWasClicked);
      this.listenTo(
        cell,
        app.CellView.Events.CellViewDidBecomeSelected,
        this._cellDidBecomeSelected,
      );
      this.listenTo(
        cell,
        app.CellView.Events.CellViewDidBecomeUnselected,
        this._cellDidBecomeUnselected,
      );
    }

    return cell;
  },

  __fetchSelectedItem(selectedItemId) {
    if (!this.options.paginateToLoadSelectedItem) {
      app.BaseListView.prototype.__fetchSelectedItem.apply(this, [selectedItemId]);
      return;
    }

    if (!this._canFetchNextPage()) {
      app.view.updateUrlParam(null);
      new app.AlertView().show(
        `The ${this.collection.getSchema().displayName} you were looking for could not be found.`,
      );
      return;
    }

    // Paginate until we either exhaust the collection or we find what we are looking for
    this.listenToOnce(this.collection, 'update', () => {
      this.__processSelectedItemId();
    });
    this._fetchNextPage();
  },

  _canFetchNextPage() {
    return this.collection.size() && !this.collection.hasReachedEndOfList();
  },

  _fetchNextPage() {
    this._$loadMoreButton.hide();
    this._$loadMoreRow.find('.fetching').show();
    const $loadMoreRow = this._$loadMoreRow;
    this.listenToOnce(this.collection, 'update', () => {
      $loadMoreRow.remove();
    });
    this.collection.fetch({ remove: false });
  },

  getAccessoryLabelText(model) {
    if (!model.hasAccessoryLabel()) {
      return '';
    }
    if (this.options.showTimestampsInLocationTimezone) {
      const location =
        app.location || (model.get('locationId') && app.locationList?.get(model.get('locationId')));
      const timezone = location?.get('timezone') || Time.BITE_TZ;
      return TimeHelper.displayDateFromTimestamp(model.get('createdAt'), timezone);
    }
    return TimeHelper.displayDateFromTimestamp(model.get('createdAt'));
  },

  _renderList() {
    app.BaseListView.prototype._renderList.apply(this, arguments);

    if (this.$list && this._canFetchNextPage()) {
      if (this._$loadMoreRow) {
        // Prevents duplicates.
        // Since this row may have been mutated, we can't reuse it; Easier to make a new one.
        this._$loadMoreRow.remove();
      }
      this._$loadMoreRow = $(
        '<div class="load-more no-user-select">' +
          '<div class="load-more-button">Load More</div>' +
          '<div class="fetching"></div>' +
          '</div>',
      );
      this._$loadMoreButton = this._$loadMoreRow.find('.load-more-button');
      this._$loadMoreButton.click(() => {
        this._fetchNextPage();
      });
      this.$list.append(this._$loadMoreRow);
    } else if (!this._canFetchNextPage() && this._$loadMoreRow) {
      // Previously, the list had a next page, but after fetching again, it no longer does.
      this._$loadMoreRow.remove();
    }
  },
});
