app.MenuItemCellView = app.CellView.extend({
  getTitle() {
    let title = app.CellView.prototype.getTitle.apply(this, arguments);
    const parentCollection = this.model.isMod() ? app.modGroupList : app.menuSectionList;

    if (this.model.get('variationSource')) {
      title += ' <span class="cell-badge variation">variation</span>';
    } else if (
      !this.model.get('archivedAt') &&
      parentCollection.hasBeenFetched() &&
      !this.model.getVisibleInPlaces().length
    ) {
      title += ' <span class="cell-badge not-in-menu">not in menu</span>';
    }

    return title;
  },
});
