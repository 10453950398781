import Backbone from 'backbone';

app.MultiView = Backbone.View.extend({
  className: 'multi-view',

  initialize(options) {
    this.views = options.views || [];
  },

  addView(view) {
    if (!view) {
      return;
    }
    this.views.append(view);
  },

  canDismissModal() {
    return this.views.every((view) => {
      return !view.canDismissModal || view.canDismissModal();
    });
  },

  suspend() {
    for (let i = this.views.length - 1; i >= 0; i--) {
      this._removeView(this.views[i]);
    }
  },

  _removeView(view) {
    if (view && view.canDismissModal && !view.options.isPermanent) {
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i] === view) {
          this.views.splice(i, 1);
          break;
        }
      }
      this.trigger(app.MultiView.Events.MultiViewWillRemoveView, this, view);
      view.destroy();
      view.remove();
      return true;
    }
    return false;
  },

  removeViewAtIndex(index) {
    if (this.views.length > index) {
      const view = this.views[index];
      if (view.canDismissModal && view.canDismissModal()) {
        this._removeView(view);
      }
    }
  },

  replaceViewAtIndex(index, view) {
    if (this.views.length > index) {
      const prevView = this.views[index];
      this._removeView(prevView);
      this.views.splice(index, 0, view);
    } else {
      this.views.push(view);
    }
  },

  render() {
    this.$el.html('');
    for (let i = 0; i < this.views.length; i++) {
      this.$el.append(this.views[i].render().$el);
    }
    return this;
  },
});

app.MultiView.Events = {
  MultiViewWillRemoveView: 'MultiViewWillRemoveView',
};
