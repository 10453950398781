import { Environment } from '@biteinc/enums';

app.OrgDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    // Listen for the locations to be loaded so we can render the location dropdowns in web and
    // catering settings
    this.listenTo(app.locationList, 'reset', (/* e */) => {
      if (this.$el.html().length) {
        this.render();
      }
    });
  },

  getSchema() {
    const schema = app.JsonHelper.deepClone(app.BaseDetailsView.prototype.getSchema.apply(this));

    if (Environment.DEV !== app.data.APP_ENV) {
      delete schema.fields.customCssPath;
    }

    return schema;
  },
});
