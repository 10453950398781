import $ from 'jquery';
import _ from 'underscore';

app.SpiritList = app.AbstractCollection.extend({
  model: app.Spirit,

  initialize(models, options) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);
    this.category = options.category;
  },

  getSchema() {
    return this.model.Schemas[this.category];
  },

  url() {
    return '/api/spirits';
  },

  fetch(opts) {
    const options = _.extend(
      {
        data: $.param({ category: this.category }),
        processData: true,
      },
      opts || {},
    );
    app.AbstractCollection.prototype.fetch.apply(this, [options]);
  },
});
