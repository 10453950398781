import Backbone from 'backbone';
import plupload from 'plupload';
import _ from 'underscore';

import { template } from '../template';

app.ImageUploadView = Backbone.View.extend({
  className: 'image-upload-view',
  template: template($('#image-upload-view-template').html()),
  states: {
    NO_FILE: 0,
    WAITING: 1,
    UPLOADING: 2,
    ERROR: 3,
  },

  initialize(options) {
    const { image, schema } = options;
    this.schema = schema;
    this.initialImage = image;
    this.image = image;
    this.isReadOnly = options.isReadOnly;
    this._prevValue = this.getImage();
  },

  destroy() {
    if (this.image) {
      this.stopListening(this.image);
    }
    if (this.frameEditView) {
      this.stopListening(this.frameEditView);
    }
    if (this.image && this.image.uploader) {
      this.image.uploader.stop();
      this.image.uploader.unbindAll();
      this.image.uploader.destroy();
    }
    if (this.imagePreview) {
      this.imagePreview.destroy();
    }
  },

  _setState(state, error) {
    if (this.frameEditView) {
      this.frameEditView.$el.hide();
      this.$el.removeClass('has-frame');
    }

    switch (state) {
      case this.states.NO_FILE: {
        this.$scrim.hide();
        const imgParams = this.getImage();
        if (imgParams && this.frameEditView) {
          this.frameEditView.$el.show();
          this.$el.addClass('has-frame');
          this.frameEditView.setSize({
            width: imgParams.width,
            height: imgParams.height,
          });
          if (this.image.has('frame')) {
            this.frameEditView.setValue(this.image.get('frame'));
          }
        }
        break;
      }
      case this.states.WAITING:
        this.$scrim.show();
        this.$progress.hide();
        this.$retryButton.hide();
        this.$spinner.show();
        break;
      case this.states.UPLOADING:
        this.$scrim.show();
        this.$progress.show();
        this.$retryButton.hide();
        this.$spinner.hide();
        break;
      case this.states.ERROR:
        this.$scrim.show();
        this.$progress.hide();
        this.$retryButton.show();
        this.$retryButton.find('.msg').text(error);
        this.$spinner.hide();
        break;
    }
    if (error && state === this.states.NO_FILE) {
      this.$errorLabel.show().html(error);
    } else {
      this.$errorLabel.hide();
    }

    if (this.image) {
      this._checkIfImageHasChanged();
    }
  },

  _deleteButtonWasClicked() {
    this.$deleteButton.hide();
    this.$deleteConfirmation.show();
  },

  _declineButtonWasClicked() {
    this.$deleteButton.show();
    this.$deleteConfirmation.hide();
  },

  _confirmButtonWasClicked() {
    this.trigger(app.ImageUploadView.Events.ImageUploadViewWasRemoved, this);
  },

  _retryButtonWasClicked() {
    this.image.startUploading();
  },

  _imageUploadDidFail(image) {
    if (image === this.image) {
      this._setState(this.states.ERROR, 'There was an error. Please try again later.');
    }
  },

  _imageUploadDidFinish(image) {
    if (image === this.image) {
      this._setState(this.states.NO_FILE);
    }
  },

  _imageUploadDidStart(image) {
    if (image === this.image) {
      this._setState(this.states.WAITING);
    }
  },

  _imageUploadDidProgress(image, percentComplete, secondFile) {
    if (image === this.image) {
      this._setState(this.states.UPLOADING);
      let percent = Math.round(percentComplete / 2);
      if (secondFile) {
        percent += 50;
      }
      this.$progress.html(`${percent}%`);
    }
  },

  _checkIfImageHasChanged() {
    const value = this.getImage();
    const changed = !_.isEqual(value, this._prevValue);
    if (changed) {
      this.trigger(app.ImageUploadView.Events.ImageUploadViewDidChange, this);
    }
    this._prevValue = value;
  },

  setImage() {
    this.imagePreview = new app.ImagePreview({ model: this.image });
    if (this.frameEditView) {
      this.imagePreview.render().$el.insertAfter(this.frameEditView.$el);
    } else {
      this.$el.prepend(this.imagePreview.render().el);
    }

    let hideInputButton = false;
    if (this.image.has('url')) {
      this._setState(this.states.NO_FILE);
      hideInputButton = true;
      if (!this.isReadOnly) {
        this.$deleteButton.show();
      }
      this.$dropArea.hide();
    }

    if (hideInputButton) {
      this.$inputButton.hide();
    } else {
      const size = this.schema.jpgSize || this.schema.size;
      const uploader = new plupload.Uploader({
        browse_button: this.$inputButton[0],
        drop_element: this.$dropArea[0],
        filters: {
          // Having a max_file_size filter here, means that plupload just silently exits
          // without any explanation as to why it won't touch the file.
          mime_types: [
            {
              title: 'Image files',
              extensions: 'jpg,png,jpeg,gif,webp',
            },
          ],
        },
        http_method: 'put',
        multi_selection: false,
        multipart: false,
        resize: {
          width: size.width,
          height: size.height,
          crop: true,
          quality: 90,
          preserve_headers: false,
        },
        runtimes: 'html5,html4',
        send_file_name: false,
        url: 'https://admin.getbite.com', // This is a fake one
        init: {
          FilesAdded: (up, files) => {
            this.$dropArea.hide();
            if (up.files.length % 2 === 1) {
              const nativeFile = files[0].getNative();
              const err = this.image.setFromFile(nativeFile);
              if (err) {
                this._setState(this.states.NO_FILE, err);
                return;
              }

              this._setState(this.states.WAITING);

              const origFile = new plupload.File(nativeFile);
              origFile.isOriginal = true;
              up.addFile(origFile);
            }
          },
        },
      });
      uploader.init();
      this.image.uploader = uploader;

      this.listenTo(this.image, app.Image.Events.ImageUploadDidFail, this._imageUploadDidFail);
      this.listenTo(this.image, app.Image.Events.ImageUploadDidFinish, this._imageUploadDidFinish);
      this.listenTo(
        this.image,
        app.Image.Events.ImageUploadDidProgress,
        this._imageUploadDidProgress,
      );
      this.listenTo(this.image, app.Image.Events.ImageUploadDidStart, this._imageUploadDidStart);
    }
  },

  getImage() {
    if (this.image && this.image.has('url') && this.image.has('origUrl')) {
      const params = {
        url: this.image.src(),
        width: this.image.width(),
        height: this.image.height(),
        origUrl: this.image.get('origUrl'),
        origWidth: this.image.get('origWidth'),
        origHeight: this.image.get('origHeight'),
      };
      if (this.initialImage && this.initialImage.get('isCellar')) {
        params.isCellar = true;
      }
      if (this.initialImage && this.initialImage.get('posUrl')) {
        params.posUrl = this.initialImage.get('posUrl');
      }
      return params;
    }
    return null;
  },

  render() {
    this.$el.html(this.template());

    const $tools = this.$('.tools-container');
    this.$dropArea = $tools.find('.drop-area');
    this.$inputButton = $tools.find('.input-container button');
    this.$errorLabel = $tools.find('.error').hide();
    this.$scrim = $tools.find('.scrim.info').hide();
    this.$spinner = this.$scrim.find('.spinner').hide();
    this.$retryButton = this.$scrim.find('.retry').hide();
    this.$progress = this.$scrim.find('.upload-progress').hide();
    this.$deleteButton = $tools.find('.delete-button');
    this.$deleteConfirmation = $tools.find('.scrim.confirmation').hide();

    this.$retryButton.click(this._retryButtonWasClicked.bind(this));
    this.$deleteButton.click(this._deleteButtonWasClicked.bind(this));
    this.$deleteConfirmation.find('.decline').click(this._declineButtonWasClicked.bind(this));
    this.$deleteConfirmation.find('.confirm').click(this._confirmButtonWasClicked.bind(this));

    this.$dropArea.bind('dragover', () => {
      this.$dropArea.addClass('drag-over');
    });
    this.$dropArea.bind('dragleave', () => {
      this.$dropArea.removeClass('drag-over');
    });
    this._setState(this.states.NO_FILE);

    if (this.isReadOnly) {
      this.$deleteButton.hide();
    }

    return this;
  },
});

app.ImageUploadView.Events = {
  ImageUploadViewWasRemoved: 'imageUploadViewWasRemoved',
  ImageUploadViewDidChange: 'ImageUploadDidChange',
};
