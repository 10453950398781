import _ from 'underscore';

app.LocationList = app.AbstractCollection.extend({
  model: app.Location,

  comparator(location) {
    return `${location.get('orgNormalizedName')}/${location.get('normalizedName')}`;
  },

  url() {
    return `/api/orgs/${app.org.id}/locations`;
  },

  getAllLiveLocations() {
    return this.models.filter((location) => {
      return location.isLive();
    });
  },

  getLocationsWithSiteId(siteId) {
    return this.models.filter((location) => {
      return location.get('siteId') === siteId;
    });
  },

  newLocationListWithOrderChannel(orderChannel) {
    const allowedLocations = _.filter(app.locationList.models, (location) => {
      return location.get('orderChannel') === orderChannel;
    });
    return new app.LocationList(allowedLocations);
  },
});
