import $ from 'jquery';
import _ from 'underscore';

app.ModList = app.MenuItemList.extend({
  model: app.Mod,

  fetch(opts) {
    const options = _.extend(
      {
        data: $.param({ isMod: 1 }),
        processData: true,
      },
      opts || {},
    );
    app.MenuItemList.prototype.fetch.apply(this, [options]);
  },

  getSchema() {
    if (!this._schema) {
      const schema = app.AbstractCollection.prototype.getSchema.apply(this);
      this._schema = app.JsonHelper.deepClone(schema);
      if (app.location.getPosI9nSchema()) {
        delete this._schema.fields.name.required;
        this._schema.fields.name.displayName = 'Display Name';
      } else {
        delete this._schema.fields.posName;
      }

      if (!app.location.usesFulfillmentNames()) {
        delete this._schema.fields.fulfillmentName;
        delete this._schema.fields.priceOptions.fields.fulfillmentName;
      }
    }
    return this._schema;
  },
});
