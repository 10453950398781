import _ from 'underscore';

app.ItemArrayItem = app.AbstractModel.extend({
  ModelName: 'itemArrayItem',

  isModGroup() {
    return this.collection.model === app.ModGroup;
  },

  save(data, opts) {
    const itemRef = _.mapObject(data.settings, (value) => {
      return value.value;
    });
    itemRef._id = this.options.item.id;

    const self = this;
    this.options.itemArray.updateItem(
      this.options.item.id,
      itemRef,
      (d, updatedItemRef) => {
        self.set(app.ItemArrayItem.attributesFromItemRef(updatedItemRef));
        if (opts.success) {
          opts.success(self, {});
        }
      },
      (err) => {
        app.showSavedToast('Failed. :(');
        if (opts.error) {
          opts.error(err);
        }
      },
    );
  },

  getFieldCollection(field, _subProperty, _includeAllValues, keyModel) {
    switch (field) {
      case 'settings':
        return this.options.keyList;
    }
    const itemArrayIntegrationId = this.options.itemArray.get('integrations')[0]?._id;
    switch (keyModel.id) {
      case 'autoSelectedSubMods': {
        const modById = {};
        const possibleModGroups = _.filter(app.modGroupList.models, (modGroup) => {
          // Make sure that that auto-selected sub mods come from the same integration
          return (
            modGroup.get('isFloating') &&
            modGroup.get('integrations')[0]._id === itemArrayIntegrationId
          );
        });
        possibleModGroups.push(...this.options.item.getModGroups());

        _.each(possibleModGroups, (modGroup) => {
          _.each(modGroup.get('items'), (modRef) => {
            // Only take the first one because we are finding the first one in
            // _getModGroupForModId
            if (!modById[modRef._id]) {
              modById[modRef._id] = app.modList.get(modRef._id);
            }
          });
        });
        return new app.ModList(_.values(modById));
      }
    }
    return null;
  },

  getListFieldElementAttributesFromModel(field, element, subProperty, keyModel) {
    const attrs = { _id: element.id };
    if ('autoSelectedSubMods' === keyModel.id) {
      const modGroup = this._getModGroupForModId(element.id);
      attrs.modGroupId = modGroup.id;
    }
    return attrs;
  },

  detailsViewClassForListField(field, subProperty, keyModel) {
    switch (keyModel.id) {
      case 'autoSelectedSubMods':
        return app.MenuItemDetailsView;
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    let displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    if ('autoSelectedSubMods' === keyModel.id) {
      const modGroup = this._getModGroupForModId(element.id);
      displayName = `${modGroup.displayNameHtml()} > ${displayName}`;
    }
    return displayName;
  },

  _getModGroupForModId(modId) {
    const possibleModGroups = app.modGroupList
      .where({ isFloating: true })
      .concat(this.options.item.getModGroups());
    return _.find(possibleModGroups, (modGroup) => {
      return _.contains(_.pluck(modGroup.get('items'), '_id'), modId);
    });
  },
});

app.ItemArrayItem.attributesFromItemRef = function attributesFromItemRef(itemRef) {
  const attributes = {
    settings: Object.keys(itemRef).reduce((acc, key) => {
      acc[key] = { value: itemRef[key] };
      return acc;
    }, {}),
  };
  // remove settings not to be set from bureau
  delete attributes.settings._id;
  return attributes;
};

function defaultValueForFieldType(fieldType) {
  switch (fieldType) {
    case 'array':
      return [];
    case 'bool':
      return false;
    case 'dayTimestamp':
    case 'float':
    case 'int':
    case 'price':
    case 'timestamp':
      return 0;
    case 'map':
    case 'object':
      return {};
    case 'keyString':
    case 'shortString':
    case 'longString':
    case 'codeString':
    case 'email':
    case 'phoneNumber':
    case 'mongoId':
    case 'url':
      return '';
    default:
      throw new Error(`unsupported field type: ${fieldType}`);
  }
}

app.ItemArrayItem.create = function create(itemArray, item) {
  const itemRef = _.find(itemArray.get('items'), (ref) => {
    return ref._id === item.id;
  });

  const keys = [];
  const schema = {
    fields: {
      settings: {
        type: 'map',
        key: {
          type: 'shortString',
          displayName: 'Setting',
          ui: 'dropdown',
        },
        fields: {
          value: {
            type: 'shortString',
          },
        },
      },
    },
  };

  const attributes = app.ItemArrayItem.attributesFromItemRef(itemRef);

  _.each(itemArray.Schema.fields.items.fields, (fieldSchema, fieldName) => {
    if (fieldSchema.isHidden) {
      return;
    }
    if (fieldSchema.biteOnly && !app.sessionUser.isBite()) {
      return;
    }

    keys.push({
      _id: fieldName,
      name: fieldSchema.displayName || fieldName,
      Schema: {
        fields: {
          value: _.extend({}, fieldSchema, {
            displayName: ' ',
            defaultValue: defaultValueForFieldType(fieldSchema.type),
          }),
        },
      },
    });
  });

  return new app.ItemArrayItem(attributes, {
    itemArray,
    item,
    schema,
    keyList: app.AbstractCollection.createFromEnum({ schema: keys }),
  });
};
