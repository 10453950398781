import { MaitredActivityUpdateType } from '@biteinc/enums';

import { buildMaitredActivityListFilterSchema } from './maitred_activity_list_filter_schema';

app.MaitredActivityListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = {
      cellView: app.MaitredActivityCellView,
      searchEnabled: false,
      fetchOnInit: false,
      showTimestampsInLocationTimezone: !!app.site,
      filterSchemaButton: true,
      filterSchema: buildMaitredActivityListFilterSchema(!!app.org, !!app.site, !!app.location),
      ...(opts || {}),
    };
    app.PaginatedListView.prototype.initialize.apply(this, [options]);

    if (app.locationGroupList && !app.locationGroupList.hasBeenFetched()) {
      this.listenToOnce(app.locationGroupList, 'reset', this.render);
      app.locationGroupList.fetch({ reset: true });
    }
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'updateType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MaitredActivityUpdateType,
          nameGenerator: (updateType) => {
            switch (updateType) {
              case MaitredActivityUpdateType.Changeset:
                return 'Changeset';
              case MaitredActivityUpdateType.Clone:
                return 'Clone';
              case MaitredActivityUpdateType.EightySix:
                return '86ed or Un-86ed';
              case MaitredActivityUpdateType.MagicCopy:
                return 'Magic Copy or Precision Copy';
              default:
                throw new Error(`unrecognized update type: ${updateType}`);
            }
          },
        });
      case 'locationGroupId': {
        return app.LocationGroupList.newSelectableList(app.locationGroupList, {
          includeOrgWide: true,
          includeAllLive: true,
          includeSingleLocationGroups: false,
        });
      }
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'locationGroupId': {
        const locationGroup = element;
        return locationGroup.displayNameAsListFieldElement(plainTextOnly);
      }
    }
    return displayName;
  },

  _exportQuicksandRecords() {
    // Reliably start the download and allows us to set the file name.
    const aLink = document.createElement('a');
    aLink.download = `activity-log-${app.location.id}.csv`;
    const lines = [
      [
        'Time',
        'User',
        'Activity Type',
        'Model Type',
        'Model Name',
        'Model ID',
        'Description',
        'Original (Updates Only)',
      ],
    ];

    this.collection.models.forEach((model) => {
      lines.push(model.toCsv());
    });
    const data = lines.join('\n');
    const csvContent = 'data:text/csv;charset=utf-8,';

    aLink.href = encodeURI(csvContent + data);
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    const $downloadButton = $(
      '<button type="button" class="btn btn-sm btn-primary download">Download as CSV</button>',
    );
    this.$('.card-header .right-button-container').prepend($downloadButton);
    $downloadButton.click(() => {
      this._exportQuicksandRecords();
    });

    return this;
  },
});
