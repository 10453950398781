// Copied verbatim from /packages/db-models-interfaces/src/public_types/delivery_address.ts
type DeliveryAddress = {
  line1: string;
  line2?: string;
  city: string;
  state?: string;
  postalCode: string;
};

// Based off of `addressAsMultipleLines` from /packages/order-service/src/helpers/receipt_helper.ts
export function parseAddressToSingleLine(address: DeliveryAddress): string {
  const { line1, line2, city, postalCode, state } = address;
  const addressLines: string[] = [];
  addressLines.push(line1);
  if (line2) {
    addressLines.push(line2);
  }
  let line3 = `${city},`;
  if (state) {
    line3 += ` ${state}`;
  }
  line3 += ` ${postalCode}`;
  addressLines.push(line3);
  return addressLines.join(', ');
}
