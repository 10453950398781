// Adapted From: https://github.com/LorDOniX/json-viewer
import Backbone from 'backbone';
import _ from 'underscore';

import { StringHelper, Time } from '@biteinc/helpers';

import { TimeHelper } from '../helpers/time_helper';

const INDENT_HTML = '<span class="indent">  </span>';
const NEW_YEARS_2015 = 1420070400000;

app.JsonView = Backbone.View.extend({
  /**
   * Recursive walk for input value
   *
   * @param {JQuery<HTMLElement>} outputParent is the Element that will contain the new DOM
   * @param {Object | Array<any>} value Input value
   * @param {number} depth
   * @param {Object | undefined} numberEnumLookupMap
   */
  _walkJsonTree(outputParent, value, depth, numberEnumLookupMap) {
    if (_.isObject(value) && value !== null && !_.isDate(value)) {
      const isArray = Array.isArray(value);
      const items = isArray ? value : Object.keys(value);

      if (items.length) {
        const $list = $(`<ul class="type-${isArray ? 'array' : 'object'}"></ul>`);
        outputParent.append($list);

        items.forEach((key, index) => {
          const item = isArray ? key : value[key];
          const $listItem = $('<li></li>');
          $list.append($listItem);

          for (let i = 0; i < depth; ++i) {
            $listItem.append($(INDENT_HTML));
          }

          if (item === null || _.isObject(item)) {
            if (item === null || _.isDate(item)) {
              // null and dates
              $listItem.append($(`<span>${isArray ? '' : `${key}: `}</span>`));
              $listItem.append(this._createSimpleView(item, depth));
            } else {
              // arrays and objects
              const itemIsArray = Array.isArray(item);
              const isEmpty = !(itemIsArray ? item.length : Object.keys(item).length);

              if (isEmpty) {
                $listItem.append($(`<span>${key}: ${itemIsArray ? '[]' : '{}'}</span>`));
              } else {
                const itemTitle = (_.isString(key) ? `${key}: ` : '') + (itemIsArray ? '[' : '{');
                const $itemLink = this._createLink(itemTitle);
                const $collapsedContent = this._createCollapsedContent();

                $itemLink.append($collapsedContent);
                $listItem.append($itemLink);

                // recursive
                this._walkJsonTree(
                  $listItem,
                  item,
                  depth + 1,
                  _.isObject(item) || _.isArray(item)
                    ? numberEnumLookupMap
                    : numberEnumLookupMap && numberEnumLookupMap[key],
                );

                const tailIndents = [];

                for (let i = 0; i < depth; ++i) {
                  const $tailIndent = $(INDENT_HTML);
                  $listItem.append($tailIndent);
                  tailIndents.push($tailIndent);
                }

                $listItem.append($(`<span>${itemIsArray ? ']' : '}'}</span>`));

                const $childLists = $listItem.find('ul');
                const itemLinkCb = () => {
                  // hide/show
                  $itemLink.toggleClass('collapsed');
                  $collapsedContent.toggleClass('hide');
                  $childLists.toggleClass('hide');

                  tailIndents.forEach(($tailIndent) => {
                    $tailIndent.toggleClass('hide');
                  });
                };

                $itemLink.click(itemLinkCb);

                if (this._preCollapsedFields.has(key)) {
                  itemLinkCb();
                }
              }
            }
          } else {
            // simple values

            if (!isArray) {
              $listItem.append($(`<span>${key}: </span>`));
            }

            // recursive
            this._walkJsonTree(
              $listItem,
              item,
              depth + 1,
              numberEnumLookupMap && numberEnumLookupMap[key],
            );
          }

          if (index < items.length - 1) {
            // add comma to the end
            $listItem.append($('<span>,</span>'));
          }
        });
      }
    } else {
      // simple values
      outputParent.append(
        this._createSimpleView(value, depth, numberEnumLookupMap && numberEnumLookupMap[value]),
      );
    }
  },

  /**
   * Create simple value (not an object or array)
   *
   * @param {number | string | null | undefined | Date} value
   * @param {number} depth
   * @param {string} hintTextForNumberValue
   * @return {Element}
   */
  _createSimpleView(value, depth, hintTextForNumberValue = '') {
    let type;
    let asText;

    if (_.isString(value)) {
      asText = depth === 0 ? value : `"${StringHelper.escapeHtml(value)}"`;
    } else if (value === null) {
      type = 'null';
      asText = 'null';
    } else if (_.isDate(value)) {
      type = 'date';
      asText = TimeHelper.format(value, TimeHelper.FullDateFormat);
    } else {
      type = typeof value;
      asText = `${value}`;

      if (type === 'number' && value > NEW_YEARS_2015) {
        // The number is likely a timestamp, but not guaranteed
        // An alternate approach to guarantee the field is a timestamp is to have a switch
        // statement with the key of each timestamp field, but everyone would have to remember to
        // append this switch statement whenever they add a new timestamp field somewhere
        const timezone = app.location?.get('timezone') || 'utc';
        const timestampString = Time.moment(value, timezone).format('YYYY-MM-DD HH:mm:ss.SSS z');
        type = 'timestamp';
        asText += ` <span class="human-readable-string">(${timestampString})</span>`;
      } else if (hintTextForNumberValue) {
        asText += ` <span class="human-readable-string">(${hintTextForNumberValue})</span>`;
      }
    }

    return $(`<span class="type-${type}">${asText}</span>`);
  },

  _createCollapsedContent() {
    return $('<span class="items-ph hide">...</span>');
  },

  _createLink(title) {
    return $(`<span class="list-link" href="javascript:void(0)">${title || ''}</span>`);
  },

  initialize(options) {
    this._altText = options.altText;
    this._jsonValue = options.jsonValue;
    this._isHidden = options.isHidden;
    this._numberEnumLookupMap = options.numberEnumLookupMap || {};
    this._preCollapsedFields = new Set(options.preCollapsedFields || []);
  },

  render() {
    const $content = $(`<pre class="json-view-content" alt="${this._altText}"></pre>`);

    if (this._isHidden) {
      $content.hide();
    }

    this.$el.html($content);
    this._walkJsonTree($content, this._jsonValue, 0, this._numberEnumLookupMap);

    return this;
  },
});
