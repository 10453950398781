import Backbone from 'backbone';
import _ from 'underscore';

import { template } from '../../template';

app.TabView = Backbone.View.extend({
  className() {
    let className = 'tab-view container-fluid';
    if (this.isSingleListTab) {
      className += ' single-list-tab';
    }
    return className;
  },
  template: template(
    // prettier-ignore
    '<div class="tab-panel left-panel col-lg-6"></div>' +
    '<div class="tab-panel right-panel col-lg-6"></div>',
  ),
  suspended: true,
  _height: 0,
  _keyboardListeners: null,
  isSingleListTab: false,
  supportsElementRouting: false,
  supportsViewportMode: true,

  initialize(options) {
    this.options = _.extend(
      {
        canAccessParentModel: true,
      },
      options || {},
    );

    this.isReadOnly = this.options.isReadOnly;
    this.canAccessParentModel = this.options.canAccessParentModel;
    this._keyboardListeners = [];
  },

  addKeyboardListener(keyCode, callback) {
    const listenerId = `keyup.tabView${keyCode}`;
    this._keyboardListeners.push(listenerId);
    $(document).on(listenerId, (e) => {
      if (!this.suspended && e.ctrlKey && keyCode === e.keyCode) {
        callback();
        return false;
      }
      return true;
    });
  },

  setHeight(height) {
    this._height = height;
    this.$el.css('height', `${this._height}px`);
    this._applyHeight();
  },

  destroy() {
    _.each(this._keyboardListeners, (listenerId) => {
      $(document).unbind(listenerId);
    });
    this.stopListening();
    if (this.leftView && this.leftView.destroy) {
      this.leftView.destroy();
    }
    if (this.rightView && this.rightView.destroy) {
      this.rightView.destroy();
    }
  },

  _applyHeight() {
    if (this._height > 0 && this.$leftPanel && this.$rightPanel) {
      if ($('body').outerWidth() <= 1024) {
        let height = this.$el.height();
        if (this.leftView && this.rightView) {
          height = Math.max(height / 2, 340);
        }
        if (this.leftView && this.leftView.setHeight) {
          this.leftView.setHeight(height);
        }
        if (this.rightView && this.rightView.setHeight) {
          this.rightView.setHeight(height);
        }
      } else {
        if (this.leftView && this.leftView.setHeight) {
          this.$leftPanel.css('height', '100%');
          let leftHeight = this.$el.height();
          if (this.isSingleListTab) {
            leftHeight += 40;
          }
          this.leftView.setHeight(leftHeight);
        }
        if (this.rightView && this.rightView.setHeight) {
          this.$rightPanel.css('height', '100%');
          this.rightView.setHeight(this.$el.height());
        }
      }
    }
  },

  _anchoredModalCanClose(right) {
    const view = right ? this.rightView : this.leftView;
    if (!view || !view.canDismissModal) {
      return true;
    }
    return view.canDismissModal();
  },

  _anchoredModalNeedsToClose() {
    if (this._anchoredModalCanClose(true)) {
      this._closeRightView();
    }
  },

  _closeRightView() {
    if (this.rightView) {
      if (this.rightView.destroy) {
        this.rightView.destroy();
      }
      this.rightView.remove();
      this.stopListening(this.rightView);
      this.rightView = null;
      this.sidePaneMode = false;
    }
  },

  // Calling this with view=null hides the view.
  _setView(view, anchor, isLeft) {
    if (anchor) {
      // eslint-disable-next-line no-param-reassign
      view = new app.AnchoredModalView({
        view,
        isPermanent: true,
      });
    }
    if (isLeft) {
      this.leftView = view;
    } else {
      this.rightView = view;
    }
    this._applyHeight();
  },

  setLeftView(view, anchor) {
    this._setView(view, anchor, true);
  },

  setRightView(view, anchor, sidePaneMode) {
    if (view && !this._anchoredModalCanClose(true)) {
      return false;
    }
    this._closeRightView();
    this._setView(view, anchor, false);

    this.sidePaneMode = !!sidePaneMode;
    if (this.sidePaneMode) {
      const evt = app.AnchoredModalView.Events.AnchoredModalNeedsToClose;
      this.listenTo(this.rightView, evt, this._anchoredModalNeedsToClose);
      this.$rightPanel.html(this.rightView.render().el);
    }
    return true;
  },

  canSuspend() {
    if (!this._anchoredModalCanClose(false)) {
      return false;
    }
    if (!this._anchoredModalCanClose(true)) {
      return false;
    }
    return true;
  },

  _suspendLeftView() {
    if (this.leftView && this.leftView.suspend) {
      this.leftView.suspend();
    }
  },

  suspend() {
    if (this.sidePaneMode) {
      this._closeRightView();
    }
    this._suspendLeftView();
    this.suspended = true;
    this.$el.html('');
  },

  setBadge(badge) {
    this._badgeText = (badge || '').toString();
    const $link = this.$tabTongue.find('span');
    if (this._badgeText.length) {
      $link.toggleClass('has-badge', true);
      $link.attr('data-badge', this._badgeText);
    } else {
      $link.toggleClass('has-badge', false);
    }
  },

  setSelectedElementId(modelId) {
    if (this.leftView && this.leftView.setSelectedItemId) {
      this.leftView.setSelectedItemId(modelId);
    }
  },

  render() {
    this.suspended = false;
    this.$el.html(this.template());
    this.$leftPanel = this.$('.left-panel');
    this.$rightPanel = this.$('.right-panel');

    if (this.leftView) {
      this.$leftPanel.html(this.leftView.render().$el);
    }
    if (this.rightView) {
      this.$rightPanel.html(this.rightView.render().$el);
    }

    this._applyHeight();

    return this;
  },
});
