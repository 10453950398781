import Backbone from 'backbone';

import { template } from '../template';

app.AnchoredModalView = Backbone.View.extend({
  template: template($('#anchored-modal-view-template').html()),
  className: 'anchored-modal',
  _height: 0,
  isAnchored: true,

  initialize(options) {
    this.options = options || {};
    this.view = this.options.view;
    if (this.view.setSuperView) {
      this.view.setSuperView(this);
    }
  },

  canDismissModal() {
    return !this.view || !this.view.checkForUnsavedChanges || !this.view.checkForUnsavedChanges();
  },

  _needsToClose() {
    this.trigger(app.AnchoredModalView.Events.AnchoredModalNeedsToClose);
    return false;
  },

  destroy() {
    if (this.canDismissModal()) {
      this.clearState();
      return true;
    }
    return false;
  },

  setHeight(height) {
    this._height = height;
    this._applyHeight();
  },

  _applyHeight() {
    if (this._height && this.$body) {
      this.$body.css('maxHeight', `${this._height - 96}px`);
    }
  },

  render() {
    this.$el.html(this.template());

    this.$header = this.$('.card-header');
    this.$titleLabel = this.$header.find('.title');
    this.$body = this.$el.find('.card-body');

    this.footer = new app.ModalFooterView({
      className: 'card-footer',
      $headerTitle: this.$titleLabel,
    });
    this.footer.render().$el.insertAfter(this.$body);
    if (this.options.isPermanent) {
      this.$header.find('button.close').remove();
      this.footer.setDeleteButtonVisible(false);
      const onDiscard = () => {
        this.footer.setState(this.footer.states.DEFAULT);
        this.view.render();
        return false;
      };
      this.footer.$discardButton.click(onDiscard);
      this.footer.$hardDiscardButton.click(onDiscard);
    } else {
      this.$('.card-header button.close').click(this._needsToClose.bind(this));

      this.listenTo(this.view, app.ModalView.Events.ModalViewShouldHide, this._needsToClose);
      this.footer.$discardButton.click(this._needsToClose.bind(this));
      this.footer.$hardDiscardButton.click(this._needsToClose.bind(this));
    }

    this.$body.html(this.view.render().el);

    $('html,body').stop().animate(
      {
        scrollTop: 0,
      },
      300,
    );

    if (!this.hasRendered) {
      this.hasRendered = true;
      setTimeout(() => {
        if (this.view.viewWasAddedToDom) {
          this.view.viewWasAddedToDom();
        }
      }, 1);
    }

    this._applyHeight();

    return this;
  },

  clearState() {
    if (this.view) {
      this.footer.destroy();
      this.stopListening(this.view);
      if (this.view.viewWasRemovedFromDom) {
        this.view.viewWasRemovedFromDom();
      }
      this.view.clearState();
      this.view = null;
      this.options = null;
    }
  },
});

app.AnchoredModalView.Events = {
  AnchoredModalNeedsToClose: 'anchoredModalNeedsToClose',
};
