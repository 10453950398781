import _ from 'underscore';

app.HomeScreenImageView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          title: 'New Home Screen Image',
          isEnabled: true,
        };
      },
      getTitle: (fieldGroupView) => {
        const { title, desktopImage, mobileImage } = fieldGroupView.getValue();
        let suffix = '';
        if (desktopImage?.length && mobileImage?.length) {
          suffix = ' (desktop + mobile)';
        } else if (desktopImage?.length) {
          suffix = ' (desktop only)';
        } else if (mobileImage?.length) {
          suffix = ' (mobile only)';
        }
        return `${(title || '').trim()}${suffix}`;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new home screen image',
        emptyText: 'no home screen yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
