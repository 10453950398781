import { BiteUrl } from '@biteinc/common';
import { MenuFilterCategory, ModelType } from '@biteinc/enums';
import { badgeSchema } from '@biteinc/schemas';

app.Badge = app.AbstractModel.extend({
  ModelName: 'badge',
  Schema: badgeSchema,
  Type: ModelType.Badge,

  defaults() {
    return {
      menuId: app.menu.id,
    };
  },

  isMissingImages() {
    return !this.hasArr('icons');
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'filterCategory':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuFilterCategory,
          nameGenerator: (filterCategory) => {
            switch (filterCategory) {
              case MenuFilterCategory.Restrictive:
                return 'Restrictive';
              case MenuFilterCategory.Dietary:
                return 'Dietary';
              case MenuFilterCategory.Other:
                return 'Other';
              default:
                throw new Error(`unrecognized filterCategory value: ${filterCategory}`);
            }
          },
        });
    }
    return null;
  },

  displayNameHtml() {
    let title = '';
    if (this.isMissingImages()) {
      title +=
        '<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" ' +
        'srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>';
    } else {
      const url = BiteUrl.biteAssetUrlWithNewHost(this.get('icons')[0].url);
      title += `<img class="badge-img" src="${url}"/>`;
    }
    title += `<span>${this.displayName()}</span>`;

    return title;
  },
});
