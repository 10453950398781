import qs from 'qs';

app.QuicksandRecordList = app.AbstractCollection.extend({
  model: app.QuicksandRecord,

  comparator(model) {
    return model.get('createdAt') * -1;
  },

  isValidQuery(query) {
    return query.length >= 1 || Object.keys(this.filterFieldGroupView.getValue()).length;
  },

  url() {
    let urlWip = '/api/v2/quicksand-records';

    const filterFieldGroupViewValue = this.filterFieldGroupView.getValue();
    const hasFilterParams = Object.keys(filterFieldGroupViewValue).length;

    if (this.query || hasFilterParams) {
      const qsObj = {
        query: this.query,
        ...filterFieldGroupViewValue,
      };
      urlWip += `?${qs.stringify(qsObj)}`;
    }

    return urlWip;
  },
});
