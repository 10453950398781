import _ from 'underscore';

import { OloCustomKeyOrderFieldHelper } from '@biteinc/enums';

app.OloCustomFieldView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} virtual-sub-group-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          customFieldKey: '',
          orderField: '',
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        const fields = fieldGroupView.getValue();
        if (fields?.orderField && fields?.customFieldKey) {
          return `${OloCustomKeyOrderFieldHelper.name(fields.orderField)}: ${fields.customFieldKey}`;
        }
        return 'Incomplete key mapping';
      },
      templateData: {
        title: 'Olo Custom Field',
        buttonText: 'New custom field',
        emptyText: 'No custom fields yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
