import _ from 'underscore';

import { ModelType } from '@biteinc/enums';

app.LocationSelectorListView = app.ModelSelectorListView.extend({
  initialize(options) {
    app.ModelSelectorListView.prototype.initialize.apply(this, arguments);

    this._liveFirst = options.liveFirst;
  },

  getListSections() {
    const locationGroupCollection =
      this.collection.getCollections().length > 1 && this.collection.getCollections()[0];

    const locationGroupSection = locationGroupCollection && {
      header: 'Location Groups',
      models: locationGroupCollection.getModels(),
    };

    const locations = this.collection.getMainCollection().getModels();
    const [liveLocations, nonLiveLocations] = _.partition(locations, (location) => {
      return location.isLive();
    });
    const liveSection = liveLocations.length && {
      header: 'Live',
      models: liveLocations,
      allowAllToBeSelected: true,
    };
    const nonLiveSection = nonLiveLocations.length && {
      header: 'Non-Live',
      models: nonLiveLocations,
      allowAllToBeSelected: true,
    };
    const sections = [locationGroupSection];
    if (this._liveFirst) {
      sections.push(liveSection, nonLiveSection);
    } else {
      sections.push(nonLiveSection, liveSection);
    }
    return _.compact(sections);
  },

  __cellWasClicked(cellView, _eventOpt) {
    if (cellView.model.Type === ModelType.Location) {
      app.ModelSelectorListView.prototype.__cellWasClicked.apply(this, arguments);
      return;
    }

    const locationGroup = cellView.model;
    this.selectModelIds(locationGroup.get('locationIds'));
  },
});
