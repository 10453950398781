import _ from 'underscore';

app.MenuItemListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'add', this._updateTitle);
    this.listenTo(this.collection, 'remove', this._updateTitle);
    this.listenTo(this.collection, 'change', this._updateTitle);
    this.listenTo(this.collection, 'reset', this._updateTitle);
  },

  _filteredModels() {
    return _.filter(this.collection.models, (menuItem) => {
      return !menuItem.isCompoundItem();
    });
  },

  getTitle() {
    let title = app.BaseListView.prototype.getTitle.apply(this, arguments);
    const filteredModels = this._filteredModels();
    if (_.size(filteredModels)) {
      title += ` (${_.size(filteredModels)}`;
      const imagelessCount = _.reduce(
        filteredModels,
        (count, model) => {
          return count + (model.isMissingImages() ? 1 : 0);
        },
        0,
      );
      if (imagelessCount) {
        title += ` | ${imagelessCount}`;
        title +=
          ' <img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png"' +
          ' srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos">';
      }
      title += ')';
    }
    return title;
  },

  _updateTitle() {
    this.setTitle(this.getTitle());
  },

  getListSections() {
    return [{ models: this._filteredModels() }];
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (app.sessionUser.isBite()) {
      const filterKeywords = [':hidden', ':no-images', ':orphan'];
      if (this.collection.model === app.Mod) {
        filterKeywords.push(':is-customized');
      } else {
        filterKeywords.push(':alcohol');
      }
      const keywordHtml = _.map(filterKeywords, (keyword) => {
        return `<span>${keyword}</span>`;
      }).join(', ');
      this.$searchHint.html(`Hint: you can use the following keywords<br />${keywordHtml}.`);
      this.$searchHint.find('span').click(function onClick() {
        this.$searchField.val($(this).text());
        this.$searchField.trigger('search');
      });
      this.$searchField.on('focus', () => {
        this.$searchHint.slideDown(400, () => {
          this._applyHeight();
        });
      });
    }
    if (!app.menuSectionList.hasBeenFetched()) {
      this.listenToOnce(app.menuSectionList, 'reset', () => {
        this.render();
      });
    }
    if (!app.badgeList.hasBeenFetched()) {
      this.listenToOnce(app.badgeList, 'reset', () => {
        this.render();
      });
    }
    return this;
  },
});
