app.CompoundMenuItemTabView = app.MenuItemTabView.extend({
  initialize(options) {
    // call grandparent initializer
    app.TabView.prototype.initialize.apply(this, arguments);

    this.menuItemListView = new app.CompoundMenuItemListView({
      collection: this.collection,
      detailsView: app.CompoundMenuItemDetailsView,
      canCreate: options.canCreate,
      cellView: app.MenuItemCellView,
      tabView: this,
      isReadOnly: this.isReadOnly,
    });
    this.setLeftView(this.menuItemListView);

    this._bindHotkeys();
  },
});
