import _ from 'underscore';

import { ModGroupI9nItemRefSortFields, ModGroupI9nItemRefSortFieldsHelper } from '@biteinc/enums';

app.SortPrioritiesView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        const field = fieldGroupView.getValue();
        return ModGroupI9nItemRefSortFieldsHelper.name(field.sortField);
      },
      templateData: {
        title: 'Sort Priorities',
        buttonText: 'New Sort Priority',
        emptyText: 'No sort priorities yet',
      },
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: ModGroupI9nItemRefSortFields,
            nameGenerator: ModGroupI9nItemRefSortFieldsHelper.name,
          });
        },
        canShowModel: (sortField, elements) => {
          // Filter out the sort priorities that are already entered (but maybe not saved)
          return !_.findWhere(elements, { sortField: sortField.sortField });
        },
        getNewElementFromModel: (sortField) => {
          return {
            sortField: sortField.id,
          };
        },
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
