import _ from 'underscore';

app.LocationGroupList = app.AbstractCollection.extend({
  model: app.LocationGroup,

  url: () => {
    return '/api/v2/location-groups';
  },
});

app.LocationGroupList.newSelectableList = function newSelectableList(
  list,
  { includeAllLive = false, includeOrgWide = false, includeSingleLocationGroups = false } = {},
) {
  const models = _.filter(list?.models, (locationGroup) => {
    if (!locationGroup.get('sessionUserCanManage')) {
      return false;
    }

    if (locationGroup.isSingleLocationGroup()) {
      if (!includeSingleLocationGroups) {
        return false;
      }

      const location = app.locationList.get(locationGroup.get('locationIds')[0]);
      return !location.isBiteOnly();
    }
    if (locationGroup.isAllLive()) {
      return includeAllLive;
    }
    if (locationGroup.isOrgWide()) {
      return includeOrgWide;
    }
    return true;
  });
  return new app.LocationGroupList(models, {
    comparator(locationGroup) {
      if (locationGroup.isOrgWide()) {
        return '0';
      }
      if (locationGroup.isAllLive()) {
        return '1';
      }
      if (locationGroup.isSingleLocationGroup()) {
        return `2${app.AbstractCollection.prototype.comparator.apply(this, arguments)}`;
      }
      return `3${app.AbstractCollection.prototype.comparator.apply(this, arguments)}`;
    },
  });
};
