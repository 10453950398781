import { ApiTokenCreationOption, apiTokenCreationOptionName } from './api_token_creation_option';

app.ApiTokenListView = app.BaseListView.extend({
  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (this.options.canCreate) {
      const collection = app.AbstractCollection.createFromTsEnum({
        tsEnum: ApiTokenCreationOption,
        nameGenerator: apiTokenCreationOptionName,
      });
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(`<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new api token`);
      dropdownView.setup(collection, {
        onAdd: (creationOptionModel) => {
          /** @type ApiTokenCreationOption */
          const apiTokenCreationOption = creationOptionModel.id;
          switch (apiTokenCreationOption) {
            case ApiTokenCreationOption.AddToExistingToken:
              const detailsView = new app.AddApiTokenToOrgDetailsView({
                collection: this.collection,
                canAccessParentModel: this.canAccessParentModel,
              });
              this.showDetailsView(detailsView);
              break;
            case ApiTokenCreationOption.CreateNewToken:
              this.createNew();
              break;
            default:
              throw new Error(`Unhandled api token creation option: ${creationOptionModel.id}`);
          }
        },
      });
    }

    return this;
  },
});
