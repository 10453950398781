import { getArchivedBadgeHtml } from '../../helpers/html_builder';

app.MenuItemArrayCellView = app.CellView.extend({
  getTitle() {
    let title = app.CellView.prototype.getTitle.apply(this, arguments);

    const archivedBadgeHtml = getArchivedBadgeHtml(
      this.model.get('archivedAt'),
      this.model.isModGroup(),
    );
    if (archivedBadgeHtml) {
      title += ` ${archivedBadgeHtml}`;
    }

    if (this.model.get('variationSource')) {
      title += ' <span class="cell-badge variation">variation</span>';
    } else if (this.canAccessParentModel && this.model.isNotOnTheMenu()) {
      title += ' <span class="cell-badge not-in-menu">not in menu</span>';
    }

    return title;
  },
});

app.MenuItemArrayListView = app.BaseListView.extend({
  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'add', this._updateTitle);
    this.listenTo(this.collection, 'remove', this._updateTitle);
    this.listenTo(this.collection, 'change', this._updateTitle);
    this.listenTo(this.collection, 'reset', this._updateTitle);

    // Wait for the vendor list to be loaded
    if (app.MenuSection === this.collection.model && app.location.supportsMultipleVendors()) {
      this.listenTo(app.vendorList, 'reset', this.render);
      this.listenTo(app.vendorList, 'change', this.render);
    }
  },

  getTitle() {
    let title = app.BaseListView.prototype.getTitle.apply(this, arguments);
    if (this.collection.size()) {
      title += ` (${this.collection.size()}`;
      const imagelessCount = this.collection.reduce((count, model) => {
        return count + (model.isMissingImages() ? 1 : 0);
      }, 0);
      if (imagelessCount) {
        title += ` | ${imagelessCount}`;
        title +=
          ' <img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png"' +
          ' srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos">';
      }
      title += ')';
    }
    return title;
  },

  _updateTitle() {
    this.setTitle(this.getTitle());
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);
    const self = this;
    if (
      app.MenuSection === this.collection.model &&
      this.canAccessParentModel &&
      !app.menuStructureList.hasBeenFetched()
    ) {
      this.listenToOnce(app.menuStructureList, 'reset', () => {
        self.render();
      });
    }
    return this;
  },
});

app.MenuItemArrayTabView = app.TabView.extend({
  isSingleListTab: true,
  supportsElementRouting: true,

  initialize(options) {
    app.TabView.prototype.initialize.apply(this, arguments);

    this.menuItemArrayListView = new app.MenuItemArrayListView({
      collection: this.collection,
      detailsView: app.MenuItemArrayDetailsView,
      tabView: this,
      useVList: true,
      cellView: app.MenuItemArrayCellView,
      canCreate: options.canCreate && !this.isReadOnly,
      isReadOnly: this.isReadOnly,
      canAccessParentModel: this.canAccessParentModel,
      updateUrlWithSelectedModel: (_menuSectionId) => {
        return true;
      },
    });
    this.setLeftView(this.menuItemArrayListView);

    this.listenTo(this.collection, 'add', this._menuItemArrayWasAdded);
    this.listenTo(app.integrationList, 'change', this._integrationListDidChange);
  },

  _menuItemArrayWasAdded(model) {
    if (!this.rightView) {
      const detailsView = new app.MenuItemArrayDetailsView({
        model,
        collection: this.collection,
        isReadOnly: this.isReadOnly,
        canAccessParentModel: this.canAccessParentModel,
      });
      this.menuItemArrayListView.showDetailsView(detailsView);
    }
  },

  _integrationListDidChange() {
    const Type = this.menuItemArrayListView.collection.model.prototype.Type;
    this.menuItemArrayListView.setCanCreate(!app.location.syncsModelsWithType(Type));
  },

  setSelectedElementId(itemId) {
    this.menuItemArrayListView.setSelectedItemId(itemId);
  },
});
