import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
import { ModelType, ModelTypeHelper, QuicksandRecordType } from '@biteinc/enums';

import { ModelHelper } from '../helpers/model_helper';
import { TimeHelper } from '../helpers/time_helper';
import {
  cssClassFromRecordType,
  stringFromRecordType,
  stringFromUpdateType,
} from './quicksand_record_helper';

app.QuicksandRecord = app.AbstractModel.extend({
  ModelName: 'record',
  Schema: {
    displayName: 'sync record',
  },
  Type: ModelType.QuicksandRecord,

  displayNameHtml() {
    const className = cssClassFromRecordType(this.get('type'));
    let recordType = stringFromRecordType(this.get('type'));
    if (this.get('type') === QuicksandRecordType.Update) {
      const recordSubtypeParts = [];
      if (this.get('updateType')) {
        recordSubtypeParts.push(stringFromUpdateType(this.get('updateType')));
      }
      if (this.get('isWebhook')) {
        recordSubtypeParts.push('webhook');
      }
      if (recordSubtypeParts.length) {
        recordType += ` (${recordSubtypeParts.join(' - ')})`;
      }
    }
    const name = $('<div></div>');
    name.append($(`<span class="cell-badge ${className}">${recordType}</span>`));
    name.append($('<span> </span>'));
    name.append($(`<span>${ModelTypeHelper.name(this.get('modelType'))}</span>`));
    name.append($('<br />'));
    if (this.has('prevName')) {
      name.append($(`<span>${this.get('prevName')} &#x21E8 </span>`));
    }

    if (QuicksandRecordType.SyncSummary === this.get('type')) {
      const duration = TimeHelper.durationFromTimestamp(this.get('syncDuration'), true);
      const totalRequestCount =
        this.get('totalRequestCount') ||
        (this.get('getRequestCount') || 0) + (this.get('postRequestCount') || 0);
      const avgReqTime = Math.floor(this.get('totalRequestDuration') / totalRequestCount);
      const lines = [];
      lines.push(`Integration:     ${I9nSchemaBySystem[this.get('system')].name}`);
      if (this.get('i9nLastUpdatedAt')) {
        lines.push(
          `Last Updated At: ${TimeHelper.displayDateFromTimestamp(this.get('i9nLastUpdatedAt'), app.location.get('timezone'))}`,
        );
      }

      const username = this.get('username');
      const phiUsername = username === 'lambdaTimer' ? 'Automatic Process' : username;
      lines.push(`Synced By:       ${phiUsername}`);

      lines.push(
        ...[
          `Forced Sync:     ${this.get('isForcedSync') ? 'Yes' : 'No'}`,
          `Sync Duration:   ${duration}`,
          `Avg Req Time:    ${avgReqTime}ms`,
          `Req Duration:    ${this.get('totalRequestDuration')}ms`,
        ],
      );
      if (this.get('imageDownloadCount') > 0) {
        const avgImgTime = Math.floor(
          this.get('totalImageDownloadDuration') / this.get('imageDownloadCount'),
        );
        lines.push(`Avg Img Time:    ${avgImgTime}ms`);
        lines.push(`Image Count:     ${this.get('imageDownloadCount')}`);
      }
      if (this.get('errorCount') > 0) {
        lines.push(`Errors:          ${this.get('errorCount')}`);
      }

      name.append($(`<pre>${lines.join('\n')}</pre>`));
    } else {
      const modelType = this.get('modelType');
      const modelId = this.get('modelId');
      let modelNameSpan = `<span class="model-name" data-bs-toggle="tooltip" data-bs-title="${modelId}"`;
      if (app.QuicksandRecord._isClickableModelType(modelType)) {
        modelNameSpan += ` model-id="${modelId}" model-type="${modelType}"`;
      }
      modelNameSpan += `>${this.get('modelName')}</span>`;
      name.append($(modelNameSpan));
    }

    if (QuicksandRecordType.Update === this.get('type')) {
      // Build a placeholder so that we can insert the json-view for the diff when the user clicks
      // the "View Diff" button
      name.append($('<div class="json-view-container"></div>'));
    }

    if (this.hasStr('err')) {
      name.append($('<br />'));
      name.append($(`<span>${this.get('err')}</span>`));
    }

    return name;
  },

  getModel() {
    const { model } = ModelHelper.getCollectionAndModelWithIdAndType(
      this.get('modelId'),
      this.get('modelType'),
    );
    return model || null;
  },

  isSimilar(other) {
    return this.get('sessionId') === other.get('sessionId');
  },

  toCsv() {
    let recordType = stringFromRecordType(this.get('type'));
    if (this.get('type') === QuicksandRecordType.Update) {
      if (this.get('updateType')) {
        recordType += ` (${stringFromUpdateType(this.get('updateType'))})`;
      }
    }
    let syncType = 'Automatic';
    if (this.get('isForcedSync')) {
      syncType = 'Manual';
    } else if (this.get('isWebhook')) {
      syncType = 'Webhook';
    }

    let formattedDiff = null;
    if (this.get('diff')) {
      formattedDiff = {};
      _.each(this.get('diff'), (value, key) => {
        formattedDiff[key.replace(/^\\\$/, '$')] = value;
      });
    }

    return [
      recordType,
      ModelTypeHelper.name(this.get('modelType')),
      this.get('modelName'),
      this.get('modelId'),
      syncType,
      TimeHelper.displayDateFromTimestamp(this.get('createdAt'), app.location.get('timezone')),
      formattedDiff ? `"${JSON.stringify(formattedDiff).replaceAll('"', '""')}"` : '',
    ];
  },
});

app.QuicksandRecord._isClickableModelType = function isClickableModelType(type) {
  switch (type) {
    case ModelType.Mod:
    case ModelType.MenuItem:
    case ModelType.MenuSection:
    case ModelType.ModGroup:
    case ModelType.TaxProfile:
      return true;
    default:
      return false;
  }
};
