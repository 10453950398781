import _ from 'underscore';

import { Weekday, WeekdayHelper } from '@biteinc/enums';

app.DayScheduleAccordionView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: Weekday,
            nameGenerator: WeekdayHelper.name,
            sort: app.AbstractCollection.SortOptions.WORK_WEEKDAYS,
          });
        },
        getNewElementFromModel: (weekdayModel) => {
          const weekday = weekdayModel.id;
          return { weekday };
        },
        canShowModel: (weekdayModel, elements) => {
          // Filter out weekdays that are already entered (but maybe not saved)
          return !_.findWhere(elements, { weekday: weekdayModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const { weekday } = fieldGroupView.getValue();
        return `${WeekdayHelper.name(weekday)}s`;
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new day schedule',
        emptyText: 'no day schedules yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
