import Sortable from 'sortablejs';
import _ from 'underscore';

import { template } from '../../template';

app.AccordionFieldGroupView = app.FieldGroupView.extend({
  initialize(options) {
    app.FieldGroupView.prototype.initialize.apply(this, arguments);

    this.listenTo(
      this,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      this._updateCellWithTitle.bind(this),
    );

    const self = this;
    this.cell = new app.ComplexCellView({
      confirmation: 'Are you sure you want to delete this item?',
      canDelete() {
        return options.canDelete(self);
      },
      canRename() {
        return false;
      },
      canReorder() {
        return options.canReorder(self);
      },
      onDelete() {
        options.onDelete(self);
      },
      onClick() {
        options.onClick(self);
      },
      getName() {
        return self._cellTitle || '';
      },
    });
  },

  _updateCellWithTitle() {
    const title = this.options.getTitle(this);
    this.cell.setName(title);
    this._cellTitle = title;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const response = app.FieldGroupView.prototype.checkValidity.apply(this);
    this._updateCellWithTitle();
    return response;
  },

  setValue() {
    app.FieldGroupView.prototype.setValue.apply(this, arguments);

    if (this.options.setSetValueHandlers) {
      this.options.setSetValueHandlers(this, this._updateCellWithTitle.bind(this));
    }

    this._updateCellWithTitle();
  },
});

app.AccordionFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} accordion-panel`;
  },
  template: template($('#accordion-view-template').html()),

  initialize(options) {
    app.FieldView.prototype.initialize.apply(this, arguments);
    this._options = options;
  },

  destroy() {
    app.FieldView.prototype.destroy.apply(this, arguments);

    if (this._sortable) {
      this._sortable.destroy();
      this._sortable = null;
    }

    _.each(this._fieldGroupViews, (fieldGroupView) => {
      fieldGroupView.destroy();
    });
  },

  _addNewElement(element) {
    const newFieldGroupView = this._addElement(element);
    // Activate tooltips on new accordion elements since we normally activate those on render of the
    // parent element (e.g. details view).
    app.activateTooltips(newFieldGroupView.$el);

    this._selectFieldGroup(_.last(this._fieldGroupViews));
    if (this._fieldGroupViews.length === 2) {
      this.trigger(app.AccordionFieldView.Events.AddedNewFieldGroup, this);
    }
    this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
  },

  _addButtonWasClicked() {
    this._addNewElement(this._options.newValue());
  },

  _selectFieldGroup(fieldGroupView, unAnimated) {
    const duration = unAnimated ? 0 : 400;
    if (this._selectedFieldGroupView === fieldGroupView) {
      fieldGroupView.$el.stop().slideUp(duration, 'easeInOutExpo');
      this._selectedFieldGroupView = null;
    } else {
      // Save this here because it becomes 0/1 after slideDown is called
      const viewHeight = fieldGroupView.$el.outerHeight();
      this.$fgList.find('.complex-cell>.field-group').stop().slideUp(duration, 'easeInOutExpo');
      fieldGroupView.$el.stop().slideDown(duration, 'easeInOutExpo');
      this._selectedFieldGroupView = fieldGroupView;

      const $parent = this.$el.parents('.content-body');
      if ($parent.length) {
        const $cell = fieldGroupView.cell.$el;
        let height = viewHeight - $cell.outerHeight();
        height -= $cell.parent().outerHeight() - $cell.position().top;
        const scrollTop = $parent.scrollTop();
        $parent.stop().animate(
          {
            scrollTop: scrollTop + Math.min($parent.outerHeight() - 100, height),
          },
          500,
          'easeInOutExpo',
        );
      }
    }
  },

  _updatePanes() {
    this.$('.empty-pane').toggle(this._fieldGroupViews.length === 0);
  },

  _removeFieldGroup(fieldGroupView) {
    if (this._fieldGroupViews.length <= (this.schema.minCount || 0)) {
      return;
    }

    fieldGroupView.cell.$el.remove();
    const index = this._fieldGroupViews.indexOf(fieldGroupView);
    if (index >= 0) {
      this._fieldGroupViews.splice(index, 1);
    }
    this._updateCellRemoveButtons();
    if (this._selectedFieldGroupView === fieldGroupView) {
      this._selectedFieldGroupView = null;
    }
    this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    this._updatePanes();
  },

  _addElement(element) {
    const FieldGroupViewClass = this._options.FGVClass || app.AccordionFieldGroupView;
    const fieldGroupView = new FieldGroupViewClass({
      schema: {
        type: this.schema.elementType,
        subtype: this.schema.elementSubtype,
        fields: this._options.getElementSchemaFields
          ? this._options.getElementSchemaFields(element, this.model)
          : this.schema.fields,
      },
      subProperty: this.subProperty ? `${this.subProperty}.${this.field}` : this.field,
      keyModel: this._options.useElementAsKeyModel ? element : this.keyModel,
      canDelete: () => {
        return (
          !this.model.fieldIsPermanent(this.field, this.subProperty, element) &&
          !this.isReadOnly &&
          (!this.schema.writeRight || app.sessionUser.hasRight(this.schema.writeRight))
        );
      },
      canReorder: () => {
        return (
          !this.model.fieldIsPermanent(this.field, this.subProperty, element) &&
          !this.isReadOnly &&
          (!this.schema.writeRight || app.sessionUser.hasRight(this.schema.writeRight))
        );
      },
      onDelete: this._removeFieldGroup.bind(this),
      onClick: this._selectFieldGroup.bind(this),
      setSetValueHandlers: this._options.setSetValueHandlers,
      getTitle: this._options.getTitle,
      isReadOnly: this.isReadOnly,
    });
    this.$fgList.append(fieldGroupView.cell.render().$el);
    fieldGroupView.cell.$el.append(fieldGroupView.render().$el);
    fieldGroupView.setValue(element, this.model);
    fieldGroupView.$el.hide();

    this._fieldGroupViews.push(fieldGroupView);

    this._updateCellRemoveButtons();

    if (this._viewHasBeenAddedToDom) {
      fieldGroupView.viewWasAddedToDom();
    }

    this.listenTo(
      fieldGroupView,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      this._fieldGroupViewDidChangeValue,
    );
    this._updatePanes();

    return fieldGroupView;
  },

  _updateCellRemoveButtons() {
    const minCount = this.schema.minCount || 0;
    const showRemoveButton = this._fieldGroupViews.length > minCount;
    _.each(this._fieldGroupViews, (fieldGroupView) => {
      fieldGroupView.cell.setRemoveButtonVisible(showRemoveButton);
    });
  },

  viewWasAddedToDom() {
    this._viewHasBeenAddedToDom = true;

    _.each(this._fieldGroupViews, (fieldGroupView) => {
      fieldGroupView.viewWasAddedToDom();
    });
  },

  _fieldGroupViewDidChangeValue() {
    this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
  },

  setValue(elements, model) {
    this.model = model;
    this.initialValue = elements;

    _.each(this._fieldGroupViews, (fieldGroupView) => {
      fieldGroupView.destroy();
    });
    this._fieldGroupViews = [];
    this._selectedFieldGroupView = null;
    this.$fgList.html('');

    _.each(elements, (element) => {
      this._addElement(element);
    });
  },

  getValue() {
    const list = _.map(this._fieldGroupViews, (fieldGroupView) => {
      return fieldGroupView.getValue();
    });
    if (!list.length && !this.initialValue) {
      return this.initialValue;
    }
    return list;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    return this._fieldGroupViews.reduce(
      (response, fieldGroupView) => {
        const fieldName = `${fieldGroupView.options.getTitle(fieldGroupView)}`;

        const { isValid, invalidFieldNames } = fieldGroupView.checkValidity();
        if (isValid) {
          return response;
        }
        return {
          isValid: false,
          invalidFieldNames: [
            // previously encountered invalid field names
            ...(response.isValid ? [] : response.invalidFieldNames),
            // Either we have nested fields that are invalid or this current field is invalid by itself.
            ...(invalidFieldNames.length
              ? invalidFieldNames.map((nestedFieldName) => `${fieldName} > ${nestedFieldName}`)
              : [fieldName]),
          ],
        };
      },
      { isValid: true },
    );
  },

  render() {
    _.each(this._fieldGroupViews || [], (fieldGroupView) => {
      fieldGroupView.destroy();
    });
    this._fieldGroupViews = [];
    this._selectedFieldGroupView = null;
    this.$el.append(this.template(this._options.templateData));

    if (this.isReadOnly) {
      this.$('button.add-new').hide();
    } else if (this.isPermanent()) {
      this.$('button.add-new').prop('disabled', true);
    } else if (this._options.newElementDropdown) {
      const collection = this._options.newElementDropdown.getCollection();
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(
        `<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;${this._options.templateData.buttonText}`,
      );
      dropdownView.setup(collection, {
        validate: (model) => {
          return this._options.newElementDropdown.canShowModel(model, this.getValue());
        },
        onAdd: (model) => {
          this._addNewElement(this._options.newElementDropdown.getNewElementFromModel(model));
        },
      });
    } else {
      this.$('button.add-new').click(this._addButtonWasClicked.bind(this));
    }

    this.$fgList = this.$('.accordion>.card>.list-group');

    if (this._sortable) {
      this._sortable.destroy();
    }

    if (!this.isPermanent()) {
      this._sortable = Sortable.create(this.$fgList[0], {
        draggable: '.sortable',
        delay: app.sortableDelay(),
        handle: '.handle',
        ghostClass: 'ghost',
        onStart: () => {
          if (this._selectedFieldGroupView) {
            this._selectFieldGroup(this._selectedFieldGroupView);
          }
        },
        onEnd: (e) => {
          const oldIndex = e.oldIndex >= 0 ? e.oldIndex : -1;
          const newIndex = e.newIndex >= 0 ? e.newIndex : -1;
          if (oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
            const element = this._fieldGroupViews[oldIndex];
            this._fieldGroupViews.splice(oldIndex, 1);
            this._fieldGroupViews.splice(newIndex, 0, element);
            this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
          }
        },
      });
    }

    this._updatePanes();

    return this;
  },
});

app.AccordionFieldView.Events = {
  AddedNewFieldGroup: 'AddedNewFieldGroup',
};
