import { DayPartHelper } from '@biteinc/business-rules';
import { ModelType } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';
import { menuTimingSchema } from '@biteinc/schemas';

app.MenuTiming = app.AbstractModel.extend({
  ModelName: 'timing',
  Schema: menuTimingSchema,
  Type: ModelType.MenuTiming,

  defaults() {
    return {
      menuId: app.menu.id,
    };
  },

  save(data, opts) {
    if (data.dateRange) {
      const timezone = app.location.get('timezone');

      data.dateRange = {
        from: Time.moment(data.dateRange.from, timezone).valueOf(),
        to: Time.moment(data.dateRange.to, timezone).valueOf(),
      };
    }

    app.AbstractModel.prototype.save.apply(this, [data, opts]);
  },

  displayName() {
    const name = app.AbstractModel.prototype.displayName.apply(this, arguments);
    const summary = this.getSummary();
    return `${name}${summary.length ? ` (${summary})` : ''}`;
  },

  getSummary() {
    const timeRangesSummary = DayPartHelper.dayPartSummary(this.attributes).join(', ');
    const dateRange = this.attributes.dateRange;

    if (!dateRange) {
      return timeRangesSummary;
    }

    const timezone = app.location.get('timezone');
    const displayFormat = 'YYYY/MM/DD';

    const fromDateString = Time.moment(dateRange.from, timezone).format(displayFormat);
    // `to` is saved in the DB as the first millisecond of the day after the range
    // Subtract a day so that it is displayed as the last day of the range instead
    const toDateString = Time.moment(dateRange.to, timezone)
      .subtract(1, 'day')
      .format(displayFormat);

    return `${fromDateString} - ${toDateString}, ${timeRangesSummary}`;
  },
});
