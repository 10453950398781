import { template } from '../../template';

let fieldsetCounter = 0;

app.RadioListFieldView = app.FieldView.extend({
  radioListTemplate: template('<div class="radio-list"></div>'),

  setValue(value, model) {
    this.model = model;
    this.value = value;
    this._renderList();
    this._updateList();
  },

  getValue() {
    if (this.value === 0) {
      return 0;
    }
    return this.value || null;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const value = this.getValue();
    const isValid = !this.isRequired() || value !== null;
    this.setState(isValid ? this.states.INIT : this.states.ERROR);
    return isValid ? { isValid } : { isValid, invalidFieldNames: [] };
  },

  _updateList() {
    if (this.value === undefined) {
      return;
    }
    const targetValue = this.value.toString();
    const $radioButtons = this.$('input:radio');
    $radioButtons.each(function iterator() {
      if (this.value.toString() === targetValue.toString()) {
        const $radioToSelect = $(this);
        $radioToSelect.prop('checked', true);
      }
    });
  },

  _renderList() {
    const self = this;
    const collection = this.model.getFieldCollection(
      this.field,
      this.subProperty,
      false,
      this.keyModel,
    );
    this.$radioList.html('');
    fieldsetCounter++;
    collection.each((object, index) => {
      const $radioItem = $(
        `<label class="radio-item">` +
          `<input type="radio" value="${
            object.id
          }" name="group${fieldsetCounter}">${object.displayName()}</label>`,
      );
      if (self.isPermanent()) {
        const $radio = $radioItem.find('input:radio');
        $radio.prop('disabled', true);
        $radioItem.addClass('disabled');
      } else {
        $radioItem.click(() => {
          const value = object.id;
          if (value !== self.value) {
            self.value = value;
            self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
          }
        });
      }
      self.$radioList.append($radioItem);
      if (index >= 0 && index < collection.size() - 1) {
        self.$radioList.append('&nbsp;');
      }
    });
  },

  renderField() {
    this.$inputContainer.append(this.radioListTemplate());
    this.$radioList = this.$('.radio-list');
  },
});
