import { ModelType } from '@biteinc/enums';
import { MathHelper } from '@biteinc/helpers';

import { BrowserHelper } from '../../helpers/browser_helper';
import { TimeHelper } from '../../helpers/time_helper';
import { template } from '../../template';

app.OrderCellView = app.CellView.extend({
  buttonTemplate: template(
    // prettier-ignore
    '<div style="margin-top: 10px;">' +
      '<button type="button" class="btn btn-secondary btn-sm" style="width: 100%;">' +
        '<@= title @>' +
      '</button>' +
    '</div>',
  ),

  _requestOrderCancel($button) {
    const question = 'Are you sure you want to cancel sending this order?';
    if (!window.confirm(question)) {
      return;
    }

    const reqId = $button.initLoadingButton(null, 'Requesting', 'Requested');
    this.model.requestOrderCancel((err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        alert('Please remember to refund any transactions.');
      }
    });
  },

  _requestOrderRefund($button) {
    const question =
      'Are you sure you want to fully refund this order?\nIf you need to issue a partial refund, please refund a specific transaction.';
    if (!window.confirm(question)) {
      return;
    }

    const reqId = $button.initLoadingButton(null, 'Refunding', 'Refunded');
    this.model.requestOrderRefund((err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
      }
    });
  },

  _commitTransaction(transaction, $button) {
    const reqId = $button.initLoadingButton(null, 'Committing', 'Committed');
    this.model.commitTransaction(transaction, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
      }
    });
  },

  _appendRow(text, buttonInfo) {
    const $row = $(this.template());
    $row.append('<span class="accessory-label"></span>');
    const $label = $row.find('.accessory-label');
    this.$el.append($row);

    $row.find('.name').text(text);

    if (buttonInfo) {
      const $buttonContainer = $(
        this.buttonTemplate({
          title: buttonInfo.title,
        }),
      );
      $label.append($buttonContainer);
      $buttonContainer.find('button').click(buttonInfo.clickHandler);
    }
  },

  render() {
    app.CellView.prototype.render.apply(this, arguments);

    const order = this.model;

    if (
      app.sessionUser.isBite() &&
      app.location.hasIdentifiableKioskGuests() &&
      !this.options.guestId &&
      !order.get('userDidRejectConsent')
    ) {
      const guestId = order.get('guestId');
      this.$('.name').prepend(
        `Guest <span class="guestId" model-type="${ModelType.Guest}" model-id="${guestId}" data-bs-toggle="tooltip" data-bs-title="${guestId}">${guestId}</span>${app.HtmlHelper.biteRightIcon}<br />`,
      );
    }

    if (app.sessionUser.canRefundOrders()) {
      if (order.isCancellable()) {
        this._appendRow('', {
          title: 'Cancel Order',
          clickHandler: (e) => {
            this._requestOrderCancel($(e.target));
          },
        });
      } else if (order.isRefundable()) {
        this._appendRow('', {
          title: 'Refund Order',
          clickHandler: (e) => {
            this._requestOrderRefund($(e.target));
          },
        });
      }
    }

    const self = this;

    const multipleTransactions = order.transactions.length > 1;
    order.transactions.forEach((transaction) => {
      if (transaction.isRefund()) {
        return;
      }
      let transactionButtonOptions = null;
      if (app.sessionUser.isBiteSupport() && order.canCommitTransaction(transaction)) {
        transactionButtonOptions = {
          title: 'Commit Transaction',
          clickHandler(e) {
            self._commitTransaction(transaction, $(e.target));
          },
        };
      }
      this._appendRow(transaction.detailsString(multipleTransactions), transactionButtonOptions);

      order
        .get('refunds')
        ?.filter((refund) => {
          return refund.transactionId === transaction.id;
        })
        .forEach(({ total, createdAt, accessUsername }) => {
          const refundTotal = MathHelper.displayPrice(total);
          const refundTime = TimeHelper.displayDateFromTimestamp(
            createdAt,
            app.location.get('timezone'),
          );
          this._appendRow(`Refunded: $${refundTotal} on ${refundTime} by ${accessUsername}`);
        });
    });

    if (!this._$linkButton) {
      this._$linkButton = $(
        this.buttonTemplate({
          title: 'Copy Link &#x1F517;',
        }),
      );
      this.$label.append(this._$linkButton);
    }
    this._$linkButton.find('button').click(() => {
      const url = `${window.location.href.split('#')[0]}#orders/${self.model.id}`;
      if (BrowserHelper.copyTextToClipboard(url)) {
        app.showSavedToast('Copied Link to This Order!');
      }
      return false;
    });

    if (order.hasEnoughDataForReceipt()) {
      if (!this._$receiptButton) {
        this._$receiptButton = $(
          this.buttonTemplate({
            title: 'Email Receipt 📤',
          }),
        );
        this.$label.append(this._$receiptButton);
      }

      const $receiptButton = this._$receiptButton.find('button');
      $receiptButton.click(() => {
        const email = prompt(
          'Please enter the email address to send the receipt to:',
          order.get('guestEmail') || 'Email address',
        );
        const reqId = $receiptButton.initLoadingButton($receiptButton.html(), 'Sending', ' ');
        if (!(email || '').trim().length) {
          $receiptButton.loadingDidFinishWithError(reqId);
          return false;
        }

        self.model.requestReceipt(email, (err) => {
          if (err) {
            $receiptButton.loadingDidFinishWithError(reqId);
          } else {
            $receiptButton.loadingDidFinishSuccessfully(reqId);
          }
        });
        return false;
      });
    }

    if (app.location.getKioskPaymentI9nSchema() || app.location.getEcommPaymentI9nSchema()) {
      if (!this._$trButton) {
        const title = 'View Transactions';
        this._$trButton = $(this.buttonTemplate({ title }));
        this.$label.append(this._$trButton);
      }

      this._$trButton.find('button').click(() => {
        self.trigger(app.OrderCellView.Events.UserDidSelectTransactions, self);
        return false;
      });
    }

    if (app.sessionUser.canViewSystemLogs()) {
      if (!this._$systemLogsButton) {
        const title = `View System Logs ${app.HtmlHelper.resellerRightIcon}`;
        this._$systemLogsButton = $(this.buttonTemplate({ title }));
        this.$label.append(this._$systemLogsButton);
      }

      this._$systemLogsButton.find('button').click(() => {
        self.trigger(app.OrderCellView.Events.UserDidSelectSystemLogs, self);
        return false;
      });
    }

    if (order.get('clientOrderedItems') && app.sessionUser.isBiteEng()) {
      if (!this._$orderedItemsSwapButton) {
        const title = 'Swap Ordered Items ⚙';
        this._$orderedItemsSwapButton = $(this.buttonTemplate({ title }));
        this.$label.append(this._$orderedItemsSwapButton);
      }

      const $clientOrderedItems = this.$('.client-ordered-items');
      const $serverOrderedItems = this.$('.server-ordered-items');

      this._$orderedItemsSwapButton.find('button').click(() => {
        $clientOrderedItems.toggleClass('d-none');
        $serverOrderedItems.toggleClass('d-none');
      });
    }

    if (app.sessionUser.isBiteSupport()) {
      if (!this._$vjButtonContainer) {
        this._$vjButtonContainer = $(
          this.buttonTemplate({
            title: 'View JSON ⚒',
          }),
        );
        this.$label.append(self._$vjButtonContainer);
      }

      this._$vjButtonContainer.find('button').click(() => {
        const jsonView = new app.ModelJsonView({
          model: self.model,
          altText: 'order-json',
          title: `Order from ${TimeHelper.displayDateFromTimestamp(
            self.model.get('createdAt'),
            app.location.get('timezone'),
          )}`,
        });
        app.modalManager.showModalWithView(jsonView);
        return false;
      });
    }

    if (
      app.sessionUser.isBiteEng() &&
      app.location.hasMultiPaymentSupport() &&
      order.requiresDisbursement()
    ) {
      if (!this._$disburseButton) {
        this._$disburseButton = $(
          this.buttonTemplate({
            title: 'Disburse Payment ⚙',
          }),
        );
        this.$label.append(this._$disburseButton);
        const $disburseButton = this._$disburseButton.find('button');
        $disburseButton.click(() => {
          const reqId = $disburseButton.initLoadingButton(
            $disburseButton.html(),
            'Requesting',
            ' ',
          );
          self.model.requestDisbursement((err) => {
            if (err) {
              $disburseButton.loadingDidFinishWithError(reqId);
            } else {
              $disburseButton.loadingDidFinishSuccessfully(reqId);
            }
          });
          return false;
        });
      }
    }

    return this;
  },
});

app.OrderCellView.Events = {
  UserDidSelectTransactions: 'UserDidSelectTransactions',
  UserDidSelectSystemLogs: 'UserDidSelectSystemLogs',
};
