import _ from 'underscore';

import { template } from '../../template';

app.CheckListFieldView = app.FieldView.extend({
  checkListTemplate: template('<div class="check-list collapsible"></div>'),

  destroy() {
    app.FieldView.prototype.destroy.apply(this, arguments);
    this.stopListening(this.collection);
  },

  setValue(list, model) {
    this.model = model;
    this.initialValue = list;

    if (!this.collection) {
      this.collection = model.getFieldCollection(
        this.field,
        this.subProperty,
        false,
        this.keyModel,
      );
      this.listenTo(this.collection, 'reset', this._renderList);
    }

    // Create a new array object so that the pointer would be different
    this.list = [].concat(list || []);

    this.toggleCollapsedView(!this.list.length);

    this._renderList();
  },

  _renderList() {
    const self = this;

    this.$checkList.html('');
    this.collection.each((object, index, collection) => {
      const value = object.id ? object.id : object;
      const $checkbox = $(
        `<div class="form-check">` +
          `<input id="checkbox-${value}" class="form-check-input" type="checkbox" value="${value}">` +
          `<label class="form-check-label check-item" for="checkbox-${value}">` +
          `<span class="name">${
            object.displayNameHtml ? object.displayNameHtml() : object.displayName()
          }</span>` +
          `</label>` +
          `</div>`,
      );
      if (object.get('disclaimer')) {
        $checkbox.append(
          `<span class="disclaimer">${object.get('disclaimer').split('\n').join('<br />')}</span>`,
        );
      }
      const $box = $checkbox.find(`input:checkbox`);
      const $label = $checkbox.find(`label`);
      $box.click(() => {
        if (_.contains(self.list, value)) {
          app.JsonHelper.removeElementFromArray(self.list, value);
        } else {
          self.list.push(value);
        }

        self.trigger(app.FieldView.Events.FieldDidChangeValue, self);
      });
      self.$checkList.append($checkbox);
      if (index >= 0 && index < collection.length - 1) {
        self.$checkList.append('  ');
      }
      if (_.include(self.list, value)) {
        $box.prop('checked', true);
      }

      if (self.isPermanent() || this.options.isValueDisabled?.(value, self.list)) {
        $checkbox.addClass('disabled');
        $label.prop('disabled', true);
        $box.prop('disabled', true);
      }

      if (!self.isPermanent()) {
        // If the field is permanent, then we don't want to give it the opportunity to enable boxes
        self.listenTo(self, app.FieldView.Events.FieldDidChangeValue, () => {
          if (this.options.isValueDisabled?.(value, self.list)) {
            $checkbox.addClass('disabled');
            $box.prop('disabled', true);
            $label.prop('disabled', true);
          } else {
            $checkbox.removeClass('disabled');
            $box.prop('disabled', false);
            $label.prop('disabled', false);
          }
        });
      }
    });

    if (this.schema.canCreateNew && !self.isPermanent() && app.sessionUser.canEditAppearance()) {
      self.$checkList.append('  ');
      const $checkbox = $('<label class="check-item create-item">create new badge</label>');
      $checkbox.click(() => {
        const detailsView = new app.BaseDetailsView({
          collection: self.collection,
          callback() {
            const value = self.getValue();
            self.render();
            self.setValue(value);
          },
        });
        app.modalManager.showModalWithView(detailsView);
        return false;
      });
      self.$checkList.append($checkbox);
    }
  },

  getValue() {
    if (this.isPermanent()) {
      return this.initialValue;
    }
    if (!this.list.length && !this.initialValue) {
      // if there were no badges and no list
      return this.initialValue;
    }
    return this.list;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    return { isValid: true };
  },

  renderField() {
    this.$inputContainer.append(this.checkListTemplate());
    this.$checkList = this.$el.find('.check-list');
  },
});

app.ExtendedCheckListFieldView = app.CheckListFieldView.extend({
  className() {
    return `${app.CheckListFieldView.prototype.className.apply(this, arguments)} extended`;
  },
});
