import { KioskOs, ModelType } from '@biteinc/enums';

app.KioskStatus = app.AbstractModel.extend({
  ModelName: 'gazeboStatus',
  Schema: {
    displayName: 'kiosk status',
  },
  Type: ModelType.KioskStatus,

  canReceivePusherNotifications() {
    return this.hasStr('pusherChannel');
  },

  displayName() {
    return this.get('peerId');
  },

  isIOS() {
    return this.get('deviceStatus').os === KioskOs.iOS;
  },

  isAndroid() {
    return this.get('deviceStatus').os === KioskOs.Android;
  },

  notifyDevice(notificationType, data, options) {
    app.makeRequestWithOptions({
      method: 'POST',
      data,
      url: `${this.url()}/notifyPeer?type=${notificationType}`,
      onSuccess: options.success,
      onFailure: options.error,
    });
  },
});
