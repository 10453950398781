import {
  BiteLogAdyenEvent,
  BiteLogAdyenEventHelper,
  BiteLogCardConnectEvent,
  BiteLogCardConnectEventHelper,
  BiteLogDelphiEvent,
  BiteLogDelphiEventHelper,
  BiteLogDeviceEvent,
  BiteLogDeviceEventHelper,
  BiteLogEigenEvent,
  BiteLogEigenEventHelper,
  BiteLogElavonEvent,
  BiteLogElavonEventHelper,
  BiteLogFatalError,
  BiteLogFatalErrorHelper,
  BiteLogFreedomPayEvent,
  BiteLogFreedomPayEventHelper,
  BiteLogHeartlandEvent,
  BiteLogHeartlandEventHelper,
  BiteLogKioskEvent,
  BiteLogKioskEventHelper,
  BiteLogLoyaltyEvent,
  BiteLogLoyaltyEventHelper,
  BiteLogLrsTableTrackerEvent,
  BiteLogLrsTableTrackerEventHelper,
  BiteLogMaitredEvent,
  BiteLogMaitredEventHelper,
  BiteLogOloPayKioskEvent,
  BiteLogOloPayKioskEventHelper,
  BiteLogPrinterEvent,
  BiteLogPrinterEventHelper,
  BiteLogPusherEvent,
  BiteLogPusherEventHelper,
  BiteLogSimpleEvent,
  BiteLogSimpleEventHelper,
  BiteLogSquareEvent,
  BiteLogSquareEventHelper,
  BiteLogStripeEvent,
  BiteLogStripeEventHelper,
  BiteLogType,
  BiteLogTypeHelper,
  BiteLogVerifonePointCloudEvent,
  BiteLogVerifonePointCloudEventHelper,
  BiteLogVerifonePointScaEvent,
  BiteLogVerifonePointScaEventHelper,
  BiteLogWorldpayEvent,
  BiteLogWorldpayEventHelper,
} from '@biteinc/enums';

import { buildBiteLogListFilterSchema } from './bite_log_list_filter_schema';

app.BiteLogListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = {
      fetchOnInit: false,
      canCollapse: true,
      isReadOnly: true,
      paginateToLoadSelectedItem: true,
      cellOptions: {
        clickable: true,
      },
      cellView: app.BiteLogCellView,
      useVList: true,
      hideStatusButton: false,
      showTimestampsInLocationTimezone: true,
      filterSchemaButton: true,
      filterSchema: buildBiteLogListFilterSchema(!!app.org, !!app.site, !!app.location),
      ...(opts || {}),
    };
    app.PaginatedListView.prototype.initialize.apply(this, [options]);
  },

  _getLogStatusFilterEnumAndNameFunction() {
    if (!this.collection.filterFieldGroupView) {
      return null;
    }

    let logStatusEnum;
    let logStatusEnumHelper;

    switch (this.collection.filterFieldGroupView.getValue()['logType']) {
      case BiteLogType.Adyen:
        logStatusEnum = BiteLogAdyenEvent;
        logStatusEnumHelper = BiteLogAdyenEventHelper;
        break;
      case BiteLogType.CardConnect:
        logStatusEnum = BiteLogCardConnectEvent;
        logStatusEnumHelper = BiteLogCardConnectEventHelper;
        break;
      case BiteLogType.Delphi:
        logStatusEnum = BiteLogDelphiEvent;
        logStatusEnumHelper = BiteLogDelphiEventHelper;
        break;
      case BiteLogType.Device:
        logStatusEnum = BiteLogDeviceEvent;
        logStatusEnumHelper = BiteLogDeviceEventHelper;
        break;
      case BiteLogType.Eigen:
        logStatusEnum = BiteLogEigenEvent;
        logStatusEnumHelper = BiteLogEigenEventHelper;
        break;
      case BiteLogType.Elavon:
        logStatusEnum = BiteLogElavonEvent;
        logStatusEnumHelper = BiteLogElavonEventHelper;
        break;
      case BiteLogType.FatalError:
        logStatusEnum = BiteLogFatalError;
        logStatusEnumHelper = BiteLogFatalErrorHelper;
        break;
      case BiteLogType.FreedomPay:
        logStatusEnum = BiteLogFreedomPayEvent;
        logStatusEnumHelper = BiteLogFreedomPayEventHelper;
        break;
      case BiteLogType.Heartland:
        logStatusEnum = BiteLogHeartlandEvent;
        logStatusEnumHelper = BiteLogHeartlandEventHelper;
        break;
      case BiteLogType.Kiosk:
        logStatusEnum = BiteLogKioskEvent;
        logStatusEnumHelper = BiteLogKioskEventHelper;
        break;
      case BiteLogType.Loyalty:
        logStatusEnum = BiteLogLoyaltyEvent;
        logStatusEnumHelper = BiteLogLoyaltyEventHelper;
        break;
      case BiteLogType.LrsTableTracker:
        logStatusEnum = BiteLogLrsTableTrackerEvent;
        logStatusEnumHelper = BiteLogLrsTableTrackerEventHelper;
        break;
      case BiteLogType.Maitred:
        logStatusEnum = BiteLogMaitredEvent;
        logStatusEnumHelper = BiteLogMaitredEventHelper;
        break;
      case BiteLogType.OloPayKiosk:
        logStatusEnum = BiteLogOloPayKioskEvent;
        logStatusEnumHelper = BiteLogOloPayKioskEventHelper;
        break;
      case BiteLogType.Printer:
        logStatusEnum = BiteLogPrinterEvent;
        logStatusEnumHelper = BiteLogPrinterEventHelper;
        break;
      case BiteLogType.Pusher:
        logStatusEnum = BiteLogPusherEvent;
        logStatusEnumHelper = BiteLogPusherEventHelper;
        break;
      case BiteLogType.Simple:
        logStatusEnum = BiteLogSimpleEvent;
        logStatusEnumHelper = BiteLogSimpleEventHelper;
        break;
      case BiteLogType.Square:
        logStatusEnum = BiteLogSquareEvent;
        logStatusEnumHelper = BiteLogSquareEventHelper;
        break;
      case BiteLogType.Stripe:
        logStatusEnum = BiteLogStripeEvent;
        logStatusEnumHelper = BiteLogStripeEventHelper;
        break;
      case BiteLogType.VerifonePointCloud:
        logStatusEnum = BiteLogVerifonePointCloudEvent;
        logStatusEnumHelper = BiteLogVerifonePointCloudEventHelper;
        break;
      case BiteLogType.VerifonePointSca:
        logStatusEnum = BiteLogVerifonePointScaEvent;
        logStatusEnumHelper = BiteLogVerifonePointScaEventHelper;
        break;
      case BiteLogType.Worldpay:
        logStatusEnum = BiteLogWorldpayEvent;
        logStatusEnumHelper = BiteLogWorldpayEventHelper;
        break;
    }

    if (logStatusEnum === undefined || logStatusEnumHelper === undefined) {
      return null;
    }

    return { logStatusEnum, logStatusEnumHelper };
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'logType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: BiteLogType,
          nameGenerator: (logType) => {
            if (logType === BiteLogType.FatalError) {
              return `Kiosk Down/Up`;
            }
            return BiteLogTypeHelper.name(logType);
          },
        });
      case 'logStatus': {
        const enumAndHelper = this._getLogStatusFilterEnumAndNameFunction();
        if (!enumAndHelper) {
          return null;
        }
        const { logStatusEnum, logStatusEnumHelper } = enumAndHelper;

        return app.AbstractCollection.createFromTsEnum({
          tsEnum: logStatusEnum,
          nameGenerator: logStatusEnumHelper.name,
        });
      }
    }
    return null;
  },

  __cellWasClicked(cellView, _eventOpt) {
    const biteLog = cellView.model;
    const detailsView = new app.BiteLogView({
      model: biteLog,
    });
    this.showDetailsView(detailsView);
  },

  __navigateToModel(log) {
    this.scrollToItemWithId(log.id, true);
  },

  _exportQuicksandRecords() {
    const scope = app.location ?? app.site ?? app.org;

    // Reliably start the download and allows us to set the file name.
    const aLink = document.createElement('a');
    aLink.download = `system-logs-${scope.id}.csv`;
    const lines = [['Time', 'Log Type', 'Log Status', 'Client', 'Order ID']];

    this.collection.models.forEach((model) => {
      lines.push(model.toCsv());
    });
    const data = lines.join('\n');
    const csvContent = 'data:text/csv;charset=utf-8,';

    aLink.href = encodeURI(csvContent + data);
    const event = new MouseEvent('click');
    aLink.dispatchEvent(event);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    if (this.collection.filterFieldGroupView) {
      // When the user changes the log type, reset the log status field to have the correct options.
      this.listenTo(
        this.collection.filterFieldGroupView.fieldViewsByField['logType'],
        app.FieldView.Events.FieldDidChangeValue,
        () => {
          this.collection.filterFieldGroupView.fieldViewsByField['logStatus'].setValue(null);
          this.collection.filterFieldGroupView.fieldViewsByField['logStatus'].setupListViewFilter(
            this,
          );
        },
      );
    }

    const $downloadButton = $(
      '<button type="button" class="btn btn-sm btn-primary download">Download as CSV</button>',
    );
    this.$('.card-header .right-button-container').prepend($downloadButton);
    $downloadButton.click(() => {
      this._exportQuicksandRecords();
    });

    return this;
  },
});
