import _ from 'underscore';

import { template } from '../../template';

app.DictionaryWordListView = app.BaseListView.extend({
  subsTemplate: template('<div class="field-group"></div>'),

  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'reset', this.render);
    this.listenTo(this.collection, 'remove', this.render);
    this.listenTo(this.collection, 'add', this.render);
    this.listenTo(app.dictionaryVolumeList, 'reset', this.render);
  },

  hasFetchedAllData() {
    return (
      app.dictionaryVolumeList.hasBeenFetched() &&
      app.BaseListView.prototype.hasFetchedAllData.apply(this, arguments)
    );
  },

  _layoutPanes() {
    app.BaseListView.prototype._layoutPanes.apply(this, arguments);

    // $listHeader contains all the subscription controls and must always be
    // shown even if there are no invoices.
    if (this.hasFetchedAllData() && this.$listHeader) {
      this.$listHeader.show();
    }
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (!app.dictionaryVolumeList.hasBeenFetched()) {
      app.dictionaryVolumeList.fetch({ reset: true });
    }
    if (!this.hasFetchedAllData()) {
      return this;
    }

    // Find if there are any volumes to show
    const self = this;
    const shouldShow = app.dictionaryVolumeList.any((volume) => {
      return !self.collection.containsAllWordsFromVolume(volume);
    });

    if (shouldShow) {
      const dropdownView = new app.DropdownView();
      this.$listHeader.html(dropdownView.render().$el);
      dropdownView.setTitle('Choose a list of words:');
      dropdownView.setup(app.dictionaryVolumeList, {
        validate(volume) {
          return !self.collection.containsAllWordsFromVolume(volume);
        },
        onAdd(volume) {
          self.collection.createFromVolume(volume, {
            error() {
              self.render();
            },
            success(collection, data) {
              const newCount = _.size(data.data.dictionaryWords);
              app.showSavedToast(`Added ${newCount} new word${newCount === 1 ? '' : 's'}!`);
            },
          });
        },
      });
    } else {
      this.$listHeader.hide();
    }

    return this;
  },
});
