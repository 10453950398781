import type { Schema } from '@biteinc/common';

export const recommendationVariationSchema: Schema.Model = {
  displayName: 'recommendation variation',
  type: 'object',
  fields: {
    name: {
      type: 'keyString',
      required: true,
    },
    recommendationRuleIds: {
      displayName: 'Rules in Pipeline',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Recommendation Rule',
      collapse: true,
      required: true,
    },
    weight: {
      type: 'int',
      required: true,
      min: 0,
      max: 100,
    },
  },
};
