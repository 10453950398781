import type { Schema } from '@biteinc/common';
import { RecommendationRuleEffectName } from '@biteinc/enums';

import { ConditionSchemaName } from '../enums/condition_schema_name';

export const recommendationRuleSchema: Schema.Model = {
  displayName: 'recommendation rule',
  type: 'object',
  fields: {
    description: {
      type: 'shortString',
      required: true,
    },
    // the schema here is a string because it will be inputted as a string
    conditionSchemaName: {
      displayName: 'Condition',
      type: 'keyString',
      required: true,
      ui: 'dropdown',
    },

    // custom schema
    conditionSchema: {
      displayName: 'Custom Condition (JSON Schema)',
      type: 'codeString',
      conditions: [
        {
          field: 'conditionSchemaName',
          values: [ConditionSchemaName.Custom],
        },
      ],
    },

    // condition schema prebuild schema fields
    conditionSchemaSectionIds: {
      displayName: 'Condition Sections',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Sections',
      collapse: true,
      conditions: [
        {
          field: 'conditionSchemaName',
          values: [
            ConditionSchemaName.OneOfMenuSectionsIsSelectedItem,
            ConditionSchemaName.OneOfMenuSectionsAreInCart,
            ConditionSchemaName.OneOfMenuSectionsAreSelectedItemOrInCart,
          ],
        },
      ],
    },
    conditionSchemaSectionId: {
      displayName: 'Condition Section',
      type: 'mongoId',
      ui: 'dropdown',
      required: true,
      conditions: [
        {
          field: 'conditionSchemaName',
          values: [ConditionSchemaName.MenuSectionIsRecommendation],
        },
      ],
    },
    conditionSchemaItemIds: {
      displayName: 'Condition Items',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Items',
      collapse: true,
      conditions: [
        {
          field: 'conditionSchemaName',
          values: [
            ConditionSchemaName.OneOfMenuItemsIsSelectedItem,
            ConditionSchemaName.OneOfMenuItemsAreInCart,
            ConditionSchemaName.OneOfMenuItemsAreSelectedItemOrInCart,
          ],
        },
      ],
    },

    effectName: {
      displayName: 'Effect',
      type: 'keyString',
      required: true,
      ui: 'dropdown',
    },

    // recommendation effect body fields
    effectFormulaDescription: {
      displayName: 'Formula Description',
      type: 'keyString',
      ui: 'dropdown',
      conditions: [
        {
          field: 'effectName',
          values: [RecommendationRuleEffectName.MenuItemAffinity],
        },
      ],
    },

    effectSectionId: {
      displayName: 'Effect Section',
      type: 'mongoId',
      ui: 'dropdown',
      conditions: [
        {
          field: 'effectName',
          values: [RecommendationRuleEffectName.SortItemsInSection],
        },
      ],
    },
    effectSectionIds: {
      displayName: 'Effect Sections',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Sections',
      collapse: true,
      conditions: [
        {
          field: 'effectName',
          values: [
            RecommendationRuleEffectName.SortItemsInSectionsRandomly,
            RecommendationRuleEffectName.RecommendItemsInSection,
            RecommendationRuleEffectName.DoNotRecommendItemsInSection,
          ],
        },
      ],
    },
    effectItemIds: {
      displayName: 'Effect Items',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Items',
      collapse: true,
      conditions: [
        {
          field: 'effectName',
          values: [
            RecommendationRuleEffectName.SortItemsInSection,
            RecommendationRuleEffectName.DoNotRecommendItems,
            RecommendationRuleEffectName.RecommendItems,
          ],
        },
      ],
    },
    effectModifierIds: {
      displayName: 'Effect Mods',
      type: 'array',
      elementType: 'mongoId',
      ui: 'table',
      placeholder: 'Select Mods',
      collapse: true,
      conditions: [
        {
          field: 'effectName',
          values: [RecommendationRuleEffectName.RecommendModifiers],
        },
      ],
    },
    randomizeEffectItemIds: {
      displayName: 'Randomize Effect Items',
      type: 'bool',
      conditions: [
        {
          field: 'effectName',
          values: [RecommendationRuleEffectName.RecommendItems],
        },
      ],
    },
    effectLimitItemsAfterFiltering: {
      displayName: 'Effect Maximum Recommendations',
      type: 'int',
      conditions: [
        {
          field: 'effectName',
          values: [
            RecommendationRuleEffectName.RecommendItemsInSection,
            RecommendationRuleEffectName.RecommendGuestPreviouslyOrderedItems,
            RecommendationRuleEffectName.RecommendGuestFavoriteItems,
            RecommendationRuleEffectName.RecommendGuestPredicationsOnPreviouslyOrderedItems,
            RecommendationRuleEffectName.RecommendItems,
            RecommendationRuleEffectName.HeuristicsV2,
          ],
        },
      ],
    },
    effectRandomSortItemsWithinSections: {
      displayName: 'Effect Randomly Sort Items Within Sections',
      type: 'bool',
      conditions: [
        {
          field: 'effectName',
          values: [RecommendationRuleEffectName.HeuristicsV2],
        },
      ],
    },
  },
};
