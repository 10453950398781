import _ from 'underscore';

import {
  FulfillmentMethodHelper,
  OrderChannelHelper,
  PromotionThresholdType,
} from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { locationSchema } from '@biteinc/schemas';

app.FreeItemPromotionsView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
        };
      },

      // Provide the method for determining the title of the cell.
      getTitle: (fieldGroupView) => {
        let title = '';
        const { name, thresholdType, thresholdValue, fulfillmentMethods, menuItemId } =
          fieldGroupView.getValue();

        if (name) {
          title += `${name}`;
        }

        const menuItemName = app.menuItemList?.get(menuItemId)?.get('posName') ?? false;
        if (menuItemName) {
          title += ` <strong>${menuItemName}</strong> `;
        }

        if (thresholdType && thresholdValue) {
          switch (thresholdType) {
            case PromotionThresholdType.CartSize: {
              title += `<br/> Cart Size of ${thresholdValue} items`;
              break;
            }
            case PromotionThresholdType.CartValue: {
              title += `<br/> Cart Value of $${thresholdValue}`;
              break;
            }
          }
        }

        if (fulfillmentMethods?.length) {
          title += `<br/>Applied to: ${fulfillmentMethods
            .map((fulfillmentMethod) => {
              const diningOptionName = FulfillmentMethodHelper.name(fulfillmentMethod);
              const orderChannel = FulfillmentMethodHelper.orderChannel(fulfillmentMethod);
              return `${diningOptionName} (${OrderChannelHelper.name(orderChannel)})`;
            })
            .join(', ')}`;
        }

        return title;
      },

      getElementSchemaFields: (/* element */) => {
        return locationSchema.fields.freeItemPromotions.fields;
      },

      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || 'Free Item Promotions',
        buttonText: 'new Free Item Promotion',
        emptyText: 'No Free Item Promotions yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
