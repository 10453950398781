import type { UserData } from './types/user_data';

export function getCsvLinesFromUserList(
  userList: Backbone.Collection<Backbone.Model<UserData>>,
  callback: { (err: Error | null, csvLines: string[][]): void },
): void {
  if (app.userRoleList && !app.userRoleList.hasBeenFetched()) {
    app.userRoleList.once('reset', () => {
      getCsvLinesFromUserList(userList, callback);
    });
    void app.userRoleList.fetch({ reset: true });
    return;
  }

  const lines: string[][] = [];
  lines.push(['Email Address', 'First Name', 'Last Name', 'Role', 'Location Access']);
  userList.models.forEach((user) => {
    const userRole = app.userRoleList.get(user.get('userRoleId')!);
    lines.push([
      user.get('username')!,
      user.get('firstName') || '',
      user.get('lastName') || '',
      userRole.get('name')!,
      getLocationGroupName(user.get('locationGroupId')),
    ]);
  });

  callback(null, lines);
}

function getLocationGroupName(locationGroupId: string | undefined): string {
  if (!locationGroupId) {
    throw new Error('Bite users should not show up in this list.');
  }
  const locationGroup = app.locationGroupList.get(locationGroupId);
  // @ts-expect-error this method is defined on app.LocationGroup
  return locationGroup.displayNameAsListFieldElement(true);
}
