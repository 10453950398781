import { ChangesetType } from '@biteinc/enums';

app.ChangesetDetailsView = app.BaseDetailsView.extend({
  _insertSupplementElements() {
    const type = this.fieldGroupView.fieldViewsByField.type.getValue();

    if (!this.supplementElements) {
      this.supplementElements = [];
    }
    this.supplementElements.forEach((element) => {
      element.remove();
    });

    if (!type) {
      return this;
    }

    if (type === ChangesetType.MagicCopy) {
      const magicCopyParamsFieldView = this.fieldGroupView.fieldViewsByField.magicCopyParams;
      const toLocationIdsFieldView =
        magicCopyParamsFieldView.nestedFieldGroupView.fieldViewsByField.toLocationIds;

      if (!this.model.get('appliedAt')) {
        const $locationSelectorAnchor = $(`
          <div class="field-container">
            <div class="col-md-8">
              <a><span>
                Click to Choose Locations
              </span></a>
            </div>
          </div>`);
        $locationSelectorAnchor.insertBefore(toLocationIdsFieldView.$el);

        $locationSelectorAnchor.on('click', () => {
          app.showLocationSelector(
            {
              modalTitle: 'Select destination locations to copy to',
              locationsUrl: '/api/v2/magic-copy/locations',
              locationGroupsUrl: '/api/v2/magic-copy/location-groups',
              maxSelectable: null,
            },
            (err, selectedLocations) => {
              toLocationIdsFieldView.setValue(
                selectedLocations.map((location) => location.id),
                this.model,
              );
              // We need to trigger the event ourselves since we are setting the value directly
              toLocationIdsFieldView.trigger(
                app.FieldView.Events.FieldDidChangeValue,
                toLocationIdsFieldView,
              );
            },
          );
        });

        this.supplementElements.push($locationSelectorAnchor);
      }

      if (app.location.getPosI9nSchema()) {
        // Copying prices and taxes is not permitted if the location has a POS
        const optionsFieldView =
          magicCopyParamsFieldView.nestedFieldGroupView.fieldViewsByField.options;
        const pricesAndTaxesFieldView =
          optionsFieldView.nestedFieldGroupView.fieldViewsByField.pricesAndTaxes;
        pricesAndTaxesFieldView.$form.prop('disabled', true);
      }
    }
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.type,
      app.FieldView.Events.FieldDidChangeValue,
      () => {
        // If the user changes the type, then we may want to update what is rendered
        this._insertSupplementElements();
      },
    );

    this._insertSupplementElements();

    return this;
  },
});
