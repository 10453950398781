import _ from 'underscore';

import { ItemHiddenState } from '@biteinc/enums';
import { MathHelper, StringHelper } from '@biteinc/helpers';

import { getArchivedBadgeHtml } from '../../../helpers/html_builder';

app.PriceOptionView = app.AccordionFieldGroupView.extend({
  initialize() {
    app.AccordionFieldGroupView.prototype.initialize.apply(this, arguments);

    if (app.sessionUser.can86Items()) {
      this._setUpShowHideButtons();
      this.listenTo(
        this,
        app.FieldGroupView.Events.FieldGroupDidChangeValue,
        this._updateHideShowButtons.bind(this),
      );
    }
  },

  _setUpShowHideButtons() {
    if (!app.sessionUser.can86Items()) {
      return;
    }

    const self = this;
    const showHtml = '<i class="bi bi-eye-fill" aria-hidden="true"></i>show';
    this.$showButtonCtr = this.cell.addButton(showHtml, null, () => {
      self._updateHiddenState(ItemHiddenState.Shown, 'The item is now shown on the menu');
      return false;
    });
    const hideHtml = '<i class="bi bi-eye-slash-fill" aria-hidden="true"></i>hide';
    this.$hideButtonCtr = this.cell.addButton(
      hideHtml,
      {
        'item-86': 'hide until tomorrow',
        'item-hide': 'hide indefinitely',
      },
      ($button, dropdownClassName) => {
        let hiddenState = ItemHiddenState.HiddenIndefinitely;
        let toastTitle = 'The item is now hidden indefinitely';
        if ('item-86' === dropdownClassName) {
          hiddenState = ItemHiddenState.HiddenUntilTomorrow;
          toastTitle = 'The item is now hidden until tomorrow';
        }
        self._updateHiddenState(hiddenState, toastTitle);
        return false;
      },
    );
    this.$hiddenOnPosMessage = this.cell.addLabel('Hidden on POS');
  },

  _updateHiddenState(hiddenState, toastTitle) {
    const self = this;
    const priceOptionId = this.getParamWithoutFieldView('_id');
    this.model.updateHiddenStateForPriceOption(hiddenState, priceOptionId, (err) => {
      if (!err) {
        self.setParamWithoutFieldView('hiddenState', hiddenState);
        app.showSavedToast(toastTitle);
      }
    });
  },

  _updateHideShowButtons() {
    if (!app.sessionUser.can86Items()) {
      return;
    }

    this.$hiddenOnPosMessage.hide();
    this.$hideButtonCtr.hide();
    this.$showButtonCtr.hide();

    if (!this.model || this.model.isNew()) {
      return;
    }

    const priceOptionId = this.getParamWithoutFieldView('_id');
    const priceOption = this.model.priceOptionById(priceOptionId);
    const poCanBeHidden = this.model.priceOptionCanBeHidden(priceOptionId);
    if (priceOption && poCanBeHidden) {
      if (this.getParamWithoutFieldView('hiddenState')) {
        this.$showButtonCtr.show();
      } else {
        this.$hideButtonCtr.show();
      }
    } else if (priceOption) {
      // This po is being created right now or this po is hidden on the pos
      this.$hiddenOnPosMessage.show();
    }
  },

  setValue() {
    app.AccordionFieldGroupView.prototype.setValue.apply(this, arguments);

    this._updateHideShowButtons();
  },
});

app.PriceOptionsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} price-option-panel`;
  },

  initialize(options) {
    const self = this;
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
          price: 0,
        };
      },
      FGVClass: app.PriceOptionView,
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let title = '';
        const priceOption = fieldGroupView.getValue();
        if (priceOption.name) {
          title += `${priceOption.name}: `;
        } else if (priceOption.posName) {
          title += `${priceOption.posName}: `;
        }
        if (_.has(priceOption, 'price')) {
          title += `$${MathHelper.displayPrice(priceOption.price, priceOption.saleUnit)}`;
          fieldGroupView.cell.$el.removeClass('has-error');
        } else {
          title += '$';
          if (fieldGroupView.hasValidated) {
            fieldGroupView.cell.$el.addClass('has-error');
          } else {
            fieldGroupView.cell.$el.removeClass('has-error');
          }
        }
        const hiddenState = fieldGroupView.getParamWithoutFieldView('hiddenState');
        if (hiddenState === ItemHiddenState.HiddenIndefinitely) {
          title += ' (hidden indefinitely)';
        } else if (hiddenState === ItemHiddenState.HiddenUntilTomorrow) {
          title += ' (hidden until tomorrow)';
        }
        const archivedBadgeHtml = getArchivedBadgeHtml(priceOption.archivedAt, false);
        if (archivedBadgeHtml) {
          title += ` ${archivedBadgeHtml}`;
        }

        return title;
      },
      // |handler| is a method that updates the cell UI.
      setSetValueHandlers(fieldGroupView, handler) {
        if (fieldGroupView.fieldViewsByField.name) {
          fieldGroupView.fieldViewsByField.name.$form.keyup(handler);
        }
        if (fieldGroupView.fieldViewsByField.saleUnit) {
          self.listenTo(
            fieldGroupView.fieldViewsByField.saleUnit,
            app.FieldView.Events.FieldDidChangeValue,
            handler,
          );
        }
        fieldGroupView.fieldViewsByField.price.$form.keyup(handler);
      },
      templateData: {
        title: 'Price Options',
        buttonText: 'new price option',
        emptyText: 'no price options',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },

  focusOnPrice() {
    const priceOptionView = _.first(this._fieldGroupViews);
    this._selectFieldGroup(priceOptionView);
    priceOptionView.fieldViewsByField.price.$form.focus();
  },

  render() {
    app.AccordionFieldView.prototype.render.apply(this, arguments);

    if (
      (app.location && app.location.getPosI9nSchema()) ||
      (this.schema.maxCount || 1000) <= 1 ||
      this.isReadOnly
    ) {
      this.$('button.add-new').remove();
    }

    return this;
  },
});
