app.RecommendationRuleDetailsView = app.BaseDetailsView.extend({
  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    // add some spacing around these two distinct concepts
    const $conditionLabel = this.$el.find("label:contains('Condition:')");
    $conditionLabel.parent().prepend('<br /><hr />');

    const $effectLabel = this.$el.find("label:contains('Effect:')");
    $effectLabel.parent().prepend('<br /><hr />');

    return this;
  },
  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);
  },
});
