import _ from 'underscore';

import { LanguageCode, ModelType } from '@biteinc/enums';
import { MathHelper, StringHelper, Time } from '@biteinc/helpers';

import { TimeHelper } from '../helpers/time_helper';

app.Guest = app.AbstractModel.extend({
  ModelName: 'guest',
  Schema: {
    displayName: 'guest',
  },
  Type: ModelType.Guest,

  _isFetchingOrders: false,
  _orders: null,

  initialize() {
    app.AbstractModel.prototype.initialize.apply(this, arguments);

    if (this.id) {
      this._precalculateDemographicInfo();
    } else {
      this.listenToOnce(this, 'change', this._precalculateDemographicInfo);
    }

    this._locationPartialOrders = _.filter(this.get('orders'), (partialOrder) => {
      return partialOrder.locationId === app.location.id;
    });

    this.lastOrderAt = _.reduce(
      this._locationPartialOrders,
      (max, partialOrder) => {
        return Math.max(max, partialOrder.createdAt);
      },
      -1,
    );
  },

  _precalculateDemographicInfo() {
    this._ageRange = this._calculateAgeRange();
    this._genderMaleConfidence = this._calculateGenderMaleConfidence();
    this._sortedEthnicities = this._getSortedEthnicities();
  },

  _getOptedOutBadgeHtml() {
    if (!this.get('optedOutAt')) {
      return '';
    }

    const alt = `Opted-out on ${TimeHelper.format(this.get('optedOutAt'))}.`;
    return `<span class="cell-badge opted-out" title="${alt}">opted-out</span>`;
  },

  displayName(header) {
    const visitsStr = header
      ? ''
      : ` (${this._locationPartialOrders.length} visit${
          this._locationPartialOrders.length === 1 ? '' : 's'
        })`;
    const optedOutHtml = this._getOptedOutBadgeHtml();

    if (this.hasArr('images')) {
      return (
        this.gender() +
        (header ? ' Guest, ' : ', ') +
        (header ? 'Age ' : '') +
        this.ageRange() +
        visitsStr +
        optedOutHtml
      );
    }
    if (this.get('phoneNumber')) {
      return `Guest ${StringHelper.toFormattedPhoneNumber(
        this.get('phoneNumber'),
      )}${visitsStr}${optedOutHtml}`;
    }
    return 'Unidentified Guest';
  },

  fetchOrders() {
    if (this._isFetchingOrders || this._orders) {
      return;
    }

    const self = this;
    this._isFetchingOrders = true;
    app.makeRequestWithOptions({
      url: `${this.url()}/orders`,
      onSuccess(data) {
        self._isFetchingOrders = true;
        self._orders = _.map(data.orders || [], (orderJSON) => {
          return new app.Order(orderJSON);
        });
        self.trigger('change');
      },
      onFailure(/* err */) {
        self._isFetchingOrders = true;
        self.trigger('change');
      },
    });
  },

  getOrders() {
    return this._orders;
  },

  getEthnicity() {
    let ethnicity = `Ethnicity: ${this._sortedEthnicities[0].name} (${this._sortedEthnicities[0].confidence}%)`;
    if (this._sortedEthnicities[1].confidence > 5) {
      ethnicity += `, ${this._sortedEthnicities[1].name} (${this._sortedEthnicities[1].confidence}%)`;
    }
    return ethnicity;
  },

  getDemographicSummary() {
    if (!this.hasArr('images')) {
      return 'No guest demographic data has been received yet.';
    }

    const isMale = this._genderMaleConfidence >= 0.5;
    let gender = 'Gender: ';
    gender += `${isMale >= 0.5 ? 'M' : 'F'} (`;
    if (isMale) {
      gender += Math.floor(this._genderMaleConfidence * 100);
    } else {
      gender += Math.floor((1 - this._genderMaleConfidence) * 100);
    }
    gender += '% confidence)';

    const age = `Age: ${this.ageRange()}`;

    // var ethnicity = this.getEthnicity();

    return [gender, age].join('\n');
  },

  _calculateAgeRange() {
    const ages = _.map(this.get('images'), (image) => {
      return image.kairosAttributes.age;
    });

    const ageAvg = MathHelper.avg(ages);
    const ageStDev = MathHelper.stDev(ages);
    const ageMin = Math.round(ageAvg - ageStDev);
    const ageMax = Math.round(ageAvg + ageStDev);
    let age = ageMin;
    if (ageMin !== ageMax) {
      age += `-${ageMax}`;
    }
    return age;
  },

  _getSortedEthnicities() {
    if (!this.hasArr('images')) {
      return null;
    }

    const confByName = {
      asian: 0,
      black: 0,
      hispanic: 0,
      other: 0,
      white: 0,
    };
    _.each(this.get('images'), (image) => {
      const attrs = image.kairosAttributes;
      confByName.asian += attrs.asian;
      confByName.black += attrs.black;
      confByName.hispanic += attrs.hispanic;
      confByName.other += attrs.other;
      confByName.white += attrs.white;
    });

    confByName.asian /= this.get('images').length;
    confByName.black /= this.get('images').length;
    confByName.hispanic /= this.get('images').length;
    confByName.other /= this.get('images').length;
    confByName.white /= this.get('images').length;

    const ethnicities = _.map(confByName, (confidence, name) => {
      return {
        name,
        confidence: Math.round(confidence * 100),
      };
    });
    return _.sortBy(ethnicities, (ethnicity) => {
      return ethnicity.confidence * -1;
    });
  },

  _calculateGenderMaleConfidence() {
    if (this.hasArr('images')) {
      let genderMale = 0;
      _.each(this.get('images'), (image) => {
        if (_.has(image.kairosAttributes.gender, 'maleConfidence')) {
          genderMale += image.kairosAttributes.gender.maleConfidence;
        } else if (image.kairosAttributes.gender.type.toLowerCase() === 'm') {
          genderMale += 1;
        }
      });

      return genderMale / this.get('images').length;
    }
    return -1;
  },

  ageRange() {
    return this._ageRange;
  },

  gender() {
    return this._genderMaleConfidence >= 0.5 ? 'Male' : 'Female';
  },

  getVisitSummary() {
    let totalSpend = 0;
    let earliestOrder = null;
    let weekVisitCount = 0;
    const oneWeekAgo = TimeHelper.localMoment().subtract(1, 'week').valueOf();

    _.each(this._orders, (order) => {
      if (order.has('total')) {
        totalSpend += order.get('total');
      } else {
        totalSpend += order.get('subTotal');
      }
      if (!earliestOrder || order.get('createdAt') < earliestOrder.get('createdAt')) {
        earliestOrder = order;
      }
      if (order.get('createdAt') >= oneWeekAgo) {
        weekVisitCount++;
      }
    });

    const timePeriod = Math.max(Date.now() - earliestOrder.get('createdAt'), Time.WEEK);
    const weekCount = timePeriod / Time.WEEK;
    // weekCount = Math.ceil(weekCount);

    return {
      visitCount: this._orders.length,
      avgSpend: Math.ceil(totalSpend / this._orders.length),
      totalSpend,
      lastWeekVisitCount: weekVisitCount,
      avgVisitCountPerWeek: (this._orders.length / weekCount).toFixed(2),
    };
  },

  getSessionSummary() {
    let shortestSessionDuration = Date.now();
    let longestSessionDuration = 0;
    let totalSessionDuration = 0;
    _.each(this._orders, (order) => {
      if (order.get('sessionDuration') < shortestSessionDuration) {
        shortestSessionDuration = order.get('sessionDuration');
      }
      if (order.get('sessionDuration') > longestSessionDuration) {
        longestSessionDuration = order.get('sessionDuration');
      }
      totalSessionDuration += order.get('sessionDuration');
    });

    return {
      shortestSessionDuration,
      longestSessionDuration,
      avgSessionDuration: Math.floor(totalSessionDuration / this._orders.length),
    };
  },

  getOrderingSummary() {
    const countByItemId = {};
    _.each(this._orders, (order) => {
      _.each(order.orderedItems, (item) => {
        const quantity = item.get('priceOption').quantity;
        countByItemId[item.id] = (countByItemId[item.id] || 0) + quantity;
      });
    });
    const itemIds = _.sortBy(_.keys(countByItemId), (itemId) => {
      return countByItemId[itemId] * -1;
    });
    let topItem = null;
    for (let i = 0; i < itemIds.length && !topItem; i++) {
      topItem = app.menuItemList.get(itemIds[i]);
    }

    const topRecos = [];
    if (this.has('recoList')) {
      const recoList = this.get('recoList');
      for (let j = 0; topRecos.length < 6 && j < recoList.length; j++) {
        if (topItem && recoList[j] === topItem.recoId()) {
          // Don't include the top item.
          // eslint-disable-next-line no-continue
          continue;
        }
        const itemModel = app.menuItemList.getItemWithRecoId(recoList[j]);
        if (itemModel) {
          topRecos.push(itemModel);
        }
      }
    }

    return {
      topItem,
      topRecos,
    };
  },

  getPreferencesSummary() {
    const frequencyByTimePeriod = {
      morning: 0,
      afternoon: 0,
      evening: 0,
    };
    let textSizePref = 'regular';
    let languagePref = LanguageCode.EN_US;
    const badgeIdSet = {};

    _.each(this._orders, (order) => {
      const m = Time.moment(order.get('createdAt'), app.location.get('timezone'));
      if (m.hour() < 12) {
        frequencyByTimePeriod.morning++;
      } else if (m.hour() >= 12 && m.hour() < 16) {
        frequencyByTimePeriod.afternoon++;
      } else {
        frequencyByTimePeriod.evening++;
      }

      if (order.has('preferences')) {
        const preferences = order.get('preferences');
        if (preferences['gcn:setting:textSize']) {
          textSizePref = preferences['gcn:setting:textSize'];
        }
        if (preferences['gcn:setting:language']) {
          languagePref = preferences['gcn:setting:language'];
        }
        if (preferences['gcn:setting:badges']) {
          _.extend(badgeIdSet, preferences['gcn:setting:badges']);
        }
      }
    });

    let timePeriods = _.map(frequencyByTimePeriod, (frequency, name) => {
      return {
        name,
        frequency,
      };
    });
    timePeriods = _.sortBy(timePeriods, (period) => {
      return period.frequency * -1;
    });

    const badgeNames = _.compact(
      _.map(badgeIdSet, (v, badgeId) => {
        const badge = app.badgeList.get(badgeId);
        return badge ? badge.displayName() : null;
      }),
    );

    return {
      timePeriods,
      badgeNames,
      language: languagePref,
      textSize: textSizePref,
    };
  },

  getContactInfo() {
    return ['Email: (not provided)', 'Phone Number: (not provided)'].join('\n');
  },

  url() {
    return `/api/v2/guests/${this.id}`;
  },
});
