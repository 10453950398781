import { getBadgeHtml } from '../../helpers/html_builder';
import { template } from '../../template';

app.IntegrationPlaceholderCellView = app.CellView.extend({
  template: template(
    // prettier-ignore
    '<div class="cell-row">' +
      '<div class="cell-body">' +
        '<@= cta @>' +
      '</div>' +
    '</div>',
  ),
  className() {
    return `${app.CellView.prototype.className.apply(this, arguments)} integration-placeholder-cell-view`;
  },

  render() {
    const i9n = this.model;
    const i9nSchema = i9n.getI9nSchema();

    this.$el.html(
      this.template({
        cta: `Create a new ${getBadgeHtml(i9nSchema)} ${i9n.displayName()} integration`,
      }),
    );

    this.attachListeners();

    return this;
  },
});
