import _ from 'underscore';

import {
  CardEntryMethodHelper,
  CardSchemeIdHelper,
  Gateway,
  ModelType,
  TransactionResult,
  TransactionState,
  TransactionType,
} from '@biteinc/enums';
import { MathHelper } from '@biteinc/helpers';

app.Transaction = app.AbstractModel.extend({
  ModelName: 'transaction',
  Schema: {
    displayName: 'transaction',
  },
  Type: ModelType.Transaction,

  canBeCommitted() {
    return (
      this.get('result') === TransactionResult.Approved &&
      this.get('state') === TransactionState.Uncommitted
    );
  },

  getLastFour() {
    if (Gateway.None === this.get('gateway')) {
      return '1234';
    }
    return this.get('last4') || '';
  },

  getCardEntryMethodName() {
    if (Gateway.None === this.get('gateway')) {
      return 'DemoPay';
    }
    return CardEntryMethodHelper.name(this.get('cardEntryMethod'));
  },

  getRefundableAmount() {
    if (this.hasArr('tenders')) {
      return _.reduce(
        this.get('tenders'),
        (total, tender) => {
          return total + tender.amount;
        },
        0,
      );
    }
    // If amountAuthorized is undefined, then most likely we have a bug with the integration.
    // If it's actually 0... how could a transaction be successful and be authorized for $0?
    return (this.get('amountAuthorized') || 0) > 0
      ? this.get('amountAuthorized')
      : this.get('amount');
  },

  getTenders() {
    const customCardName = this.hasStr('customCardSchemeName')
      ? this.get('customCardSchemeName')
      : null;
    if (this.hasArr('tenders')) {
      return _.map(this.get('tenders'), (tender) => {
        return {
          amount: tender.amount,
          cardSchemeName: customCardName || CardSchemeIdHelper.name(tender.cardSchemeId),
        };
      });
    }
    let cardSchemeName = customCardName || CardSchemeIdHelper.name(this.get('cardSchemeId'));
    if (Gateway.None === this.get('gateway')) {
      cardSchemeName = 'DemoPayCard';
    }
    return [
      {
        amount: this.get('amount'),
        cardSchemeName,
      },
    ];
  },

  detailsString(multipleTransactions) {
    const tenders = this.getTenders();
    const lastFour = this.getLastFour();
    const parts = [];
    if (lastFour) {
      parts.push(`Card: *${lastFour}`);
    }
    if (tenders.length === 1) {
      parts.push(`Type: ${tenders[0].cardSchemeName}`);
    }
    if (this.has('cardEntryMethod')) {
      parts.push(this.getCardEntryMethodName());
    }
    if (this.hasStr('authCode')) {
      parts.push(`Auth Code: ${this.get('authCode')}`);
    }
    if (multipleTransactions || tenders.length > 1) {
      if (tenders.length === 1) {
        parts.push(`$${MathHelper.displayPrice(tenders[0].amount)}`);
      } else {
        _.each(tenders, (tender) => {
          parts.push(`${tender.cardSchemeName}: $${MathHelper.displayPrice(tender.amount)}`);
        });
      }
    }
    return parts.join(' | ');
  },

  isRefund() {
    return this.get('type') === TransactionType.Refund;
  },
});
