import _ from 'underscore';

import { allEnumValues, KioskPeripheralType, KioskPeripheralTypeHelper } from '@biteinc/enums';

app.KioskPeripheralsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} kiosk-peripherals-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue: () => {
        return {};
      },
      // Provide the method for determining the title of the cell.
      getTitle: (fieldGroupView) => {
        let title = null;
        const fields = fieldGroupView.getValue();
        if (_.has(fields, 'peripheralType')) {
          const collection = app.AbstractCollection.createFromTsEnum({
            tsEnum: KioskPeripheralType,
            nameGenerator: KioskPeripheralTypeHelper.name,
            values: allEnumValues(KioskPeripheralType).filter((peripheralType) => {
              return KioskPeripheralTypeHelper.isSupported(peripheralType);
            }),
          });
          const Type = collection.get(fields.peripheralType);
          if (Type) {
            title = Type.get('name');
          }
        }
        return title || '';
      },
      setSetValueHandlers: (fieldView, handler) => {
        this.listenTo(
          fieldView.fieldViewsByField.peripheralType,
          app.FieldView.Events.FieldDidChangeValue,
          handler,
        );
      },
      templateData: {
        title: options.schema.displayName || 'Peripherals',
        buttonText: 'new peripheral',
        emptyText: 'no peripherals yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
