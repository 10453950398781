import _ from 'underscore';

import { StringHelper } from '@biteinc/helpers';
import { menuItemSchema } from '@biteinc/schemas';

const { fields: GalleryItemFields } = menuItemSchema.fields.displayBlocks.fields.galleryItems;

app.MenuItemDisplayBlockGalleryItemView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const { captionText } = fieldGroupView.getValue();
        return captionText ? StringHelper.toTitleCase(captionText) : 'Image';
      },
      getElementSchemaFields: (/* element */) => {
        return GalleryItemFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new gallery image',
        emptyText: 'no gallery images yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
