import { MaitredActivityType, ModelType, ModelTypeHelper } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';

const backedUpModels = [
  ModelType.Location,
  ModelType.Menu,
  ModelType.Settings,
  ModelType.MenuStructure,
  ModelType.MenuSection,
  ModelType.MenuItem,
  ModelType.ModGroup,
  ModelType.MenuCover,
  ModelType.MenuCoverPromo,
  ModelType.RecommendationRule,
  ModelType.RecommendationVariation,
  ModelType.MenuTiming,
  ModelType.OpenHoursTimetable,
  ModelType.OpenHoursOverride,
  ModelType.Badge,
  ModelType.DictionaryWord,
  ModelType.TaxProfile,
  ModelType.Vendor,
];

app.MaitredActivityCellView = app.CellView.extend({
  className() {
    return `${app.CellView.prototype.className.apply(this, arguments)} maitred-activity`;
  },

  render() {
    app.CellView.prototype.render.apply(this, arguments);

    if (this.model.get('snapshotKey') && app.sessionUser.isBiteSupport()) {
      const $restoreButton = this.addButtonWithClass(
        'btn-primary',
        this._restoreButtonWasClicked.bind(this),
      );
      $restoreButton.html('Restore');
    }

    if (this.model.get('type') === MaitredActivityType.Checkpoint) {
      const $restoreButton = this.addButtonWithClass(
        'btn-danger',
        this._undoButtonWasClicked.bind(this),
      );
      $restoreButton.html('Undo');
    }

    return this;
  },

  _restoreButtonWasClicked(e) {
    let promptMessage = 'Restoring a backup will replace the following: \n\n';

    backedUpModels.forEach((modelType) => {
      promptMessage += `${StringHelper.toTitleCase(ModelTypeHelper.name(modelType))}\n`;
    });

    promptMessage += '\nSynced data will be lost and restored upon the nest sync.\n';
    promptMessage += '\nPlease type in "Restore" to confirm that you want to restore this backup.';

    const confirmed = app.promptUserToTypeInConfirmation(promptMessage, 'restore');
    if (!confirmed) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Restoring', 'Restored');

    app.QueueHelper.makeRequest('POST', `${this.model.url()}/restore`, null, true, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Restored!', true);
        window.location.reload();
      }
    });

    return false;
  },

  _undoButtonWasClicked(e) {
    let promptMessage =
      'Undoing an activity will restore locations to the point before the activity was performed.\n';
    promptMessage += 'This action will affect the following: \n\n';

    backedUpModels.forEach((modelType) => {
      promptMessage += `${StringHelper.toTitleCase(ModelTypeHelper.name(modelType))}\n`;
    });

    promptMessage += '\nSynced data will be lost and restored upon the nest sync.\n';
    promptMessage += '\nPlease type in "Undo" to confirm that you want to undo this activity.';

    const confirmed = app.promptUserToTypeInConfirmation(promptMessage, 'undo');
    if (!confirmed) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Undoing', 'Undone');

    app.QueueHelper.makeRequest('POST', `${this.model.url()}/undo`, null, true, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
        app.showSavedToastAndReload('Undone!', true);
        window.location.reload();
      }
    });

    return false;
  },
});
