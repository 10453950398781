export enum ApiTokenCreationOption {
  CreateNewToken = 'create-new-token',
  AddToExistingToken = 'add-to-existing-token',
}

export function apiTokenCreationOptionName(option: ApiTokenCreationOption): string {
  switch (option) {
    case ApiTokenCreationOption.AddToExistingToken:
      return 'Add this org to an existing token';
    case ApiTokenCreationOption.CreateNewToken:
      return 'Create a brand new token';
  }
}
