import Backbone from 'backbone';

import {
  CheckinType,
  FulfillmentMethodHelper,
  KioskPreviewMode,
  OrderChannel,
} from '@biteinc/enums';

import { BrowserHelper } from '../helpers/browser_helper';
import { template } from '../template';

const makeKioskPreviewUrl = ({ embedId, previewMode, forcedMenuStructureId }) => {
  const params = new URLSearchParams({
    previewMode,
    ...(forcedMenuStructureId ? { forcedMenuStructureId } : {}),
  });
  return `/v2/kiosk-preview/${embedId}?${params.toString()}`;
};

app.KioskPreviewView = Backbone.View.extend({
  className: 'field-container location-settings container-fluid kiosk-preview-view',
  template: template($('#kiosk-preview-view-template').html()),

  initialize(options) {
    this._previewName = options.previewName;
    this._showFlashDisclaimer = options.showFlashDisclaimer;
    this._forcedMenuStructureId = options.forcedMenuStructureId;
    this._fulfillmentMethods = options.fulfillmentMethods || [];
    this._displayLiveUrl = options.displayLiveUrl;
  },

  setFulfillmentMethods(fulfillmentMethods) {
    this._fulfillmentMethods = fulfillmentMethods;
    this.render();
  },

  render() {
    const embedId = app.location.get('embedId');
    let flashUrl;
    let flashUrlsWithSessionToken = [];
    let checkinUrl;
    if (app.location.get('flashEnabled')) {
      flashUrl =
        app.location.getFlashUrl() +
        (this._forcedMenuStructureId
          ? `?forcedMenuStructureId=${this._forcedMenuStructureId}`
          : '');

      if (app.sessionUser.isBiteOps()) {
        if (app.externalAuthStrategy.hasBeenFetched()) {
          if (app.externalAuthStrategy.get('secretKey')) {
            flashUrlsWithSessionToken = app.location.getFlashUrlsWithSessionToken(
              app.externalAuthStrategy,
              this._forcedMenuStructureId,
            );
          }
        } else {
          this.listenToOnce(app.externalAuthStrategy, 'reset', this.render);
          app.externalAuthStrategy.fetch({ reset: true });
        }
      }

      checkinUrl =
        app.location.get('checkinType') === CheckinType.Code &&
        `${app.location.getFlashUrl()}/checkin`;
    }

    let showKioskFulfillmentWarning = true;
    let showFlashFulfillmentWarning = true;

    this._fulfillmentMethods
      .filter((fulfillmentMethod) => {
        return app.location.getDiningOption(fulfillmentMethod).isEnabled;
      })
      // Using "every" to short if one of the fulfillment methods fails the test
      .every((fulfillmentMethod) => {
        if (FulfillmentMethodHelper.isKiosk(fulfillmentMethod)) {
          showKioskFulfillmentWarning = false;
        } else {
          // If it's not a kiosk channel, then it's a flash-compatible channel
          showFlashFulfillmentWarning = false;
        }
        return showKioskFulfillmentWarning || showFlashFulfillmentWarning;
      });

    this.$el.html(
      this.template({
        kioskPreviewUrlLive:
          app.sessionUser.isAdmin() &&
          this._displayLiveUrl &&
          makeKioskPreviewUrl({
            embedId,
            previewMode: KioskPreviewMode.Live,
            forcedMenuStructureId: this._forcedMenuStructureId,
          }),
        kioskPreviewUrlDemo: makeKioskPreviewUrl({
          embedId,
          previewMode: KioskPreviewMode.Demo,
          forcedMenuStructureId: this._forcedMenuStructureId,
        }),
        flashUrl,
        flashUrlsWithSessionToken,
        checkinUrl,
        previewName: this._previewName,
        canShowKioskPreview: [
          OrderChannel.DriveThru,
          OrderChannel.Kiosk,
          OrderChannel.Linebuster,
        ].includes(app.location.get('orderChannel')),
        showKioskFulfillmentWarning,
        showFlashFulfillmentWarning,
        showFlashDisclaimer: this._showFlashDisclaimer,
      }),
    );

    this.$('#copy-flash-url').on('click', () => {
      if (BrowserHelper.copyTextToClipboard(flashUrl)) {
        app.showSavedToast('Copied Flash Link to Clipboard!');
      }
    });

    return this;
  },
});
