import _ from 'underscore';

import { template } from '../../template';

app.ImagesFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} images-field-view`;
  },
  template: template(
    '<label class="col-form-label col-md-6"></label>' +
      '<div class="col-md-12 clearfix images-container collapsible">' +
      '<div class="plus-button">+</div>' +
      '</div>',
  ),

  initialize() {
    app.FieldView.prototype.initialize.apply(this, arguments);
    this.imageViews = [];
  },

  destroy() {
    app.FieldView.prototype.destroy.apply(this, arguments);
    _.each(this.imageViews, (imageView) => {
      this.stopListening(imageView);
      imageView.destroy();
    });
  },

  _plusButtonWasClicked() {
    this._addImage({});
  },

  _imageUploadViewWasRemoved(imageView) {
    const index = this.imageViews.indexOf(imageView);
    if (index >= 0) {
      this.imageViews.splice(index, 1);
      this.images.splice(index, 1);
    }
    this._removeImageView(imageView);
    this._checkIfFieldHasChanged();
  },

  _removeImageView(imageView) {
    this.stopListening(imageView);
    imageView.destroy();
    imageView.remove();
    this.$plusButton.show();
  },

  _addImage(imageJson) {
    const image = new app.Image(imageJson, {
      uploadParams: this.uploadParams,
      schema: this.schema,
    });
    const imageUploadView = new app.ImageUploadView({
      schema: this.schema,
      image,
      isReadOnly: this.isPermanent(),
    });
    this.imageViews.push(imageUploadView);
    this.images.push(image);

    const e1 = app.ImageUploadView.Events.ImageUploadViewWasRemoved;
    this.listenTo(imageUploadView, e1, this._imageUploadViewWasRemoved);
    const e2 = app.ImageUploadView.Events.ImageUploadViewDidChange;
    this.listenTo(imageUploadView, e2, this._checkIfFieldHasChanged);

    if (this.imageViews.length === this.schema.maxCount) {
      this.$plusButton.hide();
    }

    if (this._viewHasBeenAddedToDom) {
      imageUploadView.render().$el.insertBefore(this.$plusButton);
      imageUploadView.setImage();
    }
  },

  viewWasAddedToDom() {
    if (!this.isDestroyed && !this._viewHasBeenAddedToDom) {
      _.each(this.imageViews, (imageUploadView) => {
        imageUploadView.render().$el.insertBefore(this.$plusButton);
        imageUploadView.setImage();
      });
    }
    app.FieldView.prototype.viewWasAddedToDom.apply(this, arguments);
  },

  _checkIfFieldHasChanged() {
    const value = this.getValue();
    const changed = !_.isEqual(value, this._prevValue);
    this._prevValue = value;
    if (changed) {
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    }
  },

  setValue(images, model) {
    this.model = model;
    this.initialValue = images;

    this.uploadParams = {
      imagePrefix: this.schema.imagePrefix,
      modelType: this.model.Type,
    };
    _.each(this.imageViews, this._removeImageView.bind(this));
    this.imageViews = [];
    this.images = [];
    _.each(images, this._addImage.bind(this));

    this._prevValue = this.getValue();

    // For some reason, doing this right away causes a huge margin to appear
    // at the bottom of the page (see home page tab)
    setTimeout(() => {
      if (this.isDestroyed) {
        return;
      }

      if (!this.imageViews.length && !this.isPermanent()) {
        this._addImage({});
        this.toggleCollapsedView(true);
      }
    }, 1);
  },

  getValue() {
    const images = [];
    _.each(this.imageViews, (imageView) => {
      const image = imageView.getImage();
      if (image) {
        const proxyPrefix = '/proxy?url=';
        images.push({
          ...image,
          ...(image.url.startsWith(proxyPrefix) && {
            url: image.url.substring(proxyPrefix.length),
          }),
          ...(image.origUrl.startsWith(proxyPrefix) && {
            origUrl: image.origUrl.substring(proxyPrefix.length),
          }),
          ...(image.posUrl?.startsWith(proxyPrefix) && {
            posUrl: image.posUrl.substring(proxyPrefix.length),
          }),
        });
      }
    });

    if (!images.length && !this.initialValue) {
      return null;
    }
    return images;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    const value = this.getValue() || [];
    const isValid = value.length > 0 || !this.isRequired();
    this.setState(isValid ? this.states.INIT : this.states.ERROR);
    return isValid ? { isValid } : { isValid, invalidFieldNames: [] };
  },

  render() {
    this.$el.html(this.template());
    this.$label = this.$('.col-form-label');
    this.$list = this.$('.images-container');
    this.$plusButton = this.$list.find('.plus-button');
    this.$plusButton.click(this._plusButtonWasClicked.bind(this));
    const { width, height } = this.schema.jpgSize || this.schema.size;
    this.$label.html(`${this.getDisplayName()} (${width}×${height}px):`);

    if (this.schema.tooltip) {
      this.$label.prepend(app.renderTooltip(this.schema.tooltip));
    }

    if (this.schema.collapse) {
      this.addCollapseButton();
    }

    if (this.isPermanent()) {
      this.$plusButton.hide();
    }

    return this;
  },
});
