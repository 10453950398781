import { Validators } from '@biteinc/common';
import {
  MenuCoverPromotionType,
  MenuCoverPromotionTypeHelper,
  MenuCoverType,
  ModelType,
  VitrineMenuCoverType,
  VitrineMenuCoverTypeHelper,
} from '@biteinc/enums';
import { menuCoverSchema } from '@biteinc/schemas';

app.MenuCover = app.AbstractModel.extend({
  ModelName: 'menuCover',
  Schema: menuCoverSchema,
  Type: ModelType.MenuCover,

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? `${subProperty}.${field}` : field;
    switch (fieldName) {
      case 'menuCoverPromoIds':
      case 'promotion.menuCoverPromoIds':
        return app.menuCoverPromoList;
      case 'coverType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuCoverType,
          nameGenerator: (name) => {
            switch (name) {
              case MenuCoverType.VitrineLandscape:
                return 'Vitrine Landscape';
              case MenuCoverType.VitrinePortrait:
                return 'Vitrine Portrait';
              case MenuCoverType.Gcn:
                return 'GCN';
            }
          },
        });
      case 'vitrineCoverType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: VitrineMenuCoverType,
          nameGenerator: VitrineMenuCoverTypeHelper.name,
        });
      case 'promotion.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuCoverPromotionType,
          nameGenerator: MenuCoverPromotionTypeHelper.name,
        });
    }
    return null;
  },

  validateField(field, value, callback) {
    switch (field) {
      case 'delphiTextColor': {
        const passes = new RegExp(Validators.hexColorWithOptionalOpacityRegexString, 'i').test(
          value,
        );
        if (!passes) {
          callback(
            false,
            'Please use RGB(A) format: #FF0000 for red or #FF00007F for red with 50% alpha.',
          );
          return false;
        }
        callback(true);
        break;
      }
      default:
        callback(true);
    }
    return true;
  },
});
