import Backbone from 'backbone';
import _ from 'underscore';

import { template } from '../../template';

app.CellView = Backbone.View.extend({
  template: template(
    // prettier-ignore
    '<div class="cell-row">' +
      '<span class="name"></span>' +
      '<div class="btn-group-container">' +
        '<div class="btn-group" role="group"></div>' +
      '</div>' +
    '</div>',
  ),
  className() {
    return `list-group-item cell ${this.model.id}`;
  },
  _$extraButtons: null,
  _extraButtonClickHandlers: null,

  initialize(options) {
    this.options = _.extend(
      {
        clickable: true,
        selectable: false,
        canAccessParentModel: true,
      },
      options || {},
    );
    this.listenTo(this.model, 'change', this.__modelDidChange);
    this.removeExtraButtons();

    this.getName = this.options.getName;
    this.getNameUrl = this.options.getNameUrl;
    this.getSubtitle = this.options.getSubtitle;
    this.canAccessParentModel = this.options.canAccessParentModel;
  },

  destroy() {
    this.stopListening(this.model);
    this.removeExtraButtons();
  },

  removeExtraButtons() {
    this._$extraButtons = [];
    this._extraButtonClickHandlers = [];
  },

  setSelected(selected) {
    this.$el.toggleClass('selected', selected);
  },

  flash() {
    const self = this;
    const animation = 'flash';
    const animationEnd =
      'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    this.$el.toggleClass('animated', true);
    this.$el.toggleClass(animation, true);
    this.$el.one(animationEnd, () => {
      self.$el.toggleClass('animated', false);
      self.$el.toggleClass(animation, false);
    });
  },

  __modelDidChange() {
    this.render();
  },

  _cellWasClicked(event) {
    this.trigger(app.CellView.Events.CellViewWasClicked, this, event);
  },

  addAccessoryLabel(text) {
    this.$label = $(`<span class="accessory-label">${text}</span>`);
    if (this._$cellRow) {
      this._$cellRow.append(this.$label);
    }
  },

  removeAccessoryLabel() {
    if (this._$cellRow) {
      this._$cellRow.html('');
    }
    this.$label = null;
  },

  addButtonWithClass(buttonClassName, onClickHandler) {
    const $button = $('<button type="button" class="btn btn-sm"></button>');
    $button.addClass(buttonClassName);
    this._$extraButtons.push($button);
    this._extraButtonClickHandlers.push(onClickHandler);
    if (this.$buttonGroup) {
      this.$buttonGroup.prepend($button);
      $button.click(onClickHandler);
    }
    return $button;
  },

  getTitle() {
    if (this.getNameUrl) {
      const { href, alt, innerHtml } = this.getNameUrl(this.model);
      return `<a href="${href}" alt="${alt}">${innerHtml}</a>`;
    }
    if (this.getName) {
      return this.getName(this.model);
    }
    if (this.model.displayNameHtml) {
      return this.model.displayNameHtml();
    }
    return this.model.displayName();
  },

  collapseViews(viewsToCollapse) {
    const self = this;

    this._viewsToCollapse = viewsToCollapse;

    this.addAccessoryLabel(`(${this._viewsToCollapse.length} more)`);

    this.$el.click(() => {
      _.each(self._viewsToCollapse, (view) => {
        view.$el.slideDown();
      });
      if (self.$label) {
        self.$label.fadeOut('fast', () => {
          self.$label.remove();
          self.$label = null;
        });
      }
      self._collapsedViewsRevealed = true;
    });
    // TODO: Reset the thing.
    _.each(this._viewsToCollapse, (view) => {
      view.$el.hide();
    });
  },

  attachListeners() {
    _.each(this._$extraButtons, ($button, i) => {
      $button.off().click(this._extraButtonClickHandlers[i]);
    });

    if (this.options.clickable) {
      this.$el.toggleClass('clickable', true);
      this.$el.off();
      if (!this.getNameUrl) {
        this.$el.click((e) => {
          if (!this._viewsToCollapse || this._collapsedViewsRevealed) {
            this._cellWasClicked(e);
          }
        });
      }
    }
  },

  render() {
    this.$el.html(this.template());

    this._$cellRow = this.$('.cell-row');

    if (!this.options.selectable) {
      this.$el.addClass('no-user-select');
    }

    this.$buttonGroup = this.$('.btn-group');
    _.each(this._$extraButtons, ($button) => {
      this.$buttonGroup.append($button);
    });
    this.$('.name').html(this.getTitle());
    if (this.getNameUrl) {
      this.$('.name').addClass('with-link');
    }
    const subtitle = this.getSubtitle ? this.getSubtitle(this.model) : '';
    if (subtitle) {
      this.$('.name a').append(`<span class="subtitle">${subtitle}</span>`);
    }

    if (this.$label) {
      this._$cellRow.append(this.$label);
    }

    this.attachListeners();

    return this;
  },
});

app.CellView.Events = {
  CellViewWasClicked: 'CellViewWasClicked',
  CellViewDidBecomeSelected: 'CellViewDidBecomeSelected',
  CellViewDidBecomeUnselected: 'CellViewDidBecomeUnselected',
};
