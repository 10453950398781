import { ModelType } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { spiritProducerSchema } from '@biteinc/schemas';

app.SpiritProducer = app.AbstractModel.extend({
  ModelName: 'spiritProducer',
  Schema: spiritProducerSchema,
  Type: ModelType.SpiritProducer,

  isMissingImages() {
    return !this.hasArr('logo');
  },

  displayNameHtml() {
    let title = `<span>${this.displayName()}</span>`;
    if (this.isMissingImages()) {
      title +=
        '&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" ' +
        'srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>';
    }

    return title;
  },

  getParamsForSchema(/* itemSchema, optParams */) {
    return {
      producerId: this.id,
      name: StringHelper.toTitleCase(`${this.get('prefixes')[0]} `),
    };
  },
});
