app.CustomCssVitrineBuildList = app.AbstractCollection.extend({
  model: app.Build,

  comparator(model) {
    // sort by name ascending, createdAt descending
    const [orgName, filename] = model.get('name').split('|'); // 3rd element would be checksum
    return `${orgName}/${filename} ${9999999999999 - model.get('createdAt')}`;
  },

  url() {
    return '/api/v2/builds/custom-css/vitrine';
  },
});
