import _ from 'underscore';

import { FulfillmentMethodHelper, OrderChannelHelper } from '@biteinc/enums';
import { StringHelper, Time } from '@biteinc/helpers';
import { orderThrottlingRuleSchema } from '@biteinc/schemas';

const { fields: OrderThrottlingFields } = orderThrottlingRuleSchema;

app.OrderThrottlingRuleView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
          isEnabled: true,
        };
      },
      getTitle: (fieldGroupView) => {
        const { name, isEnabled, timePeriod, weekdays, fulfillmentMethods } =
          fieldGroupView.getValue();

        let titleString = '';
        if (name) {
          titleString += `${name} `;
        }
        if (timePeriod) {
          const ruleFrom = Time.stringTimeFromDayTimeStamp(timePeriod.from);
          const ruleTo = Time.stringTimeFromDayTimeStamp(timePeriod.to);
          titleString += `${ruleFrom} - ${ruleTo} `;
        }
        if (weekdays?.length) {
          titleString += `${Time.dateStringsFromWeekdays(weekdays)} `;
        }
        if (!isEnabled) {
          titleString += '(Disabled).';
        }
        if (fulfillmentMethods?.length) {
          titleString += `<br/>Applied to: ${fulfillmentMethods
            .map((fulfillmentMethod) => {
              const diningOptionName = FulfillmentMethodHelper.name(fulfillmentMethod);
              const orderChannel = FulfillmentMethodHelper.orderChannel(fulfillmentMethod);
              return `${diningOptionName} (${OrderChannelHelper.name(orderChannel)})`;
            })
            .join(', ')}.`;
        }

        return titleString;
      },
      getElementSchemaFields: (/* element */) => {
        return OrderThrottlingFields;
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new order throttling rule',
        emptyText: 'no order throttling rules yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
