import _ from 'underscore';

app.CustomerListView = app.PaginatedListView.extend({
  initialize(opts) {
    const options = _.extend(
      {
        title: 'Customers',
        searchEnabled: true,
        isLocalSearch: false,
        cellOptions: { clickable: true },
      },
      opts || {},
    );
    app.PaginatedListView.prototype.initialize.apply(this, [options]);
  },

  getAccessoryLabelText(customer) {
    const orderCount = customer.getTotalOrderCount();
    return `${orderCount} order${orderCount === 1 ? '' : 's'}`;
  },

  __navigateToModel(customer) {
    this.scrollToItemWithId(customer.id, true);
  },

  render() {
    app.PaginatedListView.prototype.render.apply(this, arguments);

    const self = this;
    this.$searchHint.html('Search for customers by name or email.');
    this.$searchField.on('focus', () => {
      self.$searchHint.slideDown();
    });

    return this;
  },
});
