import Backbone from 'backbone';
import _ from 'underscore';

import { template } from '../../template';

app.ComplexCellView = Backbone.View.extend({
  className() {
    return `complex-cell sortable ${this.model ? this.model.id : ''}`;
  },
  template: template($('#complex-cell-view-template').html()),
  _extraButtonContainers: null,

  initialize(options) {
    const positiveFunction = () => {
      return true;
    };
    const opts = _.extend(
      {
        canRename: positiveFunction,
        canDelete: positiveFunction,
        canReorder: positiveFunction,
      },
      options || {},
    );
    this.model = opts.model;
    this.canDelete = opts.canDelete;
    this.canRename = opts.canRename;
    this.canReorder = opts.canReorder;
    this.deleteButtonName = opts.deleteButtonName;
    this.deleteConfirmation = opts.confirmation;
    this.deleteError = opts.deleteError;
    this.getName = opts.getName;
    this.getEditableName = opts.getEditableName || opts.getName;
    this.getAccessoryText = opts.getAccessoryText;
    this.getDescriptionHtml = opts.getDescriptionHtml;
    this.onClick = opts.onClick;
    this.onDelete = opts.onDelete;
    this.onRename = opts.onRename;
    this.isUnclickable = opts.isUnclickable;

    this._extraButtonContainers = [];

    if (this.model) {
      this.listenTo(this.model, 'change', this.render);
    }
  },

  states: {
    NORMAL: '0',
    EDIT: '1',
    CONFIRM: '2',
    ERROR: '3',
  },

  destroy() {
    this.stopListening();
  },

  setName(text) {
    this.$cellPanel.find('.name').html(text);
  },

  setRemoveButtonVisible(visible) {
    this.$cellPanel.find('.remove').toggle(visible);
  },

  _setState(state, error) {
    _.each(this.panelsByState, ($panel) => {
      $panel.hide();
    });
    this.panelsByState[state].show();
    this.panelsByState[state].css('display', 'block');

    switch (state) {
      case this.states.NORMAL:
        this.setName(this.getName());
        if (this.getDescriptionHtml && this.getDescriptionHtml()) {
          this.$cellPanel
            .find('.name')
            .append(`<span class="description">${this.getDescriptionHtml()}</span>`);
        }
        if (this.getAccessoryText) {
          this.$cellPanel.find('.accessory-text').text(this.getAccessoryText() || '');
        }
        break;
      case this.states.EDIT:
        this.$nameInputContainer.removeClass('has-error');
        this.$nameInput.val(this.getEditableName());
        break;
      case this.states.ERROR:
        this.$errorPanel.find('.warning').html(error);
        break;
    }
  },

  _cellWasClicked(e) {
    if (!e.originalEvent.isDropdownEvent) {
      if (this.onClick && !this.isUnclickable) {
        this.onClick();
      }
      return false;
    }
    return true;
  },

  _editButtonWasClicked() {
    this._setState(this.states.EDIT);
    return false;
  },

  _closeButtonWasClicked() {
    this._setState(this.states.NORMAL);
    return false;
  },

  _renameButtonWasClicked() {
    const name = this.$nameInput.val().toString().trim();
    if (!name.length) {
      this.$nameInputContainer.addClass('has-error');
    } else {
      this.$nameInputContainer.removeClass('has-error');
      this.onRename(
        name,
        () => {
          this._setState(this.states.NORMAL);
        },
        this.$editPanel.find('.rename'),
      );
    }
    return false;
  },

  _softRemoveButtonWasClicked() {
    const error = this.deleteError ? this.deleteError() : null;
    if (error) {
      this._setState(this.states.ERROR, error);
    } else {
      this._setState(this.states.CONFIRM);
    }
    return false;
  },

  _hardRemoveButtonWasClicked() {
    this._setState(this.states.NORMAL);
    this.onDelete(this.$editPanel.find('.remove'));
    return false;
  },

  addButton(title, dropdownItemsByClassName, clickHandler) {
    const $buttonContainer = $(
      // prettier-ignore
      '<div class="btn-group">' +
        '<button type="button" class="btn btn-secondary btn-sm" aria-label="Hide" aria-expanded="false"></button>' +
      '</div>',
    );
    const $button = $buttonContainer.find('button');
    $button.html(title);

    if (_.keys(dropdownItemsByClassName).length) {
      $button.attr('data-bs-toggle', 'dropdown');
      $button.on('click.bs.dropdown', (e) => {
        // Prevent the click from going through to the cell.
        e.originalEvent.isDropdownEvent = true;
      });

      let html = '<ul class="dropdown-menu dropdown-menu-right">';
      _.each(dropdownItemsByClassName, (text, className) => {
        html += `<li class="item ${className}"><a>${text}</a></li>`;
      });
      html += '</ul>';
      $buttonContainer.append(html);

      _.each(dropdownItemsByClassName, (text, className) => {
        $buttonContainer.find(`.item.${className}`).click(() => {
          clickHandler($button, className);
          return false;
        });
      });
    } else {
      $button.click(() => {
        clickHandler($button);
        return false;
      });
    }

    if (this.$cellPanel) {
      $buttonContainer.insertBefore(this.$cellPanel.find('.btn-group').last());
    } else {
      this._extraButtonContainers.push($buttonContainer);
    }

    return $buttonContainer;
  },

  addLabel(text, isWarning = false) {
    if (this._$label) {
      this._$label.remove();
      this._$label = null;
    }

    if (!text) {
      return null;
    }

    const $label = $(`<span class="${isWarning ? 'label warning' : 'label'}">${text}</span>`);
    if (this.$cellPanel) {
      this._insertLabel($label);
    } else {
      this._$label = $label;
    }
    return $label;
  },

  _insertLabel($label) {
    $label.insertBefore(this.$cellPanel.find('.accessory-text').last());
  },

  render() {
    this.$el.html(
      this.template({
        canDelete: this.canDelete(),
        canRename: this.canRename(),
      }),
    );

    this.$cellPanel = this.$('.cell');
    this.$editPanel = this.$('.edit-panel');
    this.$confirmPanel = this.$('.confirm-panel');
    this.$errorPanel = this.$('.error-panel');

    this.panelsByState = {};
    this.panelsByState[this.states.NORMAL] = this.$cellPanel;
    this.panelsByState[this.states.EDIT] = this.$editPanel;
    this.panelsByState[this.states.CONFIRM] = this.$confirmPanel;
    this.panelsByState[this.states.ERROR] = this.$errorPanel;

    this.$cellPanel.click(this._cellWasClicked.bind(this));
    this.$cellPanel.find('.edit').click(this._editButtonWasClicked.bind(this));
    this.$cellPanel.find('.remove').click(this._softRemoveButtonWasClicked.bind(this));
    this.$editPanel.find('.close').click(this._closeButtonWasClicked.bind(this));
    this.$editPanel.find('.rename').click(this._renameButtonWasClicked.bind(this));
    this.$editPanel.find('.remove').click(this._softRemoveButtonWasClicked.bind(this));
    this.$confirmPanel.find('.cancel').click(this._closeButtonWasClicked.bind(this));
    this.$confirmPanel.find('.remove').click(this._hardRemoveButtonWasClicked.bind(this));
    this.$errorPanel.find('.close').click(this._closeButtonWasClicked.bind(this));

    this.$nameInputContainer = this.$editPanel.find('.input-group');
    this.$nameInput = this.$nameInputContainer.find('input');

    this.$confirmPanel.find('.confirmation').text(this.deleteConfirmation);
    if (this.deleteButtonName) {
      this.$editPanel.find('.remove').text(this.deleteButtonName);
    }

    if (this.canReorder()) {
      this.$cellPanel.find('.handle').show();
      this.$cellPanel.find('.handle').css('display', 'table-cell');
    } else {
      this.$cellPanel.find('.handle').hide();
    }

    if (this.onClick && !this.isUnclickable) {
      this.$cellPanel.addClass('clickable');
    }

    if (this._$label) {
      this._insertLabel(this._$label);
      this._$label = null;
    }
    const self = this;
    _.each(this._extraButtonContainers, ($buttonContainer) => {
      $buttonContainer.insertBefore(self.$cellPanel.find('.btn-group').last());
    });
    this._extraButtonContainers = [];

    this._setState(this.states.NORMAL);

    return this;
  },
});
