import {
  DiscountLevel,
  DiscountLevelHelper,
  DiscountType,
  DiscountTypeHelper,
  ModelType,
} from '@biteinc/enums';
import { couponSchema } from '@biteinc/schemas';

app.Coupon = app.AbstractModel.extend({
  ModelName: 'coupon',
  Schema: couponSchema,
  Type: ModelType.Coupon,

  defaults() {
    const posI9n = app.location.getFullPosI9n();

    return {
      level: DiscountLevel.Check,
      posIntegrationId: posI9n?.id,
    };
  },

  displayName() {
    const name = app.AbstractModel.prototype.displayName.apply(this, arguments);
    const posI9n = app.location.getFullI9nWithId(this.get('posIntegrationId'));
    // PosI9n might not exist if the site changes point of sale integrations
    return `${name} (${posI9n?.displayName() || 'No POS Integration'})`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'level':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: DiscountLevel,
          nameGenerator: DiscountLevelHelper.name,
        });
      case 'itemIds':
        return app.menuItemList;
      case 'modifierIds':
        return app.modList;
      case 'type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: DiscountType,
          nameGenerator: DiscountTypeHelper.name,
        });
      case 'posDiscountID': {
        const posIntegrationId = this.get('posIntegrationId');
        if (!posIntegrationId) {
          return new app.AbstractCollection([]);
        }
        const posI9n = app.location.getFullI9nWithId(posIntegrationId);
        if (!posI9n) {
          return new app.AbstractCollection([]);
        }
        if (posI9n.getSyncedProp('couponDiscountById')) {
          return app.AbstractCollection.createFromEnum({
            schema: posI9n.getSyncedProp('couponDiscountById'),
          });
        }
        break;
      }
      case 'posIntegrationId': {
        const posI9ns = app.location.getFullPosI9ns();
        return new app.AbstractCollection(posI9ns);
      }
    }
    return null;
  },
});
