app.QueueHelper = {
  makeRequest(method, url, body, showAlertOnError, onJobFinishCallback, onJobCreateCallback) {
    app.QueueHelperCommon.makeRequest(
      method,
      url,
      body,
      (buildJobResponse, checkStatusCb) => {
        if (buildJobResponse.postJob.queuePath) {
          app.QueueHelperCommon.buildDoUntilAsyncFunction(buildJobResponse.postJob)(checkStatusCb);
        } else {
          // If there is no queuePath, then the job was not created.
          // There is no job status to check, so return right now.
          checkStatusCb(null, buildJobResponse);
        }
      },
      (err, getJobResponse) => {
        const error = err || getJobResponse.checkStatus.err;

        if (error) {
          if (showAlertOnError) {
            new app.AlertView().show(error.message || error);
          }
          onJobFinishCallback(error);
        } else {
          onJobFinishCallback(null, getJobResponse.checkStatus.result);
        }
      },
      onJobCreateCallback,
    );
  },
};
