import _ from 'underscore';

import { Time } from '@biteinc/helpers';

const wrapperKey = 'schedule';

app.ScheduleAccordionView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const schedule = fieldGroupView.getValue()[wrapperKey];
        if (schedule) {
          const hourRange = Time.hourRangeFromSchedule(schedule);
          const from = Time.stringTimeFromDayTimeStamp(hourRange.from);
          const to = Time.stringTimeFromDayTimeStamp(hourRange.to);
          return `${from} - ${to}`;
        }
        return '';
      },
      getElementSchemaFields: (/* element */) => {
        return {
          [wrapperKey]: {
            displayName: 'Range',
            type: 'object',
            required: true,
            subtype: 'Schedule',
            condensed: true,
          },
        };
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new schedule',
        emptyText: 'no schedules yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },

  // Wrap the value if it exists
  setValue(elements, model) {
    const wrapped = elements?.map((element) => {
      return {
        [wrapperKey]: element,
      };
    });
    app.AccordionFieldView.prototype.setValue.apply(this, [wrapped, model]);
  },

  // Unwrap the value if it exists
  getValue() {
    const elements = app.AccordionFieldView.prototype.getValue.apply(this, arguments);
    return elements?.map((element) => {
      return element[wrapperKey];
    });
  },
});
