import Backbone from 'backbone';

app.ModallableView = Backbone.View.extend({
  initialize(options) {
    this._options = options || {};
  },

  setSuperView(superView) {
    this.superView = superView;
  },

  clearState() {
    this.stopListening();
    this.superView = null;
    this._options = null;
  },

  render() {
    this.superView.footer.$el.hide();

    let title = this._options.title;
    if (!title && this._options.view && this._options.view.getTitle) {
      title = this._options.view.getTitle();
    }
    this.superView.$titleLabel.text(title);

    if (this._options.html) {
      this.$el.html(this._options.html);
    } else if (this._options.views?.length) {
      this.$el.html(
        this._options.views.map((view) => {
          if (view.setHeight && window.innerHeight && view._height === 0) {
            view.setHeight(window.innerHeight - 200);
          }
          return view.render().$el;
        }),
      );
    }

    if (this._options.primaryFooterButton) {
      const footer = this.superView.footer;
      footer.$el.show();
      footer.setDeleteButtonVisible(false);
      footer.$discardButton.hide();

      {
        const { title: buttonTitle, callback, isEnabled } = this._options.primaryFooterButton;
        footer.setSaveButtonVisible(true, buttonTitle, callback);
        footer.setSaveButtonEnabled(isEnabled);
      }

      this._options.secondaryFooterButtons?.forEach(({ title: buttonTitle, callback }) => {
        const $button = footer.addButton(buttonTitle);
        $button.on('click', () => {
          callback();
          return false;
        });
      });
    }
    return this;
  },
});
