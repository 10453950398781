export module BureauMathHelper {
  // Returns a validation object with the value being a number representation of
  // the price in cents.
  export function priceFromString(
    string: string,
    canBeNegative: boolean,
  ): { value: number } | { error: string } {
    const minPrice = canBeNegative ? -100000 : 0;
    let priceInDollars = NaN;

    const parts = string.split('.');
    if (parts.length > 2) {
      return { error: 'more than one period' };
    }

    if (parts.length > 1) {
      priceInDollars = parseFloat(string);
      if (priceInDollars >= minPrice && priceInDollars < 100000) {
        const decimalPlaces = parts[1].length;
        if (decimalPlaces > 2) {
          parts[1] = parts[1].substr(0, parts[1].length - (decimalPlaces - 2));
        } else if (decimalPlaces < 2) {
          for (let i = 0; i < 2 - decimalPlaces; i++) {
            parts[1] += '0';
          }
        }
        return { value: parseInt(parts.join(''), 10) };
      }
    }

    priceInDollars = parseInt(string, 10);
    if (priceInDollars >= minPrice && priceInDollars < 100000) {
      return { value: priceInDollars * 100 };
    }

    return { error: 'invalid price' };
  }
}
