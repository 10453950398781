import _ from 'underscore';

app.CouponDetailsView = app.BaseDetailsView.extend({
  getTitle() {
    if (this.model.isNew()) {
      const posI9n = app.location.getFullI9nWithId(this.model.get('posIntegrationId'));
      return `${app.BaseDetailsView.prototype.getTitle.apply(this)} (${posI9n.displayName()})`;
    }

    return `Edit ${this.model.displayName()}`;
  },

  getSchema() {
    const originalSchema = app.BaseDetailsView.prototype.getSchema.apply(this);
    const schema = app.JsonHelper.deepClone(originalSchema);

    const posI9n = app.location.getFullI9nWithId(this.model.get('posIntegrationId'));
    // Might not exist if the site changes point of sale integrations
    if (posI9n?.getSyncedProp('couponDiscountById')) {
      schema.fields.posDiscountID.ui = 'dropdown';
    }

    return schema;
  },

  _getCouponDiscountSyncedProp(prop) {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const posDiscountID = fieldViews.posDiscountID.getValue();
    const posI9n = app.location.getFullI9nWithId(this.model.get('posIntegrationId'));
    // Might not exist if the site changes point of sale integrations
    const couponDiscountById = posI9n?.getSyncedProp('couponDiscountById');
    if (
      couponDiscountById &&
      couponDiscountById[posDiscountID] &&
      couponDiscountById[posDiscountID].i9nData &&
      _.has(couponDiscountById[posDiscountID].i9nData, prop)
    ) {
      return couponDiscountById[posDiscountID].i9nData[prop];
    }
    return undefined;
  },

  _setValueFromCouponDiscount(prop, syncedProp) {
    // set values from couponDiscount if they are synced
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const syncedValue = this._getCouponDiscountSyncedProp(syncedProp);
    if (!_.isUndefined(syncedValue)) {
      fieldViews[prop].setValue(syncedValue, this.model);
      fieldViews[prop].$el.toggle(false);
    } else {
      fieldViews[prop].$el.toggle(true);
    }
  },

  _setSingleUseCodesValueFromCouponDiscount() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const isSingleUse = this._getCouponDiscountSyncedProp('singleUseCodes');

    if (isSingleUse) {
      // Only hide the single-use field if the POS says that the coupon is single-use
      fieldViews.singleUseCodes.setValue(isSingleUse, this.model);
      fieldViews.singleUseCodes.$el.toggle(false);
    } else {
      // If the POS says that the coupons are unlimited or does not provide this information,
      // then allow the user to make the coupon single-use through Bureau
      fieldViews.singleUseCodes.$el.toggle(true);
    }
  },

  _updateOpenDiscountFields() {
    this._setValueFromCouponDiscount('level', 'level');
    this._setValueFromCouponDiscount('type', 'type');
    this._setValueFromCouponDiscount('amount', 'amount');
    this._setSingleUseCodesValueFromCouponDiscount();
  },

  _updateIsOpenDiscountField() {
    this._setValueFromCouponDiscount('posDiscountIsOpen', 'isOpenDiscount');
    // need to fire manually
    this._updateOpenDiscountFields();
  },

  __fieldGroupDidChangeValue() {
    app.BaseDetailsView.prototype.__fieldGroupDidChangeValue.apply(this, arguments);

    this._updateIsOpenDiscountField();
    this._updateOpenDiscountFields();
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.posDiscountID,
      app.FieldView.Events.FieldDidChangeValue,
      this._updateIsOpenDiscountField,
    );
    this._updateIsOpenDiscountField();

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.posDiscountIsOpen,
      app.FieldView.Events.FieldDidChangeValue,
      this._updateOpenDiscountFields,
    );
    this._updateOpenDiscountFields();
    return this;
  },
});
