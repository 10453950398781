import { UserRight } from '@biteinc/enums';

export function nameFromAssignableUserRight(right: UserRight): string {
  switch (right) {
    case UserRight.ViewOrders:
      return 'View Orders';
    case UserRight.RefundOrders:
      return 'Refund Orders';
    case UserRight.ControlKiosk:
      return 'Control Kiosk';
    case UserRight.AllVendorAccess:
      return 'All Vendor Access';
    case UserRight.ViewMenuLibrary:
      return 'View Menu Library';
    case UserRight.Reporting:
      return 'Generate Reports';
    case UserRight.VerifyAlcoholPurchases:
      return 'Verify Alcohol Purchases';
    case UserRight.Items86:
      return '86 Menu Items';
    case UserRight.ManageMenu:
      return 'Manage Menu';
    case UserRight.ManageLowerUsers:
      return 'Create and Manage Users';
    case UserRight.ManageMenuCovers:
      return 'Manage Menu Covers';
    case UserRight.ManageDiscounts:
      return 'Manage Discounts';
    case UserRight.EditMenuAppearance:
      return 'Edit Menu Appearance';
    case UserRight.ManageChannelSettings:
      return 'Manage Channel Settings';
    case UserRight.ShowFlashClosedScreen:
      return 'Can Show Flash Closed Screen';
    case UserRight.ManageBiteLift:
      return 'Manage Bite Lift';
    case UserRight.ManageOrderThrottling:
      return 'Create and manage order throttling rules';
    case UserRight.ManageCustomers:
      return 'Manage Customers';
    case UserRight.ManageWebSettings:
      return 'Manage Web Settings';
    case UserRight.ManageCateringSettings:
      return 'Manage Catering Settings';
    case UserRight.ManageOtherUsersChangeset:
      return "Manage Other Users' Changesets";
    case UserRight.Admin:
      return 'Admin';
    case UserRight.ViewActivities:
      return 'View Activities';
    case UserRight.ManageI9nLite:
      return 'Manage Integrations (Limited)';
    case UserRight.DeleteCustomers:
      return 'Delete Customers';
    case UserRight.ManageBrandColorAndLogo:
      return 'Manage Brand Color and Logo';
    case UserRight.ManageThirdPartyPrivacyPolicy:
      return 'Manage Third Party Privacy Policy';
    case UserRight.MagicCopyLocation:
      return 'Magic Copy';
    case UserRight.CreateSitesAndChannels:
      return 'Create Sites and Channels';
    case UserRight.ManageI9n:
      return 'Manage Integrations (Full)';
    case UserRight.ViewSystemLogs:
      return 'View System Logs';
    case UserRight.ManageSupportedLanguages:
      return 'Manage Supported Languages';
    case UserRight.ManageKiosks:
      return 'Manage Kiosks';
    case UserRight.ManageCommonSettings:
      return 'Manage Common Settings';

    case UserRight.SendOrderToLivePos:
    case UserRight.KioskRight:
    case UserRight.FlashRight:
    case UserRight.ApiToken:
    case UserRight.BiteAccess:
    case UserRight.BiteSupport:
    case UserRight.BiteOps:
    case UserRight.ManageLowerBiteUsers:
    case UserRight.BiteEng:
    case UserRight.BiteAdmin:
    case UserRight.ApiTokenReporting:
    case UserRight.ApiTokenMobileApp:
    case UserRight.ApiTokenLocations:
      throw new Error(`user right should not be displayed: ${right}`);
  }
}

export function descriptionFromAssignableUserRight(right: UserRight): string {
  switch (right) {
    case UserRight.ViewOrders:
      return 'Allows the user to view and search for orders.';
    case UserRight.RefundOrders:
      return 'Allows the user to search and refund orders, in the Bite Admin Portal or on the kiosk.';
    case UserRight.ControlKiosk:
      return 'Allows the user to restart kiosk app or device (Elo or iOS), turn kiosks on or off, and change the closed screen copy.';
    case UserRight.AllVendorAccess:
      return 'Allows the user access to all vendors, regardless of if the user is granted access by the vendor itself.';
    case UserRight.ViewMenuLibrary:
      return 'Allows the user to view existing menu items, menu sections, modifiers, and modifier group.';
    case UserRight.Reporting:
      return 'Allows the user to generate sales reports for the locations they have access to.';
    case UserRight.VerifyAlcoholPurchases:
      return 'Allows the user to use their access code on kiosks to allow guests to order alcohol, if that functionality is set up.\n\nNote that this right should only be given to users who are legally allowed to check IDs and verify alcohol purchases.';
    case UserRight.Items86:
      return 'Allows the user to 86 items from the Bite Admin Portal, hiding items for the day, or indefinitely.';
    case UserRight.ManageMenu:
      return 'Allows the user to log into the Bite Admin Portal and fully control the menus at the location, force a manual POS sync, and control Day Parts.\n\nUsers with this right can edit the Menu Items, Menu Sections, Modifier Groups, Modifiers.\n\nUsers with this right can also create, delete, and edit menus and assign dining options to the menus.';
    case UserRight.ManageLowerUsers:
      return 'Allows the user to create new users.\nA user can manage (update, delete) other subordinate users.';
    case UserRight.ManageMenuCovers:
      return 'Allows the user to manage menu covers.';
    case UserRight.ManageDiscounts:
      return 'Allows the user to manage discounts.';
    case UserRight.EditMenuAppearance:
      return 'Allows the user to change the menu appearance.';
    case UserRight.ManageChannelSettings:
      return 'Allows the user to manage most settings in the Kiosk Settings, Flash Settings, Common Settings, Receipt Settings, SMS Settings, and Order Lead Times tabs';
    case UserRight.ShowFlashClosedScreen:
      return 'Allows user to toggle the closed screen for flash menus.';
    case UserRight.ManageBiteLift:
      return 'Allows users to manage bite lift.';
    case UserRight.ManageOrderThrottling:
      return 'Allows the user to change rules surrounding order throttling for a location.';
    case UserRight.ManageCustomers:
      return 'Allows the user to manage customers on the organization level.\nReset passwords and disable customers.';
    case UserRight.ManageWebSettings:
      return 'Allows the user to manage web settings on the organization level.';
    case UserRight.ManageCateringSettings:
      return 'Allows the user to manage catering settings on the organization level.';
    case UserRight.ManageOtherUsersChangeset:
      return 'Allows the user to manage changesets created by other users.';
    case UserRight.Admin:
      return 'Special Right that makes users with this role an administrator. Admins can edit and delete other users, and can manage the Roles and Location Groups in the Org.\nAdmins can also create other Admins by creating users with the Admin Role or granting the Admin Right to other Roles.';
    case UserRight.ViewActivities:
      return 'Allows user to view activities on the organization and location levels.';
    case UserRight.ManageI9nLite:
      return 'Allows user to manage basic settings on POS, Loyalty, and Twilio integrations.';
    case UserRight.DeleteCustomers:
      return 'Allows the user to delete customers on the organization level.';
    case UserRight.ManageBrandColorAndLogo:
      return "Allows the user to manage the brand's color, logo and loyalty logo.";
    case UserRight.ManageThirdPartyPrivacyPolicy:
      return 'Allows the user to manage the 3rd-party privacy policy HTML and links.';
    case UserRight.MagicCopyLocation:
      return 'Allows the user to magic copy optional data from this location to another location. This includes precision copying the details of a menu item from one location to another.';
    case UserRight.CreateSitesAndChannels:
      return 'Allows the user to create and update sites and channels';
    case UserRight.ManageI9n:
      return 'Allows the user to create, update, and delete integrations, as well as run integration tasks.';
    case UserRight.ViewSystemLogs:
      return 'Allows the user to view the system logs.';
    case UserRight.ManageSupportedLanguages:
      return 'Allows the user to manage Supported Languages under the Kiosk Settings tab.';
    case UserRight.ManageKiosks:
      return 'Allows the user to create, update, and delete kiosks, as well as run kiosk tasks.';
    case UserRight.ManageCommonSettings:
      return 'Allows the user to manage the common settings of a channel.';

    case UserRight.SendOrderToLivePos:
    case UserRight.KioskRight:
    case UserRight.FlashRight:
    case UserRight.ApiToken:
    case UserRight.BiteAccess:
    case UserRight.BiteSupport:
    case UserRight.BiteOps:
    case UserRight.ManageLowerBiteUsers:
    case UserRight.BiteEng:
    case UserRight.BiteAdmin:
    case UserRight.ApiTokenReporting:
    case UserRight.ApiTokenMobileApp:
    case UserRight.ApiTokenLocations:
      throw new Error(`user right should not be displayed: ${right}`);
  }
}
