import $ from 'jquery';
import _ from 'underscore';

app.UserList = app.AbstractCollection.extend({
  model: app.User,

  url() {
    return '/api/users';
  },

  fetch(opts) {
    const params = {};
    if (app.location) {
      params.orgId = app.org.id;
      params.locationId = app.location.id;
    } else if (app.org) {
      params.orgId = app.org.id;
    }
    const options = _.extend(
      {
        data: $.param(params),
        processData: true,
      },
      opts || {},
    );
    app.AbstractCollection.prototype.fetch.apply(this, [options]);
  },
});
