import type { Schema } from '@biteinc/common';

export const updateSimphonyPasswordsSchema: Schema.Model = {
  displayName: 'update simphony passwords',
  type: 'object',
  fields: {
    options: {
      type: 'object',
      required: true,
      fields: {
        organizationShortName: {
          type: 'keyString',
          required: true,
          displayName: 'Organization Short Name',
          tooltip: {
            text: 'The Simphony OSN',
          },
        },
        username: {
          type: 'keyString',
          required: true,
          displayName: 'Username',
          tooltip: {
            text: 'The Simphony user associated with the password being updated',
          },
        },
        currentPassword: {
          type: 'keyString',
          required: true,
          displayName: 'Current Password',
          tooltip: {
            text: 'The current Simphony password being used',
          },
        },
        newPassword: {
          type: 'keyString',
          required: true,
          displayName: 'New Password',
          tooltip: {
            text: 'The new Simphony password to be set',
          },
        },
      },
    },
  },
};
