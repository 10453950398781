app.KioskCellView = app.CellView.extend({
  initialize(/* options */) {
    app.CellView.prototype.initialize.apply(this, arguments);

    if (this.model.collection.model === app.Kiosk) {
      this._statusView = new app.KioskStatusView({
        kiosk: this.model,
        status: this.model.status,
        editKioskCallback: () => {
          this.trigger(app.CellView.Events.CellViewWasClicked, this);
        },
      });
    } else if (this.model.collection.model === app.ExpoPrinter) {
      this._statusView = new app.ExpoPrinterStatusView({ printer: this.model });
    } else {
      this._statusView = new app.KioskStatusView({ status: this.model });
    }

    this.listenTo(
      this._statusView,
      app.KioskStatusView.Events.ViewWillExpand,
      this._statusViewWillExpand,
    );
    this.listenTo(
      this._statusView,
      app.KioskStatusView.Events.ViewWillCollapse,
      this._statusViewWillCollapse,
    );
  },

  setSelected(selected) {
    this._statusView.setExpanded(selected);
  },

  _statusViewWillExpand() {
    this.trigger(app.CellView.Events.CellViewDidBecomeSelected, this);
  },

  _statusViewWillCollapse() {
    this.trigger(app.CellView.Events.CellViewDidBecomeUnselected, this);
  },

  render() {
    this.$el.html('');
    this.$el.append(this._statusView.render().$el);
    this.$el.addClass('clickable');

    return this;
  },
});
