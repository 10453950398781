import { Validators } from '@biteinc/common';
import {
  allEnumValues,
  CustomerAuthProvider,
  CustomerAuthProviderHelper,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  LocationState,
  ModelType,
  OrderChannel,
  OrgOrderChannelState,
  UrlTarget,
} from '@biteinc/enums';
import { orgSchema } from '@biteinc/schemas';

import { i9nBadgesHtml } from '../helpers/html_builder';

app.Org = app.AbstractModel.extend({
  ModelName: 'org',
  Schema: orgSchema,
  Type: ModelType.Org,

  initialize() {
    app.AbstractModel.prototype.initialize.apply(this, arguments);

    const liveI9nSchemaBySystem = {};
    const nonLiveI9nSchemaBySystem = {};
    this.locations = (this.get('locations') || []).map((locationJson) => {
      return new app.Location(locationJson);
    });

    // First add the live integrations
    this.locations.forEach((location) => {
      if (location.isLive()) {
        location.getAllI9nSchemas().forEach((i9nSchema) => {
          liveI9nSchemaBySystem[i9nSchema.system] = i9nSchema;
        });
      }
    });

    // Then add all the others
    this.locations.forEach((location) => {
      if (!location.isLive()) {
        location.getAllI9nSchemas().forEach((i9nSchema) => {
          if (!liveI9nSchemaBySystem[i9nSchema.system]) {
            nonLiveI9nSchemaBySystem[i9nSchema.system] = i9nSchema;
          }
        });
      }
    });

    this.liveI9nSchemas = Object.values(liveI9nSchemaBySystem);
    this.nonLiveI9nSchemas = Object.values(nonLiveI9nSchemaBySystem);
    this.liveLocationCount = this.locations.reduce((acc, location) => {
      return acc + (location.isLive() ? 1 : 0);
    }, 0);
  },

  canBeDestroyed() {
    return false;
  },

  url() {
    return `/api/orgs/${this.id || ''}`;
  },

  bureauUrl() {
    return `/${this.get('normalizedName')}`;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'cateringLocationIds':
      case 'webLocationIds':
        return plainTextOnly
          ? element.displayName()
          : `${element.displayNameHtml()} ${i9nBadgesHtml(element.getAllI9nSchemas())}`;
    }
    return displayName;
  },

  hasIntegrationWithSystem(system) {
    return app.locationList.some((location) => {
      return location.hasIntegrationWithSystem(system);
    });
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'appearanceId':
        return app.orgAppearanceList;
      case 'customerAuthProvider':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CustomerAuthProvider,
          nameGenerator: CustomerAuthProviderHelper.name,
        });
      case 'cateringFulfillmentMethods':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: FulfillmentMethod,
          nameGenerator: FulfillmentMethodHelper.name,
          values: allEnumValues(FulfillmentMethod).filter((fulfillmentMethod) => {
            return (
              FulfillmentMethodHelper.orderChannel(fulfillmentMethod) === OrderChannel.Catering
            );
          }),
        });
      case 'cateringLocationIds':
        return app.locationList.newLocationListWithOrderChannel(OrderChannel.Catering);
      case 'webFulfillmentMethods':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: FulfillmentMethod,
          nameGenerator: FulfillmentMethodHelper.name,
          values: allEnumValues(FulfillmentMethod).filter((fulfillmentMethod) => {
            return FulfillmentMethodHelper.orderChannel(fulfillmentMethod) === OrderChannel.Web;
          }),
        });
      case 'webLocationIds':
        return app.locationList.newLocationListWithOrderChannel(OrderChannel.Web);
      case 'customCssBuildId':
        return app.customCssGcnBuildList;
      case 'customCssVitrineBuildId':
        return app.customCssVitrineBuildList;
      case 'cateringOrderChannelState':
      case 'webOrderChannelState':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrgOrderChannelState,
          nameGenerator: (orgOrderChannelState) => {
            switch (orgOrderChannelState) {
              case OrgOrderChannelState.Draft:
                return 'Draft';
              case OrgOrderChannelState.Live:
                return 'Live';
            }
          },
          disclaimerGenerator: (orgOrderChannelState) => {
            switch (orgOrderChannelState) {
              case OrgOrderChannelState.Draft:
                return 'Only locations listed in the list below will be shown on the landing page';
              case OrgOrderChannelState.Live:
                return 'Only *live* locations will be shown on the landing page.';
              default:
                throw new Error(`unrecognized OrgOrderChannelState value: ${orgOrderChannelState}`);
            }
          },
        });
      case 'mobileAppSidebarLinks.target':
      case 'mobileAppPolicyLinks.target':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: UrlTarget,
          nameGenerator: (urlTarget) => {
            switch (urlTarget) {
              case UrlTarget.ExternalBrowser:
                return 'External browser';
              case UrlTarget.InternalBrowser:
                return 'Internal browser';
              case UrlTarget.ShareDialog:
                return 'Share dialog';
            }
          },
        });
    }
    return null;
  },

  validateField(field, value, callback) {
    switch (field) {
      case 'brandColor': {
        const passes = new RegExp(Validators.hexColorWithOptionalOpacityRegexString, 'i').test(
          value,
        );
        if (!passes) {
          callback(
            false,
            'Please use RGB(A) format: #FF0000 for red or #FF00007F for red with 50% alpha.',
          );
          return false;
        }
        callback(true);
        break;
      }
      default:
        callback(true);
    }
    return true;
  },

  getLocationCountThatMatchesQuery(query, includeOnlyLiveLocations) {
    const specialTokens = query
      .toLowerCase()
      .split(' ')
      .filter((token) => {
        return token.charAt(0) === ':';
      });
    return this.locations.filter((location) => {
      if (includeOnlyLiveLocations && location.state !== LocationState.Live) {
        return false;
      }
      return specialTokens.every((token) => {
        return location.matchesQuery(token);
      });
    }).length;
  },

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    if (this.locations.length) {
      const matchesAllTokens = this.getLocationCountThatMatchesQuery(query, false) > 0;
      if (!matchesAllTokens) {
        return false;
      }
    }

    const tokenlessQuery = query
      .split(' ')
      .filter((token) => {
        return token.charAt(0) !== ':';
      })
      .join(' ');

    return app.AbstractModel.prototype.matchesQuery.apply(this, [tokenlessQuery]);
  },

  isSprinkles() {
    return this.id === '5f340b7ea47803001ed17fd7';
  },

  removeArchive(options) {
    const url = `${this.url()}/archive`;
    app.makeRequest('DELETE', url, null, options.success, options.error);
  },
});
