import _ from 'underscore';

app.I9nCredentialsDetailsView = app.BaseDetailsView.extend({
  canShowDeleteButton() {
    return false;
  },

  getSchema() {
    const i9nSchema = this.model.getI9nSchema();
    const userProvidedFieldSchema = {
      displayName: `${i9nSchema.name} Integration`,
      fields: {},
    };
    _.each(i9nSchema.fields, (fieldSchema, field) => {
      if (fieldSchema.requiresUserInput) {
        const fieldSchemaClone = app.JsonHelper.deepClone(fieldSchema);
        userProvidedFieldSchema.fields[field] = fieldSchemaClone;
      }
    });
    return userProvidedFieldSchema;
  },

  // Make sure we are only sending userProvided fields to the right url
  _save(data, opts, $saveButton) {
    const schema = this.getSchema();
    _.each(data, (value, field) => {
      if (!schema.fields[field]) {
        delete data[field];
      }
    });
    opts.url = `${this.model.url()}/credentials`;
    opts.patch = true;
    app.BaseDetailsView.prototype._save.apply(this, [data, opts, $saveButton]);
  },
});
