import _ from 'underscore';

import { UserRight } from '@biteinc/enums';

app.ApiTokenDetailsView = app.BaseDetailsView.extend({
  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.$copyToClipboardButton) {
      const footer = this.superView.footer;
      this.$copyToClipboardButton = footer.addButton('Copy Token to Clipboard');
      this.$copyToClipboardButton.click(() => {
        navigator.clipboard.writeText(this.model.get('token'));
      });
    }

    if (!this.model.isNew()) {
      const $tokenIdDisplayGroup = $('<div class="field-group"></div>');
      $tokenIdDisplayGroup.append(
        this.valueDisplayTemplate({
          label: 'Token ID:',
          value: this.model.id,
        }),
      );
      this.$el.append($tokenIdDisplayGroup);

      if (this.model.getOrgMemberships().length > 1) {
        const otherOrgMembershipLinks = _.chain(this.model.getOrgMemberships())
          .filter(({ orgId }) => {
            if (app.org.id === orgId) {
              return false;
            }
            const membership = app.sessionUser.getMembership(orgId);
            return membership?.rights.includes(UserRight.Admin);
          })
          .sortBy(({ orgName }) => {
            return orgName.toLowerCase();
          })
          .map(({ orgName, orgNormalizedName }) => {
            return `<a href="/${orgNormalizedName}" target="_blank">${orgName}</a>`;
          })
          .value();

        const $displayGroup = $('<div class="field-group"></div>');
        $displayGroup.append(
          this.valueDisplayTemplate({
            label: 'Also belongs to:',
            value: otherOrgMembershipLinks.join(', '),
          }),
        );
        this.$el.append($displayGroup);
      }
    }

    return this;
  },
});

app.AddApiTokenToOrgDetailsView = app.ApiTokenDetailsView.extend({
  getSchema() {
    let schema = app.BaseDetailsView.prototype.getSchema.apply(this);
    if (!this.model.isNew()) {
      return schema;
    }

    schema = app.JsonHelper.deepClone(schema);
    schema.fields = {
      existingTokenId: {
        displayName: 'Existing token ID',
        type: 'mongoId',
        required: true,
        tooltip: {
          text: 'You can find the existing token ID by clicking on that token in its org',
        },
      },
      ...schema.fields,
    };

    delete schema.fields.firstName;
    delete schema.fields.token;
    delete schema.fields.disabled;

    return schema;
  },
});
