app.GuestList = app.AbstractCollection.extend({
  model: app.Guest,

  comparator(guest) {
    return guest.lastOrderAt * -1;
  },

  url() {
    return '/api/v2/guests/';
  },
});
