import Backbone from 'backbone';

app.AutoCompleteCellView = Backbone.View.extend({
  tagName: 'li',

  initialize() {
    this.listenTo(this.model, 'isSelected', this._updateSelected);
    this.$el.mouseenter(() => {
      this.model.setSelected(true);
    });
    this.$el.mouseleave(() => {
      this.model.setSelected(false);
    });
  },

  _cellWasClicked(e) {
    this.trigger('autoCompleteItemWasClicked', this.model);
    e.stopPropagation();
    return false;
  },

  _updateSelected() {
    if (this.model.isSelected) {
      this.$el.addClass('selected');
    } else {
      this.$el.removeClass('selected');
    }
  },

  render() {
    this.$el.html(`<a>${this.model.displayName()}</a>`);
    this.$('a').click(this._cellWasClicked.bind(this));

    return this;
  },
});
