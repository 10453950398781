import _ from 'underscore';

app.BrinkDiscountByCardIdView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} virtual-sub-group-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          loyaltyCardId: '',
          discountIds: [],
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        const fields = fieldGroupView.getValue();
        return fields?.loyaltyCardId;
      },
      templateData: {
        title: 'Discount - Loyalty Card Mapping',
        buttonText: 'New mapping',
        emptyText: 'No mappings yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
