import _ from 'underscore';

import { CustomerState, ModelType } from '@biteinc/enums';

app.Customer = app.AbstractModel.extend({
  ModelName: 'customer',
  Schema: {
    displayName: 'customer',
  },
  Type: ModelType.Customer,

  _isFetchingOrders: false,
  _orders: null,

  fullName() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  },

  displayName() {
    return `${this.fullName()} (${this.get('email')})`;
  },

  isDisabled() {
    return this.get('state') === CustomerState.Disabled;
  },

  getTotalOrderCount() {
    return _.reduce(
      this.get('locationStats'),
      (total, locationStat) => {
        return total + locationStat.orderCount;
      },
      0,
    );
  },

  // Define the model url here because the collection might have a url with a search query
  url() {
    return `/api/v2/customers/${this.id}`;
  },

  fetchOrders() {
    if (this._isFetchingOrders || this._orders) {
      return;
    }

    this._isFetchingOrders = true;
    app.makeRequestWithOptions({
      url: `${this.url()}/orders`,
      onSuccess: (data) => {
        this._isFetchingOrders = true;
        this._orders = _.map(data.orders, (orderJson) => {
          return new app.Order(orderJson);
        });
        this.trigger('change');
      },
      onFailure: (/* err */) => {
        this._isFetchingOrders = true;
        this.trigger('change');
      },
    });
  },

  getOrders() {
    return this._orders;
  },

  resetPassword(callback) {
    app.makeRequestWithOptions({
      method: 'POST',
      url: `${this.url()}/reset-password`,
      onSuccess: () => {
        callback();
      },
      onFailure: (err) => {
        callback(err);
      },
    });
  },

  toggleAvailability(callback) {
    this.save(
      {},
      {
        patch: true,
        method: 'PUT',
        url: `${this.url()}/${this.isDisabled() ? 'enable' : 'disable'}`,
        error: (err) => {
          callback(err);
        },
        success: () => {
          this.set('state', this.isDisabled() ? CustomerState.Enabled : CustomerState.Disabled);
          callback();
        },
      },
    );
  },
});
