import { magicCopyOptionsSchema } from './schemas/magic_copy_options_schema';

app.MagicCopyOptionsDetailsView = app.SchemaDetailsView.extend({
  initialize(options) {
    const schema = app.JsonHelper.deepClone(magicCopyOptionsSchema);
    if (options.locationHasPos) {
      schema.fields.pricesAndTaxes.isReadOnly = true;
    }

    app.SchemaDetailsView.prototype.initialize.apply(this, [
      {
        title: 'Select attributes to copy over (Step 2/2)',
        saveButtonTitles: [' ✨Run Magic Copy ✨', ' ✨Running ✨', ' ✨Done! ✨'],
        subProperty: 'options',
        schema,
        callback: options.callback,
      },
    ]);
  },

  render() {
    app.SchemaDetailsView.prototype.render.apply(this);
    const $deselectAllButton = this.superView.footer.addButton(
      'Deselect All',
      'deselect-all',
      true,
    );

    /** @type {Record<string, false>} */
    const deselectAllValue = {};

    Object.keys(magicCopyOptionsSchema.fields).forEach((magicCopyOption) => {
      deselectAllValue[magicCopyOption] = false;
    });

    $deselectAllButton.click(() => {
      this.fieldGroupView.setValue(deselectAllValue, this.model);
    });

    return this;
  },
});
