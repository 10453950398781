export module DatePickerHelper {
  type DatePickerOptions = {
    startDate?: Date;
    endDate?: Date | string;
  };

  export function buildDatePicker(
    $dateForm: any,
    datePickerOptions: DatePickerOptions,
    onChange: Function,
  ): void {
    $dateForm.datepicker({
      format: 'yyyy/mm/dd',
      autoclose: true,
      weekStart: 1,
      todayHighlight: true,
      disableTouchKeyboard: true,
      ...datePickerOptions,
    });
    // When the date picker closes, it triggers a global backbone modal close event, which makes
    // our modals think they are being dismissed. This is a way to prevent that.
    $dateForm.on('show.bs.modal', () => {
      return false;
    });
    $dateForm.on('hide.bs.modal', () => {
      return false;
    });
    $dateForm.on('changeDate', onChange);
    $dateForm.on('blur', onChange);
  }

  export function setDatePickerValue($dateForm: any, value: number | undefined): void {
    if (value) {
      $dateForm.datepicker('setDate', new Date(value));
      $dateForm.datepicker('update');
    } else {
      $dateForm.datepicker('update', null);
    }
  }
}
