import _ from 'underscore';

app.KioskList = app.AbstractCollection.extend({
  model: app.Kiosk,

  changeAvailability(availability, options) {
    const self = this;
    app.postRequest(
      `${this.url()}/availability`,
      {
        availability,
      },
      () => {
        _.each(self.models, (model) => {
          model.set('availability', availability);
        });
        if (options.success) {
          options.success();
        }
      },
      options.error,
    );
  },

  /**
   * @param {string} availabilityText
   * @param {KioskAvailability} availability
   */
  changeAvailabilityText(availabilityText, availability, options) {
    const self = this;
    app.postRequest(
      `${this.url()}/availabilityText`,
      {
        availabilityText,
        availability,
      },
      () => {
        _.each(self.models, (model) => {
          const updatedAvailabilityText = model.get('availabilityText');
          updatedAvailabilityText[availability] = availabilityText;
          model.set('availabilityText', updatedAvailabilityText);
        });
        if (options.success) {
          options.success();
        }
      },
      options.error,
    );
  },

  url() {
    return '/api/v2/kiosks';
  },
});
