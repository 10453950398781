import { GatewayHelper } from '@biteinc/enums';
import { MathHelper, PaymentHelper, StringHelper } from '@biteinc/helpers';

import { BureauMathHelper } from '../../helpers/bureau_math_helper';
import { ModelHelper } from '../../helpers/model_helper';
import { template } from '../../template';

app.TransactionCellView = app.CellView.extend({
  buttonTemplate: template(
    // prettier-ignore
    '<div style="margin-top: 10px;">' +
      '<button type="button" class="btn btn-secondary btn-sm" style="width: 100%;"><@= title @></button>' +
    '</div>',
  ),

  getTitle() {
    const html = $('<div></div>');

    const transaction = this.model;
    if (transaction.isRefund()) {
      html.append('<span>Refund Transaction: </span>');
      if (transaction.get('refunderUsername')) {
        html.append(`<span>Requested by ${transaction.get('refunderUsername')}</span>`);
      }
    }
    const url = PaymentHelper.urlOnGateway(transaction.attributes);
    if (url) {
      const linkText = `See on ${GatewayHelper.name(transaction.get('gateway'))}`;
      html.append($(`<a target="_blank" href="${url}">${linkText}</a>`));
      html.append($('<br />'));
    }

    html.append(
      new app.JsonView({
        jsonValue: transaction.attributes,
        altText: 'transaction',
        preCollapsedFields: ModelHelper.getPreCollapsedJsonFieldsForModelType(transaction.Type),
        numberEnumLookupMap: ModelHelper.getNumberEnumLookupMapForModelType(transaction.Type),
      }).render().$el,
    );

    return html;
  },

  _requestRefund(transaction, order, $button) {
    let refundAmount = transaction.getRefundableAmount();
    const unrefundedAmount = order.getUnrefundedAmountForTransaction(transaction);
    if (PaymentHelper.gatewaySupportsPartialRefunds(transaction.get('gateway'))) {
      const unrefundedAmountStr = MathHelper.displayPrice(unrefundedAmount);
      const refundAmountStr = window.prompt(
        `Please enter the amount to refund (up to $${unrefundedAmountStr})`,
        unrefundedAmountStr,
      );
      if (!refundAmountStr) {
        return;
      }
      const validation = BureauMathHelper.priceFromString(refundAmountStr.trim());
      if (validation.error) {
        alert(validation.error);
        return;
      }

      refundAmount = validation.value;
    } else {
      const refundAmountStr = MathHelper.displayPrice(refundAmount);
      const question = `Are you sure you want to refund this transaction of $${refundAmountStr}?`;
      if (!window.confirm(question)) {
        return;
      }
    }

    const reqId = $button.initLoadingButton(null, 'Requesting', 'Requested');
    const clientId = StringHelper.randomHexString(24);
    order.requestRefund(transaction.id, refundAmount, unrefundedAmount, clientId, (err) => {
      if (err) {
        $button.loadingDidFinishWithError(reqId);
      } else {
        $button.loadingDidFinishSuccessfully(reqId);
      }
    });
  },

  render() {
    app.CellView.prototype.render.apply(this, arguments);

    const transaction = this.model;
    const { order } = this.options;
    if (
      !this._$commitButtonContainer &&
      transaction.canBeCommitted() &&
      !order.hasArr('transactionIds')
    ) {
      this._$commitButtonContainer = $(
        this.buttonTemplate({
          title: 'Commit Transaction',
        }),
      );
      this.$label.append(this._$commitButtonContainer);
      const $rmButton = this._$commitButtonContainer.find('button');
      $rmButton.toggleClass('btn-danger', true).toggleClass('btn-secondary', false);
      this._$commitButtonContainer.click(() => {
        const confirmation =
          'Are you sure you want to mark this transaction as committed and associate it with this order?' +
          '\nThis action is not reversible.';
        if (window.confirm(confirmation)) {
          const reqId = $rmButton.initLoadingButton(null, 'Committing', 'Committed');
          order.commitTransaction(transaction, (err) => {
            if (err) {
              $rmButton.loadingDidFinishWithError(reqId);
            } else {
              $rmButton.loadingDidFinishSuccessfully(reqId);
            }
          });
        }
      });
    } else if (this._$commitButtonContainer) {
      this._$commitButtonContainer.remove();
    }

    if (!this._$noteButtonContainer && app.sessionUser.isBiteSupport()) {
      this._$noteButtonContainer = $(
        this.buttonTemplate({
          title: `Add a Note ${app.HtmlHelper.biteRightIcon}`,
        }),
      );
      this.$label.append(this._$noteButtonContainer);
      const $noteButton = this._$noteButtonContainer.find('button');
      this._$noteButtonContainer.click(() => {
        const note = window.prompt('Please enter a note').trim();
        if (note.length) {
          const reqId = $noteButton.initLoadingButton(null, 'Adding', 'Added');
          transaction.save(
            {
              text: note,
            },
            {
              patch: true,
              url: `${transaction.url()}/note`,
              error() {
                $noteButton.loadingDidFinishWithError(reqId);
              },
              success() {
                $noteButton.loadingDidFinishSuccessfully(reqId);
              },
            },
          );
        }
      });
    }

    if (!this._$syncGatewayStateButtonContainer && app.sessionUser.isBiteEng()) {
      this._$syncGatewayStateButtonContainer = $(
        this.buttonTemplate({
          title: `Sync Gateway State ⚙`,
        }),
      );
      this.$label.append(this._$syncGatewayStateButtonContainer);
      const $button = this._$syncGatewayStateButtonContainer.find('button');
      this._$syncGatewayStateButtonContainer.click(() => {
        const reqId = $button.initLoadingButton(null, 'Requesting', 'Requested');
        order.syncTransactionStateWithGateway(transaction.id, (err) => {
          if (err) {
            $button.loadingDidFinishWithError(reqId);
          } else {
            $button.loadingDidFinishSuccessfully(reqId);
          }
        });

        return false;
      });
    }

    if (!this._$copyToClipboardContainer) {
      this._$copyToClipboardContainer = $(
        this.buttonTemplate({
          title: 'Copy to Clipboard',
        }),
      );
      this.$label.append(this._$copyToClipboardContainer);
      this._$copyToClipboardContainer.click(() => {
        navigator.clipboard.writeText(JSON.stringify(transaction.attributes, null, 2));
      });
    }

    if (
      !this._refundOrderButtonContainer &&
      order.canRefundTransaction(transaction) &&
      app.sessionUser.canRefundOrders() &&
      !transaction.isRefund()
    ) {
      this._$_refundOrderButtonContainer = $(
        this.buttonTemplate({
          title: 'Request Refund',
        }),
      );
      this.$label.append(this._$_refundOrderButtonContainer);
      const $refundButton = this._$_refundOrderButtonContainer.find('button');
      this._$_refundOrderButtonContainer.click(() => {
        this._requestRefund(transaction, order, $refundButton);
      });
    }

    return this;
  },
});
