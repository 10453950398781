import { DayPartHelper } from '@biteinc/business-rules';
import { ModelType } from '@biteinc/enums';

import { openHoursOverrideSchema } from './open_hours_override_schema';

app.OpenHoursOverride = app.AbstractModel.extend({
  ModelName: 'openHoursOverride',
  Schema: openHoursOverrideSchema,
  Type: ModelType.OpenHoursOverride,

  initialize() {
    this.Schema.fields.date.useSiteTimezone = !!app.site;
    app.AbstractModel.prototype.initialize.apply(this, arguments);
  },

  displayName() {
    const diningOptionName = app.location.getDiningOptionName(this.get('fulfillmentMethod'));
    const overrideSummary = DayPartHelper.openHoursOverrideSummary(
      this.attributes,
      app.location.get('timezone'),
    );
    return `${this.attributes.reason}: ${overrideSummary} | ${diningOptionName}`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'fulfillmentMethod':
        return app.location.getCollectionFromExistingFulfillmentMethods();
    }
    return null;
  },

  canBeDestroyed() {
    return !app.location.syncsModelsWithType(this.Type);
  },

  fieldIsPermanent() {
    return app.location.syncsModelsWithType(this.Type);
  },
});
