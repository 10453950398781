export function convertCsvLinesToCsvData(csvLines: string[][]): string {
  const data = csvLines
    .map((line) => {
      return line
        .map((cellContent) => {
          const escaped = cellContent
            // 1. Escape any double quotes since they are considered to be escape characters in csv
            .replace(/"/g, '""')
            // 2. Escape any new lines with a carriage return symbol
            .replace(/(\r\n|\n|\r)/gm, '⏎');

          // 3. If the string contains any commas, wrap it in quotes
          if (escaped.indexOf(',') >= 0) {
            return `"${escaped}"`;
          }
          return escaped;
        })
        .join(',');
    })
    .join('\n');

  // Blobs are a way to store binary data in the browser.
  // We used to encode the data as a URI, but there were issues with characters such as #.
  const blob = new Blob([data], { type: 'text/csv' });

  return window.URL.createObjectURL(blob);
}
