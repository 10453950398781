import _ from 'underscore';

import { Time } from '@biteinc/helpers';

import { TimePickerHelper } from './time_picker_helper';

app.DayTimestampFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} day-timestamp-field-view`;
  },

  setValue(value, model) {
    this.model = model;
    this.initialValue = value;

    if (this.isPermanent()) {
      this.$timeForm.prop('disabled', true);
    }

    if (_.isNumber(value)) {
      this.$timeForm.timepicker('setTime', Time.dateWithOffsetFromDayTimestamp(value));
    }

    this._prevValue = this.getValue();
  },

  getValue() {
    const isEmpty = this._isEmpty();
    const timeDate = this.$timeForm.timepicker('getTime');
    if (timeDate) {
      return this.$timeForm.timepicker('getSecondsFromMidnight') * 1000;
    }

    if (isEmpty && !this.initialValue) {
      return this.initialValue;
    }
    return null;
  },

  isNullValid() {
    return true;
  },

  _isEmpty() {
    const fromValue = this.$timeForm.val().toString();
    // On mobile the timepicker returns `None` if nothing is selected whereas on desktop it's an
    // empty string
    return fromValue.length === 0 || fromValue === 'None';
  },

  getValidationDetails() {
    const value = this.getValue();
    const isEmpty = this._isEmpty();
    // If the user chooses 12:00 AM, then the value will be 0
    const hasValue = !!value || value === 0;
    const isValid = this.isRequired() ? hasValue && !isEmpty : hasValue !== isEmpty;
    return { value, isEmpty, isValid };
  },

  _checkIfFieldHasChanged() {
    const value = this.getValue();
    // Check for prev value to avoid triggering an update event on the very
    // first setDate.
    if (_.has(this, '_prevValue') && !_.isEqual(value, this._prevValue)) {
      this._prevValue = value;
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    }
  },

  render() {
    app.FieldView.prototype.render.apply(this, arguments);

    this.$timeForm = $('<input type="text" class="form-control time start" />');
    this.$inputContainer.prepend(this.$timeForm);

    TimePickerHelper.buildTimePicker(
      this.$timeForm,
      {
        noneOption: !this.isRequired(),
      },
      this._checkIfFieldHasChanged.bind(this),
    );

    return this;
  },
});
