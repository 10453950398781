import _ from 'underscore';

import { UserRight } from '@biteinc/enums';

app.UserDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    if (app.userRoleList && !app.userRoleList.hasBeenFetched()) {
      this.listenToOnce(app.userRoleList, 'reset', this.render);
      app.userRoleList.fetch({ reset: true });
    }
  },

  getFocusFieldName() {
    return 'username';
  },

  getSchema() {
    const schema = app.JsonHelper.deepClone(app.User.Schema());
    const user = this.model;
    if (!user.isBite() || !app.sessionUser.hasRight(UserRight.ManageLowerBiteUsers)) {
      // We only ping Bite employees
      delete schema.fields.slackId;
    }
    // hide the userRoleId field in the "your profile" screen
    if (user.isBite() && !app.sessionUser.hasRight(UserRight.ManageLowerBiteUsers)) {
      delete schema.fields.userRoleId;
    }
    if (!app.org || user.isBite()) {
      delete schema.fields.locationGroupId;
    }
    if (app.location) {
      delete schema.fields.locationGroupId;
    }
    if (!user.isNew()) {
      delete schema.fields.password;
    }
    return schema;
  },

  render() {
    const self = this;
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.$passwordButton && !this.model.isNew()) {
      const footer = this.superView.footer;
      this.$passwordButton = footer.addButton('Change Password', 'password');
      this.$passwordButton.click(() => {
        self.trigger(app.ModalView.Events.ModalViewShouldHide, self);
        const passwordModal = new app.ChangePasswordModal({
          user: self.model,
          schema: self.getSchema(),
        });
        app.modalManager.showModalWithView(passwordModal);
      });
    }

    if (app.sessionUser.isBite() && this.model.getOrgMemberships().length > 1) {
      const otherOrgMembershipLinks = _.chain(this.model.getOrgMemberships())
        .filter(({ orgId }) => {
          return orgId !== app.org.id;
        })
        .sortBy(({ orgName }) => {
          return orgName.toLowerCase();
        })
        .map(({ orgName, orgNormalizedName }) => {
          return `<a href="/${orgNormalizedName}" target="_blank">${orgName}</a>`;
        })
        .value();

      const $displayGroup = $('<div class="field-group"></div>');
      $displayGroup.append(
        this.valueDisplayTemplate({
          label: 'Also belongs to:',
          value: otherOrgMembershipLinks.join(', '),
        }),
      );
      this.$el.append($displayGroup);
    }

    return this;
  },
});
