import { KioskDeviceType, PrinterType } from '@biteinc/enums';

export function kioskIconUrlFromType(deviceType: KioskDeviceType): string | null {
  switch (deviceType) {
    case KioskDeviceType.AndroidElo15:
      return 'https://static.bureau.getbite.com/images/status-kiosk-elo-android-15@2x.png';
    case KioskDeviceType.AndroidElo22:
    case KioskDeviceType.AndroidElo22Backpack:
      return 'https://static.bureau.getbite.com/images/status-kiosk-elo-android-22-backpack@2x.png';
    case KioskDeviceType.AndroidElo22DriveThru:
      return 'https://static.bureau.getbite.com/images/status-kiosk-elo-android-22@2x.png';
    case KioskDeviceType.AndroidElo22WorkspaceOne:
    case KioskDeviceType.AndroidEloBackpackWorkspaceOne:
      return 'https://static.bureau.getbite.com/images/status-kiosk-elo-android-22-workspaceone@2x.png';
    case KioskDeviceType.AndroidElo32Backpack:
      return 'https://static.bureau.getbite.com/images/status-kiosk-elo-android-32-backpack-frame@2x.png';
    case KioskDeviceType.AndroidHPEngagePro19_5:
    case KioskDeviceType.AndroidHPEngagePro24:
      return null;
    case KioskDeviceType.AndroidSamsungGalaxyTabASeries:
    case KioskDeviceType.AndroidSamsungGalaxyTabActiveProSeries:
    case KioskDeviceType.AndroidSamsungGalaxyTabSSeries:
      return 'https://static.bureau.getbite.com/images/status-kiosk-samsung-android@2x.png';
    case KioskDeviceType.AndroidLenovoK10:
    case KioskDeviceType.AndroidLenovoM90n:
      return 'https://static.bureau.getbite.com/images/status-kiosk-lenovo-android@2x.png';
    case KioskDeviceType.AndroidMicroTouch22:
      return 'https://static.bureau.getbite.com/images/status-kiosk-micro-touch-android-22@2x.png';
    case KioskDeviceType.AndroidPyramidFlex215:
      return null;
    case KioskDeviceType.AndroidSunmi215:
      return 'https://static.bureau.getbite.com/images/status-kiosk-sunmi-android-215@2x.png';
    case KioskDeviceType.AndroidZebraET40:
      return 'https://static.bureau.getbite.com/images/status-kiosk-zebra-et40@2x.png';
    case KioskDeviceType.ChromeOsAcrelecK22:
    case KioskDeviceType.ChromeOsElo15:
    case KioskDeviceType.ChromeOsElo22:
    case KioskDeviceType.ChromeOsHPEngagePro19_5:
    case KioskDeviceType.ChromeOsHPEngagePro24:
    case KioskDeviceType.ChromeOsLenovoChromeBox:
    case KioskDeviceType.ChromeOsLenovoM90n:
    case KioskDeviceType.ChromeOsLG27KC3PK_C:
    case KioskDeviceType.ChromeOsPosiflexMercury:
    case KioskDeviceType.ChromeOsSamsungKM24ALenovoChromeBox:
    case KioskDeviceType.ChromeOsSamsungKM24W:
    case KioskDeviceType.ChromeOsTouchDynamicEdgeUltra22:
      // BITE-7194: Remove generic chromeos icon once we get proper chromeos device information
      // return 'https://static.bureau.getbite.com/images/status-kiosk-acrelec-chromeos@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-elo-chromeos-22@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-hp-chromeos-195@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-hp-chromeos-24@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-lg-chromeos-27@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-posiflex-chromeos@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-tizen-convert-chromeos@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-samsung-chromeos@2x.png';
      // return 'https://static.bureau.getbite.com/images/status-kiosk-elo-chromeos-15@2x.png';
      return 'https://static.bureau.getbite.com/images/status-kiosk-chrome@2x.png';
    case KioskDeviceType.iPad:
      return 'https://static.bureau.getbite.com/images/status-kiosk-ios-10@2x.png';
    case KioskDeviceType.iPadPro:
      return 'https://static.bureau.getbite.com/images/status-kiosk-ios-13@2x.png';
    case KioskDeviceType.TizenSamsungKM24A:
      return 'https://static.bureau.getbite.com/images/status-kiosk-samsung-tizen@2x.png';
  }
}

export function printerIconUrlFromType(printerType: PrinterType): string | null {
  switch (printerType) {
    case PrinterType.TM_M30:
    case PrinterType.TouchDynamicBuiltInEpsonM30:
      return 'https://static.bureau.getbite.com/images/status-printer-m30@2x.png';
    case PrinterType.TM_T88V:
    case PrinterType.TM_T88VI_IHub:
      return 'https://static.bureau.getbite.com/images/status-printer-tm88@2x.png';
    case PrinterType.TM_U220:
    case PrinterType.TM_U220I:
      return 'https://static.bureau.getbite.com/images/status-printer-u220@2x.png';
    case PrinterType.SamsungBuiltIn:
    case PrinterType.LgBuiltIn:
    case PrinterType.AcrelecBuiltIn:
    case PrinterType.HpBuiltIn:
    case PrinterType.PosiflexBuiltIn:
    case PrinterType.NoPrinter:
      return null;
  }
}
