import _ from 'underscore';

import { allEnumValues } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { spiritsSchemas } from '@biteinc/schemas';

import { template } from '../../template';

app.SpiritProducerDetailsView = app.BaseDetailsView.extend({
  template: template($('#menu-item-array-details-template').html()),

  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    const producer = this.model;
    const spirits = allEnumValues(spiritsSchemas.Category).reduce((allSpirits, category) => {
      const collection = app.spiritCollectionByCategory[category];
      return [
        ...allSpirits,
        ...collection.filter((spirit) => {
          return !producer.isNew() && spirit.get('producerId') === producer.id;
        }),
      ];
    }, []);
    this.spiritList = new app.AbstractCollection(spirits);
    this.listenTo(this.spiritList, 'add', this._renderItemList);
    this.listenTo(this.spiritList, 'change', this._renderItemList);
    this.listenTo(this.spiritList, 'remove', this._renderItemList);
  },

  _getMostCommonCategory() {
    const groups = _.countBy(this.spiritList.models, (spirit) => {
      return spirit.get('category');
    });
    let maxCount = 0;
    let mostCommonCategory = 0;
    _.each(groups, (count, category) => {
      if (count > maxCount) {
        maxCount = count;
        mostCommonCategory = category;
      }
    });
    if (!app.Spirit.Schemas[mostCommonCategory]) {
      mostCommonCategory = app.Spirit.Category.BEER;
    }
    return mostCommonCategory;
  },

  _renderItemList() {
    const itemIds = this.spiritList.map((item) => {
      return item.id;
    });

    if (this.dropdown) {
      this.dropdown.destroy();
    }
    this.dropdown = new app.Dropdown();
    const $itemPanel = this.$('.item-list-panel');
    this.dropdown.setupRows($itemPanel, itemIds, this.spiritList, {
      onClick(item) {
        const detailsView = new app.SpiritDetailsView({
          model: item,
        });
        app.modalManager.showModalWithView(detailsView);
      },
      canBeRemoved() {
        return false;
      },
    });

    this.$el.find('.card-footer').show();
    let summary = '';
    if (itemIds.length) {
      summary += `${itemIds.length} spirit${itemIds.length === 1 ? '' : 's'}`;
    } else {
      summary += 'No spirits from this producer yet.';
    }
    this.$el.find('.card-footer').html(summary);
  },

  render() {
    const self = this;
    app.BaseDetailsView.prototype.render.apply(this);

    if (!this.model.isNew()) {
      const itemCategory = this._getMostCommonCategory();
      const categoryName = app.Spirit.Schemas[itemCategory].displayName;
      this.$el.append(
        this.template({
          canAddItems: false,
          canCreateItems: true,
          itemCategory: StringHelper.toTitleCase(categoryName),
        }),
      );
      this.$('.reco-panel').remove();

      this.$el.find('button.new-item').click(() => {
        const category = self._getMostCommonCategory();
        const collection = app.spiritCollectionByCategory[category];
        let name = '';
        if (self.model.hasArr('prefixes')) {
          name = StringHelper.toTitleCase(self.model.get('prefixes')[0]);
        }
        const spirit = new app.Spirit(
          {
            category,
            name,
            producerId: self.model.id,
          },
          {
            collection,
          },
        );
        const detailsView = new app.SpiritDetailsView({
          collection,
          model: spirit,
          callback(newSpirit) {
            self.spiritList.add(newSpirit);
          },
        });
        app.modalManager.showModalWithView(detailsView);
      });

      this._renderItemList();
    }

    return this;
  },
});
