import type { Schema } from '@biteinc/common';
import { ChangesetType } from '@biteinc/enums';

import { magicCopyOptionsSchema } from '../views/detail_views/schemas/magic_copy_options_schema';

export const changesetSchema: Schema.Model = {
  displayName: 'changeset',
  type: 'object',
  fields: {
    type: {
      type: 'keyString',
      required: true,
      displayName: 'Type',
      ui: 'dropdown',
    },
    scheduledFor: {
      type: 'timestamp',
      required: true,
      displayName: 'Scheduled For',
      allowFutureDates: true,
      allowPastDates: false,
      tooltip: {
        text: 'Displayed in timezone of current location.',
      },
    },
    env: {
      type: 'keyString',
      required: true,
      isHidden: true,
      defaultValue: process.env.APP_ENV,
    },
    magicCopyParams: {
      type: 'object',
      displayName: 'Parameters',
      conditions: [
        {
          field: 'type',
          values: [ChangesetType.MagicCopy],
        },
      ],
      fields: {
        toLocationIds: {
          type: 'array',
          elementType: 'mongoId',
          required: true,
          minCount: 1,
          displayName: 'Destination Locations',
          ui: 'table',
          isReadOnly: true,
        },
        options: {
          type: 'object',
          required: true,
          displayName: 'Attributes',
          fields: magicCopyOptionsSchema.fields,
        },
      },
    },
  },
};
