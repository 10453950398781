import type { Timezone } from '@biteinc/enums';
import { Time } from '@biteinc/helpers';

export module TimeHelper {
  export const FullDateFormat = 'MMMM Do YYYY, HH:mm:ss';

  export function localMoment(timestamp?: number | Date): Time.Moment {
    return Time.moment(timestamp || new Date(), Time.guess());
  }

  export function format(
    timestamp: number | Date = Date.now(),
    outputFormat: string = 'MM/DD HH:mm',
  ): string {
    return localMoment(timestamp).format(outputFormat);
  }

  export function displayDateFromTimestamp(t: number, timezone?: Timezone): string {
    const m = Time.moment(t, timezone || Time.guess());
    let outputFormat = 'ddd MM/DD h:mma';
    // Show the year if this is from more than a year ago.
    if (m.isBefore(Time.moment(new Date(), timezone || Time.BITE_TZ).subtract(1, 'year'))) {
      outputFormat = 'ddd MM/DD/YYYY h:mma';
    }
    if (timezone && Time.guess() !== timezone) {
      outputFormat += ' z';
    }
    return m.format(outputFormat);
  }

  export function durationFromTimestamp(t: number, includeSeconds?: boolean): string {
    let duration = '';
    let d = Math.floor(t / 1000);
    const days = Math.floor(d / (3600 * 24));
    d -= days * 3600 * 24;
    if (days >= 1) {
      duration += `${days}day${days === 1 ? '' : 's'} `;
    }
    const hours = Math.floor(d / 3600);
    d -= hours * 3600;
    if (hours >= 1) {
      duration += `${hours}hr${hours > 1 ? 's' : ''} `;
    }
    const mins = includeSeconds ? Math.floor(d / 60) : Math.ceil(d / 60);
    if (mins) {
      duration += `${mins}min`;
    }
    if (includeSeconds) {
      if (duration.length) {
        duration += ' ';
      }
      duration += `${d % 60}s`;
    }
    return duration;
  }

  export function roughDurationFromTimestamp(t: number): string {
    let duration = '';
    let d = Math.floor(t / 1000);
    const days = Math.floor(d / (3600 * 24));
    d -= days * 3600 * 24;
    if (days >= 1) {
      duration += `${days}day${days === 1 ? '' : 's'} `;
    }
    const hours = Math.floor(d / 3600);
    d -= hours * 3600;
    if (hours >= 1 && days < 4) {
      duration += `${hours}hr${hours > 1 ? 's' : ''} `;
    }
    const mins = Math.floor(d / 60);
    if (hours < 2 && mins && days < 1) {
      duration += `${mins}min`;
    }
    if (mins < 10 && hours < 1 && days < 1) {
      duration += ` ${d % 60}s`;
    }
    return duration;
  }

  export function timeAgoStrFromNow(t: number): string {
    return roughDurationFromTimestamp(Date.now() - t);
  }
}
