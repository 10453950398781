import {
  FulfillmentMethod,
  FulfillmentMethodHelper,
  ModelType,
  OrderChannelHelper,
  OrderThrottlingCapDuration,
  OrderThrottlingCapDurationHelper,
  OrderThrottlingMethod,
  Timezone,
  TimezoneHelper,
  Weekday,
  WeekdayHelper,
} from '@biteinc/enums';

import { siteSchema } from './site_schema';

app.Site = app.AbstractModel.extend({
  ModelName: 'site',
  Schema: siteSchema,
  Type: ModelType.Site,

  bureauUrl() {
    return `/${this.get('orgNormalizedName')}/${this.get('normalizedName')}`;
  },

  canBeDestroyed() {
    return false;
  },

  url() {
    return `/api/v2/sites/${this.id || ''}`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'timezone':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Timezone,
          nameGenerator: (timezone) => {
            const name = TimezoneHelper.name(timezone);
            // prepend timezones likes EST/CST with a space so they are sorted to the top
            return name === timezone ? timezone : ` ${name}`;
          },
        });
      case 'orderThrottlingRules.weekdays':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Weekday,
          nameGenerator: WeekdayHelper.name,
          sort: app.AbstractCollection.SortOptions.WORK_WEEKDAYS,
        });
      case 'orderThrottlingRules.orderThrottlingMethod':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrderThrottlingMethod,
          nameGenerator: (orderThrottlingMethod) => {
            switch (orderThrottlingMethod) {
              case OrderThrottlingMethod.DollarValue:
                return 'Dollar Value';
              case OrderThrottlingMethod.OrderCount:
                return 'Order Count';
            }
          },
        });
      case 'orderThrottlingRules.orderThrottlingCapDuration':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrderThrottlingCapDuration,
          nameGenerator: OrderThrottlingCapDurationHelper.name,
        });
      case 'orderThrottlingRules.fulfillmentMethods': {
        const siteLocations = app.locationList.getLocationsWithSiteId(app.site.id);
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: FulfillmentMethod,
          nameGenerator: (fulfillmentMethod) => {
            const diningOptionName = FulfillmentMethodHelper.name(fulfillmentMethod);
            const channelName = OrderChannelHelper.name(
              FulfillmentMethodHelper.orderChannel(fulfillmentMethod),
            );
            return `${diningOptionName} (${channelName})`;
          },
          values: siteLocations.reduce((allFulfillmentMethods, location) => {
            return [...allFulfillmentMethods, ...location.getExistingFulfillmentMethods()];
          }, []),
        });
      }
    }
    return null;
  },

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    // Find our groups' names
    const locationGroups = app.HtmlHelper.getSiteLocationGroups(this, app.locationGroupList);

    // Check if any of location group's name match the query
    if (
      locationGroups.some((locationGroup) => {
        return locationGroup.matchesQuery(query);
      })
    ) {
      return true;
    }

    return app.AbstractModel.prototype.matchesQuery.apply(this, [query]);
  },
});
