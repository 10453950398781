import _ from 'underscore';

import { IntegrationSystem, TaxRateType } from '@biteinc/enums';
import { MathHelper, StringHelper } from '@biteinc/helpers';

app.TaxRatesView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} tax-rates-panel`;
  },

  initialize(options) {
    if (app.location.getAllI9nSchemas().some((schema) => schema.system === IntegrationSystem.PDI)) {
      delete options.schema.fields.posId.isHidden;
    }
    _.extend(options || {}, {
      newValue() {
        return {
          _id: StringHelper.randomHexString(24),
          name: 'New Tax Rate',
          type: TaxRateType.Percent,
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let title;
        const fields = fieldGroupView.getValue();
        if (_.has(fields, 'name')) {
          title = fields.name;
        }
        if (_.has(fields, 'value') && title) {
          title += ' (';
          if (_.has(fields, 'type') && fields.type === TaxRateType.FixedUnimplemented) {
            title += '$';
          }
          title += MathHelper.stringFromFloat5(fields.value);
          title += ')';
        }
        return title || '';
      },
      setSetValueHandlers(fieldView, handler) {
        fieldView.fieldViewsByField.name.$form.keyup(handler);
        fieldView.fieldViewsByField.value.$form.keyup(handler);
      },
      templateData: {
        title: 'Tax Rates',
        buttonText: 'new tax rate',
        emptyText: 'no tax rates yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
