import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
import {
  AlertType,
  CardSchemeId,
  CardSchemeIdHelper,
  CheckinType,
  CouponCodeTransform,
  CouponProvider,
  CouponProviderHelper,
  CustomerIdentifier,
  CustomerIdentifierHelper,
  CustomerIdentifierInputMethod,
  CustomerIdentifierInputMethodHelper,
  DefaultModifierDeselectPrintOption,
  DefaultModifierDeselectPrintOptionHelper,
  EpsonBarcodeFormat,
  EpsonBarcodeFormatHelper,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  IntegrationSystem,
  LocationMultiPaymentSupport,
  LocationMultiVendorSupport,
  LocationPaymentType,
  LocationPresetTipType,
  LocationState,
  LocationStateHelper,
  LocationTipsLevel,
  LocationTipsLevelHelper,
  ModelType,
  OrderChannel,
  OrderChannelHelper,
  PromotionThresholdType,
  QsrAutomationsMode,
  Timezone,
  TimezoneHelper,
} from '@biteinc/enums';
import { StringHelper, Time } from '@biteinc/helpers';
import { diningOptionSchema, locationSchema } from '@biteinc/schemas';

import { CryptHelper } from '../helpers/crypt_helper';

app.Location = app.AbstractModel.extend({
  ModelName: 'location',
  Schema: locationSchema,
  Type: ModelType.Location,

  canBeDestroyed() {
    return false;
  },

  nameWithChannel() {
    return `${this.get('name')} (${OrderChannelHelper.nameCombiningKioskAndFlash(
      this.get('orderChannel'),
    )})`;
  },

  debugNameWithChannel() {
    return `${this.debugName()} (${OrderChannelHelper.nameCombiningKioskAndFlash(
      this.get('orderChannel'),
    )})`;
  },

  debugName() {
    const prefix = this.get('orgName').toLowerCase();
    let locationName = this.get('name');
    if (locationName.toLowerCase().indexOf(prefix) === 0) {
      locationName = locationName.substr(prefix.length).trim();
    }
    if (locationName.length) {
      return `${this.get('orgName')} ${locationName}`;
    }
    return this.get('orgName');
  },

  isCanary() {
    return !!this.get('isCanaryLocation');
  },

  isLive() {
    return this.get('state') === LocationState.Live;
  },

  isStale() {
    return this.get('state') === LocationState.Stale;
  },

  isBiteOnly() {
    return this.get('state') === LocationState.BiteOnly;
  },

  hasCouponProvider() {
    return this.get('couponProvider') !== CouponProvider.None;
  },

  bureauUrl() {
    return `/${this.get('orgNormalizedName')}/${this.get('normalizedName')}/${this.get(
      'orderChannel',
    )}`;
  },

  getStripeEmail(vendorId) {
    if (this.get('multiPaymentSupport') === LocationMultiPaymentSupport.Enabled) {
      return `${this.get('orgNormalizedName')}-${vendorId}-${this.get(
        'shortCode',
      )}@store.biteflash.com`;
    }
    return `${this.get('orgNormalizedName')}-${this.get('shortCode')}@store.biteflash.com`;
  },

  getFlashUrl() {
    if (this.get('orgDomain')) {
      return `https://${this.get('orgDomain')}/${this.get('urlSlug')}`;
    }
    return `${app.data.FLASH_PROMO_BASE_URL}/${this.get('urlSlug')}`;
  },

  getFlashUrlsWithSessionToken(externalAuthStrategy, forcedMenuStructureId) {
    let decryptedTokensAndNames = [];
    const storedValueI9nSchema = this.getStoredValueI9nSchema();
    switch (storedValueI9nSchema && storedValueI9nSchema.system) {
      // stub for testing
      // case IntegrationSystem.Atrium:
      //   decryptedTokensAndNames = [
      //     {
      //       name: 'Atrium Test',
      //       decryptedToken: JSON.stringify({
      //         version: 1,
      //         cards: [
      //           {
      //             cardName: 'Atrium Test Card',
      //             cardNumber: '889919106'
      //           }
      //         ],
      //         validAt: Date.now()
      //       })
      //     }
      //   ];
      //   break;
      case IntegrationSystem.PinataStoredValue:
        decryptedTokensAndNames = [
          {
            name: 'legacy v0 unlimited',
            decryptedToken: `${Date.now()}-${Date.now()}v0`,
          },
          {
            name: 'legacy v0 50 cent',
            decryptedToken: `${Date.now()}-${Date.now()}v0balance${50}`,
          },
          {
            name: 'v1',
            decryptedToken: JSON.stringify({
              version: 1,
              cards: [
                {
                  cardName: '50 Cent Card',
                  cardNumber: `${Date.now()}v1balance${50}`,
                },
                {
                  cardName: '1 Dollar Card',
                  cardNumber: `${Date.now()}v1balance${100}`,
                },
                {
                  cardName: 'Unlimited',
                  cardNumber: `${Date.now()}v1`,
                },
              ],
              validAt: Date.now(),
            }),
          },
        ];
        break;
    }
    return decryptedTokensAndNames.map(({ name, decryptedToken }) => {
      const sessionToken = this._encryptToken(decryptedToken, externalAuthStrategy);
      const url = `${this.getFlashUrl()}?sessionToken=${sessionToken}${
        forcedMenuStructureId ? `&forcedMenuStructureId=${forcedMenuStructureId}` : ''
      }`;
      return {
        name,
        url,
      };
    });
  },

  _encryptToken(decryptedToken, authStrategy) {
    const encryptedToken = CryptHelper.encrypt(decryptedToken, authStrategy.get('secretKey'));
    const encodedSessionToken = encodeURIComponent(encryptedToken);
    return encodedSessionToken;
  },

  getFieldCollection(field, subProperty, _includeAllValues, keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'alertRules.frequencyByAlertType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: AlertType,
          nameGenerator: (alertType) => {
            switch (alertType) {
              case AlertType.KioskDown:
                return 'Kiosk Down or Missing';
              case AlertType.KioskTurnedOff:
                return 'Kiosk Turned Off Manually';
              case AlertType.Ordering:
                return 'Ordering';
              case AlertType.SendingOrderItem86d:
                return 'Item 86d Sending Order';
              case AlertType.GenericSendingOrder:
                return 'Generic Sending Order Error';
              case AlertType.SendingOrderTimeout:
                return 'Sending Order Timeout';
              case AlertType.UncommittedTransactions:
                return 'Uncommitted Transactions';
              case AlertType.SendingWebhookError:
                return 'Sending Webhook Error';
              case AlertType.KioskInactive:
                return 'Kiosk Inactive';
              default:
                throw new Error(`unrecognized alertType value: ${alertType}`);
            }
          },
        });
      case 'freeItemPromotions.menuItemId': {
        const possibleMenuItems = _.filter(app.menuItemList.models, (item) => {
          return item.canBePromotionItem();
        });
        return new app.MenuItemList(possibleMenuItems);
      }
      case 'freeItemPromotions.thresholdType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PromotionThresholdType,
          nameGenerator: (thresholdType) => {
            switch (thresholdType) {
              case PromotionThresholdType.CartSize:
                return 'Cart Size';
              case PromotionThresholdType.CartValue:
                return 'Cart Value';
              default:
                return 'N/A';
            }
          },
        });
      case 'freeItemPromotions.fulfillmentMethods':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: FulfillmentMethod,
          nameGenerator: (fulfillmentMethod) => {
            return this.getDiningOptionName(fulfillmentMethod);
          },
          values: app.location.getExistingFulfillmentMethods(),
        });
      case 'checkinType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CheckinType,
          nameGenerator: (checkinType) => {
            switch (checkinType) {
              case CheckinType.Off:
                return 'No check-in';
              case CheckinType.Button:
                return 'Check-in with button';
              case CheckinType.Code:
                return 'Check-in with code';
              default:
                throw new Error(`unrecognized checkinType value: ${checkinType}`);
            }
          },
        });

      case 'defaultModifierDeselectionPrintOption':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: DefaultModifierDeselectPrintOption,
          nameGenerator: DefaultModifierDeselectPrintOptionHelper.name,
        });
      case 'menuReferenceLocationId':
        return new app.LocationList(
          app.locationList.models.filter((location) => {
            return location.id !== this.id;
          }),
        );
      case 'multiVendorSupport':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationMultiVendorSupport,
          nameGenerator: (multiVendorSupport) => {
            switch (multiVendorSupport) {
              case LocationMultiVendorSupport.Disabled:
                return 'Disabled';
              case LocationMultiVendorSupport.Enabled:
                return 'Enabled';
              default:
                throw new Error(`unrecognized multiVendorSupport value: ${multiVendorSupport}`);
            }
          },
          disclaimerGenerator: (multiVendorSupport) => {
            switch (multiVendorSupport) {
              case LocationMultiVendorSupport.Disabled:
                return '';
              case LocationMultiVendorSupport.Enabled:
                return 'For situations when all POS integrations have different rev centers or point to actually different POSs. Does not allow for shared items.';
              default:
                throw new Error(`unrecognized multiVendorSupport value: ${multiVendorSupport}`);
            }
          },
        });
      case 'multiPaymentSupport':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationMultiPaymentSupport,
          nameGenerator: (multiPaymentSupport) => {
            switch (multiPaymentSupport) {
              case LocationMultiPaymentSupport.Disabled:
                return 'Disabled (All Use a Single Payment Integration)';
              case LocationMultiPaymentSupport.Enabled:
                return 'Enabled (Each Vendor uses a Single Payment Integration)';
              default:
                throw new Error(`unrecognized multiPaymentSupport value: ${multiPaymentSupport}`);
            }
          },
        });
      case 'orderChannel':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: OrderChannel,
          nameGenerator: OrderChannelHelper.name,
        });
      case 'state':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationState,
          nameGenerator: LocationStateHelper.name,
          // We can't change the state of Bite-Only locations from here; the site must have the
          // Bite-Only flag set to false
          values:
            this.get('state') === LocationState.BiteOnly
              ? [LocationState.BiteOnly]
              : [
                  LocationState.Draft,
                  LocationState.Live,
                  LocationState.Stale,
                  LocationState.LabWithLivePos,
                ],
        });
      case 'paymentType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationPaymentType,
          nameGenerator: (paymentType) => {
            switch (paymentType) {
              case LocationPaymentType.CashierOnly:
                return 'Only at a cashier';
              case LocationPaymentType.CreditCard:
                return 'Credit card';
              default:
                throw new Error(`unrecognized paymentType value: ${paymentType}`);
            }
          },
        });
      case 'orderNumberBarcodeFormat':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: EpsonBarcodeFormat,
          nameGenerator: EpsonBarcodeFormatHelper.name,
        });
      case 'orgAppearanceId':
        return app.orgAppearanceList;
      case 'timezone':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: Timezone,
          nameGenerator: (timezone) => {
            const name = TimezoneHelper.name(timezone);
            // prepend timezones likes EST/CST with a space so they are sorted to the top
            return name === timezone ? timezone : ` ${name}`;
          },
        });
      case 'tipsLevel':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationTipsLevel,
          nameGenerator: LocationTipsLevelHelper.name,
        });
      case 'defaultTip.type':
      case 'presetTips.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LocationPresetTipType,
          nameGenerator: (type) => {
            if (type === LocationPresetTipType.Fixed) {
              return 'Fixed';
            }
            if (type === LocationPresetTipType.Percentage) {
              return 'Percentage';
            }
            throw new Error(`unrecognized presetTips.type value: ${type}`);
          },
        });
      case 'couponCodeTransform': {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CouponCodeTransform,
          nameGenerator: (couponCodeTransform) => {
            return StringHelper.toTitleCase(couponCodeTransform.split('-').join(' '));
          },
        });
      }
      case 'couponProvider':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CouponProvider,
          nameGenerator: CouponProviderHelper.name,
        });
      case 'flashDiningOptions.outposts.pickupTimeSlots.timeSlots':
      case 'kioskDiningOptions.outposts.pickupTimeSlots.timeSlots':
        return app.AbstractCollection.createFromEnum({
          schema: _.map(
            Time.weekTimestampsForOneDay(keyModel.futureOrdersTimeIntervals * Time.MINUTE),
            (timestamp) => {
              return {
                _id: timestamp,
                name: Time.utc(timestamp).format('hh:mma'),
              };
            },
          ),
          sort: app.AbstractCollection.SortOptions.AS_IS,
        });
      case 'kioskDiningOptions.customerIdentifierOptions.numberOfDigits':
      case 'flashDiningOptions.customerIdentifierOptions.numberOfDigits': {
        const { min, max } =
          diningOptionSchema.fields.customerIdentifierOptions.fields.numberOfDigits;
        return app.AbstractCollection.createFromIntValues(min, max);
      }
      case 'kioskDiningOptions.customerIdentifierOptions.inputMethod':
      case 'flashDiningOptions.customerIdentifierOptions.inputMethod': {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CustomerIdentifierInputMethod,
          nameGenerator: CustomerIdentifierInputMethodHelper.name,
          values: CustomerIdentifierHelper.inputMethods(keyModel.customerIdentifier),
        });
      }
      case 'kioskDiningOptions.toGoBagItemId': {
        const possibleBagOptInItems = _.filter(app.menuItemList.models, (item) => {
          const priceOptions = item.get('priceOptions');
          const archivedAt = item.get('archivedAt');

          return priceOptions.length === 1 && !priceOptions[0].addonSetIds?.length && !archivedAt;
        });

        return new app.MenuItemList(possibleBagOptInItems);
      }
      case 'orderLeadTimeRules.menuSectionIds':
        return app.menuSectionList;
      case 'supportedCardSchemes':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: CardSchemeId,
          nameGenerator: CardSchemeIdHelper.name,
        });
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    switch (field) {
      case 'menuReferenceLocationId': {
        const location = element;
        return location.nameWithChannel();
      }
    }
    return displayName;
  },

  getListFieldElementAttributesFromModel(_field, element) {
    const attrs = { _id: element.id };
    return attrs;
  },

  fieldIsPermanent(field) {
    if (app.AbstractModel.prototype.fieldIsPermanent.apply(this, arguments)) {
      return true;
    }

    const permanentFields = ['mode', 'paymentType', 'multiVendorSupport', 'showNutritionDemo'];
    if (this.isLive() && _.contains(permanentFields, field)) {
      return true;
    }

    if ('paymentType' === field && this.getKioskPaymentI9nSchema()) {
      return true;
    }

    return false;
  },

  syncMenu(callback) {
    app.MultiQueueHelper.makeRequest(`${this.url()}/sync-menu`, null, true, callback);
  },

  deleteMenu(options) {
    const url = `${this.url()}/menu`;
    app.makeRequest('DELETE', url, null, options.success, options.error);
  },

  refreshMenuCache(options) {
    const url = `${this.url()}/refresh-menu-cache`;
    app.makeRequest('POST', url, null, options.success, options.error);
  },

  hasIntegrationWithSystem(system) {
    return _.any(this.get('integrationById'), (partialI9n) => {
      return partialI9n.system === system;
    });
  },

  getAllDiningOptions() {
    return [...this.get('flashDiningOptions'), ...this.get('kioskDiningOptions')];
  },

  getDiningOption(fulfillmentMethod) {
    return _.findWhere(this.getAllDiningOptions(), { fulfillmentMethod });
  },

  getDiningOptionName(fulfillmentMethod, showDisabledState = false) {
    const diningOption = this.getDiningOption(fulfillmentMethod);
    const diningOptionName = diningOption?.name || FulfillmentMethodHelper.name(fulfillmentMethod);

    // TODO: remove once we break up flash and kiosk
    const channelSuffix =
      this.get('orderChannel') === OrderChannel.Kiosk
        ? ` (${OrderChannelHelper.name(FulfillmentMethodHelper.orderChannel(fulfillmentMethod))})`
        : '';
    const disabledSuffix =
      showDisabledState && diningOption && !diningOption.isEnabled ? ' [Disabled]' : '';

    return `${diningOptionName}${channelSuffix}${disabledSuffix}`;
  },

  getExistingFulfillmentMethods() {
    return _.pluck(this.getAllDiningOptions(), 'fulfillmentMethod');
  },

  getCollectionFromExistingFulfillmentMethods(showDisabledState = false) {
    return app.AbstractCollection.createFromTsEnum({
      tsEnum: FulfillmentMethod,
      nameGenerator: (fulfillmentMethod) => {
        return this.getDiningOptionName(fulfillmentMethod, showDisabledState);
      },
      values: this.getExistingFulfillmentMethods(),
    });
  },

  hasPhoneNumberCustomerIdentifierAtTheBeginningOnKiosk() {
    return this.get('kioskDiningOptions').some(({ customerIdentifierOptions }) => {
      return customerIdentifierOptions.some(({ customerIdentifier, askAtBeginning }) => {
        return customerIdentifier === CustomerIdentifier.PhoneNumber && askAtBeginning;
      });
    });
  },

  hasIdentifiableKioskGuests() {
    return this.get('usesDelphi') || this.hasPhoneNumberCustomerIdentifierAtTheBeginningOnKiosk();
  },

  getAllI9nSchemas() {
    return Object.values(I9nSchemaBySystem).filter((i9nSchema) => {
      return this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getCreatableI9nSchemas() {
    const posI9nSchema = this.getPosI9nSchema();
    const loyaltyI9nSchema = this.getLoyaltyI9nSchema();
    return Object.values(I9nSchemaBySystem).filter((i9nSchema) => {
      if (i9nSchema.isDeprecated) {
        return false;
      }

      if (i9nSchema.type === 'pos') {
        if (posI9nSchema) {
          if (this.get('multiVendorSupport') === LocationMultiVendorSupport.Enabled) {
            return true; // We don't care which systems are going to be mixed and matched
          }
          return false;
        }
        return true; // it's our first pos integration
      }
      if (i9nSchema.type === 'kiosk-payment') {
        if (!OrderChannelHelper.isKiosk(this.get('orderChannel'))) {
          return false;
        }

        const paymentI9nSchema = this.getKioskPaymentI9nSchema();
        if (paymentI9nSchema) {
          if (
            i9nSchema.system === IntegrationSystem.StripeTerminal &&
            this.get('multiPaymentSupport') === LocationMultiPaymentSupport.Enabled
          ) {
            return true;
          }
          return false;
        }
        return true; // it's our first payment integration
      }
      if (i9nSchema.type === 'ecomm-payment') {
        const ecommI9nSchema = this.getEcommPaymentI9nSchema();
        if (ecommI9nSchema) {
          if (
            i9nSchema.system === IntegrationSystem.Stripe &&
            this.get('multiPaymentSupport') === LocationMultiPaymentSupport.Enabled
          ) {
            return true;
          }
          return false;
        }
        return true; // it's our first payment integration
      }

      if (i9nSchema.type === 'loyalty' && loyaltyI9nSchema) {
        return false;
      }

      if (i9nSchema.type === 'supplementary-menu-source') {
        switch (i9nSchema.system) {
          case IntegrationSystem.Sprinkles:
            return app.org.isSprinkles();
          default:
            throw new Error(`unhandled supplementary menu source: ${i9nSchema.system}`);
        }
      }

      return !this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getLoyaltyI9nSchema() {
    return Object.values(I9nSchemaBySystem).find((i9nSchema) => {
      return i9nSchema.type === 'loyalty' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  usesFulfillmentNames() {
    return app.integrationList.models.some((i9n) => {
      const i9nSchema = i9n.getI9nSchema();

      if (i9nSchema.type !== 'fulfillment') {
        return false;
      }
      if (!this.hasIntegrationWithSystem(i9nSchema.system)) {
        return false;
      }

      if (
        i9nSchema.system === IntegrationSystem.QsrAutomations &&
        i9n.get('qsrAutomationsMode') === QsrAutomationsMode.PosWebhooks
      ) {
        return false;
      }

      return true;
    });
  },

  getPosI9nSchema() {
    return Object.values(I9nSchemaBySystem).find((i9nSchema) => {
      return i9nSchema.type === 'pos' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getPosI9nSchemas() {
    return Object.values(I9nSchemaBySystem).filter((i9nSchema) => {
      return i9nSchema.type === 'pos' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getStoredValueI9nSchema() {
    return Object.values(I9nSchemaBySystem).find((i9nSchema) => {
      return i9nSchema.type === 'stored-value' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getKioskPaymentI9nSchema() {
    return Object.values(I9nSchemaBySystem).find((i9nSchema) => {
      return i9nSchema.type === 'kiosk-payment' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getEcommPaymentI9nSchema() {
    return Object.values(I9nSchemaBySystem).find((i9nSchema) => {
      return i9nSchema.type === 'ecomm-payment' && this.hasIntegrationWithSystem(i9nSchema.system);
    });
  },

  getFullPosI9n() {
    return _.first(this.getFullPosI9ns());
  },

  getFullPosI9ns() {
    return _.filter(app.integrationList.models, (i9n) => {
      return i9n.getI9nSchema().type === 'pos';
    });
  },

  getFullLoyaltyI9n() {
    return _.find(app.integrationList.models, (i9n) => {
      return i9n.getI9nSchema().type === 'loyalty';
    });
  },

  getFullI9nWithId(i9nId) {
    return _.find(app.integrationList.models, (i9n) => {
      return i9n.id === i9nId;
    });
  },

  syncsModelsWithType(modelType) {
    return this.getFullPosI9ns().some((i9n) => {
      return i9n.syncsModelsWithType(modelType);
    });
  },

  supportsCompoundMenuItems() {
    const posI9nSchema = this.getPosI9nSchema();
    if (!posI9nSchema) {
      return false;
    }
    if (!posI9nSchema.supportsCompoundMenuItems) {
      return false;
    }
    const loyaltyI9nSchema = this.getLoyaltyI9nSchema();
    if (loyaltyI9nSchema && !loyaltyI9nSchema.supportsCompoundMenuItems) {
      return false;
    }
    return true;
  },

  isSimilar(location) {
    return this.get('orgId') === location.get('orgId');
  },

  isInDifferentTimezone() {
    return Time.guess() !== this.get('timezone');
  },

  getTimezoneAbbr() {
    return Time.zone(this.get('timezone')).abbr(Date.now());
  },

  getTimezoneSearchToken() {
    return `:${TimezoneHelper.name(this.get('timezone'))}`;
  },

  usesTaxProfiles() {
    return (
      !this.getPosI9nSchemas().length ||
      this.getFullPosI9ns().some((i9n) => {
        return i9n.syncsModelsWithType(ModelType.TaxProfile);
      }) ||
      this.getPosI9nSchemas().some((posI9nSchema) => {
        return posI9nSchema.usesManualTaxProfiles;
      }) ||
      app.vendorList.isSomeVendorNonintegrated()
    );
  },

  supportsMultipleVendors() {
    return !!this.get('multiVendorSupport');
  },

  supportsPayingWithCash() {
    return _.all(this.getFullPosI9ns(), (i9n) => {
      return i9n.supportsPayingWithCash();
    });
  },

  supportsTips() {
    const posI9nsSupportTips = _.all(this.getFullPosI9ns(), (i9n) => {
      return i9n.supportsTips();
    });
    return posI9nsSupportTips && !this.hasMultiPaymentSupport();
  },

  supportsManualDiscounts() {
    const posI9nSchema = this.getPosI9nSchema();
    return posI9nSchema && posI9nSchema.supportsApplyingDiscounts;
  },

  hasMultiPaymentSupport() {
    return this.get('multiPaymentSupport') === LocationMultiPaymentSupport.Enabled;
  },

  getCalorieSuffix() {
    const hasSuffix = this.hasStr('calorieSuffix');
    const suffix = hasSuffix ? this.get('calorieSuffix') : 'Cals';
    return suffix;
  },

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    const specialTokens = query
      .toLowerCase()
      .split(' ')
      .filter((token) => {
        return token.charAt(0) === ':';
      });
    const matchesAllTokens = specialTokens.every((token) => {
      switch (token) {
        case ':canary':
          return this.isCanary();
        case ':delphi':
          return this.get('usesDelphi');
        case ':standalone':
          return !this.getPosI9nSchema();
        case `:${TimezoneHelper.name(Timezone.AmericaChicago).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.AmericaDenver).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.AmericaLosAngeles).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.AmericaNewYork).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.AmericaPhoenix).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.AmericaPuertoRico).toLowerCase()}`:
        case `:${TimezoneHelper.name(Timezone.PacificHonolulu).toLowerCase()}`:
          return this.getTimezoneSearchToken().toLowerCase() === token;
      }

      return Object.values(I9nSchemaBySystem)
        .filter((i9nSchema) => {
          return !!i9nSchema.searchTokens.find((searchToken) => `:${searchToken}` === token);
        })
        .some((i9nSchema) => {
          return this.hasIntegrationWithSystem(i9nSchema.system);
        });
    });
    if (!matchesAllTokens) {
      return false;
    }

    const tokenlessQuery = query
      .split(' ')
      .filter((token) => {
        return token.charAt(0) !== ':';
      })
      .join(' ');

    return app.AbstractModel.prototype.matchesQuery.apply(this, [tokenlessQuery]);
  },
});
