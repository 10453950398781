import _ from 'underscore';

import { Time } from '@biteinc/helpers';

const wrapperKey = 'dateRange';

// This class wraps and unwraps a date range field in an object just to make
// our UI components work.
app.DateRangeAccordionView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const dateRange = fieldGroupView.getValue()[wrapperKey];
        if (dateRange) {
          const ruleFrom = Time.moment(dateRange.from).format('MM/DD/YY');
          const ruleTo = Time.moment(dateRange.to).format('MM/DD/YY');
          return `${ruleFrom} - ${ruleTo}`;
        }
        return '';
      },
      getElementSchemaFields: (/* element */) => {
        return {
          [wrapperKey]: {
            displayName: 'Range',
            type: 'object',
            subtype: 'DateRange',
            required: true,
            condensed: true,
            allowFutureDates: true,
          },
        };
      },
      useElementAsKeyModel: true,
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new availability date range',
        emptyText: 'no specific availability date ranges yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },

  // Wrap the value if it exists
  setValue(elements, model) {
    const wrapped = elements?.map((element) => {
      return {
        [wrapperKey]: element,
      };
    });
    app.AccordionFieldView.prototype.setValue.apply(this, [wrapped, model]);
  },

  // Unwrap the value if it exists
  getValue() {
    const elements = app.AccordionFieldView.prototype.getValue.apply(this, arguments);
    return elements?.map((element) => {
      return element[wrapperKey];
    });
  },
});
