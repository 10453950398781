import { template } from '../../template';

app.VendorDetailsView = app.BaseDetailsView.extend({
  template: template($('#menu-item-array-details-template').html()),
  valueDisplayTemplate: template($('#value-display-template').html()),

  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    const vendor = this.model;
    if (!vendor.isNew()) {
      const items = app.menuItemList.filter((item) => {
        return item.get('vendorId') === vendor.id;
      });
      this.itemList = new app.MenuItemList(items);
      this.listenTo(this.itemList, 'add', this._renderItemList);
      this.listenTo(this.itemList, 'change', this._renderItemList);
      this.listenTo(this.itemList, 'remove', this._renderItemList);
    }

    if (!app.userList.hasBeenFetched()) {
      // We don't need to listen for the event since the list field view already does that
      app.userList.fetch({ reset: true });
    }
  },

  _renderItemList() {
    const itemIds = this.itemList.map((item) => {
      return item.id;
    });

    if (this.dropdown) {
      this.dropdown.destroy();
    }
    this.dropdown = new app.Dropdown();
    const $itemPanel = this.$('.item-list-panel');
    this.dropdown.setupRows($itemPanel, itemIds, this.itemList, {
      onClick(item) {
        const detailsView = new app.MenuItemDetailsView({
          model: item,
        });
        app.modalManager.showModalWithView(detailsView);
      },
      canBeRemoved() {
        return false;
      },
    });

    this.$el.find('.card-footer').show();
    let summary = '';
    if (itemIds.length) {
      summary += `${itemIds.length} menu item${itemIds.length === 1 ? '' : 's'}`;
    } else {
      summary += 'No menu items in this vendor yet.';
    }
    this.$el.find('.card-footer').html(summary);
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    if (this.itemList) {
      this.$el.append(
        this.template({
          canAddItems: false,
          canCreateItems: false,
          itemCategory: 'Menu Item',
        }),
      );
      this.$('.reco-panel').remove();

      this._renderItemList();
    }

    const vendorId = this.model.id;
    const $valueDisplay = this.valueDisplayTemplate({
      label: 'Vendor ID:',
      value: vendorId,
    });

    const $displayGroup = $('<div class="field-group"></div>');
    $displayGroup.append($valueDisplay);
    this.$el.append($displayGroup);

    return this;
  },
});
