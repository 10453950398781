app.RecommendationRuleCellView = app.CellView.extend({
  getTitle() {
    const title = app.CellView.prototype.getTitle.apply(this, arguments);

    const variationNameByRuleId = app.recommendationRuleList.getVariationNameByRuleId();

    const variationNames = variationNameByRuleId[this.model.id];

    return `${title} [${variationNames ? variationNames.join(', ') : 'unassigned'}]`;
  },
});
