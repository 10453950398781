app.UserRoleDetailsView = app.BaseDetailsView.extend({
  initialize() {
    app.BaseDetailsView.prototype.initialize.apply(this, arguments);

    this.listenTo(app.userList, 'reset', this.render);
  },

  viewWasAddedToDom() {
    app.BaseDetailsView.prototype.viewWasAddedToDom.apply(this, arguments);

    if (!app.userList.hasBeenFetched()) {
      app.userList.fetch({ reset: true });
    }
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    const usernamesWithRole = app.userList.models
      .filter((user) => {
        return user.getMembership().userRoleId === this.model.id;
      })
      .map((user) => {
        return user.displayName();
      });

    const afterFieldView = this.fieldGroupView.fieldViewsByField.name;
    $(
      this.valueDisplayTemplate({
        label: 'Users with this role:',
        value: usernamesWithRole.length ? usernamesWithRole.join(', ') : 'No one yet',
      }),
    ).insertAfter(afterFieldView.$el);

    return this;
  },
});
