import { KioskNotificationType } from '@biteinc/enums';

export function getProcessingNameFromNotificationType(
  notificationType: KioskNotificationType,
): string {
  switch (notificationType) {
    case KioskNotificationType.RestartApp:
      return 'Restarting';
    case KioskNotificationType.AppLogout:
    case KioskNotificationType.RequestLogs:
    case KioskNotificationType.RequestScreenshot:
    case KioskNotificationType.RequestStatus:
      return 'Requesting';
    case KioskNotificationType.UpdatePaymentTerminal:
    case KioskNotificationType.ConfigUpdate:
    case KioskNotificationType.MenuUpdate:
    case KioskNotificationType.AppUpdate:
    case KioskNotificationType.JawsLicenseUpdate:
      return 'Updating';
    case KioskNotificationType.EnableAsam:
      return 'Enabling';
    case KioskNotificationType.DisableAsam:
      return 'Disabling';
    case KioskNotificationType.TestPrint:
    case KioskNotificationType.RunWhitelistTest:
      return 'Sending Command';
    case KioskNotificationType.UploadFreedomPayLogs:
      return 'Uploading';
    case KioskNotificationType.KioskUpdate:
    case KioskNotificationType.PaymentTerminalStatusUpdate:
      throw new Error('This notification type cannot be triggered by a user');
  }
}
