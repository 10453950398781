export enum KeyCode {
  Tab = 9,
  Enter = 13,
  Up = 38,
  Down = 40,
  d = 68,
  h = 72,
  k = 75,
  n = 78,
}
