import { ModelHelper } from '../helpers/model_helper';

app.ModelJsonView = app.ModallableView.extend({
  initialize(options) {
    const { altText, model } = options;
    options.views = [
      new app.JsonView({
        altText,
        jsonValue: model.attributes,
        preCollapsedFields: ModelHelper.getPreCollapsedJsonFieldsForModelType(model.Type),
        numberEnumLookupMap: ModelHelper.getNumberEnumLookupMapForModelType(model.Type),
      }),
    ];
    options.primaryFooterButton = {
      title: 'Copy to Clipboard',
      isEnabled: true,
      callback() {
        navigator.clipboard.writeText(JSON.stringify(options.model.attributes, null, 2));
      },
    };

    app.ModallableView.prototype.initialize.apply(this, arguments);
  },
});
