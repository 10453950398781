import Backbone from 'backbone';

app.BaseToolsView = Backbone.View.extend({
  className: 'tools-view container-fluid',

  addToolbarButton(className, text, clickHandler) {
    const $button = $(`<button type="button" class="btn ${className}">${text}</button>`);
    $button.click(clickHandler);
    this._$toolbar.append($button);
  },

  render() {
    this.$el.html('');

    this._$toolbar = $('<div class="card panel-default toolbar"></div>');
    this.$el.append(this._$toolbar);

    return this;
  },
});
