import { ReportType } from '@biteinc/enums';

app.ReportScheduleDetailsView = app.BaseDetailsView.extend({
  getSchema() {
    const schema = app.BaseDetailsView.prototype.getSchema.apply(this);

    // Only show on the org page
    if (app.site || app.location) {
      delete schema.fields.locationGroupId;
      delete schema.fields.timezone;
    }

    // Only show on the site page
    if (!app.site || app.location) {
      delete schema.fields.orderChannels;
    }

    return schema;
  },

  _toggleLocationGroupIdsField() {
    const fieldViews = this.fieldGroupView.fieldViewsByField;
    const reportTypeView = fieldViews.type;
    const locationGroupIdView = fieldViews.locationGroupId;
    const salesByLocationGroupIdsView = fieldViews.salesByLocationGroupIds;

    if (!(locationGroupIdView && salesByLocationGroupIdsView)) {
      return;
    }

    if (
      !locationGroupIdView.value ||
      !reportTypeView ||
      reportTypeView.value !== ReportType.SalesSummaryOrg
    ) {
      salesByLocationGroupIdsView.$el.toggle(false);

      return;
    }

    const locationGroup = app.locationGroupList.get(locationGroupIdView.value);

    if (locationGroup && (locationGroup.isOrgWide() || locationGroup.isAllLive())) {
      salesByLocationGroupIdsView.$el.toggle(true);
    } else {
      salesByLocationGroupIdsView.$el.toggle(false);
    }
  },

  render() {
    app.BaseDetailsView.prototype.render.apply(this);

    this.listenTo(
      this.fieldGroupView.fieldViewsByField.locationGroupId,
      app.FieldView.Events.FieldDidChangeValue,
      this._toggleLocationGroupIdsField,
    );
    this.listenTo(
      this.fieldGroupView.fieldViewsByField.type,
      app.FieldView.Events.FieldDidChangeValue,
      this._toggleLocationGroupIdsField,
    );
    this._toggleLocationGroupIdsField();

    return this;
  },
});
