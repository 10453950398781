import { ModelType } from '@biteinc/enums';
import { externalAuthStrategySchema } from '@biteinc/schemas';

app.ExternalAuthStrategy = app.AbstractModel.extend({
  ModelName: 'externalAuthStrategy',
  Schema: externalAuthStrategySchema,
  Type: ModelType.ExternalAuthStrategy,
  _hasBeenFetched: false,

  parse(data) {
    return data.data.externalAuthStrategy;
  },

  hasBeenFetched() {
    return this._hasBeenFetched;
  },

  fetch() {
    app.AbstractModel.prototype.fetch.apply(this, [
      {
        reset: true,
        success: () => {
          this._hasBeenFetched = true;
          this.trigger('reset');
        },
      },
    ]);
  },

  sync(method, model, options) {
    if (method === 'update') {
      options.method = 'POST';
    }
    app.AbstractModel.prototype.sync.apply(this, [method, model, options]);
  },

  url() {
    return '/api/v2/external-auth-strategy';
  },
});
