import Backbone from 'backbone';
import _ from 'underscore';

import { JsonHelper } from '@biteinc/helpers';

app.AbstractModel = Backbone.Model.extend({
  idAttribute: '_id',

  initialize(attributes, options) {
    this.options = options || {};
  },

  defaults() {
    return {};
  },

  hasArr(key) {
    return (this.get(key) || '').length > 0;
  },

  hasStr(key) {
    return (this.get(key) || '').length > 0;
  },

  displayName() {
    let name = this.get('name') || this.get('posName') || this.id;
    if (this.get('internalName')) {
      name += ` (${this.get('internalName')})`;
    }
    if (this.get('posInternalName')) {
      name += ` (${this.get('posInternalName')})`;
    }
    return name;
  },

  displayNameHtml() {
    return `<span>${this.displayName()}</span>`;
  },

  canBeDestroyed() {
    if (
      this.has('locationId') &&
      app.location &&
      !this.has('archivedAt') &&
      this.has('i9nId') &&
      this.getFullVendor()?.syncsModelsWithType(this.Type)
    ) {
      return false;
    }
    return !this.isNew() && this.canBeManaged();
  },

  canBeManaged() {
    return true;
  },

  getFullVendor() {
    if (!this.has('vendorId')) {
      return null;
    }
    return app.vendorList.get(this.get('vendorId'));
  },

  // Match if at least two sub terms in the query match in toSearch.
  _matchTwoPlusWords(query, toSearch) {
    const words = query.split(' ');
    if (words.length < 2) {
      return false;
    }

    let matches = 0;
    _.each(words, (word) => {
      if (toSearch.indexOf(word) >= 0) {
        matches++;
      }
    });
    return matches > 1;
  },

  _getSearchableName(optDisplayName) {
    return optDisplayName || (this.debugName && this.debugName()) || this.displayName();
  },

  matchesQuery(query, optDisplayName, optSubtitle) {
    if (!query) {
      return true;
    }

    if (this.id === query) {
      return true;
    }

    const displayName = this._getSearchableName(optDisplayName).toLowerCase();
    const subtitle = optSubtitle?.toLowerCase();

    let matches = displayName.indexOf(query) >= 0 || subtitle?.indexOf(query) >= 0;
    if (!matches) {
      matches =
        this._matchTwoPlusWords(query, displayName) ||
        (subtitle && this._matchTwoPlusWords(query, subtitle));
    }
    if (!matches) {
      let searchFields = [];
      if (this.collection && this.collection.getSchema()) {
        searchFields = this.collection.getSchema().searchFields || [];
      }
      matches = _.any(searchFields, (field) => {
        if (this.has(field)) {
          if (_.isString(this.get(field))) {
            return this.get(field).toLowerCase().indexOf(query) >= 0;
          }
          if (_.isArray(this.get(field))) {
            return _.any(this.get(field), (element) => {
              return element.toLowerCase().indexOf(query) >= 0;
            });
          }
        }
        return false;
      });
    }

    return matches;
  },

  isMissingImages() {
    return false;
  },

  fieldIsPermanent(/* field, subProperty */) {
    return false;
  },

  fieldIsHidden(/* field, subProperty */) {
    return false;
  },

  /**
   * @returns {Record<fieldName, newValue>[]}
   */
  getUpdatedFields(data) {
    if (!this.id) {
      return [];
    }
    return JsonHelper.getDifferenceBetween(this.attributes, data)?.b || [];
  },

  /**
   * @param {string} fieldName
   * @returns {string[]}
   */
  fieldNamesThatCanBeCopiedToOtherModels() {
    return [];
  },

  getCopyableFieldTooltip(/* fieldName */) {
    return null;
  },

  getIntraLocationCopyPayload(/* fieldNames, toModelIds */) {
    throw new Error('please implement this method in the specific model');
  },

  /**
   * Some models may have specific logic to determine whether a particular field has been changed
   * and if it should be copied by default.
   * Here, we don't assume we should copy falsy values necessarily.
   */
  shouldSpecificFieldBeCopiedByDefault(fieldName) {
    if (!this.get(fieldName)) {
      return false;
    }
    return true;
  },

  getFieldModelForValue(field, value, subProperty, keyModel) {
    if (!value && value !== 0) {
      return null;
    }
    const collection = this.getFieldCollection(
      field,
      subProperty,
      true /* includeAllValues */,
      keyModel,
    );
    if (collection) {
      return collection.get(value);
    }
    return null;
  },

  getFieldHelpers(_field) {
    return null;
  },

  detailsViewClassForListField(/* field */) {
    return app.BaseDetailsView;
  },

  parse(data, options) {
    let resp = null;
    if ((options || {}).collection) {
      resp = data;
    } else if (data.data) {
      resp = data.data[this.ModelName];
    }
    if (resp && !(options || {}).patch) {
      // If this was not a patch update, then remove all the values that we
      // didn't get back
      _.each(this.attributes, (val, attr) => {
        if (!_.has(resp, attr)) {
          resp[attr] = null;
        }
      });
    }
    return resp;
  },

  save(attrs, opts) {
    const options = _.extend(
      {
        wait: true,
      },
      opts || {},
    );
    Backbone.Model.prototype.save.apply(this, [attrs, options]);
  },

  destroy(opts) {
    const options = _.extend(
      {
        wait: true,
      },
      opts || {},
    );
    Backbone.Model.prototype.destroy.apply(this, [options]);
  },

  canBePrecisionCopied() {
    return false;
  },

  hasViewableJson() {
    return !!this.Type;
  },

  hasAccessoryLabel() {
    return true;
  },
});

app.AbstractModel.Schema = function getSchema() {
  return this.prototype.Schema;
};
