import Backbone from 'backbone';
import { Modal } from 'bootstrap';
import _ from 'underscore';

import { Log } from '@biteinc/common';

app.ModalManager = Backbone.View.extend({
  modalStack: [],

  mustShowModal() {
    return $('body').width() <= 1024;
  },

  showModalWithView(view) {
    const modal = new app.ModalView({ view });
    this.$el.append(modal.render().$el);

    let scrollTop = 0;
    modal.el.addEventListener('show.bs.modal', () => {
      scrollTop = $(window).scrollTop();
    });
    modal.el.addEventListener('shown.bs.modal', () => {
      if (view.viewWasAddedToDom) {
        view.viewWasAddedToDom();
      }
      // This prevents the body from being scrolled behind the modal on mobile
      // But it causes the body to scroll up, so we mark where we were on the
      // screen and scroll back there, once the modal is hidden.
      $('body').css({ position: 'fixed' });
    });
    modal.el.addEventListener('hide.bs.modal', (e) => {
      const canBypassCheck = _.last(this.modalStack) !== modal;
      if (canBypassCheck || modal.canDismissModal()) {
        this._modalWillHide(modal);
        if (view.viewWasRemovedFromDom) {
          view.viewWasRemovedFromDom();
        }

        $('body').css({ position: 'relative' });
        window.scrollTo(0, scrollTop);
      } else {
        e.preventDefault();
      }
    });
    modal.el.addEventListener('hidden.bs.modal', () => {
      this._modalDidHide(modal);
    });

    this._showModal(modal);
    return modal;
  },

  _showModal(modal) {
    if (this.modalStack.length) {
      const curModal = _.last(this.modalStack);
      this.modalStack.push(modal);
      Modal.getOrCreateInstance(curModal.el).hide();
      // curModal.$el.modal('hide');
    } else {
      this.modalStack.push(modal);
      Modal.getOrCreateInstance(modal.el).show();
      // modal.$el.modal('show');
    }
  },

  hideModal(modal) {
    if (_.last(this.modalStack) !== modal) {
      Log.error('Error trying to hide not the top-most modal.');
      return;
    }

    Modal.getOrCreateInstance(modal.el).hide();
    // modal.$el.modal('hide');
  },

  _modalWillHide(modal) {
    if (_.last(this.modalStack) === modal) {
      modal.$body.scrollTop(0);
    }
  },

  _modalDidHide(modal) {
    Modal.getOrCreateInstance(modal.el).dispose();

    if (_.last(this.modalStack) === modal) {
      // Pop this guy off the stack
      this.modalStack.pop();
    }

    if (!_.contains(this.modalStack, modal)) {
      modal.clearState();
      modal.remove();
    }

    // Present the next one
    if (this.modalStack.length) {
      Modal.getOrCreateInstance(_.last(this.modalStack).el).show();
      // _.last(this.modalStack).$el.modal('show');
    }
  },
});
