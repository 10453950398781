import { addressSchema } from '@biteinc/schemas';

app.AddressFieldView = app.NestedObjectFieldView.extend({
  getNestedObjectDefaultValue() {
    return {
      line1: '',
      line2: '',
      crossStreet: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    };
  },

  getNestedObjectSchema() {
    return addressSchema;
  },
});
