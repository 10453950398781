import _ from 'underscore';

import { MdmProvider, MdmProviderHelper } from '@biteinc/enums';

app.MdmSettingsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} mdm-settings-accordion`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: MdmProvider,
            nameGenerator: MdmProviderHelper.name,
            values: [MdmProvider.EloView, MdmProvider.SignageOS, MdmProvider.WorkspaceOne],
          });
        },
        getNewElementFromModel: (mdmProviderModel) => {
          const mdmProvider = mdmProviderModel.id;
          return {
            mdmProvider,
          };
        },
        canShowModel: (mdmProviderModel, elements) => {
          // Filter out the mdm providers that are already entered (but maybe not saved)
          return !_.findWhere(elements, { mdmProvider: mdmProviderModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const { mdmProvider } = fieldGroupView.getValue();
        return MdmProviderHelper.name(mdmProvider);
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'New MDM',
        emptyText: 'No MDM Settings Yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
