import async from 'async';
import _ from 'underscore';

import { I9nHelper } from '@biteinc/helpers';

import { convertCsvLinesToCsvData } from '../../csv_builder';
import { i9nBadgesHtml } from '../../helpers/html_builder';
import { TimeHelper } from '../../helpers/time_helper';
import { getCsvLinesFromOrgList } from '../../org_list_csv_builder';

app.OrgListView = app.BaseListView.extend({
  title: 'All Our Orgs',

  initialize(options) {
    options.cellOptions = {
      getNameUrl(org) {
        const orgName = org.displayName();
        let orgNameHtml = orgName;
        const parts = [];
        if (org.liveLocationCount) {
          parts.push(`${org.liveLocationCount} live ${i9nBadgesHtml(org.liveI9nSchemas)}`);
          if (org.nonLiveI9nSchemas.length) {
            parts.push(`draft ${i9nBadgesHtml(org.nonLiveI9nSchemas)}`);
          }
        } else if (org.nonLiveI9nSchemas.length) {
          parts.push(i9nBadgesHtml(org.nonLiveI9nSchemas));
        }
        if (parts.length) {
          orgNameHtml += ` [${parts.join(' | ')}]`;
        }
        return {
          href: `/${org.get('normalizedName')}`,
          alt: orgName,
          innerHtml: orgNameHtml,
        };
      },
    };

    app.BaseListView.prototype.initialize.apply(this, arguments);
  },

  createNew(e) {
    this._createOrgFromButtonAndOrgId($(e.currentTarget));
  },

  getListSections() {
    const liveOrgs = [];
    const draftOrgs = [];
    const staleOrgs = [];
    this.collection.models.forEach((org) => {
      if (org.get('isLive')) {
        liveOrgs.push(org);
        return;
      }
      if (org.get('isStale')) {
        staleOrgs.push(org);
        return;
      }
      draftOrgs.push(org);
    });
    return [
      {
        header: 'Live Accounts',
        models: liveOrgs,
      },
      {
        header: 'Non-Live Accounts',
        models: draftOrgs,
      },
      {
        header: 'Stale Accounts',
        models: staleOrgs,
      },
    ];
  },

  _renderSearchHint() {
    const allLocations = this.collection.getAllLocations();
    const usedTimezoneKeywords = _.chain(allLocations)
      .map((location) => {
        return location.getTimezoneSearchToken();
      })
      .uniq()
      .sortBy()
      .value();

    const keywordsByLabel = {
      POS: I9nHelper.getSearchTokensWithTypes(['pos'], ':standalone'),
      Fulfillment: I9nHelper.getSearchTokensWithTypes(['fulfillment']),
      Payments: I9nHelper.getSearchTokensWithTypes(['ecomm-payment', 'kiosk-payment']),
      Loyalty: I9nHelper.getSearchTokensWithTypes(['loyalty']),
      'Stored Value': I9nHelper.getSearchTokensWithTypes(['stored-value', 'comp-card']),
      Features: [':Delphi'], // TODO add back ':Localization' when we can search using settings
      Timezones: usedTimezoneKeywords,
    };

    const countByKeyword = {};
    if (this.collection.hasBeenFetched()) {
      _.each(keywordsByLabel, (keywords) => {
        _.each(keywords, (keyword) => {
          const count = this.collection.filter((org) => {
            return org.locations.some((location) => {
              return location.matchesQuery(keyword);
            });
          }).length;
          countByKeyword[keyword] = count;
        });
      });
    }

    app.HtmlHelper.renderSearchHints(
      '',
      keywordsByLabel,
      ':olo :worldpay for all orgs that have at least one location with olo and WP',
      this.$searchHint,
      this.$searchField,
      undefined,
      'search',
      countByKeyword,
    );
  },

  _createOrgFromButtonAndOrgId($button) {
    const self = this;
    const name = prompt('Please enter a name');
    if (!(name || '').trim().length) {
      return;
    }
    // eslint-disable-next-line new-cap
    const org = new this.collection.model(
      {},
      {
        collection: this.collection,
      },
    );
    const orgData = { name };
    let reqId;
    if ($button) {
      reqId = $button.initLoadingButton($button.html(), 'Making', 'Made');
    }
    org.save(orgData, {
      error() {
        if ($button) {
          $button.loadingDidFinishWithError(reqId);
        }
      },
      success() {
        if ($button) {
          $button.loadingDidFinishSuccessfully(reqId);
        }
        if (!self.collection.get(org.id)) {
          self.collection.add(org);
        }
        app.showSavedToast();
      },
    });
  },

  // Reliably start the download and allows us to set the file name.
  _exportOrgList() {
    const query = this._getCurrentQuery();

    async.auto(
      {
        reportOptions: (cb) => {
          if (!query) {
            cb(null, {});
            return;
          }

          const detailsView = new app.SchemaDetailsView({
            schema: {
              type: 'object',
              fields: {
                countLiveLocationsThatMatchQuery: {
                  displayName: 'Count only live locations that match your query',
                  type: 'bool',
                  defaultValue: false,
                },
              },
            },
            title: 'Options',
            saveButtonTitles: ['Download', 'Downloading', 'Done!'],
            subProperty: 'options',
            callback: (model) => {
              cb(null, model.options || {});
            },
          });
          app.modalManager.showModalWithView(detailsView);
        },
        csvLines: [
          'reportOptions',
          (results, cb) => {
            getCsvLinesFromOrgList(
              this.collection,
              query,
              results.reportOptions.countLiveLocationsThatMatchQuery,
              cb,
            );
          },
        ],
      },
      (err, results) => {
        if (err) {
          return;
        }

        const timestamp = TimeHelper.format(Date.now(), 'YYYY-MM-DD-HH-mm');
        const aLink = document.createElement('a');
        aLink.download = `bite-org-list-${timestamp}.csv`;
        aLink.href = convertCsvLinesToCsvData(results.csvLines);
        const event = new MouseEvent('click');
        aLink.dispatchEvent(event);
      },
    );
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (!this.collection.hasBeenFetched()) {
      this.listenToOnce(this.collection, 'reset', () => {
        if (app.sessionUser.isBite()) {
          this._renderSearchHint();
        }
      });
    }

    if (app.sessionUser.isBite()) {
      this._renderSearchHint();
    }

    if (app.sessionUser.isBiteOps()) {
      const $downloadButton = $(
        // prettier-ignore
        '<button type="button" class="btn btn-sm btn-primary download">' +
          'Download as CSV' +
        '</button>',
      );
      this.$('.card-header .right-button-container').prepend($downloadButton);
      $downloadButton.click(() => {
        this._exportOrgList();
      });
    }

    return this;
  },
});
