app.SiteToolsView = app.BaseToolsView.extend({
  _deleteSiteButtonWasClicked(e) {
    if (!window.confirm('Are you sure you want to delete this site?')) {
      return;
    }

    const $button = $(e.target);
    const reqId = $button.initLoadingButton($button.html(), 'Deleting', 'Deleted');
    app.site.destroy({
      error() {
        $button.loadingDidFinishWithError(reqId);
      },
      success(data) {
        $button.loadingDidFinishSuccessfully(reqId);
        const message = data.status || 'Deleted this site!';
        app.showSavedToastAndReload(message);
        setTimeout(() => {
          window.location = app.org.bureauUrl();
        }, 1500);
      },
    });
  },

  render() {
    app.BaseToolsView.prototype.render.apply(this, arguments);

    if (app.sessionUser.isBiteOps()) {
      this.addToolbarButton(
        'btn-danger',
        'Delete This Site',
        this._deleteSiteButtonWasClicked.bind(this),
      );
    }

    return this;
  },
});
