import _ from 'underscore';

import { Time } from '@biteinc/helpers';

import { DatePickerHelper } from './date_picker_helper';
import { TimePickerHelper } from './time_picker_helper';

app.TimeRangeFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} time-range-field-view`;
  },

  setValue(value, model) {
    this.model = model;
    this.initialValue = value;

    switch (this.schema.subtype) {
      case 'HourRange':
        if (value) {
          const fromDate = Time.dateWithOffsetFromDayTimestamp(value.from);
          this.$fromForm.timepicker('setTime', fromDate);

          const toDate = Time.dateWithOffsetFromDayTimestamp(value.to);
          this.$toForm.timepicker('setTime', toDate);
        }
        break;
      case 'DateRange':
        DatePickerHelper.setDatePickerValue(this.$fromForm, value?.from);

        if (value) {
          // Subtract a day from `to` because we save in the DB the first millisecond of the day
          // after the range, but we want to display the last day of the range instead
          const timezone = app.site ? app.site.get('timezone') : Time.guess();
          const toPriorDay = Time.moment(value.to, timezone).subtract(1, 'day').valueOf();
          DatePickerHelper.setDatePickerValue(this.$toForm, toPriorDay);
        } else {
          DatePickerHelper.setDatePickerValue(this.$fromForm, undefined);
        }

        break;
      case 'Schedule':
        if (value) {
          const hourRange = Time.hourRangeFromSchedule(value);
          const fromDate = Time.dateWithOffsetFromDayTimestamp(hourRange.from);
          this.$fromForm.timepicker('setTime', fromDate);

          const toDate = Time.dateWithOffsetFromDayTimestamp(hourRange.to);
          this.$toForm.timepicker('setTime', toDate);
        }
        break;
      default:
        throw new Error(`unsupported time range type: ${this.schema.subtype}`);
    }

    if (this.isPermanent()) {
      this.$fromForm.prop('disabled', true);
      this.$toForm.prop('disabled', true);
    }

    this._prevValue = this.getValue();
  },

  getValue() {
    const isEmpty = this._isEmpty();

    switch (this.schema.subtype) {
      case 'HourRange':
      case 'Schedule':
        {
          const fromDateT = this.$fromForm.timepicker('getTime');
          const toDateT = this.$toForm.timepicker('getTime');
          if (fromDateT && toDateT) {
            const hourRange = {
              from: this.$fromForm.timepicker('getSecondsFromMidnight') * 1000,
              to: this.$toForm.timepicker('getSecondsFromMidnight') * 1000,
            };
            if ('Schedule' === this.schema.subtype) {
              return Time.scheduleFromHourRange(hourRange);
            }
            return hourRange;
          }
        }
        break;
      case 'DateRange':
        {
          const fromDateD = this.$fromForm.datepicker('getDate');
          const toDateD = this.$toForm.datepicker('getDate');
          if (fromDateD && toDateD) {
            const timezone = app.site ? app.site.get('timezone') : Time.guess();
            const dateRange = {
              from: fromDateD.getTime(),
              // Add a day to `to` so that the user chooses the last day of the range, while we save
              // in the DB the first millisecond of the day after the range
              to: Time.moment(toDateD.getTime(), timezone).add(1, 'day').valueOf(),
            };
            if (dateRange.from <= dateRange.to) {
              return dateRange;
            }
          }
        }
        break;
      default:
        throw new Error(`unsupported time range type: ${this.schema.subtype}`);
    }

    if (isEmpty && !this.initialValue) {
      return this.initialValue;
    }
    return null;
  },

  isNullValid() {
    return true;
  },

  _isEmpty() {
    const fromValue = this.$fromForm.val().toString();
    const toValue = this.$toForm.val().toString();
    if (this._shouldUseTimePicker()) {
      // On mobile the timepicker returns `None` if nothing is selected whereas on desktop it's an
      // empty string
      return (
        (fromValue.length === 0 || fromValue === 'None') &&
        (toValue.length === 0 || toValue === 'None')
      );
    }
    return fromValue.length === 0 && toValue.length === 0;
  },

  getValidationDetails() {
    const value = this.getValue();
    const isEmpty = this._isEmpty();
    const hasValue = !!value;
    const isValid = this.isRequired() ? hasValue && !isEmpty : hasValue !== isEmpty;
    return { value, isEmpty, isValid };
  },

  _checkIfFieldHasChanged() {
    const value = this.getValue();
    // Check for prev value to avoid triggering an update event on the very
    // first setDate.
    if (_.has(this, '_prevValue') && !_.isEqual(value, this._prevValue)) {
      this._prevValue = value;
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    }
  },

  _shouldUseTimePicker() {
    return this.schema.subtype !== 'DateRange';
  },

  render() {
    app.FieldView.prototype.render.apply(this, arguments);

    if (this.schema.condensed) {
      this.$el.addClass('condensed');
    }

    const shouldUseTimePicker = this._shouldUseTimePicker();
    const className = shouldUseTimePicker ? 'time' : 'date';

    this.$fromForm = $('<input type="text" class="form-control from start" />');
    this.$toForm = $('<input type="text" class="form-control to end" />');
    this.$fromForm.addClass(className);
    this.$toForm.addClass(className);
    this.$inputContainer.prepend(this.$toForm);
    this.$inputContainer.prepend('<span class="time-label">To:</span>');
    this.$inputContainer.prepend(this.$fromForm);
    this.$inputContainer.prepend('<span class="time-label">From:</span>');

    if (shouldUseTimePicker) {
      // Show the none option only if this is not a required field and an array element
      const showNoneOption = !this.isRequired() && this.schema.type === 'object';
      TimePickerHelper.buildTimePicker(
        this.$fromForm,
        {
          noneOption: showNoneOption,
        },
        this._checkIfFieldHasChanged.bind(this),
      );
      TimePickerHelper.buildTimePicker(
        this.$toForm,
        {
          noneOption: showNoneOption,
        },
        this._checkIfFieldHasChanged.bind(this),
      );
    } else {
      const datePickerOptions = {
        ...(this.schema.allowFutureDates && { endDate: '+12m' }),
      };
      DatePickerHelper.buildDatePicker(
        this.$fromForm,
        datePickerOptions,
        this._checkIfFieldHasChanged.bind(this),
      );
      DatePickerHelper.buildDatePicker(
        this.$toForm,
        datePickerOptions,
        this._checkIfFieldHasChanged.bind(this),
      );
    }

    return this;
  },
});
