import _ from 'underscore';

app.LinksView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} links-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {
          title: 'New Link',
        };
      },
      // Provide the method for determining the title of the cell.
      getTitle(fieldGroupView) {
        let title;
        const fields = fieldGroupView.getValue();
        if (_.has(fields, 'title')) {
          title = fields.title;
        }
        return title || '';
      },
      setSetValueHandlers(fieldView, handler) {
        fieldView.fieldViewsByField.title.$form.keyup(handler);
        fieldView.fieldViewsByField.url.$form.keyup(handler);
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new link',
        emptyText: 'no links yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
