import Backbone from 'backbone';

import { BiteUrl } from '@biteinc/common';

import { template } from '../template';

app.MenuCoverPreview = Backbone.View.extend({
  className: 'menu-cover-preview',
  template: template(
    // prettier-ignore
    '<div class="image-container"></div>' +
    '<div class="decline-button-container">' +
      '<div class="decline-button">' +
        'To personalize your menu we try to identify you' +
        '<br />' +
        '<br />' +
        'Opt-Out Here' +
      '</div>' +
    '</div>' +
    '<div class="terms-of-use">Terms of Use<div>',
  ),

  initialize() {
    this.scale = 1;
  },

  setScale(scale) {
    this.scale = scale;
    if (this.params) {
      this.setParams(this.params);
    }
  },

  setParams(params) {
    this.params = params;

    this.$declineButtonContainer.css('transform', `scale(${this.scale}, ${this.scale})`);
    this.$termsOfUse.css(
      'color',
      `rgba(${app.HtmlHelper.rgbaFromHex(params.delphiTextColor || '#FFFFFFFF')})`,
    );
    this.$termsOfUse.css('transform', `scale(${this.scale}, ${this.scale})`);

    if (params.coverImg?.length) {
      const coverImgUrl = BiteUrl.biteAssetUrlWithNewHost(params.coverImg[0].url);
      this.$imageContainer.css('background-image', `url(${coverImgUrl})`);
    } else if (params.coverImg1080?.length) {
      const coverImgUrl1080 = BiteUrl.biteAssetUrlWithNewHost(params.coverImg1080[0].url);
      this.$imageContainer.css('background-image', `url(${coverImgUrl1080})`);
    } else {
      this.$imageContainer.css('background-image', '');
    }
  },

  render() {
    this.$el.html(this.template());

    this.$imageContainer = this.$('.image-container');
    this.$declineButtonContainer = this.$('.decline-button-container');
    this.$declineButtonContainer.toggle(app.location.get('usesDelphi'));
    this.$termsOfUse = this.$('.terms-of-use');

    return this;
  },
});
