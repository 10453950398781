import { ModelType } from '@biteinc/enums';

import { recommendationVariationSchema } from './recommendation_variation_schema';

app.RecommendationVariation = app.AbstractModel.extend({
  ModelName: 'recommendationVariation',
  Schema: recommendationVariationSchema,
  Type: ModelType.RecommendationVariation,

  defaults() {
    return {
      recommendationRuleIds: [],
    };
  },

  url() {
    return `/api/v2/recommendation-variations/${this.id || ''}`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'recommendationRuleIds':
        return app.recommendationRuleList;
    }
    return null;
  },

  detailsViewClassForListField(field) {
    switch (field) {
      case 'recommendationRuleIds':
        return app.RecommendationRuleDetailsView;
    }
  },
});
