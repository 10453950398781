import type { Schema } from '@biteinc/common';

export const configurationAuditSchema: Schema.Model = {
  displayName: 'configuration audit',
  type: 'object',
  fields: {
    options: {
      type: 'object',
      required: true,
      fields: {
        menuValidation: {
          type: 'bool',
          defaultValue: true,
          displayName: 'Menu Items (POS Validation)',
          tooltip: {
            text: 'Builds a to-go order for each item on the current menu and validates that order on the POS.',
          },
        },
        configurationValidation: {
          type: 'bool',
          defaultValue: true,
          displayName: 'Configuration (POS Validation)',
          tooltip: {
            text: 'Builds a simple order for each combination of enabled settings (order types, tips, cash payments) and validates that order on the POS.',
          },
        },
        sendMenuItems: {
          type: 'bool',
          displayName: 'Menu Items (POS Validation+Send)',
          tooltip: {
            text: 'Builds a to-go order for each item on the current menu, validates and sends that order to the POS with a Visa tender.',
            isWarning: true,
          },
        },
        sendConfigurationTests: {
          type: 'bool',
          displayName: 'Configuration (POS Validation+Send)',
          tooltip: {
            text: 'Builds a simple order for each combination of enabled settings (order types, tips, cash payments, tender types), validates and sends that order to the POS.',
            isWarning: true,
          },
        },
      },
    },
  },
};
