app.MenuTimingList = app.AbstractCollection.extend({
  model: app.MenuTiming,

  comparator(model) {
    if (model.get('isUnset')) {
      // If the model has this 'is-unset' field, then it is the model corresponding to the
      // dropdown option that will allow the user to remove the timing from a structure
      return 'в зад'; // Cyrillic will sort to the bottom;
    }
    return app.AbstractCollection.prototype.comparator.apply(this, arguments);
  },

  url() {
    return '/api/v2/menu-timings';
  },
});
