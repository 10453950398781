import _ from 'underscore';

app.DictionaryWordList = app.AbstractCollection.extend({
  model: app.DictionaryWord,

  containsAllWordsFromVolume(volume) {
    return _.all(volume.get('cellarWordIds'), (cellarWordId) => {
      return !!this.find((word) => {
        return word.get('cellarWordId') === cellarWordId;
      });
    });
  },

  createFromVolume(volume, opts) {
    this.fetch(
      _.extend(
        {
          method: 'POST',
          url: `${this.url()}/volume/${volume.id}`,
          remove: false,
        },
        opts || {},
      ),
    );
  },

  url() {
    return app.apiPathForMenu('/dictionaryWords');
  },
});
