import Backbone from 'backbone';

import { template } from '../template';

app.ModalFooterView = Backbone.View.extend({
  template: template($('#modal-footer-view-template').html()),

  states: {
    DEFAULT: 0,
    DELETE: 1,
    DISCARD: 2,
  },

  initialize(options) {
    Backbone.View.prototype.initialize.apply(this, arguments);

    this.$headerTitle = options.$headerTitle;
  },

  destroy() {
    this.onSave = null;
    this.onDelete = null;
  },

  getState() {
    return this.state || this.states.DEFAULT;
  },

  _saveButtonWasClicked() {
    if (this.onSave) {
      this.onSave(this.$saveButton);
    }
    return false;
  },

  simulateSaveButtonClick() {
    this._saveButtonWasClicked();
  },

  setState(state) {
    switch (state) {
      case this.states.DEFAULT:
        this.$discardPanel.hide();
        this.$deleteConfirmPanel.hide();
        this.$defaultPanel.show();
        if (this.$headerTitle) {
          this.$headerTitle.toggleClass('unsaved-changes', false);
        }
        break;
      case this.states.DELETE:
        this.$defaultPanel.hide();
        this.$discardPanel.hide();
        this.$deleteConfirmPanel.show();
        break;
      case this.states.DISCARD:
        this.$defaultPanel.hide();
        this.$deleteConfirmPanel.hide();
        this.$discardPanel.show();
        if (this.$headerTitle) {
          this.$headerTitle.toggleClass('unsaved-changes', true);
        }
        break;
    }
    this.state = state;
  },

  _softDeleteButtonWasClicked() {
    this.setState(this.states.DELETE);
    return false;
  },

  _cancelButtonWasClicked() {
    this.setState(this.states.DEFAULT);
    return false;
  },

  _hardDeleteButtonWasClicked() {
    this.setState(this.states.DEFAULT);
    if (this.onDelete) {
      this.onDelete(this.$deleteButton);
    }
    return false;
  },

  showDiscardConfirmation() {
    this.setState(this.states.DISCARD);
  },

  addButton(title, className, insertOnLeft) {
    const $button = $('<button type="button" class="btn btn-secondary"></button>');
    $button.html(title);
    $button.addClass(className);
    if (insertOnLeft) {
      $button.insertAfter(this.$deleteButton);
    } else {
      $button.insertBefore(this.$saveButton);
    }
    return $button;
  },

  /**
   * @param {string} title
   * @param {{[key: string]: string}} dropdownItemsByClassName
   * @param {($button: JQuery<HTMLElement>, className: string) => void} clickHandler
   * @param {boolean} insertOnLeft
   */
  addDropdownButton(title, dropdownItemsByClassName, clickHandler, insertOnLeft) {
    const $buttonContainer = $(
      // prettier-ignore
      '<div class="dropdown-button-container" style="width: 14rem">' +
        '<button type="button" class="btn btn-secondary" aria-label="Hide" aria-expanded="false"></button>' +
      '</div>',
    );
    const $button = $buttonContainer.find('button');
    $button.html(title);

    $button.attr('data-bs-toggle', 'dropdown');
    $button.on('click.bs.dropdown', (e) => {
      // Prevent the click from going through to the cell.
      e.originalEvent.isDropdownEvent = true;
    });

    let html = '<ul class="dropdown-menu dropdown-menu-right">';
    Object.entries(dropdownItemsByClassName).forEach(([className, text]) => {
      html += `<li class="item ${className}"><a>${text}</a></li>`;
    });
    html += '</ul>';
    $buttonContainer.append(html);

    // The simplest approach to having a dropbox flow upwards is to move the top up
    // We have a base value for the padding of the unordered list and increase it by the height of
    // each item
    const topValue = 40 + 80 * Object.keys(dropdownItemsByClassName).length;
    $buttonContainer.find('ul').css('top', `-${topValue}%`);

    Object.keys(dropdownItemsByClassName).forEach((className) => {
      $buttonContainer.find(`.item.${className}`).click(() => {
        clickHandler($button, className);
        return false;
      });
    });

    if (insertOnLeft) {
      $buttonContainer.insertAfter(this.$deleteButton);
    } else {
      $buttonContainer.insertBefore(this.$saveButton);
    }

    return $button;
  },

  addLabel(className, insertOnLeft) {
    const $label = $('<span class="footer-label"></span>');
    $label.addClass(className);
    if (insertOnLeft) {
      $label.insertAfter(this.$deleteButton);
    } else {
      $label.insertBefore(this.$saveButton);
    }
    return $label;
  },

  setSaveButtonVisible(visible, title, onSave) {
    if (visible) {
      this.$saveButton.show();
      this.$saveButton.setTitle(title);
      this.onSave = onSave;
    } else {
      this.$saveButton.hide();
      this.onSave = null;
    }
  },

  setSaveButtonEnabled(enabled) {
    this.$discardButton.text(enabled ? 'Discard Changes' : 'Close');
    this.$saveButton.prop('disabled', !enabled);
  },

  setDeleteButtonVisible(visible, confirmation, onDelete) {
    if (visible) {
      this.$deleteButton.show();
      this.$deleteConfirmPanel.find('.confirmation').text(confirmation);
      this.onDelete = onDelete;
    } else {
      this.$deleteButton.hide();
      this.onDelete = null;
    }
  },

  setCloseButtonVisible(visible) {
    if (visible) {
      this.$discardButton.show();
    } else {
      this.$discardButton.hide();
    }
  },

  render() {
    this.$el.html(this.template());
    this.$defaultPanel = this.$('.default-panel');
    this.$deleteConfirmPanel = this.$('.confirm-panel.delete');
    this.$discardPanel = this.$('.confirm-panel.discard');
    this.setState(this.states.DEFAULT);

    this.$discardButton = this.$defaultPanel.find('button.discard');
    this.$saveButton = this.$defaultPanel.find('button.save');
    this.$deleteButton = this.$defaultPanel.find('button.delete');
    this.$hardDiscardButton = this.$discardPanel.find('button.discard');
    this.$hardDeleteButton = this.$deleteConfirmPanel.find('button.delete');

    this.$saveButton.click(this._saveButtonWasClicked.bind(this));
    this.$deleteButton.click(this._softDeleteButtonWasClicked.bind(this));
    this.$deleteConfirmPanel.find('.cancel').click(this._cancelButtonWasClicked.bind(this));
    this.$hardDeleteButton.click(this._hardDeleteButtonWasClicked.bind(this));
    this.$discardPanel.find('.cancel').click(this._cancelButtonWasClicked.bind(this));

    return this;
  },
});
