import { ModelType } from '@biteinc/enums';
import { vendorSchema } from '@biteinc/schemas';

app.Vendor = app.AbstractModel.extend({
  ModelName: 'vendor',
  Schema: vendorSchema,
  Type: ModelType.Vendor,

  defaults() {
    return {
      userIds: [],
    };
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'userIds':
        return app.userList;
    }

    return null;
  },

  canBeDestroyed() {
    if (this.get('isDefault')) {
      return false;
    }
    if (!app.sessionUser.isBite()) {
      return false;
    }
    return app.AbstractModel.prototype.canBeDestroyed.apply(this, arguments);
  },

  getFullPosI9n() {
    return app.integrationList.models.find((i9n) => {
      return i9n.getI9nSchema().type === 'pos' && i9n.get('vendorId') === this.id;
    });
  },

  syncsModelsWithType(modelType) {
    const i9n = this.getFullPosI9n();
    if (!i9n) {
      // If this vendor does not have an associated POS integration, then it can not sync any models
      return false;
    }

    return i9n.syncsModelsWithType(modelType);
  },
});
