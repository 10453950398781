import Backbone from 'backbone';
import _ from 'underscore';

import { template } from '../../template';

app.MenuAuditResultListView = Backbone.View.extend({
  tagName: 'div',
  className: 'audit-configuration-result-list',
  sectionLabels: {
    menuValidation: 'Individual Items',
    configurationValidation: 'Order Configurations',
    sendMenuItems: 'Send to POS - Individual Menu Items',
    sendConfigurationTests: 'Send to POS - Tender Configurations',
  },
  resultTemplate: template(
    `<p class='audit-label'><strong><@= sectionLabel @></strong></p>
    <table class='audit-table' style='width: 100%;'>
      <thead style='font-weight: bold;'>
        <@ if (sectionTitle === 'menuValidation') { @>
          <th>Menu Item</th>
          <th class='icon-col'>Result</th>
          <th>Error Msg</th>
        <@ } else if (sectionTitle === 'configurationValidation') { @>
          <th>Destination</th>
          <th></th>
          <th>Payment Type</th>
          <th class='icon-col'>Tip Included</th>
          <th class='icon-col'>Result</th>
          <th>Error Msg</th>
        <@ } else if (sectionTitle === 'sendMenuItems') { @>
            <th>Menu Item</th>
            <th class='icon-col'>Result</th>
            <th>Error Msg</th>
        <@ } else if (sectionTitle === 'sendConfigurationTests') { @>
          <th>Destination</th>
          <th></th>
          <th>Tender Type</th>
          <th class='icon-col'>Tip Included</th>
          <th class='icon-col'>Result</th>
          <th>Error Msg</th>
        <@ } @>
      </thead>
      <tbody class='results' id='results-<@= sectionTitle @>'>
      </tbody>
    </table>
    <br>`,
  ),

  initialize(opts) {
    this.results = opts.results;
  },

  render() {
    _.each(this.results, (resultSection, sectionTitle) => {
      let sectionResultCountLabel = '';
      if (_.size(resultSection)) {
        let success = 0;
        let fail = 0;
        _.each(resultSection, (result) => {
          if (result.success) {
            success += 1;
          } else {
            fail += 1;
          }
        });
        sectionResultCountLabel = `${fail > 0 ? '❌' : '✅'} (${success}/${success + fail})`;
      }
      const sectionLabel = `${this.sectionLabels[sectionTitle]}&nbsp;&nbsp;${sectionResultCountLabel}`;
      const $resultSection = $(this.resultTemplate({ sectionTitle, sectionLabel }));
      const $list = $resultSection.find(`#results-${sectionTitle}`);
      _.each(resultSection, (result) => {
        if (sectionTitle === 'menuValidation' || sectionTitle === 'sendMenuItems') {
          $list.append(`<tr>
              <td>${result.item.name}</td>
              <td class='icon-col'>${result.success ? '✅' : '❌'}</td> \
              <td class='err-col'>${result.success ? '' : result.err}</td>
            </tr>
          `);
        } else if (sectionTitle === 'configurationValidation') {
          $list.append(`
            <tr>
              <td class='destination'>${result.fulfillmentOption.dest}</td>
              <td>${result.fulfillmentOption.label}</td>
              <td>${result.cashOption ? 'Cash' : 'CC'}</td>
              <td class='icon-col'>${result.tipOption ? '👍' : ''}</td>
              <td class='icon-col'>${result.success ? '✅' : '❌'}</td>
              <td class='err-col'>${result.success ? '' : result.err}</td>
            </tr>
          `);
        } else if (sectionTitle === 'sendConfigurationTests') {
          $list.append(`
            <tr>
              <td class='destination'>${result.fulfillmentOption.dest}</td>
              <td>${result.fulfillmentOption.label}</td>
              <td>${result.cardSchemeLabel}</td>
              <td class='icon-col'>${result.tipOption ? '👍' : ''}</td>
              <td class='icon-col'>${result.success ? '✅' : '❌'}</td>
              <td class='err-col'>${result.success ? '' : result.err}</td>
            </tr>
          `);
        }
        this.$el.append($resultSection);
      });
    });
    return this;
  },
});
