app.AutoCompleteResult = app.AbstractModel.extend({
  initialize(attributes, options) {
    app.AbstractModel.prototype.initialize.apply(this, arguments);
    // eslint-disable-next-line new-cap
    this.result = new options.model(attributes);
  },

  setSelected(selected) {
    this.isSelected = selected;
    this.trigger('isSelected');
  },

  displayName() {
    if (this.result) {
      if (this.result.autoCompleteDisplayName) {
        return this.result.autoCompleteDisplayName();
      }
      if (this.result.displayNameHtml) {
        return this.result.displayNameHtml();
      }
      return this.result.displayName();
    }
    return this.id;
  },
});
