app.RecommendationVariationCellView = app.CellView.extend({
  getTitle() {
    let title = app.CellView.prototype.getTitle.apply(this, arguments);
    const totalWeights = app.recommendationVariationList.models.reduce((sum, model) => {
      return sum + model.get('weight');
    }, 0);

    const percentage = (this.model.get('weight') / totalWeights || 0) * 100;
    const percentageTwoDecimalPlaces = Math.round(percentage * 100) / 100;

    title += ` - frequency: ${percentageTwoDecimalPlaces}%`;

    return title;
  },
});
