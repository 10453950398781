import _ from 'underscore';

import { MenuItemDisplayBlockTypeHelper } from '@biteinc/enums';
import { menuItemSchema } from '@biteinc/schemas';

const { fields: DisplayBlockFields } = menuItemSchema.fields.displayBlocks;

app.MenuItemDisplayBlockView = app.AccordionFieldView.extend({
  initialize(options) {
    _.extend(options || {}, {
      newValue() {
        return {};
      },
      getTitle: (fieldGroupView) => {
        const { type } = fieldGroupView.getValue();
        return MenuItemDisplayBlockTypeHelper.name(type);
      },
      getElementSchemaFields: (/* element */) => {
        return DisplayBlockFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new display block',
        emptyText: 'no display blocks yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
