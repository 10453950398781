import _ from 'underscore';

import { Time } from '@biteinc/helpers';

app.PickupTimeSlotsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} pickup-time-slots-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          const models = [0, 1, 2, 3, 4, 5, 6].map((isoDayNumber) => {
            return new app.AbstractModel({
              _id: isoDayNumber,
              name: Time.FULL_DAYS[isoDayNumber],
            });
          });
          return new app.AbstractCollection(models, {
            sort: app.AbstractCollection.SortOptions.ID,
          });
        },
        getNewElementFromModel: (dayModel) => {
          return {
            day: dayModel.id,
            timeSlots: [],
          };
        },
        canShowModel: (dayModel, elements) => {
          // Filter out the days that are already entered (but maybe not saved)
          return !_.findWhere(elements, { day: dayModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const day = fieldGroupView.getValue().day;
        return Time.FULL_DAYS[day];
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new time slot',
        emptyText: 'no pick up time slots yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
