import Backbone from 'backbone';

import { Time } from '@biteinc/helpers';

import { TimeHelper } from '../helpers/time_helper';
import { template } from '../template';

app.ExpoPrinterStatusView = Backbone.View.extend({
  template: template(
    // prettier-ignore
    '<div class="expo-printer-status">' +
      '<div class="status-row">' +
        '<div class="section printer">' +
          '<div class="header">' +
            '<div class="device-icon"></div>' +
            '<div class="device-identifier">' +
              '<div class="device-name">' +
                '<span class="name-span"></span>' +
              '</div>' +
            '</div>' +
            '<div class="device-light"></div>' +
            '<div class="timestamp"></div>' +
          '</div>' +
        '</div>' +
      '</div>' +
    '</div>',
  ),

  initialize(options) {
    this._printer = options.printer;
  },

  _isVeryOldStatusAge(ageDuration) {
    return ageDuration > 30 * Time.MINUTE;
  },

  _isOldStatusAge(ageDuration) {
    return ageDuration > 5 * Time.MINUTE;
  },

  _classForStatusAge(startTimestamp) {
    const diff = Date.now() - startTimestamp;
    if (this._isVeryOldStatusAge(diff)) {
      return 'very-old';
    }
    if (this._isOldStatusAge(diff)) {
      return 'old';
    }
    // Anything less than 5 minutes.
    return '';
  },

  _updateTimestamp($timestamp) {
    // Bail if the model has been updated and this is a dead view
    if (this._printer) {
      if (!this._printer.collection) {
        clearTimeout(this._timestampTimer);
        return;
      }
    } else if (!this._printer.collection) {
      clearTimeout(this._timestampTimer);
      return;
    }

    const updatedAt = this._printer.get('updatedAt');
    $timestamp.text(`${TimeHelper.timeAgoStrFromNow(updatedAt)} ago`);
    $timestamp.toggleClass(this._classForStatusAge(updatedAt), true);
    $timestamp.attr('title', TimeHelper.format(updatedAt, TimeHelper.FullDateFormat));
    this._timestampTimer = setTimeout(() => {
      this._updateTimestamp($timestamp);
    }, 1000);
  },

  render() {
    const self = this;

    this.$el.html(this.template());
    this.$el.off('click');

    // Used later to toggle nested cells.
    if (this._printer) {
      if (this._printer.has('kioskId')) {
        this.$el.attr('id', this._printer.get('id'));
      }
    }
    this.$el.addClass('kiosk-card');

    const $printer = this.$('.printer');

    self.$el.addClass(`status-${self._printer.id}`);

    if (this._timestampTimer) {
      clearTimeout(this._timestampTimer);
    }

    $printer.find('.device-icon').toggleClass('tm88');
    $printer.find('.device-name span.name-span').text(this._printer.displayName());

    // Overall status dot.
    this._updateTimestamp($printer.find('.timestamp'));
    const diff = Date.now() - this._printer.get('updatedAt');
    if (this._isVeryOldStatusAge(diff)) {
      $printer.find('.device-name span.name-span').toggleClass('errors', true);
    } else if (this._isOldStatusAge(diff)) {
      $printer.find('.device-name span.name-span').toggleClass('warnings', true);
    } else {
      $printer.find('.device-name span.name-span').toggleClass('good', true);
    }
    return this;
  },
});
