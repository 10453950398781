import _ from 'underscore';

import { allEnumValues, LocationState, OrderChannel, OrderChannelHelper } from '@biteinc/enums';

import { buildOrderChannelTag, i9nBadgesHtml } from '../../helpers/html_builder';

app.LocationListView = app.BaseListView.extend({
  initialize(options) {
    options.cellOptions = {
      getNameUrl(location) {
        const channelTag = buildOrderChannelTag(location.get('orderChannel'), {
          isLiveLocation: location.isLive(),
          isCanaryLocation: location.isCanary(),
        });
        return {
          href: location.bureauUrl(),
          alt: OrderChannelHelper.nameCombiningKioskAndFlash(location.get('orderChannel')),
          innerHtml: `${channelTag} ${i9nBadgesHtml(location.getAllI9nSchemas())}`,
        };
      },
    };
    options.detailsView = app.NewLocationDetailsView;

    app.BaseListView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.collection, 'change', this._updateIfCanCreate);
  },

  _updateIfCanCreate() {
    this.setCanCreate(app.location.getCreatableI9nSchemas().length > 0);
  },

  getListSections() {
    const siteLocations = this.collection.getLocationsWithSiteId(app.site.id);
    const group = _.groupBy(siteLocations, (location) => {
      return location.get('state');
    });

    const locationGroups = [
      {
        header: 'Live',
        models: group[LocationState.Live] || [],
      },
      {
        header: 'Draft',
        models: [
          ...(group[LocationState.LabWithLivePos] || []),
          ...(group[LocationState.Draft] || []),
        ],
      },
      {
        header: 'Stale',
        models: group[LocationState.Stale] || [],
      },
    ];

    if (app.sessionUser.isBite()) {
      locationGroups.push({
        header: 'Bite-Only',
        models: group[LocationState.BiteOnly],
      });
    }

    return locationGroups.filter((locationGroup) => {
      return locationGroup.models?.length;
    });
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    if (this.options.canCreate) {
      const siteLocations = this.collection.getLocationsWithSiteId(app.site.id);
      const collection = app.AbstractCollection.createFromTsEnum({
        tsEnum: OrderChannel,
        nameGenerator: OrderChannelHelper.nameCombiningKioskAndFlash,
        values: allEnumValues(OrderChannel).filter((orderChannel) => {
          if (OrderChannel.Flash === orderChannel) {
            return false;
          }
          return !siteLocations.find((location) => {
            return location.get('orderChannel') === orderChannel;
          });
        }),
      });
      const dropdownView = new app.DropdownView({
        isPrimary: true,
      });
      this.$('.card-header .right-button-container').html(dropdownView.render().$el);
      dropdownView.setTitle(
        `<i class="bi bi-plus" aria-hidden="true"></i>&nbsp;new channel ${app.HtmlHelper.resellerRightIcon}`,
      );
      dropdownView.setup(collection, {
        onAdd: (orderChannelModel) => {
          // eslint-disable-next-line new-cap
          const model = new this.collection.model(
            {
              siteId: app.site.id,
              orderChannel: orderChannelModel.id,
            },
            {
              collection: this.collection,
            },
          );
          this.editModel(model);
        },
      });
    }

    return this;
  },
});
