app.BuildListView = app.PaginatedListView.extend({
  hasFetchedAllData() {
    let hasFetched = app.PaginatedListView.prototype.hasFetchedAllData.apply(this);
    if (!app.location) {
      hasFetched = hasFetched && app.orgList.hasBeenFetched();
    }
    return hasFetched;
  },

  _renderList() {
    if (!app.orgList.hasBeenFetched()) {
      this.listenToOnce(app.orgList, 'reset', this.render);
      return;
    }

    app.PaginatedListView.prototype._renderList.apply(this, arguments);
  },
});
