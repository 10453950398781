import { RecommendationRuleEffectName } from '@biteinc/enums';

export function nameFromRecommendationRuleName(name: RecommendationRuleEffectName): string {
  switch (name) {
    case RecommendationRuleEffectName.DoNotRecommendItems:
      return 'Do not recommend items';
    case RecommendationRuleEffectName.DoNotRecommendItemsInCart:
      return 'Do not recommend items in cart';
    case RecommendationRuleEffectName.DoNotRecommendItemsInSameSectionInCart:
      return 'Do not recommend items in same section in cart';
    case RecommendationRuleEffectName.DoNotRecommendItemsInSameSectionAsSelectedItem:
      return 'Do not recommend items in same section as selected item';
    case RecommendationRuleEffectName.DoNotRecommendItemsInSection:
      return 'Do not recommend items in section';
    case RecommendationRuleEffectName.DoNotRecommendSelectedItem:
      return 'Do not recommend selected item';
    case RecommendationRuleEffectName.DoNotRecommendAlcoholicOrOtherMenuCategory:
      return 'Do not recommend alcoholic or other menu category items';
    case RecommendationRuleEffectName.DoNotRecommendZeroPriceItems:
      return 'Do not recommend items with $0 price';
    case RecommendationRuleEffectName.HeuristicsV2:
      return 'Heuristics v2';
    case RecommendationRuleEffectName.RecommendItems:
      return 'Recommend items';
    case RecommendationRuleEffectName.RecommendModifiers:
      return 'Recommend modifiers';
    case RecommendationRuleEffectName.RecommendItemsInSection:
      return 'Recommend items in section';
    case RecommendationRuleEffectName.RecommendGuestPreviouslyOrderedItems:
      return 'Recommend guest previously ordered items';
    case RecommendationRuleEffectName.RecommendGuestFavoriteItems:
      return 'Recommend guest favorite items';
    case RecommendationRuleEffectName.RecommendGuestPredicationsOnPreviouslyOrderedItems:
      return 'Recommend guest predictions on previously ordered items';
    case RecommendationRuleEffectName.SortItemsInSection:
      return 'Sort items in section';
    case RecommendationRuleEffectName.SortItemsInSectionsRandomly:
      return 'Sort items in sections randomly';
    case RecommendationRuleEffectName.MenuItemAffinity:
      return 'Menu Item Affinity';
    case RecommendationRuleEffectName.DoNotRecommendItemsWithDietaryRestriction:
      return 'Do not recommend items with dietary restriction';
    case RecommendationRuleEffectName.RecommendItemsFromPreferredCategory:
      return 'Recommend items from preferred category';
    case RecommendationRuleEffectName.RecommendOnlyItemsWithDietaryPreference:
      return 'Recommend only items with dietary preference';
  }
}
