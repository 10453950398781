import _ from 'underscore';

import { ModelType } from '@biteinc/enums';
import { dictionaryVolumeSchema } from '@biteinc/schemas';

app.DictionaryVolume = app.AbstractModel.extend({
  ModelName: 'dictionaryVolume',
  Schema: dictionaryVolumeSchema,
  Type: ModelType.DictionaryVolume,

  displayName() {
    const wordCount = _.size(this.get('cellarWordIds'));
    let name = app.AbstractModel.prototype.displayName.apply(this, arguments);
    name += ` (${wordCount} word${wordCount === 1 ? '' : 's'})`;
    return name;
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'cellarWordIds':
        return app.cellarWordList;
    }
    return null;
  },
});
