import Backbone from 'backbone';
import _ from 'underscore';

import { ModelType } from '@biteinc/enums';
import { I9nHelper } from '@biteinc/helpers';

import { ModelHelper } from '../helpers/model_helper';
import { template } from '../template';

app.SearchView = Backbone.View.extend({
  className: 'search-view form-group card panel-default',
  template: template(
    // prettier-ignore
    '<div class="card-header">' +
      '<span class="title no-user-select"><@= title @></span>' +
    '</div>' +
    '<div class="search-field">' +
      '<div class="form-group">' +
        '<div class="input-container">' +
          '<input type="search" class="form-control search" placeholder="<@= placeholder @>" />' +
          '<span class="fetching form-control-feedback"></span>' +
          '<div class="search-hint"></div>' +
        '</div>' +
      '</div>' +
    '</div>' +
    '<div class="search-results list-container list-group"></div>',
  ),

  initialize(options) {
    this._options = options || {};
    this._req = 0;
    this._prevQuery = '';
  },

  _searchFieldDidUpdate() {
    const query = this._$searchField.val().trim();
    if (this._prevQuery === query) {
      return;
    }
    this._prevQuery = query;

    const pattern = _.find(this._options.patterns, (p) => {
      return !!query.match(p.regex);
    });

    this._req++; // Invalidate the current search

    if (pattern) {
      const self = this;
      const req = this._req;
      app.makeRequestWithOptions({
        url: `/api/search?${pattern.searchParam}=${encodeURIComponent(query)}`,
        showAlertOnError: false,
        onSuccess(data) {
          if (self._req === req) {
            self._renderResults(null, data.results);
          }
        },
        onFailure(err) {
          if (self._req === req) {
            self._renderResults(err);
          }
        },
      });
      this._$spinner.show();
    } else {
      this._searchWasCancelled(true);
    }
  },

  _renderResults(err, results) {
    if (err) {
      this._$searchResults.html(`<div class="result error">${err}</div>`);
    } else {
      const Model = ModelHelper.getModelForType(results[0].modelType);
      if (!Model) {
        this._$searchResults.html(
          `<div class="result error">Unknown model type: ${results[0].modelType}</div>`,
        );
      } else {
        this._$searchResults.html('');
        _.chain(results)
          .map((result) => {
            const model = new Model(result.data);
            let modelName = model.debugName ? model.debugName() : model.displayName();
            if (result.modelType === ModelType.Order) {
              const location = app.orgList.getLocationById(model.get('locationId'));
              modelName = `Order ${model.id} at ${location.debugName()}`;
            }
            return { modelName, url: result.url };
          })
          .sortBy('modelName')
          .each(({ modelName, url }) => {
            this._$searchResults.append(
              // prettier-ignore
              '<div class="result list-group-item cell clickable">' +
                '<div class="cell-row">' +
                  `<a href="${url}" alt="${modelName}">${modelName}</a>` +
                '</div>' +
              '</div>',
            );
          })
          .value();
      }
    }

    this._$spinner.hide();
    this._$searchResults.slideDown();
  },

  _searchWasCancelled(animated) {
    this._$spinner.hide();
    this._$searchResults.slideUp(animated ? 400 : 0);
  },

  render() {
    this.$el.html(
      this.template({
        title: this._options.title,
        placeholder: this._options.placeholder,
      }),
    );

    this._$searchField = this.$('.search-field input');
    this._$searchField.on('keyup', this._searchFieldDidUpdate.bind(this));
    this._$searchField.on('search', this._searchFieldDidUpdate.bind(this));
    this._$searchHint = this.$('.search-field .search-hint');
    this._$spinner = this.$('.fetching');
    this._$searchResults = this.$('.search-results');

    this._searchWasCancelled(false);

    if (this._options.hintKeywordsByLabel) {
      app.HtmlHelper.renderSearchHints(
        '',
        this._options.hintKeywordsByLabel,
        ':stripe:acct_XXX for locations tied to that Stripe account',
        this._$searchHint,
        this._$searchField,
        undefined,
        'focus',
        {},
        ':',
      );
    }

    return this;
  },
});
app.SearchView.MongoIdRegEx = new RegExp(/^[0-9a-f]{24}$/);

app.ToolsView = Backbone.View.extend({
  className: 'tools-view',
  initialize() {
    this._locationSearchView = new app.SearchView({
      title: 'Find Location',
      placeholder: 'enter mongoId or shortCode or urlSlug (Flash/Web) or integration',
      patterns: [
        {
          regex: app.SearchView.MongoIdRegEx,
          searchParam: 'locationId',
        },
        {
          regex: new RegExp(/^[a-z]{4}/),
          searchParam: 'shortCode',
        },
        {
          /**
           * /^
           *  [a-z0-9]+   # One or more repetition of given characters
           *  (?:         # A non-capture group.
           *  -           # A hyphen
           *  [a-z0-9]+   # One or more repetition of given characters
           *  )*          # Zero or more repetition of previous group
           * $/
           */
          regex: new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/),
          searchParam: 'urlSlug',
        },
        {
          regex: new RegExp(/^:[a-z-]+:[a-zA-Z0-9_]{4,}$/),
          searchParam: 'integrationIdentifier',
        },
      ],
      hintKeywordsByLabel: {
        POS: I9nHelper.getSearchTokensWithTypes(['pos']),
        Payments: I9nHelper.getSearchTokensWithTypes(['ecomm-payment', 'kiosk-payment']),
        Loyalty: I9nHelper.getSearchTokensWithTypes(['loyalty']),
        'Stored Value': I9nHelper.getSearchTokensWithTypes(['stored-value', 'comp-card']),
      },
    });
    this._kioskSearchView = new app.SearchView({
      title: 'Find Kiosk',
      placeholder:
        'enter mongoId or Crashlytics User Identifier or, Kiosk or Payment Terminal Serial Number',
      patterns: [
        {
          regex: app.SearchView.MongoIdRegEx,
          searchParam: 'kioskId',
        },
        {
          regex: new RegExp(/^[0-9a-f]{24}\/[0-9a-f]{24}\/\w+/),
          searchParam: 'crashlyticsUserId',
        },
        {
          regex: new RegExp(/^([A-Za-z][0-9]{9})$|^[0-9A-Za-z-]{5,15}$/),
          searchParam: 'serialNumber',
        },
      ],
    });
    this._orderSearchView = new app.SearchView({
      title: 'Find Order',
      placeholder: 'enter mongoId',
      patterns: [
        {
          regex: app.SearchView.MongoIdRegEx,
          searchParam: 'orderId',
        },
      ],
    });
    this._userSearchView = new app.SearchView({
      title: 'Find User',
      placeholder: 'enter username',
      patterns: [
        {
          regex: new RegExp(/^([a-z0-9_\-.]+)@([a-z0-9_\-.]+)\.([a-z]{2,5})$/i),
          searchParam: 'username',
        },
      ],
    });
  },

  render() {
    this.$el.html('');
    this.$el.append(this._locationSearchView.render().$el);
    this.$el.append(this._kioskSearchView.render().$el);
    this.$el.append(this._orderSearchView.render().$el);
    this.$el.append(this._userSearchView.render().$el);
    return this;
  },
});
