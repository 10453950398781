import {
  MenuItemAffinityFormulaDescription,
  ModelType,
  RecommendationRuleEffectName,
} from '@biteinc/enums';

import { ConditionSchemaName, nameFromConditionSchemaName } from '../enums/condition_schema_name';
import { nameFromRecommendationRuleName } from '../enums/recommendation_rule_name';
import { RecommendationRuleHelper } from '../helpers/recommendation_rule_helper';
import { recommendationRuleSchema } from './recommendation_rule_schema';

app.RecommendationRule = app.AbstractModel.extend({
  displayName() {
    if (this.attributes.description) {
      return this.attributes.description;
    }
    if (this.isNew()) {
      return this.attributes.effectName;
    }
    return `${this.attributes.effectName} #${this.id.slice(-4)}`;
  },

  displayNameHtml() {
    let title = `<span>${this.displayName()}`;

    if (this.get('isInvalid')) {
      title += ' <span class="cell-badge error" title="Invalid Menu Item">invalid</span>';
    }

    title += '</span>';

    return title;
  },

  ModelName: 'recommendationRule',
  Schema: recommendationRuleSchema,
  Type: ModelType.RecommendationRule,

  parse(data, options) {
    const response = app.AbstractModel.prototype.parse.apply(this, [data, options]);

    const recommendationEffect = response.recommendationEffect;

    // build condition schema UI
    const conditionSchemaJson = response.conditionSchema;
    let conditionSchema;

    let conditionSchemaName;
    let conditionSchemaSectionIds;
    let conditionSchemaSectionId;
    let conditionSchemaItemIds;
    if (conditionSchemaJson) {
      // attempt to infer the bureau UI model fields based on condition schema
      const deconstructedConditionSchema =
        RecommendationRuleHelper.getDeconstructedConditionSchema(conditionSchemaJson);
      if (deconstructedConditionSchema) {
        // we have found a matching prebuilt schema
        const { name, itemIds, sectionIds, sectionId } = deconstructedConditionSchema;

        // set the ui model fields
        conditionSchemaItemIds = itemIds;
        conditionSchemaSectionIds = sectionIds;
        conditionSchemaSectionId = sectionId;
        conditionSchemaName = name;
      } else {
        conditionSchemaName = ConditionSchemaName.Custom;
      }

      conditionSchema = JSON.stringify(response.conditionSchema, null, 2);
    } else {
      conditionSchemaName = ConditionSchemaName.None;
    }

    // the model we show to the user is different from the model in the database, so transform
    const transformedResponse = {
      _id: response._id,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
      conditionSchema,
      conditionSchemaName,
      conditionSchemaSectionId,
      conditionSchemaSectionIds,
      conditionSchemaItemIds,
      description: response.description,
      effectName: recommendationEffect.name,
      ...(recommendationEffect.body?.formulaDescription && {
        effectFormulaDescription: recommendationEffect.body.formulaDescription,
      }),
      ...(recommendationEffect.body?.itemIds && {
        effectItemIds: recommendationEffect.body.itemIds,
      }),
      ...(recommendationEffect.body?.sectionId && {
        effectSectionId: recommendationEffect.body.sectionId,
      }),
      ...(recommendationEffect.body?.sectionIds && {
        effectSectionIds: recommendationEffect.body.sectionIds,
      }),
      ...(recommendationEffect.body?.limitItemsAfterFiltering && {
        effectLimitItemsAfterFiltering: recommendationEffect.body.limitItemsAfterFiltering,
      }),
      ...(recommendationEffect.body?.randomSortItemsWithinSections && {
        effectRandomSortItemsWithinSections:
          recommendationEffect.body.randomSortItemsWithinSections,
      }),
      ...(recommendationEffect.body?.randomizeEffectItemIds && {
        randomizeEffectItemIds: recommendationEffect.body.randomizeEffectItemIds,
      }),
      ...(recommendationEffect.body?.modifierIds && {
        effectModifierIds: recommendationEffect.body.modifierIds,
      }),
      ...(response.isInvalid && {
        isInvalid: response.isInvalid,
        invalidReason: response.invalidReason,
      }),
    };

    return transformedResponse;
  },

  save(attrs, opts) {
    // the model we show to the user is different from the model in the database, so transform

    let conditionSchema; // :string
    if (attrs.conditionSchemaName) {
      conditionSchema = RecommendationRuleHelper.buildConditionSchema(attrs);
    }

    let conditionSchemaJson = null; // null so that we unset if it's not set
    if (conditionSchema) {
      conditionSchemaJson = JSON.parse(conditionSchema);
    }

    const transformedAttributes = {
      conditionSchema: conditionSchemaJson,
      description: attrs.description,
      recommendationEffect: {
        name: attrs.effectName,
        body: {
          ...(attrs.effectFormulaDescription && {
            formulaDescription: attrs.effectFormulaDescription,
          }),
          ...(attrs.effectItemIds?.length && {
            itemIds: attrs.effectItemIds,
          }),
          ...(attrs.effectModifierIds?.length && {
            modifierIds: attrs.effectModifierIds,
          }),
          ...(attrs.effectSectionId && {
            sectionId: attrs.effectSectionId,
          }),
          ...(attrs.effectSectionIds?.length && {
            sectionIds: attrs.effectSectionIds,
          }),
          ...(attrs.effectLimitItemsAfterFiltering && {
            limitItemsAfterFiltering: attrs.effectLimitItemsAfterFiltering,
          }),
          ...(attrs.effectRandomSortItemsWithinSections && {
            randomSortItemsWithinSections: attrs.effectRandomSortItemsWithinSections,
          }),
          ...(attrs.randomizeEffectItemIds && {
            randomizeEffectItemIds: attrs.randomizeEffectItemIds,
          }),
        },
      },
    };

    if (!Object.keys(transformedAttributes.recommendationEffect.body).length) {
      // maitred doesn't support empty objects in the request body, so delete empty bodies
      delete transformedAttributes.recommendationEffect.body;
    }

    app.AbstractModel.prototype.save.apply(this, [transformedAttributes, opts]);
  },

  url() {
    return `/api/v2/recommendation-rules/${this.id || ''}`;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = (subProperty ? `${subProperty}.` : '') + field;
    switch (fieldName) {
      case 'conditionSchemaName':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ConditionSchemaName,
          nameGenerator: nameFromConditionSchemaName,
        });
      case 'effectName':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: RecommendationRuleEffectName,
          nameGenerator: nameFromRecommendationRuleName,
        });
      case 'effectSectionId':
      case 'effectSectionIds':
      case 'conditionSchemaSectionIds':
      case 'conditionSchemaSectionId':
        return app.menuSectionList;
      case 'conditionSchemaItemIds':
      case 'effectItemIds':
        return app.menuItemList;
      case 'effectModifierIds':
        return app.modList;
      case 'effectFormulaDescription':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemAffinityFormulaDescription,
          nameGenerator: (name) => {
            switch (name) {
              case MenuItemAffinityFormulaDescription.OrderedItemCount:
                return 'Ordered Item Count';
              default:
                throw new Error(`unrecognized menu item affinity formula description : ${name}`);
            }
          },
        });
    }
    return null;
  },
});
