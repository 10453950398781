app.MenuStructureList = app.AbstractCollection.extend({
  model: app.MenuStructure,

  initialize(models, opts) {
    app.AbstractCollection.prototype.initialize.apply(this, arguments);

    const options = opts || {};
    if (options.menuSectionList) {
      this.listenTo(options.menuSectionList, 'destroy', function onDestroy(section) {
        this.each((structure) => {
          structure.removeMenuSection(section);
        });
      });
    }
  },

  url() {
    return '/api/v2/menu-structures';
  },
});
