import _ from 'underscore';

import { ModelType } from '@biteinc/enums';
import { cellarWordSchema } from '@biteinc/schemas';

app.CellarWord = app.AbstractModel.extend({
  ModelName: 'cellarWord',
  Schema: cellarWordSchema,
  Type: ModelType.CellarWord,

  getParamsForSchema(itemSchema /* optParams */) {
    const params = {
      cellarWordId: this.id,
    };

    if (itemSchema.fields.name) {
      params.name = this.get('name');
    }
    if (itemSchema.fields.simpleDescription) {
      params.simpleDescription = this.get('simpleDescription');
    }
    if (itemSchema.fields.detailedDescription && this.hasStr('detailedDescription')) {
      params.simpleDescription = this.get('simpleDescription');
    }

    if (itemSchema.fields.images && this.hasArr('images')) {
      params.images = this.get('images');
      _.each(params.images, (image) => {
        image.isCellar = true;
      });
    }

    return params;
  },

  isMissingImages() {
    return !this.hasArr('images');
  },

  displayNameHtml() {
    let title = `<span>${this.displayName()}</span>`;
    if (this.isMissingImages()) {
      title +=
        '&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" ' +
        'srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>';
    }
    return title;
  },
});
