import { allEnumValues } from '@biteinc/enums';
import { StringHelper } from '@biteinc/helpers';
import { spiritsSchemas } from '@biteinc/schemas';

app.CellarView = app.TabbedView.extend({
  requiresPageHeader: true,

  initialize() {
    app.TabbedView.prototype.initialize.apply(this, arguments);

    this.addSeparatorWithTitle('Alcohol');

    app.spiritCollectionByCategory = {};
    allEnumValues(spiritsSchemas.Category).forEach((category) => {
      const spiritTabView = new app.SpiritTabView({
        category,
      });
      app.spiritCollectionByCategory[category] = spiritTabView.collection;
      const schema = spiritsSchemas.schemaByCategory[category];
      this.addTab(
        spiritTabView,
        StringHelper.pluralize(StringHelper.toTitleCase(schema.displayName)),
        schema.displayName.replace(' ', '-'),
      );
    });

    this.addSpacer();
    const producerTab = new app.SpiritProducerTabView();
    app.spiritProducerList = new app.SpiritProducerList();
    const spiritProducerListView = new app.SpiritListView({
      collection: app.spiritProducerList,
      tabView: producerTab,
      detailsView: app.SpiritProducerDetailsView,
    });
    producerTab.setLeftView(spiritProducerListView);
    this.addTab(producerTab, 'Producers', 'producers');

    this.addSpacer();
    this.addSeparatorWithTitle('Flourishes');

    app.cellarBadgeList = new app.CellarBadgeList();
    const badgeListView = new app.BaseListView({
      collection: app.cellarBadgeList,
    });
    const badgesTab = new app.TabView();
    badgesTab.setLeftView(badgeListView);
    this.addTab(badgesTab, 'Badges', 'badges');

    app.cellarWordList = new app.CellarWordList();
    app.dictionaryVolumeList = new app.DictionaryVolumeList();
    const wordListView = new app.BaseListView({
      collection: app.cellarWordList,
    });
    const volumeListView = new app.BaseListView({
      collection: app.dictionaryVolumeList,
    });
    const dictionaryTab = new app.TabView();
    dictionaryTab.setLeftView(wordListView);
    dictionaryTab.setRightView(volumeListView);
    this.addTab(dictionaryTab, 'Dictionary', 'dictionary');

    this.addBottomLinks();
  },

  setPageHeader(header) {
    app.TabbedView.prototype.setPageHeader.apply(this, arguments);
    header.setMainTitle('Cellar', 'Cellar', '/cellar');
  },
});
