import { QuicksandRecordType, QuicksandUpdateType } from '@biteinc/enums';

export function cssClassFromRecordType(type: QuicksandRecordType): string {
  switch (type) {
    case QuicksandRecordType.Create:
      return 'added';
    case QuicksandRecordType.Update:
      return 'updated';
    case QuicksandRecordType.Delete:
      return 'deleted';
    case QuicksandRecordType.Archive:
      return 'archived';
    case QuicksandRecordType.Unarchive:
      return 'unarchived';
    case QuicksandRecordType.SyncWarning:
      return 'error';
    case QuicksandRecordType.SyncSummary:
      return 'sync-summary';
    case QuicksandRecordType.DayPartChangeDeprecated:
      return 'day-part-changed';
  }
}

export function stringFromRecordType(type: QuicksandRecordType): string {
  switch (type) {
    case QuicksandRecordType.Create:
      return 'added';
    case QuicksandRecordType.Update:
      return 'updated';
    case QuicksandRecordType.Delete:
      return 'deleted';
    case QuicksandRecordType.Archive:
      return 'archived';
    case QuicksandRecordType.Unarchive:
      return 'unarchived';
    case QuicksandRecordType.SyncWarning:
      return 'sync warning';
    case QuicksandRecordType.SyncSummary:
      return 'sync summary';
    case QuicksandRecordType.DayPartChangeDeprecated:
      return 'day part change';
  }
}

export function stringFromUpdateType(updateType: QuicksandUpdateType): string {
  switch (updateType) {
    case QuicksandUpdateType.HiddenIndefinitely:
      return '86ed Indefinitely';
    case QuicksandUpdateType.HiddenUntilTomorrow:
      return '86ed Temporarily';
    case QuicksandUpdateType.Shown:
      return 'Restocked';
    case QuicksandUpdateType.UpdatedModAvailability:
      return 'Updated Mod Availability';
  }
}
