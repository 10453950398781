import type { Schema } from '@biteinc/common';

export const cloneLocationOptionsSchema: Schema.Model = {
  displayName: 'Clone Options',
  type: 'object',
  fields: {
    copyIntegrations: {
      displayName: 'Copy integrations',
      type: 'bool',
      defaultValue: true,
    },
    copyOrgLevelSettingsToLocation: {
      displayName: 'Copy org-level settings (Tradeshow Only!)',
      type: 'bool',
      defaultValue: false,
      tooltip: {
        text: 'If you are doing this for a non-demo location, just recreate org-level settings at the org-level',
        isWarning: true,
      },
    },
  },
};
