export module TimePickerHelper {
  type TimePickerOptions = {
    noneOption?: boolean;
  };

  export function buildTimePicker(
    $timeForm: any,
    timePickerOptions: TimePickerOptions,
    onChange: Function,
  ): void {
    $timeForm.timepicker({
      step: 15,
      forceRoundTime: true,
      showDuration: true,
      disableTextInput: false,
      disableTouchKeyboard: true,
      timeFormat: 'g:ia',
      // This option changes how the time picker is implemented, making it easier to use for small
      // screens, but it is incompatible with some of the other options and will cause various bugs,
      // therefore we cannot use it.
      // useSelect: BrowserHelper.isMobile(),
      ...timePickerOptions,
    });
    $timeForm.on('changeTime', onChange);
    $timeForm.on('change', onChange);
  }
}
