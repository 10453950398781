import { IntegrationSystem } from '@biteinc/enums';
import { nutritionInfoSchema } from '@biteinc/schemas';

app.NutritionInfoView = app.NestedObjectFieldView.extend({
  getNestedObjectSchema() {
    const syncCaloriesEnabled = app.integrationList.models.some((i9n) => {
      return (
        i9n.get('system') === IntegrationSystem.Olo ||
        i9n.get('system') === IntegrationSystem.Simphony
      );
    });

    const schema = app.JsonHelper.deepClone(nutritionInfoSchema);
    if (!syncCaloriesEnabled) {
      delete schema.fields.posBaseCalories;
      delete schema.fields.posMaxCalories;
    }
    return schema;
  },
});
