import { ErrorCode } from '@biteinc/common';

app.handleJQueryErrorResponse = function onJqueryError({
  requestMethod,
  requestUrl,
  response,
  showErrorModal,
  errorHandler,
}) {
  const error = {};
  if (response.status === 0 || !response.responseJSON) {
    error.code = ErrorCode.NetworkError;
    error.message = app.defaultError;
    error.debugMessage = `The request timed out: ${requestMethod} ${requestUrl}`;
  } else {
    error.code = response.responseJSON.code;
    error.message = response.responseJSON.error || app.defaultError;
    if (response.responseJSON.debugMessage) {
      error.debugMessage = response.responseJSON.debugMessage;
    }
  }

  let errMessage = error.message;
  if (error.debugMessage) {
    errMessage += `\n(${error.debugMessage})`;
  }
  if (showErrorModal) {
    new app.AlertView().show(errMessage);
  }
  if (errorHandler) {
    // Hack for now to let some consumers get the real error object
    errorHandler.apply(null, [errMessage, error]);
  }
};
