app.ChangesetList = app.AbstractCollection.extend({
  model: app.Changeset,

  comparator(model) {
    return model.get('scheduledFor') * -1;
  },

  url() {
    return '/api/v2/changesets';
  },
});
