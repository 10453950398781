import $ from 'jquery';

app.Install403ResponseHandler = function on403() {
  $.ajaxSetup({
    statusCode: {
      403() {
        // Most likely, the user got logged out. Refresh the page to get them
        // redirected.
        window.location.reload(true);
        return false;
      },
    },
  });
};
