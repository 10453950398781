import { ModelType } from '@biteinc/enums';

app.ExpoPrinter = app.AbstractModel.extend({
  ModelName: 'expoPrinter',
  Schema: {},
  Type: ModelType.ExpoPrinter,

  displayName() {
    return `${this.get('printerName')} (${this.get('ipAddress')})`;
  },
});
