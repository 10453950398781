import _ from 'underscore';

import { BiteUrl } from '@biteinc/common';
import { ModelType } from '@biteinc/enums';
import { cellarBadgeSchema } from '@biteinc/schemas';

app.CellarBadge = app.AbstractModel.extend({
  ModelName: 'cellarBadge',
  Schema: cellarBadgeSchema,
  Type: ModelType.CellarBadge,

  getParamsForSchema(itemSchema /* optParams */) {
    const params = {
      cellarBadgeId: this.id,
    };

    if (itemSchema.fields.name) {
      params.name = this.get('name');
    }

    if (itemSchema.fields.icons && this.hasArr('icons')) {
      params.icons = this.get('icons');
      _.each(params.icons, (image) => {
        image.isCellar = true;
      });
    }

    return params;
  },

  isMissingImages() {
    return !this.hasArr('icons');
  },

  displayNameHtml() {
    let title = '';
    if (this.isMissingImages()) {
      const url1x = 'https://static.bureau.getbite.com/images/no-photo.png';
      const url2x = 'https://static.bureau.getbite.com/images/no-photo@2x.png';
      title += `<img class="badge-img" src="${url1x}" srcset="${url2x} 2x" title="No Photos"/>`;
    } else {
      const url = BiteUrl.biteAssetUrlWithNewHost(this.get('icons')[0].url);
      title += `<img class="badge-img" src="${url}"/>`;
    }
    title += `<span>${this.displayName()}</span>`;

    return title;
  },
});
