import _ from 'underscore';

app.I9nDiningOptionsView = app.AccordionFieldView.extend({
  className() {
    return `${app.AccordionFieldView.prototype.className.apply(this)} dining-option-panel`;
  },

  initialize(options) {
    _.extend(options || {}, {
      newElementDropdown: {
        getCollection: () => {
          return app.location.getCollectionFromExistingFulfillmentMethods();
        },
        getNewElementFromModel: (fulfillmentMethodModel) => {
          return {
            fulfillmentMethod: fulfillmentMethodModel.id,
          };
        },
        canShowModel: (fulfillmentMethodModel, elements) => {
          // Filter out the fulfillment methods that are already entered (but maybe not saved)
          return !_.findWhere(elements, { fulfillmentMethod: fulfillmentMethodModel.id });
        },
      },
      getTitle: (fieldGroupView) => {
        const fulfillmentMethod = fieldGroupView.getValue().fulfillmentMethod;
        return app.location.getDiningOptionName(fulfillmentMethod);
      },
      getElementSchemaFields: (element, integration) => {
        return integration.getI9nSchema().diningOptionFields;
      },
      templateData: {
        title: options.schema.displayName || options.field,
        buttonText: 'new dining option',
        emptyText: 'no dining options yet',
      },
    });

    app.AccordionFieldView.prototype.initialize.apply(this, arguments);
  },
});
