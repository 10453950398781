app.OpenHoursOverrideList = app.AbstractCollection.extend({
  model: app.OpenHoursOverride,

  comparator(openHoursOverride) {
    return openHoursOverride.get('date');
  },

  url() {
    return `/api/v2/open-hours-overrides/${this.id || ''}`;
  },
});
